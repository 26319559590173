import { ReactElement } from 'react';
import { Navigate } from 'react-router-dom-v5-compat';
import { getDriverAccountToken } from 'shared/auth/AuthToken';

interface DriverAccountWrapperProps {
  children: ReactElement;
}

export function DriverAccountWrapper({ children }: DriverAccountWrapperProps) {
  const token = getDriverAccountToken();

  if (!token) {
    return <Navigate to="/driver-login" />;
  }

  return children;
}
