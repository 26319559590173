import { Fade } from '@material-ui/core';
import { ArrowDownward } from '@material-ui/icons';
import { Button } from '@superdispatch/ui-lab';
import { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const ArrowBox = styled.div`
  position: sticky;
  bottom: 100px;
  right: 0;
  display: flex;
  justify-content: flex-end;
`;

interface ScrollToBottomProps {
  wrapperElement?: HTMLElement | null;
}

export const ScrollToBottom = ({ wrapperElement }: ScrollToBottomProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isScrolledToBottom, setScrollToBottom] = useState(false);

  const listenChange = useCallback(() => {
    if (wrapperElement) {
      const { scrollTop, scrollHeight, clientHeight } = wrapperElement;
      const isBottom = scrollTop + clientHeight + 200 >= scrollHeight;
      setScrollToBottom(isBottom);
    } else {
      const isBottom =
        window.innerHeight + window.scrollY + 200 >= document.body.offsetHeight;
      setScrollToBottom(isBottom);
    }
  }, [setScrollToBottom, wrapperElement]);

  useEffect(() => {
    const container = wrapperElement;
    if (container) {
      container.addEventListener('scroll', listenChange);
    } else {
      window.addEventListener('scroll', listenChange);
    }

    return () => {
      container?.removeEventListener('scroll', listenChange);
      window.removeEventListener('scroll', listenChange);
    };
  }, [listenChange, wrapperElement]);

  const handleScroll = () => {
    ref.current?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    listenChange();
  }, [listenChange]);

  return (
    <>
      <Fade in={!isScrolledToBottom}>
        <ArrowBox>
          <Button onClick={handleScroll}>
            <ArrowDownward />
          </Button>
        </ArrowBox>
      </Fade>

      <div ref={ref} />
    </>
  );
};
