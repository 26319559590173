import { Typography } from '@material-ui/core';
import { Color, Stack } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { useFormikContext } from 'formik';
import { Geocoding } from 'shared/geo/GeoHelpers';
import { useBoolean } from 'shared/hooks/useBoolean';
import { joinStrings } from 'shared/utils/StringUtils';
import styled from 'styled-components';
import { trackTripsEvent } from '../data/TripsAnalytics';
import {
  useAddTripRoute,
  useDeleteTripRoute,
  useUpdateTripRoute,
} from '../data/TripsAPI';
import { TripFormDTO } from '../data/TripsDTO';
import { FormikVenueAddressAutofill } from './TripFormikVenueAddressAutofill';
import { TripLoadFieldArray } from './TripLoadFieldArray';

const Wrapper = styled.div`
  min-width: 400px;
  display: flex;
  flex-direction: column;
  flex: 1;
  border-right: 1px solid ${Color.Silver400};
`;

const FormBox = styled.div`
  flex: 3;
  padding: 32px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const BannerWrapper = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 16px;
`;

interface TripEditRouteFormProps {
  tripGuid: string;
  driverGuid?: string;
}

export const TripEditRouteForm = ({
  tripGuid,
  driverGuid,
}: TripEditRouteFormProps) => {
  const dismissBanner = useBoolean(true);
  const formik = useFormikContext<TripFormDTO>();
  const { start_address_guid, end_address_guid } = formik.values;

  const updateRoute = useUpdateTripRoute();
  const addRoute = useAddTripRoute({
    onSuccess: (response, [data, geocode]) => {
      const addressGuid =
        data.type === 'destination' ? 'end_address_guid' : 'start_address_guid';

      const address =
        data.type === 'destination' ? 'end_address' : 'start_address';

      trackTripsEvent({
        name: 'Carrier Added Route to Trip',
        direction: data.type,
      });

      void formik.setValues((values) => ({
        ...values,
        [addressGuid]: response.data.point_guid,
        [address]: geocode,
      }));
    },
  });
  const deleteRoute = useDeleteTripRoute();

  const handleSelect = (value: Geocoding | null, name: string) => {
    const isStartName = name === 'start_address';
    const addressGuid = isStartName ? start_address_guid : end_address_guid;
    const routeType = isStartName ? 'origin' : 'destination';

    if (value) {
      if (addressGuid) {
        updateRoute.mutate({
          guid: addressGuid,
          data: getData(value),
          tripGUID: tripGuid,
        });
      } else {
        addRoute.mutate([
          {
            ...getData(value),
            trip_guid: tripGuid,
            type: routeType,
          },
          value,
        ]);
      }
    } else {
      const guid = isStartName ? start_address_guid : end_address_guid;
      deleteRoute.mutate(guid as string);
    }
  };

  return (
    <Wrapper>
      <FormBox>
        <Stack space="small">
          <Typography color="textSecondary">
            By default first and last loads will be considered as start and end
            of the trip
          </Typography>
          <FormikVenueAddressAutofill
            fullWidth={true}
            name="start_address"
            startText="Start"
            onSelect={handleSelect}
          />

          <TripLoadFieldArray driverGuid={driverGuid} tripGuid={tripGuid} />

          <FormikVenueAddressAutofill
            fullWidth={true}
            name="end_address"
            startText="End"
            onSelect={handleSelect}
          />
        </Stack>
      </FormBox>
      {dismissBanner.isEnabled && (
        <BannerWrapper>
          <Box
            width="100%"
            borderRadius="medium"
            backgroundColor="Blue50"
            padding="small"
          >
            <Stack space="small">
              <Stack space="xxsmall">
                <Typography variant="body1">Auto-Save</Typography>
                <Typography>
                  Your changes will be automatically saved.
                </Typography>
              </Stack>
              <Button
                onClick={dismissBanner.setFalse}
                fullWidth={true}
                variant="neutral"
              >
                Got it
              </Button>
            </Stack>
          </Box>
        </BannerWrapper>
      )}
    </Wrapper>
  );
};

function getData(value: Geocoding) {
  return {
    address:
      joinStrings(' ', value.house_number, value.address) ||
      value.place_name ||
      '',
    city: value.place || value.region || '',
    state: value.region_short || (value.region as string),
    zip: value.postcode || null,
  };
}
