import { useFlag } from 'shared/settings/FeatureToggles';
import { useBankNameByRoutingNumber } from './MoovAPI';
import { useMoovAPI } from './MoovAPILegacy';

export function useSuperPayBankNameAction() {
  const isMoovRefactoring = useFlag('payments_moovjs_refactoring');

  const { mutateAsync: getBankNameByRoutingNumber } =
    useBankNameByRoutingNumber();
  const { getBankNameByRoutingNumber: getBankNameByRoutingNumberLegacy } =
    useMoovAPI();

  if (isMoovRefactoring) {
    return getBankNameByRoutingNumber;
  }

  return getBankNameByRoutingNumberLegacy;
}
