import { Typography } from '@material-ui/core';
import {
  FormikTextField,
  SuspendedFormikPhoneField,
  useFormikEnhanced,
} from '@superdispatch/forms';
import {
  Column,
  Columns,
  Inline,
  Stack,
  useSnackbarStack,
} from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { useState } from 'react';
import { createAPIError } from 'shared/api/APIError';
import { trackDriversEvent } from '../../../drivers/data/DriversAnalytics';
import {
  PublicDriverAcceptDriverResponseDTO,
  publicDriverInviteDriverDTO,
  PublicOfferAssignResponseDTO,
  PublicOfferDTO,
  PublicOfferInviteDriverDTO,
  PublicOfferInviteResponseDTO,
} from '../data/PublicOfferDTO';
import { usePublicOfferAPI } from '../data/PublicOffersAPI';

const JoinRequestAlreadySentError = 'JoinRequestAlreadySent';
const InviteAlreadySentError = 'InviteAlreadySent';
const ConnectionAlreadyExistsError = 'ConnectionAlreadyExists';

interface PublicOfferInviteDriverFormProps {
  token: string;
  offer: PublicOfferDTO;
  onCancel: () => void;
  onSubmitSuccess: (
    driver: PublicOfferInviteResponseDTO | PublicDriverAcceptDriverResponseDTO,
    response: PublicOfferAssignResponseDTO,
  ) => void;
}

export function PublicOfferInviteDriverForm({
  token,
  offer,
  onCancel,
  onSubmitSuccess,
}: PublicOfferInviteDriverFormProps) {
  const { addSnackbar } = useSnackbarStack();
  const [pendingDriver, setPendingDriver] = useState<{
    guid: string;
    type: string;
  }>();

  const { inviteDriver, assignDriver, acceptJoinRequest } = usePublicOfferAPI();

  const formik = useFormikEnhanced<
    PublicOfferInviteDriverDTO,
    {
      driver:
        | PublicOfferInviteResponseDTO
        | PublicDriverAcceptDriverResponseDTO;
      response: PublicOfferAssignResponseDTO;
    }
  >({
    validationSchema: publicDriverInviteDriverDTO,
    initialValues: {
      email: '',
      phone_number: '',
      name: '',
    },
    onSubmit: async (values) => {
      let driver: PublicOfferInviteResponseDTO;

      if (
        pendingDriver?.type === InviteAlreadySentError ||
        pendingDriver?.type === ConnectionAlreadyExistsError
      ) {
        return assignDriver(
          token,
          offer.guid,
          pendingDriver.guid,
          true,
          !!offer.is_first_offer,
        ).then((response) => ({ driver, response }));
      }

      if (pendingDriver?.type === JoinRequestAlreadySentError) {
        driver = await acceptJoinRequest(pendingDriver.guid, values.name);
        trackDriversEvent({
          name: 'Carrier Accepted Join Request',
          driverGuid: driver.guid,
        });
      } else {
        driver = await inviteDriver(token, values);
        if (offer.user) {
          trackDriversEvent({
            name: 'CTMS: Driver Invited',
            properties: {
              guid: driver.guid,
              userGuid: offer.user.guid,
              source: 'Assign Offer Page',
            },
          });
        }
      }

      return assignDriver(
        token,
        offer.guid,
        driver.guid,
        true,
        !!offer.is_first_offer,
      ).then((response) => ({ driver, response }));
    },
    onSubmitSuccess: ({ driver, response }) => {
      trackDriversEvent({
        name: 'Carrier Assigned Driver',
      });
      onSubmitSuccess(driver, response);
    },
    onSubmitFailure(error) {
      const errorCtx = createAPIError<{ guid: string }>(error);
      const guid = errorCtx.context?.guid;
      const { type } = errorCtx;

      if (
        (type === InviteAlreadySentError ||
          type === ConnectionAlreadyExistsError ||
          type === JoinRequestAlreadySentError) &&
        !!guid
      ) {
        setPendingDriver({
          guid,
          type,
        });
      } else {
        addSnackbar(error.message || 'Failed to invite driver');
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <Stack align="center" space="medium">
          <Typography align="center" variant="h2">
            Invite Driver
          </Typography>
          <Box width="400px" marginTop="small" marginBottom="small">
            <Stack space="small">
              <FormikTextField
                name="name"
                label={
                  <Inline space="xxsmall">
                    Full Name
                    <Typography color="textSecondary">(Optional)</Typography>
                  </Inline>
                }
                fullWidth={true}
              />

              <FormikTextField
                name="email"
                label="Email"
                fullWidth={true}
                placeholder="Type driver’s email"
              />
              <SuspendedFormikPhoneField
                fullWidth={true}
                name="phone_number"
                label="Phone Number"
              />
              <Typography color="textSecondary">
                The driver will receive email and text message with the link to
                download Super Dispatch Mobile app.
              </Typography>

              {!!pendingDriver?.type && (
                <Box
                  paddingTop="xxsmall"
                  paddingBottom="xxsmall"
                  paddingLeft="xsmall"
                  paddingRight="xsmall"
                  borderRadius="small"
                  backgroundColor="Blue50"
                >
                  <Typography>
                    {formatErrorContent(pendingDriver.type)}
                  </Typography>
                </Box>
              )}
            </Stack>
          </Box>
          <Box width="400px">
            <Columns space="small">
              <Column width="1/2">
                <Button
                  disabled={formik.isSubmitting}
                  size="large"
                  fullWidth={true}
                  variant="neutral"
                  onClick={onCancel}
                >
                  Cancel
                </Button>
              </Column>
              <Column width="1/2">
                <Button
                  size="large"
                  fullWidth={true}
                  type="submit"
                  pending={formik.isSubmitting}
                >
                  {formatErrorText(pendingDriver?.type)}
                </Button>
              </Column>
            </Columns>
          </Box>
        </Stack>
      </Form>
    </FormikProvider>
  );
}

function formatErrorContent(type: string) {
  switch (type) {
    case JoinRequestAlreadySentError:
      return 'This driver has already requested to join your carrier. Would you like to accept the driver’s request and assign the load?';
    case InviteAlreadySentError:
      return 'This driver has already been invited but hasn’t accepted the invitation yet. They will access the load once they accept your invite.';
    case ConnectionAlreadyExistsError:
      return 'This driver has already joined your carrier. Would you like to assign the load to them?';
    default:
      return '';
  }
}

function formatErrorText(type?: string) {
  if (!type) {
    return 'Invite & Assign';
  }
  switch (type) {
    case JoinRequestAlreadySentError:
      return 'Accept & Assign';
    case InviteAlreadySentError:
      return 'Assign Anyway';
    case ConnectionAlreadyExistsError:
      return 'Assign';
    default:
      return 'Invite & Assign';
  }
}
