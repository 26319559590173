import { useEffect, useState } from 'react';
import { useErrorSnackbar } from 'shared/hooks/useErrorSnackbar';
import { useFlag } from 'shared/settings/FeatureToggles';
import { useGenerateMoovToken } from './MoovAccountInfoAPILegacy';
import { MoovAccountInfoLegacy } from './MoovAccountInfoLegacy';

export function useMoovAccountInfoLegacy(): MoovAccountInfoLegacy & {
  isLoading: boolean;
} {
  const isMoovRefactoring = useFlag('payments_moovjs_refactoring');
  const [, update] = useState(false);
  const { error, isLoading, mutateAsync } = useGenerateMoovToken();

  const moovAccountInfo = MoovAccountInfoLegacy.initialize(
    'account',
    (scope) => {
      return isMoovRefactoring
        ? Promise.resolve({
            access_token: '',
            moov_account_id: '',
            moov_bank_account_id: '',
          })
        : mutateAsync(scope).then(({ data }) => data);
    },
  );

  useErrorSnackbar(error, {
    formatError: (e: Error) => e.message || 'Failed to authorize',
  });

  useEffect(
    () =>
      moovAccountInfo.subscribeToAccountDataChange(() => {
        update((state) => !state);
      }),
    [moovAccountInfo],
  );

  return Object.assign(moovAccountInfo, { isLoading });
}
