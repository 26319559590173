import { Typography } from '@material-ui/core';
import { Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { DeclinedEnvelopIcon } from 'shared/icons/DeclinedEnvelopIcon';

export function DriverRequestDeclinedContent() {
  return (
    <Box
      width="100%"
      maxWidth="600px"
      marginTop="large"
      padding="large"
      backgroundColor="White"
      borderWidth="small"
      borderRadius="medium"
      borderColor="Silver400"
    >
      <Box paddingTop="xsmall" paddingBottom="xsmall">
        <Stack align="center">
          <DeclinedEnvelopIcon />
          <Box width="360px">
            <Typography align="center" variant="h2">
              You have declined the invitation.
            </Typography>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
}
