import {
  AccountBalanceWallet,
  Business,
  ExitToApp,
  Help,
  Info,
  Person,
  Settings,
} from '@material-ui/icons';
import { useDeepEqualValue } from '@superdispatch/hooks';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom-v5-compat';
import { MobileAppBridge } from 'shared/data/MobileAppBridge';
import { trackEvent } from 'shared/helpers/Analytics';
import { DispatcherIcon } from 'shared/icons/DispatcherIcon';
import { OutlinedWeakPasswordIcon } from 'shared/icons/OutlinedWeakPasswordIcon';
import { CarrierMenuItemsLegacy } from 'shared/layout/sidebar/AppCarrierMenuLegacy';
import { useDispatcherProfile } from 'shared/modules/dispatcher/DispatcherAPI';
import { useExperimentState } from 'shared/modules/experiments/ExperimentsAPI';
import { useOnboardingDialogState } from 'shared/modules/onboarding/core/OnboardingWrapper';
import { useShowAddBillingAddressDialog } from 'shared/modules/subscription/core/useShowAddBillingAddressDialog';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';
import { useFlag } from 'shared/settings/FeatureToggles';
import { SidebarLinkLegacy } from './AppSidebarMenuLegacy';
import { useDispatcherProfileVisitedValue } from './IsDispatcherProfileVisited';

interface CarrierMenuLegacyProps {
  localPaths?: {
    dashboard?: string;
    loads?: string;
    contacts?: string;
    drivers?: string;
    trips?: string;
    tracking?: string;
    loadboard?: string;
    ratings?: string;
    dispatcherProfile?: string;
    carrierProfile?: string;
    reports?: string;
    settings?: string;
    subscriptionDetails?: string;
    logout?: string;
    dispatcherManagement?: string;
  };
}

export function useCarrierMenuLegacy({
  localPaths: localPathsProp = {},
}: CarrierMenuLegacyProps) {
  const shouldShowUpdatePassword = useFlag('show_update_password');
  const shouldShowDispatcherManagement = useFlag('dispatcher_management_page');
  const isCarrierTMSOnboardingEnabled = useFlag('ctms_onboarding');

  const location = useLocation();
  const isWebview = MobileAppBridge.isInjected();
  const { data: dispatcher } = useDispatcherProfile();
  const { openOnboardingDialog } = useOnboardingDialogState();
  const localPaths = useDeepEqualValue(localPathsProp);
  const isVisitedDispatcherProfile = useDispatcherProfileVisitedValue();
  const { data: settings } = useCarrierSettings();
  const isSuperPayBankError = !!settings?.superpay.payment_error;
  const { isInExperiment } = useExperimentState('Onboarding', {
    options: { enabled: isCarrierTMSOnboardingEnabled && !isWebview },
  });
  const { doesBillingAddressNotExist } = useShowAddBillingAddressDialog();

  const isShowWeakPassword =
    shouldShowUpdatePassword &&
    !isVisitedDispatcherProfile &&
    location.pathname !== '/dispatchers/profile' &&
    !dispatcher?.p_changed_at;

  const accountItemsSection = useMemo((): SidebarLinkLegacy[] => {
    if (isInExperiment) {
      return [
        {
          key: 'getting-started',
          Icon: Help,
          label: 'Getting Started',
          href: '#',
          dataIntercomTarget: 'onboarding-menu-item',
          onClick: () => {
            openOnboardingDialog();
          },
        },
        {
          key: 'help-center',
          Icon: Info,
          label: 'Help Center',
          href: 'https://support.superdispatch.com/en/',
          target: '_blank',
          onClick: () => {
            trackEvent('CTMS: Clicked Help Center');
          },
        },
        {
          key: 'logout',
          Icon: ExitToApp,
          label: 'Log Out',
          href: '/tms/logout',
          localPath: localPaths.logout,
        },
      ];
    }

    return [
      {
        key: 'help-center',
        Icon: Info,
        label: 'Help Center',
        href: 'https://support.superdispatch.com/en/',
        target: '_blank',
        onClick: () => {
          trackEvent('CTMS: Clicked Help Center');
        },
      },
      {
        key: 'logout',
        Icon: ExitToApp,
        label: 'Log Out',
        href: '/tms/logout',
        localPath: localPaths.logout,
      },
    ];
  }, [localPaths.logout, openOnboardingDialog, isInExperiment]);

  const { ratingsLink, carrierItems, dispatcherItems, accountItems } =
    useMemo<CarrierMenuItemsLegacy>(
      () => ({
        ratingsLink: {
          key: 'view-ratings',
          label: 'View Ratings',
          href: '/tms/profile/overview',
          localPath: localPaths.ratings,
        },
        carrierItems: [
          {
            key: 'settings',
            Icon: Settings,
            label: 'Settings',
            href: '/tms/settings',
            localPath: localPaths.settings,
          },
          {
            key: 'dispatcher-management',
            Icon: DispatcherIcon,
            label: 'Dispatchers',
            localPath: localPaths.dispatcherManagement,
            href: '/tms/dispatchers?utm_medium=navigation_bar',
            hidden: !shouldShowDispatcherManagement,
          },
          {
            key: 'carrier-profile',
            Icon: Business,
            label: 'Carrier Profile',
            href: '/tms/profile',
            localPath: localPaths.carrierProfile,
            hasError: isSuperPayBankError,
          },
          {
            key: 'subscription-details',
            Icon: AccountBalanceWallet,
            label: 'Subscription Details',
            href: '/tms/settings/subscription',
            localPath: localPaths.subscriptionDetails,
            hasError: doesBillingAddressNotExist,
          },
        ],
        dispatcherItems: [
          {
            key: 'dispatcher-profile',
            Icon: Person,
            label: shouldShowDispatcherManagement
              ? 'My Profile'
              : 'Dispatcher Profile',
            href: '/tms/dispatchers/profile',
            localPath: localPaths.dispatcherProfile,
            RightIcon: isShowWeakPassword
              ? OutlinedWeakPasswordIcon
              : undefined,
          },
        ],
        accountItems: accountItemsSection,
      }),
      [
        localPaths.dispatcherManagement,
        localPaths.ratings,
        localPaths.settings,
        localPaths.carrierProfile,
        localPaths.subscriptionDetails,
        localPaths.dispatcherProfile,
        isSuperPayBankError,
        isShowWeakPassword,
        doesBillingAddressNotExist,
        shouldShowDispatcherManagement,
        accountItemsSection,
      ],
    );

  return { ratingsLink, carrierItems, dispatcherItems, accountItems };
}
