import {
  APIMutationOptions,
  APIMutationResult,
  useAPIMutation,
} from 'shared/api/APIMutation';
import { APIResponse, requestCarrierAPI } from 'shared/api/CarrierAPIClient';
import { MoovScope, MoovTokenResponse } from './MoovDTO';

export function useGenerateMoovToken(
  options?: APIMutationOptions<MoovScope, MoovTokenResponse>,
): APIMutationResult<MoovScope, MoovTokenResponse> {
  return useAPIMutation((initialScope: MoovScope) => {
    return requestCarrierAPI<APIResponse<MoovTokenResponse>>(
      'POST /internal/web/payments/generate-moov-token/',
      { json: { scope: initialScope } },
    );
  }, options);
}
