import { useEffect, useState } from 'react';
import { useMoovAPI } from '../data/MoovAPILegacy';

export function useMoovAvailable(isEnabled = false) {
  const { isAvailable } = useMoovAPI();
  const [isMoovAvailable, setIsMoovAvailable] = useState<boolean | null>(null);

  useEffect(() => {
    if (!isEnabled) {
      setIsMoovAvailable(true);
      return;
    }
    isAvailable()
      .then((value) => {
        setIsMoovAvailable(value);
      })
      .catch(() => {
        setIsMoovAvailable(false);
      });
  }, [isAvailable, isEnabled]);

  return { isMoovAvailable, isLoading: isMoovAvailable === null };
}
