import { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import {
  APIListQueryOptions,
  APIListQueryResult,
  useAPIListQuery,
} from 'shared/api/APIListQuery';
import {
  APIMutationOptions,
  APIMutationResult,
  useAPIMutation,
} from 'shared/api/APIMutation';
import { APIPageQueryResult, useAPIPageQuery } from 'shared/api/APIPageQuery';
import { APIQueryResult, useAPIQuery } from 'shared/api/APIQuery';
import { requestCarrierAPI } from 'shared/api/CarrierAPIClient';
import { requestAPIGateway } from 'shared/api/CarrierGatewayAPIClient';
import {
  CarrierRatingDTO,
  carrierRatingSchema,
  CarrierRatingsPageParams,
  CarrierRatingsSummaryDTO,
  carrierRatingsSummarySchema,
  CARRIER_RATINGS_PAGE_SIZE,
  UnratedShipperDTO,
  unratedShipperSchema,
  UnratedShippersCountDTO,
  unratedShippersCountSchema,
} from './CarrierRatingsDTO';

export function useCarrierRatingsSummary(): APIQueryResult<CarrierRatingsSummaryDTO> {
  return useAPIQuery(
    ['ratings', 'summary'],
    () => requestCarrierAPI('GET /internal/web/carrier/ratings/summary/'),
    { schema: carrierRatingsSummarySchema },
  );
}

export function useCarrierRatings({
  ratings_page: page,
  ratings_page_size: page_size,
}: CarrierRatingsPageParams): APIPageQueryResult<CarrierRatingDTO> {
  const params = { page, page_size };

  return useAPIPageQuery(
    ['ratings', 'list', { params }],
    () =>
      requestCarrierAPI([
        'GET /internal/web/carrier/ratings/{?params*}',
        { params },
      ]),
    { schema: carrierRatingSchema },
  );
}

export function useCarrierRatingsAPI() {
  const queryClient = useQueryClient();

  return useMemo(
    () => ({
      respondRating: (ratingGuid: string, data: { response: string }) =>
        requestCarrierAPI(
          [
            'PATCH /internal/web/carrier/ratings/{ratingGuid}/rating-response/',
            { ratingGuid },
          ],
          { json: data },
        ).then((response) => {
          void queryClient.invalidateQueries(['ratings']);
          return response;
        }),
    }),
    [queryClient],
  );
}

export function useDeleteRatingResponse(
  options?: APIMutationOptions<unknown>,
): APIMutationResult<unknown> {
  const queryClient = useQueryClient();

  return useAPIMutation(
    (ratingGuid) =>
      requestCarrierAPI([
        'DELETE /internal/web/carrier/ratings/{ratingGuid}/rating-response/',
        { ratingGuid },
      ]).then((response) => {
        void queryClient.invalidateQueries(['ratings']);
        return response;
      }),
    options,
  );
}

export function useUnratedShippers(
  options?: APIListQueryOptions<UnratedShipperDTO>,
): APIListQueryResult<UnratedShipperDTO> {
  return useAPIListQuery(
    ['ratings', 'unrated-shippers'],
    (page) =>
      requestAPIGateway([
        'GET /loadboard/shippers/unrated{?page,size}',
        { page, size: CARRIER_RATINGS_PAGE_SIZE },
      ]),
    { schema: unratedShipperSchema, ...options },
  );
}

export function useUnratedShippersCount(): APIQueryResult<UnratedShippersCountDTO> {
  return useAPIQuery(
    ['ratings', 'unrated-shippers-count'],
    () => requestAPIGateway('GET /loadboard/shippers/unrated/count'),
    { schema: unratedShippersCountSchema },
  );
}
