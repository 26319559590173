import {
  StandardTextFieldProps,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { ErrorRounded } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import {
  Color,
  Column,
  Columns,
  Inline,
  OverflowText,
  Stack,
  Tag,
} from '@superdispatch/ui';
import { useField } from 'formik';
import { useSetAtom } from 'jotai';
import { forwardRef, ReactNode, useMemo, useState } from 'react';
import { useMapValue } from 'shared/form/LeafletMap';
import { formatPlural } from 'shared/helpers/IntlHelpers';
import { useDebouncedValue } from 'shared/helpers/ReactHelpers';
import { DragIcon } from 'shared/icons/DragIcon';
import { joinStrings } from 'shared/utils/StringUtils';
import styled from 'styled-components';
import { TripLoadOrderWaypoints } from '../core/TripLoadOrderWayPoints';
import { useTripLoadsList } from '../data/TripsAPI';
import { LoadForTripDTO, TripLoadDTO, TripVehicleDTO } from '../data/TripsDTO';
import { hoveredFieldNameAtom } from '../TripsDetails';

interface OrderFieldCommonProps {
  TextFieldProps?: StandardTextFieldProps;
}

const StartIcon = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 8px;
`;

const OptionBox = styled.div`
  flex: 1;
  overflow: hidden;
`;

interface TripFormLoadProps
  extends Omit<
    OrderFieldCommonProps,
    'options' | 'onChange' | 'getOptionLabel'
  > {
  id: number;
  name: string;
  guid?: string;
  disabled?: boolean;
  driverGuid?: string;
  loadNumber?: string;
  startIcon?: ReactNode;
  onSelect?: (load: LoadForTripDTO) => void;
}

export const TripFormLoad = forwardRef(
  (
    {
      id,
      guid,
      name,
      onSelect,
      disabled,
      startIcon,
      loadNumber,
      driverGuid,
      TextFieldProps,
      ...props
    }: TripFormLoadProps,
    ref,
  ) => {
    const map = useMapValue();
    const setHoveredFieldName = useSetAtom(hoveredFieldNameAtom);
    const [field, { touched, error }, { setValue, setError }] =
      useField<LoadForTripDTO>({
        name,
      });
    const [loadsField] = useField<TripLoadDTO[]>({ name: 'loads' });
    const [_loadGuidsField, { error: errorGuid }] = useField<string[]>({
      name: `load_guids`,
    });
    const [search, setSearch] = useState(loadNumber);
    const query = useDebouncedValue(search, 500);

    const { data, isFetching } = useTripLoadsList({
      q: query,
      page: 1,
      trip_guid: guid,
      driver_guid: driverGuid,
    });

    const doesLoadExistsError = errorGuid === field.value.guid;
    const errorMessage = touched && (error || doesLoadExistsError);

    const options = useMemo(() => {
      const loads = data?.pages ? data.pages.flatMap((page) => page.data) : [];
      const formikLoads = loadsField.value;
      return loads.filter(
        (load) =>
          !formikLoads.find((formikLoad) => formikLoad.guid === load.guid),
      );
    }, [data, loadsField.value]);

    return (
      <Columns align="center" space="xsmall">
        <Column width="content">
          {error || doesLoadExistsError ? (
            <Tooltip placement="top" title={typeof error === 'string' && error}>
              <ErrorRounded htmlColor={Color.Red500} />
            </Tooltip>
          ) : (
            <DragIcon data-intercom-target="trip-load-drag-icon" />
          )}
        </Column>
        <Column width="fluid">
          <Autocomplete
            {...props}
            ref={ref}
            freeSolo={true}
            options={options}
            value={field.value}
            disabled={disabled}
            loading={isFetching}
            aria-label="load-list"
            disableClearable={true}
            getOptionSelected={(option, value) => option.guid === value.guid}
            getOptionLabel={(option) => option.load_id || option.number || ''}
            getOptionDisabled={(option) =>
              !option.pickup_latitude || !option.pickup_longitude
            }
            onMouseLeave={() => {
              setHoveredFieldName('');
            }}
            onMouseOver={() => {
              setHoveredFieldName(name);
            }}
            onChange={(_, selectedValue: LoadForTripDTO) => {
              if (
                selectedValue.pickup_latitude &&
                selectedValue.pickup_longitude
              ) {
                map?.flyTo(
                  [
                    selectedValue.pickup_latitude,
                    selectedValue.pickup_longitude,
                  ],
                  5,
                );
                void setValue(selectedValue);
                onSelect?.(selectedValue);
              } else {
                setError(
                  "Can't add the load with incomplete or unrecognized address",
                );
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                {...TextFieldProps}
                data-start-icon={String(!!startIcon)}
                placeholder="Select the load to add to the trip"
                onChange={(event) => {
                  setSearch(event.target.value);
                }}
                InputProps={{
                  ...TextFieldProps?.InputProps,
                  ...params.InputProps,
                  startAdornment: (
                    <>
                      {startIcon && <StartIcon>{startIcon}</StartIcon>}
                      {params.InputProps.startAdornment}
                    </>
                  ),
                }}
                variant="outlined"
                fullWidth={true}
                error={!!errorMessage}
              />
            )}
            renderOption={(option) => {
              return (
                <Tooltip
                  key={option.guid}
                  placement="right"
                  title={
                    !option.pickup_latitude || !option.pickup_longitude
                      ? "Can't add the load with incomplete or unrecognized address"
                      : ''
                  }
                >
                  <OptionBox>
                    <Stack>
                      <Columns>
                        <Column width="fluid">
                          <Columns space="xxsmall" align="center">
                            <Column width="content">
                              <Typography color="textSecondary" variant="h6">
                                LOAD ID
                              </Typography>
                            </Column>
                            <Column width="adaptive">
                              <OverflowText
                                gutterBottom={false}
                                disableUnderline={true}
                                TooltipProps={{
                                  placement: 'top',
                                  interactive: true,
                                }}
                              >
                                {option.load_id}
                              </OverflowText>
                            </Column>
                          </Columns>
                        </Column>

                        <Column width="content">
                          <Inline horizontalAlign="right" space="xsmall">
                            <Tag
                              variant="subtle"
                              color={getTagColor(option.status)}
                            >
                              {getTagText(option.status)}
                            </Tag>
                          </Inline>
                        </Column>
                      </Columns>

                      {option.vehicles &&
                        option.vehicles.length > 0 &&
                        getVehicles(option.vehicles)}
                      <Stack>
                        <TripLoadOrderWaypoints load={option} />
                      </Stack>
                    </Stack>
                  </OptionBox>
                </Tooltip>
              );
            }}
          />
        </Column>
      </Columns>
    );
  },
);

function getVehicles(vehicles: TripVehicleDTO[]) {
  if (vehicles.length === 1) {
    const { year, make, model } = vehicles[0] as TripVehicleDTO;
    return (
      <Typography variant="h5">
        {joinStrings(' ', year, make, model)}
      </Typography>
    );
  }
  return (
    <Typography variant="h5">
      {vehicles.length} {formatPlural(vehicles.length, 'vehicle', 'vehicles')}
    </Typography>
  );
}

function getTagColor(status: string) {
  switch (status) {
    case 'new':
      return 'teal';
    case 'picked_up':
      return 'yellow';
    default:
      return 'green';
  }
}

function getTagText(status: string) {
  switch (status) {
    case 'new':
      return 'New';
    case 'picked_up':
      return 'Picked up';
    case 'delivered':
      return 'Delivered';
    default:
      return status;
  }
}
