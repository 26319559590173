import { LocalStore } from 'shared/helpers/Store';
import {
  yupArray,
  yupBoolean,
  yupDateString,
  yupEnum,
  yupNumber,
  yupNumberParam,
  yupObject,
  yupString,
  yupStringParam,
} from 'shared/utils/YupUtils';
import { InferType } from 'yup';
import {
  ATTACHMENT_FILE_TYPES,
  formatLoadStage,
  LoadStage,
  LoadStatus,
  LOAD_STATUSES,
} from '../../loads/data/LoadDTO';

export const ORDER_BY_MODIFIED_DATE_STORAGE_KEY =
  'trips_order_by_modified_date';

export type BaseVenueDTO = InferType<typeof baseVenueSchema>;
const baseVenueSchema = yupObject({
  guid: yupString().nullable(),
  latitude: yupNumber().nullable(),
  longitude: yupNumber().nullable(),
  type: yupEnum(['origin', 'destination']).nullable(),
});

export type VenueDTO = InferType<typeof venueSchema>;
const venueSchema = baseVenueSchema.shape({
  city: yupString(),
  state: yupString(),
  zip: yupString(),
  address: yupString(),
});

export type EditTripDTO = InferType<typeof editTripSchema>;
export const editTripSchema = yupObject({
  name: yupString().required('Please, input trip name').max(100),
  driver_guid: yupString().optional(),
  load_guids: yupArray(yupString()).optional(),
  route: yupObject({
    origin: venueSchema.nullable(),
    destination: venueSchema.nullable(),
  }).optional(),
});

export type TripLoadDTO = InferType<typeof tripLoadSchema>;
export const tripLoadSchema = yupObject({
  guid: yupString(),
  number: yupString(),
  status: yupEnum(LOAD_STATUSES),
  customer_name: yupString(),
  broker_fee: yupString(),

  load_order: yupNumber(),

  pickup: yupObject({
    address: yupString(),
    date: yupDateString('DateTimeISO'),
    scheduled_date: yupDateString('DateTimeISO'),
    latitude: yupNumber(),
    longitude: yupNumber(),
  }),

  delivery: yupObject({
    address: yupString(),
    date: yupDateString('DateTimeISO'),
    scheduled_date: yupDateString('DateTimeISO'),
    latitude: yupNumber(),
    longitude: yupNumber(),
  }),

  payment: yupObject({
    amount: yupString(), // BigDecimal
    term: yupString(),
  }),

  driver: yupObject({
    guid: yupString(),
    name: yupString(),
  }).nullable(),

  vehicles: yupArray(
    yupObject({
      year: yupNumber(),
      maker: yupString(),
      model: yupString(),
    }),
  ),
  terminals: yupArray(
    yupObject({
      guid: yupString(),
      name: yupString(),
      is_current: yupBoolean(),
      is_delivered: yupBoolean(),
    }),
  ).defined(),
});

export type TripDriverDTO = InferType<typeof tripDriverSchema>;
export const tripDriverSchema = yupObject({
  guid: yupString(),
  name: yupString(),
  email: yupString(),
  phone: yupString(),
});

export type TripAttachmentDTO = InferType<typeof tripAttachmentSchema>;
export const tripAttachmentSchema = yupObject({
  guid: yupString(),
  type: yupEnum(ATTACHMENT_FILE_TYPES, 'any'),
  name: yupString(),
  url: yupString().required('Wait for upload(s) to finish successfully'),
});

export const internalNoteTextSchema = yupObject({
  text: yupString().max(500),
});

export type TripInternalNoteDTO = InferType<typeof tripInternalNoteItemSchema>;
export const tripInternalNoteItemSchema = internalNoteTextSchema.shape({
  guid: yupString().nullable(false),
  created_at: yupDateString('DateTimeISO'),
  author: yupObject({ name: yupString() }),
});

export type TripDTO = InferType<typeof tripSchema>;
export const tripSchema = yupObject({
  guid: yupString(),
  name: yupString(),
  archived: yupBoolean(),
  modified_date: yupDateString('DateTimeISO'),

  total_price: yupString(), // BigDecimal
  expenses_sum: yupString(), // BigDecimal
  broker_fee_sum: yupString(), // BigDecimal
  driver_pay_sum: yupString(), // BigDecimal

  map_route_url: yupString().nullable(),

  driver: tripDriverSchema.nullable(),

  loads: yupArray(tripLoadSchema),

  vehicles_count: yupNumber(),
  vehicles_weight: yupNumber(),
  attachments: yupArray(tripAttachmentSchema).optional(),
  internal_notes: yupArray(tripInternalNoteItemSchema).optional(),
  route: yupObject({
    origin: venueSchema.nullable(),
    destination: venueSchema.nullable(),
  }).optional(),
});

export type TripShortDTO = InferType<typeof tripShortSchema>;
export const tripShortSchema = yupObject({
  guid: yupString(),
  name: yupString(),
  archived: yupBoolean(),
  changed_at: yupDateString('DateTimeISO'),

  driver: yupObject({
    guid: yupString(),
    name: yupString(),
    email: yupString(),
  }).nullable(),

  loads: yupObject({
    total: yupNumber(),
    new: yupNumber(),
    delivered: yupNumber(),
    picked_up: yupNumber(),
  }),
});

export type TripVehicleDTO = InferType<typeof tripVehicleSchema>;
const tripVehicleSchema = yupObject({
  is_inoperable: yupBoolean(),
  make: yupString().nullable(),
  model: yupString(),
  price: yupString(),
  vin: yupString(),
  year: yupNumber(),
  guid: yupString(),
});

export type LoadForTripDTO = InferType<typeof loadForTripSchema>;
export const loadForTripSchema = yupObject({
  id: yupNumber(),
  guid: yupString(),
  load_order: yupNumber(),
  load_id: yupString(),
  number: yupString(),
  status: yupEnum(LOAD_STATUSES),
  pickup_phone: yupString(),
  pickup_contact: yupString(),
  delivery_phone: yupString(),
  delivery_contact: yupString(),
  delivery_latitude: yupNumber(),
  delivery_longitude: yupNumber(),
  pickup_latitude: yupNumber(),
  pickup_longitude: yupNumber(),
  origin: yupString(),
  origin_date: yupString(),
  destination: yupString(),
  destination_date: yupString(),
  customer: yupString(),
  payment: yupString(),
  broker_fee: yupString(),
  driver_name: yupString(),
  driver_id: yupNumber(),
  is_active: yupBoolean(),
  has_inoperable_vehicle: yupBoolean(),
  vehicles: yupArray(tripVehicleSchema),
});

export type TripsPageStatusParam = (typeof TRIPS_PAGE_STATUS_PARAMS)[number];
export const TRIPS_PAGE_STATUS_PARAMS = ['archived', 'unarchived'] as const;

export type TripsPageOrderingParam =
  (typeof TRIPS_PAGE_ORDERING_PARAMS)[number];
export const TRIPS_PAGE_ORDERING_PARAMS = [
  'changed_at',
  '-changed_at',
] as const;

export type TripsSearchType = TripsPageParams['search_type'];
export type TripsPageParams = InferType<typeof tripsPageParamsSchema>;
export const tripsPageParamsSchema = yupObject({
  page: yupNumberParam().default(1).required(),
  page_size: yupNumberParam().default(25).required(),
  query: yupStringParam().ensure(),
  search_type: yupEnum(['trip', 'driver'], 'trip'),
  status: yupEnum(TRIPS_PAGE_STATUS_PARAMS, 'unarchived'),
  ordering: yupEnum(TRIPS_PAGE_ORDERING_PARAMS).default(
    getDefaultTripLoadsOrdering,
  ),
});

export interface ReorderTripLoadDTO {
  load_guid: string;
  load_order: number;
}

export function formatLoadStatusList(loads: TripLoadDTO[]) {
  const total = loads.length;
  return getStatusesCounts(loads)
    .map(([status, count]) => formatLoadStatusCount(status, count, total))
    .join(', ');
}

export function formatLoadStatusCount(
  status: string,
  count: number,
  total: number,
) {
  return `${total === count ? 'All' : count} ${formatLoadStage(
    status as LoadStage,
  )}`;
}

export function getStatusesCounts(
  loads: TripLoadDTO[],
): Array<[LoadStatus, number]> {
  const statusesCounts = new Map<LoadStatus, number>();

  for (const { status } of loads) {
    let count = statusesCounts.get(status) || 0;
    statusesCounts.set(status, count + 1);
  }

  return Array.from(statusesCounts);
}

export function getDefaultTripLoadsOrdering(): TripsPageOrderingParam {
  return (LocalStore.get(ORDER_BY_MODIFIED_DATE_STORAGE_KEY) ||
    'changed_at') as TripsPageOrderingParam;
}

export function setDefaultTripLoadsOrdering(ordering: TripsPageOrderingParam) {
  LocalStore.set(ORDER_BY_MODIFIED_DATE_STORAGE_KEY, ordering);
}

export type AddressVenueDTO = InferType<typeof addressVenueSchema>;
export const addressVenueSchema = yupObject({
  city: yupString(),
  state: yupString(),
  address: yupString(),
  zip: yupString().nullable(),
});

export type AddressDTO = InferType<typeof addressSchema>;
const addressSchema = addressVenueSchema.shape({
  latitude: yupNumber(),
  longitude: yupNumber(),
});

export type CreateTripLoadDTO = InferType<typeof createTripLoadSchema>;
export const createTripLoadSchema = yupObject({
  guid: yupString(),
  load_id: yupString().required(),
  pickup_latitude: yupNumber().nullable(),
  pickup_longitude: yupNumber().nullable(),
  delivery_latitude: yupNumber().nullable(),
  delivery_longitude: yupNumber().nullable(),
  origin: yupString(),
  destination: yupString(),
});

export type TripFormDTO = InferType<typeof tripFormSchema>;
export const tripFormSchema = yupObject({
  loads: yupArray(createTripLoadSchema.required()),
  start_address: addressSchema.nullable(),
  end_address: addressSchema.nullable(),
  start_address_guid: yupString().optional(),
  end_address_guid: yupString().optional(),
});

export const assignDriverSchema = yupObject({
  newDriverGUID: yupString().required(),
  newDriverName: yupString(),
});

export type TripCreateDTO = InferType<typeof tripCreateSchema>;
export const tripCreateSchema = tripFormSchema.shape({
  name: yupString().required('Enter trip name'),
  driver: yupObject({
    name: yupString(),
    email: yupString(),
    guid: yupString(),
  }).nullable(),
});

export type PointDTO = InferType<typeof pointSchema>;
export const pointSchema = yupObject({
  trip_guid: yupString(),
  address: yupString(),
  city: yupString(),
  state: yupString(),
  zip: yupString().nullable(),
  type: yupEnum(['origin', 'destination']),
});

export type TripsCountResponse = InferType<typeof tripsCountSchema>;
export const tripsCountSchema = yupObject({
  active: yupNumber(),
  archived: yupNumber(),
});
