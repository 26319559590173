import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { FormikContextTypeEnhanced } from '@superdispatch/forms';
import { useEventHandler } from '@superdispatch/hooks';
import { Column, Columns, useResponsiveValue } from '@superdispatch/ui';
import { Button, ButtonVariantProp, TextBox } from '@superdispatch/ui-lab';
import { Form, FormikProvider, FormikValues, useFormikContext } from 'formik';
import { noop } from 'lodash-es';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styled from 'styled-components';

interface FormikDialogContext {
  onClose: () => void;
  setIsSubmitting: (value: boolean) => void;
}

const Context = createContext<FormikDialogContext>({
  onClose: noop,
  setIsSubmitting: noop,
});

export function useFormikDialogContext(): FormikDialogContext {
  return useContext(Context);
}

const StyledForm = styled(Form)`
  display: contents;
`;

interface FormikDialogContentProps {
  title: ReactNode;
  actions: ReactNode;
  children?: ReactNode;
  formik: FormikContextTypeEnhanced<FormikValues, unknown>;
}

export function FormikDialogContent({
  title,
  actions,
  children,
  formik,
}: FormikDialogContentProps) {
  const { setIsSubmitting, onClose } = useFormikDialogContext();

  useEffect(() => {
    setIsSubmitting(formik.isSubmitting);
  }, [setIsSubmitting, formik.isSubmitting]);

  return (
    <FormikProvider value={formik}>
      <StyledForm>
        <DialogTitle disableTypography={true}>
          <Columns>
            <Column>
              <TextBox variant="heading-3">{title}</TextBox>
            </Column>

            <Column width="content">
              <IconButton size="small" onClick={onClose}>
                <Close />
              </IconButton>
            </Column>
          </Columns>
        </DialogTitle>

        <DialogContent dividers={true}>{children}</DialogContent>

        {actions}
      </StyledForm>
    </FormikProvider>
  );
}

interface FormikDialogActionsProps {
  text: ReactNode;
  secondaryText?: ReactNode;
  variant?: ButtonVariantProp;
}

export function FormikDialogActions({
  text,
  secondaryText = 'Cancel',
  variant = 'primary',
}: FormikDialogActionsProps) {
  const { onClose } = useFormikDialogContext();
  const { isSubmitting } = useFormikContext();
  const isMobile = useResponsiveValue(true, false);

  return (
    <DialogActions>
      <Button
        size={isMobile ? 'medium' : 'large'}
        variant="neutral"
        onClick={onClose}
        disabled={isSubmitting}
      >
        {secondaryText}
      </Button>

      <Button
        size={isMobile ? 'medium' : 'large'}
        type="submit"
        variant={variant}
        autoFocus={true}
        pending={isSubmitting}
      >
        {text}
      </Button>
    </DialogActions>
  );
}

interface FormikDialogProps extends Omit<DialogProps, 'onClose'> {
  onClose: () => void;
}

export function FormikDialog({
  open,
  children,
  onClose: onCloseProp,
  ...props
}: FormikDialogProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleClose = useEventHandler(() => {
    if (!isSubmitting) {
      onCloseProp();
    }
  });

  useEffect(() => {
    if (!open) setIsSubmitting(false);
  }, [open]);

  const ctx = useMemo(
    () => ({
      setIsSubmitting,
      onClose: () => {
        handleClose(undefined);
      },
    }),
    [setIsSubmitting, handleClose],
  );

  return (
    <Dialog {...props} open={open} onClose={handleClose}>
      <Context.Provider value={ctx}>{children}</Context.Provider>
    </Dialog>
  );
}
