import { useMemo } from 'react';
import { MarkerProps } from 'shared/form/LeafletMap';
import { DeliveryDownIcon } from 'shared/icons/DeliveryDownIcon';
import { OriginUpIcon } from 'shared/icons/OriginUpIcon';
import { joinStrings } from 'shared/utils/StringUtils';
import deliveryFilledImg from '../assets/delivery-filled.png';
import endPinFilledImg from '../assets/end-pin-filled.png';
import pickupFilledImg from '../assets/pickup-filled.png';
import startPinFilledImg from '../assets/start-pin-filled.png';
import { TripMarkerPopup } from '../core/TripMarkerPopup';
import { TripDTO } from './TripsDTO';
import { getMarkerIcon } from './TripsUtils';

interface TripMarkersProps {
  trip: TripDTO;
  fieldName: string;
}

export function useAddTripDetailsMarkers({
  trip,
  fieldName,
}: TripMarkersProps) {
  const markers = useMemo((): MarkerProps[] => {
    let markersArray: MarkerProps[] = [];
    const startAddress = trip.route?.origin;
    const endAddress = trip.route?.destination;

    if (trip.loads && trip.loads.length > 0) {
      for (const [index, load] of trip.loads.entries()) {
        if (load.pickup.latitude && load.pickup.longitude) {
          markersArray.push({
            name: `loads[${index}]`,
            type: 'pickup',
            position: [load.pickup.latitude, load.pickup.longitude],
            iconUrl: pickupFilledImg,
            popup: (
              <TripMarkerPopup
                icon={<OriginUpIcon />}
                caption={load.pickup.address}
                loadId={load.guid}
              />
            ),
          });
        }
        if (load.delivery.latitude && load.delivery.longitude) {
          markersArray.push({
            name: `loads[${index}]`,
            type: 'delivery',
            position: [load.delivery.latitude, load.delivery.longitude],
            iconUrl: deliveryFilledImg,
            popup: (
              <TripMarkerPopup
                icon={<DeliveryDownIcon />}
                caption={load.delivery.address}
                loadId={load.guid}
              />
            ),
          });
        }
      }
    }
    if (startAddress?.latitude && startAddress.longitude) {
      markersArray.push({
        name: 'start_address',
        type: 'pickup',
        position: [startAddress.latitude, startAddress.longitude],
        iconUrl: startPinFilledImg,
        popup: (
          <TripMarkerPopup
            icon={<OriginUpIcon />}
            caption={joinStrings(', ', startAddress.state, startAddress.zip)}
            loadId={startAddress.address}
          />
        ),
      });
    }
    if (endAddress?.latitude && endAddress.longitude) {
      markersArray.push({
        name: 'end_address',
        type: 'delivery',
        position: [endAddress.latitude, endAddress.longitude],
        iconUrl: endPinFilledImg,
        popup: (
          <TripMarkerPopup
            icon={<DeliveryDownIcon />}
            caption={joinStrings(', ', endAddress.state, endAddress.zip)}
            loadId={endAddress.address}
          />
        ),
      });
    }

    if (fieldName) {
      markersArray = markersArray.map((marker) =>
        getMarkerIcon(marker, fieldName),
      );
    }
    return markersArray;
  }, [trip, fieldName]);

  return markers;
}
