import { useEffect, useMemo } from 'react';
import { startIntercomTour } from 'shared/helpers/Intercom';
import { useFlag } from 'shared/settings/FeatureToggles';
import { useOnboardingDialogState } from './core/OnboardingWrapper';
import { useOnboardingDetails } from './data/OnboardingAPI';
import { ONBOARDING_TOURS } from './data/OnboardingConstants';
import { OnboardingStep } from './data/OnboardingDTO';
import { useSaveOnboardingWithErrorHandler } from './data/useSaveOnboardingWithErrorHandler';

// steps by priority
const ONBOARDING_STEPS = [
  'welcome_to_ctms',
  'question_how_can_ctms_make_your_job_easier',
  'question_what_brings_you_to_ctms',
  'question_experience_with_ctms',
  'team_invitation',
  'install_chrome_extension',
  'getting_started_guide',
] as const;

function getLastPendingStepName(steps: OnboardingStep['steps']) {
  for (const step of ONBOARDING_STEPS) {
    if (steps?.[step]?.status === 'pending') {
      return step;
    }
  }
  return null;
}

interface OnboardingTourHandlerProps {
  tourName: keyof typeof ONBOARDING_TOURS;
  enabled?: boolean;
  onSettled?: () => void;
}

const startedTours = new Set<string>();

export function useOnboardingTourHandler({
  tourName,
  enabled,
  onSettled,
}: OnboardingTourHandlerProps) {
  const isOnboardingEnabled = useFlag('ctms_onboarding');
  const { data: onboarding } = useOnboardingDetails({
    enabled: isOnboardingEnabled,
  });
  const { openOnboardingDialog } = useOnboardingDialogState();
  const tourStatus = onboarding?.steps?.[tourName]?.status;
  const isInitial = onboarding?.steps?.team_invitation?.status === 'pending';
  const lastPendingStepName = useMemo(
    () => (onboarding?.steps ? getLastPendingStepName(onboarding.steps) : null),
    [onboarding?.steps],
  );
  const { mutate: saveStep } = useSaveOnboardingWithErrorHandler();

  useEffect(() => {
    if (lastPendingStepName === 'getting_started_guide') {
      openOnboardingDialog();
      return;
    }

    const isEnabled = typeof enabled === 'boolean' ? enabled : true;

    if (
      isOnboardingEnabled &&
      tourStatus === 'pending' &&
      isEnabled &&
      !isInitial &&
      !startedTours.has(tourName)
    ) {
      startIntercomTour(ONBOARDING_TOURS[tourName]);
      startedTours.add(tourName);
      saveStep({ status: 'started', stepName: tourName });
      onSettled?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isOnboardingEnabled,
    tourName,
    enabled,
    lastPendingStepName,
    tourStatus,
    isInitial,
  ]);

  return {
    startTour: () => {
      startIntercomTour(ONBOARDING_TOURS[tourName]);
    },
  };
}
