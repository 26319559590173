import {
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp, Delete, Info } from '@material-ui/icons';
import { FormattedDate } from '@superdispatch/dates';
import { FormikTextField, useFormikEnhanced } from '@superdispatch/forms';
import {
  Column,
  Columns,
  Inline,
  Stack,
  useSnackbarStack,
} from '@superdispatch/ui';
import { Box, Button, MultilineText } from '@superdispatch/ui-lab';
import { FormikProvider } from 'formik';
import { useRef, useState } from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import { validateString } from 'shared/helpers/ValidationHelpers';
import { useDispatcherProfile } from 'shared/modules/dispatcher/DispatcherAPI';
import { FormattedRelativeTimeToNow } from 'shared/ui/FormattedRelativeTimeToNow';
import {
  formatLoadStatus,
  LoadDTO,
  LoadInternalNoteDTO,
} from '../data/LoadDTO';
import { trackLoadsEvent } from '../data/LoadsAnalytics';
import { useLoadDetails, useLoadsAPI, useLoadsCache } from '../data/LoadsAPI';
import { useLoadStageFromBackURL } from './ViewLoadHeaderActions';

interface DeleteConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
  id: number;
  authorName: string;
}

function DeleteConfirmationDialog({
  id,
  authorName,
  open,
  onClose,
}: DeleteConfirmationDialogProps) {
  const { loadGUID = '' } = useParams();
  const { addSnackbar } = useSnackbarStack();
  const { deleteLoadNote } = useLoadsAPI();
  const { data: load } = useLoadDetails(loadGUID);
  const { invalidateLoads } = useLoadsCache();
  const loadStage = useLoadStageFromBackURL();

  const formik = useFormikEnhanced({
    initialValues: { id },
    onSubmit: (values) => {
      return deleteLoadNote(loadGUID, values.id);
    },
    onSubmitSuccess: () => {
      onClose();
      invalidateLoads();
      trackLoadsEvent({
        name: 'Carrier Internal Note Deleted',
        utm_medium: loadStage,
        load_status: formatLoadStatus(load?.status),
        is_created_by_broker: !!load?.is_created_by_broker,
      });
    },
    onSubmitFailure: (error) => {
      addSnackbar(
        error.message || 'Failed to delete internal note, contact support.',
        { variant: 'error' },
      );
    },
  });

  return (
    <Dialog
      open={open}
      onClose={() => {
        if (!formik.isSubmitting) {
          onClose();
        }
      }}
    >
      <DialogTitle>
        Delete notes from <b>{authorName}</b>?
      </DialogTitle>
      <DialogActions>
        <Button
          variant="neutral"
          onClick={onClose}
          disabled={formik.isSubmitting}
        >
          Cancel
        </Button>
        <Button
          variant="critical"
          pending={formik.isSubmitting}
          onClick={() => {
            void formik.submitForm();
          }}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function InternalNote({ author, created_at, text, id }: LoadInternalNoteDTO) {
  const dispatcher = useDispatcherProfile();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const isCreatedByCurrentDispatcher = dispatcher.data?.name === author.name;

  return (
    <>
      <DeleteConfirmationDialog
        open={isDeleteDialogOpen}
        onClose={() => {
          setIsDeleteDialogOpen(false);
        }}
        id={id}
        authorName={author.name}
      />

      <Columns aria-label={`${id} note header`} align="center">
        <Column width="fluid">
          <Inline space="xsmall">
            <Typography variant="body1">{author.name}</Typography>
            {!!created_at && (
              <Tooltip
                enterTouchDelay={0}
                title={<FormattedDate date={created_at} variant="DateTime" />}
                placement="top"
              >
                <Typography color="textSecondary">
                  <FormattedRelativeTimeToNow
                    format="DateISO"
                    date={new Date(created_at)}
                  />
                </Typography>
              </Tooltip>
            )}
          </Inline>
        </Column>

        {isCreatedByCurrentDispatcher && (
          <Column width="content">
            <IconButton
              aria-label={`delete note ${id}`}
              size="small"
              onClick={() => {
                setIsDeleteDialogOpen(true);
              }}
            >
              <Delete fontSize="small" />
            </IconButton>
          </Column>
        )}
      </Columns>

      <Box
        aria-label={`${id} note content`}
        backgroundColor="Yellow75"
        padding="xsmall"
        borderRadius="small"
      >
        <MultilineText overflowWrap="break-word">{text}</MultilineText>
      </Box>
    </>
  );
}

const MAX_CONTENT_HEIGHT = 150; //in px

interface ViewLoadInternalNotesLegacyProps {
  load: LoadDTO;
}

export function ViewLoadInternalNotesLegacy({
  load,
}: ViewLoadInternalNotesLegacyProps) {
  const { addLoadNote } = useLoadsAPI();
  const { addSnackbar } = useSnackbarStack();
  const { invalidateLoads } = useLoadsCache();
  const [isExpanded, setIsExpanded] = useState(false);
  const notesContainer = useRef<HTMLElement>();
  const isExpandable =
    !!notesContainer.current &&
    MAX_CONTENT_HEIGHT < notesContainer.current.scrollHeight;
  const loadStage = useLoadStageFromBackURL();

  const formik = useFormikEnhanced({
    key: load.internal_notes?.length,
    initialValues: { note: '' },
    onSubmit: (values) => {
      return addLoadNote(load.guid, values.note.trim());
    },
    onSubmitSuccess: () => {
      invalidateLoads();
      trackLoadsEvent({
        name: 'Carrier Internal Note Added',
        page: 'view_load',
        utm_medium: loadStage,
        load_status: load.status,
        is_created_by_broker: !!load.is_created_by_broker,
      });
    },
    onSubmitFailure: (error) => {
      addSnackbar(
        error.message || 'Failed to add internal note, contact support.',
        { variant: 'error' },
      );
    },
  });

  return (
    <Card aria-label="Notes Card">
      <CardContent>
        <Stack space="small">
          <Inline space="xsmall" verticalAlign="center">
            <Typography
              variant="h3"
              color="textPrimary"
              id="internal-note-field"
            >
              Internal Notes
            </Typography>
            <Tooltip enterTouchDelay={0} title="Visible only to dispatchers ">
              <Info fontSize="small" color="action" />
            </Tooltip>
          </Inline>

          <FormikProvider value={formik}>
            <Stack space="xsmall">
              <FormikTextField
                name="note"
                validate={(value) => validateString(value, { maxLength: 500 })}
                aria-labelledby="internal-note-field"
                fullWidth={true}
                multiline={true}
              />
              <Inline horizontalAlign="right">
                <Button
                  disabled={!formik.dirty}
                  pending={formik.isSubmitting}
                  onClick={() => {
                    void formik.submitForm();
                  }}
                >
                  Add Note
                </Button>
              </Inline>
            </Stack>
          </FormikProvider>

          {!!load.internal_notes?.length && (
            <>
              <Divider />

              <Box
                maxHeight={isExpanded ? 'unset' : `${MAX_CONTENT_HEIGHT}px`}
                overflowY="auto"
                overflowX="hidden"
                ref={notesContainer}
              >
                <Stack space="small">
                  {load.internal_notes.map((internalNote, idx) => (
                    <InternalNote key={idx} {...internalNote} />
                  ))}
                </Stack>
              </Box>

              {isExpandable && (
                <>
                  <Divider />

                  <Button
                    variant="text"
                    fullWidth={true}
                    startIcon={isExpanded ? <ArrowDropUp /> : <ArrowDropDown />}
                    onClick={() => {
                      setIsExpanded(!isExpanded);
                    }}
                  >
                    {isExpanded ? 'Show less' : 'Show more'}
                  </Button>
                </>
              )}
            </>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}
