import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
} from '@material-ui/core';
import { ThumbUp } from '@material-ui/icons';
import { formatRelativeTime, FormattedDate } from '@superdispatch/dates';
import { Column, Columns, Inline, Stack, Tag } from '@superdispatch/ui';
import { Box, Button, MultilineText, TextBox } from '@superdispatch/ui-lab';
import { startCase, truncate } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { BankErrorSimpleBanner } from 'shared/errors/SuperPayBankErrors';
import { formatAddressLine } from 'shared/helpers/AddressHelpers';
import { formatCurrency, formatNumber } from 'shared/helpers/IntlHelpers';
import {
  formatLoadPayment,
  SuperPayLabel,
} from 'shared/helpers/PaymentHelpers';
import { formatVehicleLength } from 'shared/helpers/VehicleHelpers';
import { SplashScreen } from 'shared/layout/SplashScreen';
import { useOnboardingTourHandler } from 'shared/modules/onboarding/OnboardingIntercomTours';
import { PricingInsightsLink } from 'shared/modules/pricing-insights/PricingInsightsLink';
import { useShipperRatingDetails } from 'shared/modules/ratings/data/ShipperRatingsAPI';
import { ShipperProfileLink } from 'shared/modules/shipper/ShipperProfileLink';
import { SuperPayNudgingBanner } from 'shared/modules/superpay/SuperPayNudgingBanner';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';
import { useFlag } from 'shared/settings/FeatureToggles';
import { InlineBulletItems } from 'shared/ui/InlineBulletItems';
import { LoadWaypoints } from 'shared/ui/LoadWaypoints';
import { ResponsiveTooltip } from 'shared/ui/ResponsiveTooltip';
import styled from 'styled-components';
import OfferAcceptedLogo from '../assets/offer-accepted-logo.svg';
import OfferCancelledLogo from '../assets/offer-cancelled-logo.svg';
import OfferDeclinedLogo from '../assets/offer-declined-logo.svg';
import { OfferDescription } from '../core/OfferDescription';
import { OffersPageLayout } from '../core/OffersPageLayout';
import { OffersVehicleGallery } from '../core/OffersVehicleGallery';
import { OffersVehicleItem } from '../core/OffersVehicleItem';
import { OffersVehicleWeight } from '../core/OffersVehicleWeight';
import { trackOffersEvent } from './data/OffersAnalytics';
import { useOffer } from './data/OffersAPI';
import { mapOfferLoadToPricingInsights } from './data/OffersDTO';
import { OfferError } from './OfferError';
import {
  AcceptedOfferPageFooter,
  PendingOfferPageFooter,
} from './OffersPageFooter';

const PriceBox = styled(TextBox)`
  line-height: normal;
`;

export interface OffersPageDetailsProps {
  guid: string;
}

export function OffersPageDetails({ guid }: OffersPageDetailsProps) {
  const shouldShowPricingInsightsLink = useFlag(
    'pricing_insights_adoption_load_offer_link',
  );
  const { data, error, refetch, isLoading } = useOffer(guid);
  const { data: ratingDetails, isLoading: isRatingDetailsLoading } =
    useShipperRatingDetails(data?.load.shipper_guid);
  const { data: settings } = useCarrierSettings();

  const [modalState, setModalState] = useState<'terms'>();
  const [currentIndex, setCurrentIndex] = useState<number>(-1);

  const termsTruncated = useMemo(
    () =>
      truncate(data?.load.terms_and_conditions, {
        length: 175,
        omission: '…',
      }),
    [data?.load.terms_and_conditions],
  );

  useOnboardingTourHandler({
    tourName: 'tour_offer_accepted',
    enabled: data?.status === 'accepted',
  });

  useEffect(() => {
    trackOffersEvent({ name: 'CTMS: Viewed Load Offer', guid });
  }, [guid]);

  if (error) {
    return (
      <OffersPageLayout>
        <OfferError
          onRetry={refetch}
          title={error.message}
          isLoading={isLoading}
          description="Something went wrong while loading an offer, contact support."
        />
      </OffersPageLayout>
    );
  }

  if (!data) return <SplashScreen />;

  return (
    <OffersPageLayout
      footer={
        data.status === 'pending' ? (
          <PendingOfferPageFooter offer={data} />
        ) : data.status === 'accepted' && data.load.guid ? (
          <AcceptedOfferPageFooter loadGuid={data.load.guid} />
        ) : null
      }
    >
      <Dialog
        maxWidth="xs"
        fullWidth={true}
        open={modalState === 'terms'}
        onClose={() => {
          setModalState(undefined);
        }}
      >
        <DialogTitle>Terms and Conditions</DialogTitle>
        <DialogContent>
          <MultilineText overflowWrap="break-word">
            {data.load.terms_and_conditions}
          </MultilineText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="neutral"
            onClick={() => {
              setModalState(undefined);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Stack space="large">
        {data.status !== 'pending' && (
          <Box
            paddingBottom="xlarge"
            borderColor="Silver400"
            borderBottomWidth="small"
          >
            {data.status === 'accepted' && (
              <Stack space="medium">
                <Stack align="center">
                  <img
                    width={152}
                    height={56}
                    alt="Offer Accepted"
                    src={OfferAcceptedLogo}
                  />

                  {!data.status_changed_at ? (
                    <TextBox variant="heading-2">Offer Accepted</TextBox>
                  ) : (
                    <span>
                      <TextBox color="secondary" variant="heading-2">
                        Offer Accepted:
                      </TextBox>{' '}
                      <TextBox variant="heading-2">
                        {formatRelativeTime(data.status_changed_at)}
                      </TextBox>
                    </span>
                  )}
                </Stack>
              </Stack>
            )}

            {data.status === 'declined' && (
              <Stack align="center">
                <img
                  width={152}
                  height={56}
                  alt="Offer Declined"
                  src={OfferDeclinedLogo}
                />

                <TextBox variant="heading-2">Offer Declined</TextBox>
              </Stack>
            )}

            {data.status === 'canceled' && (
              <Stack align="center">
                <img
                  width={85}
                  height={56}
                  alt="Offer Cancelled"
                  src={OfferCancelledLogo}
                />

                <TextBox variant="heading-2">Offer Cancelled</TextBox>
              </Stack>
            )}
          </Box>
        )}

        <OfferDescription
          inline={true}
          label="Load ID"
          value={data.load.load_id || 'N/A'}
        />

        <OfferDescription
          label={!data.is_counter_offer ? 'Payment' : ''}
          value={
            <Stack space="xsmall">
              <Inline verticalAlign="bottom" space="small">
                <Stack>
                  {data.is_counter_offer && (
                    <Tag variant="subtle" color="purple">
                      Counter Offer
                    </Tag>
                  )}
                  <Inline space="xsmall" verticalAlign="bottom">
                    <PriceBox variant="heading-3">
                      {formatCurrency(data.load.price)}
                    </PriceBox>

                    {!!data.load.price_per_mile && (
                      <TextBox variant="caption" color="secondary">
                        {formatCurrency(data.load.price_per_mile, {
                          maximumFractionDigits: 2,
                        })}
                        /mi
                      </TextBox>
                    )}
                  </Inline>
                </Stack>

                {data.is_counter_offer && (
                  <>
                    <Stack space="xxsmall">
                      <TextBox variant="caption" color="secondary">
                        Bid
                      </TextBox>
                      <TextBox>
                        {formatCurrency(data.bid_price || 505, {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 0,
                        })}
                      </TextBox>
                    </Stack>

                    <Stack space="xxsmall">
                      <TextBox variant="caption" color="secondary">
                        Original
                      </TextBox>
                      <TextBox>
                        {formatCurrency(data.original_price, {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 0,
                        })}
                      </TextBox>
                    </Stack>
                  </>
                )}
              </Inline>

              {data.load.payment_method === 'superpay' ? (
                <TextBox color="secondary">
                  <InlineBulletItems>
                    <SuperPayLabel source="Load Offer Details" />
                    {data.load.payment_terms}
                  </InlineBulletItems>
                </TextBox>
              ) : (
                formatLoadPayment(data.load.payment_method)
              )}

              {shouldShowPricingInsightsLink && (
                <PricingInsightsLink
                  source="Load Offer Details"
                  data={mapOfferLoadToPricingInsights(data.load)}
                />
              )}
              {data.load.payment_method === 'superpay' ? (
                settings?.superpay.payment_error ? (
                  <BankErrorSimpleBanner
                    source="Load Offer Details"
                    paymentError={settings.superpay.payment_error}
                  />
                ) : (
                  <SuperPayNudgingBanner source="Load Offer Details" />
                )
              ) : null}
            </Stack>
          }
        />

        <Stack>
          <LoadWaypoints
            pickupAddress={formatAddressLine(
              data.load.pickup_zip,
              data.load.pickup_city,
              data.load.pickup_state,
            )}
            pickupBusinessType={data.load.pickup_business_type}
            pickupDate={
              data.load.pickup_date && (
                <FormattedDate variant="Date" date={data.load.pickup_date} />
              )
            }
            deliveryAddress={formatAddressLine(
              data.load.delivery_zip,
              data.load.delivery_city,
              data.load.delivery_state,
            )}
            deliveryBusinessType={data.load.delivery_business_type}
            deliveryDate={
              data.load.delivery_date && (
                <FormattedDate variant="Date" date={data.load.delivery_date} />
              )
            }
          />

          {!!data.load.distance_miles && (
            <TextBox color="secondary">
              {formatNumber(data.load.distance_miles, {
                maximumFractionDigits: 2,
              })}{' '}
              mi
            </TextBox>
          )}
        </Stack>

        {!!data.load.instructions && (
          <OfferDescription
            label="Special Instructions"
            value={
              <TextBox variant="heading-4">{data.load.instructions}</TextBox>
            }
          />
        )}

        {!!data.load.vehicles?.length && (
          <OfferDescription
            label={startCase(
              `${data.load.vehicles.length} ${formatVehicleLength(
                data.load.vehicles.length,
              )}`,
            )}
            value={
              <Stack space="small">
                <OffersVehicleWeight vehicles={data.load.vehicles} />

                <Stack space="medium">
                  {data.load.vehicles.map((vehicle, idx) => (
                    <OffersVehicleItem
                      key={idx}
                      vehicle={vehicle}
                      onVehicleImageClick={() => {
                        setCurrentIndex(idx);
                      }}
                    />
                  ))}
                </Stack>

                <OffersVehicleGallery
                  vehicles={data.load.vehicles}
                  currentSlideIndex={currentIndex}
                  setCurrentSlideIndex={setCurrentIndex}
                />
              </Stack>
            }
          />
        )}

        <OfferDescription
          label="Shipper"
          value={
            <>
              <Columns space="xsmall" align="bottom">
                <Column width="adaptive">
                  <Link
                    variant="h4"
                    target="_blank"
                    rel="noopener noreferrer"
                    noWrap={true}
                    href={data.load.shipper_profile_url}
                    display="block"
                  >
                    {data.load.shipper_name}
                  </Link>
                </Column>
                {data.load.shipper_is_verified === false && (
                  <Column width="content">
                    <ResponsiveTooltip title="This shipper hasn't verified all documents yet.">
                      <Tag
                        aria-label="unverified shipper"
                        color="grey"
                        variant="subtle"
                      >
                        Unverified
                      </Tag>
                    </ResponsiveTooltip>
                  </Column>
                )}
                <Column width="content">
                  {isRatingDetailsLoading ? (
                    <CircularProgress size={12} />
                  ) : (
                    ratingDetails?.rating && (
                      <Inline space="xxsmall" verticalAlign="center">
                        <ThumbUp fontSize="small" color="action" />
                        <ShipperProfileLink
                          guid={data.load.shipper_guid}
                          medium="Load Offer"
                          tab="ratings"
                        >
                          {ratingDetails.rating.percentage}%
                        </ShipperProfileLink>
                        {ratingDetails.rating.total_count > 0 && (
                          <TextBox>
                            ({ratingDetails.rating.total_count})
                          </TextBox>
                        )}
                      </Inline>
                    )
                  )}
                </Column>
              </Columns>
              <TextBox display="block" color="secondary">
                Phone: {data.load.shipper_phone}
              </TextBox>
            </>
          }
        />

        {!!termsTruncated && (
          <Stack space="small">
            <OfferDescription
              label="Terms & Conditions"
              value={
                <TextBox variant="heading-4">
                  <MultilineText overflowWrap="break-word">
                    {termsTruncated}
                  </MultilineText>
                </TextBox>
              }
            />

            {termsTruncated !== data.load.terms_and_conditions && (
              <Button
                variant="neutral"
                onClick={() => {
                  setModalState('terms');
                }}
              >
                Read More
              </Button>
            )}
          </Stack>
        )}
      </Stack>
    </OffersPageLayout>
  );
}
