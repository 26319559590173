import { Route, Routes } from 'react-router-dom-v5-compat';
import { DispatchersPage } from './dispatcher-management/DispatchersPage';
import { DispatcherProfilePage } from './dispatchers-profile/DispatcherProfilePage';

export function DispatchersContainerPage() {
  return (
    <Routes>
      <Route path="profile/*" element={<DispatcherProfilePage />} />
      <Route path=":guid" element={<DispatchersPage />} />
      <Route index={true} element={<DispatchersPage />} />
    </Routes>
  );
}
