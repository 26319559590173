import { Typography } from '@material-ui/core';
import { FormattedDate } from '@superdispatch/dates';
import { Column, Columns, Stack, useResponsiveValue } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { Logo } from 'shared/icons/Logo';
import { LogoWithBrand } from 'shared/icons/LogoWithBrand';
import { PublicOfferDTO } from './data/PublicOfferDTO';

export interface OfferViewHeaderProps {
  offer: PublicOfferDTO;
}

export function PublicOfferPageHeader({
  offer: { created_at: createdAt, carrier_name: carrierName },
}: OfferViewHeaderProps) {
  const isMobile = useResponsiveValue(true, false);

  return (
    <Box
      maxWidth="600px"
      marginLeft={['medium', 'auto', 'auto']}
      marginRight={['medium', 'auto', 'auto']}
      marginBottom="small"
      marginTop="medium"
    >
      <Columns>
        <Column width="fluid">{isMobile ? <Logo /> : <LogoWithBrand />}</Column>
        <Column width="content">
          <Stack space="none">
            <Typography align="right">Load Offer for</Typography>
            <Typography align="right">{carrierName}</Typography>

            {createdAt && (
              <Typography color="textSecondary" align="right">
                <FormattedDate variant="DateTime" date={createdAt} />
              </Typography>
            )}
          </Stack>
        </Column>
      </Columns>
    </Box>
  );
}
