import { CircularProgress, Typography } from '@material-ui/core';
import { Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { lazy, Suspense } from 'react';
import useWindowSize from 'shared/hooks/useWindowSize';
import { CompletionCelebrationIcon } from 'shared/icons/CompletionCelebrationIcon';
import styled from 'styled-components';
import { useOnboardingCompletion } from '../data/useOnboardingCompletion';
const Confetti = lazy(() => import('react-confetti'));

const CompletionBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export function OnboardingCompletion() {
  const { width, height } = useWindowSize();
  const { shouldShowConfetti } = useOnboardingCompletion();

  return (
    <>
      <CompletionBox>
        <Stack align="center" space="large">
          <CompletionCelebrationIcon />
          <Stack space="xsmall">
            <Typography align="center" variant="h3">
              Congratulations!
            </Typography>
            <Typography align="center" color="textSecondary">
              You have completed all steps!
            </Typography>
          </Stack>
        </Stack>
      </CompletionBox>
      {shouldShowConfetti && (
        <Suspense fallback={<CircularProgress />}>
          <Confetti width={width - 300} height={height - 300} />
        </Suspense>
      )}
    </>
  );
}
