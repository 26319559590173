import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Typography,
} from '@material-ui/core';
import { FormattedDate } from '@superdispatch/dates';
import { Inline, Stack, Tag } from '@superdispatch/ui';
import { Box, Button, DescriptionItem, TextBox } from '@superdispatch/ui-lab';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom-v5-compat';
import { trackEvent } from 'shared/helpers/Analytics';
import { formatCurrency } from 'shared/helpers/IntlHelpers';
import { LocalStore, useLocalStore } from 'shared/helpers/Store';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import { SubscriptionSettingsDTO } from 'shared/modules/subscription/SubscriptionDTO';
import { LinkAnchor } from 'shared/routing/Links';
import { useFlag } from 'shared/settings/FeatureToggles';
import { SubscriptionDowngrade } from './SubscriptionDowngrade';

interface SettingsSubscriptionPlanProps {
  subscriptionDetails: SubscriptionSettingsDTO;
  onReactivationSuccess?: () => void;
}

const IS_DOWNGRADE_REQUESTED_STORAGE_KEY = 'is_downgrade_requested';

export function SettingsSubscriptionPlan({
  subscriptionDetails,
}: SettingsSubscriptionPlanProps) {
  const isDowngradingEnabled = useFlag('downgrade_subscription');
  const [modalState, setModalState] = useState<
    'downgradeSuccess' | 'subscriptionDowngrade'
  >();
  const downgradeRequestedStorageItem = useLocalStore(
    IS_DOWNGRADE_REQUESTED_STORAGE_KEY,
  );
  const location = useLocation();

  const upcomingBillingDate = useMemo(() => {
    if (
      subscriptionDetails.status === 'Active' &&
      subscriptionDetails.billing_period_end_date === null
    ) {
      return <FormattedDate variant="ShortDate" date={DateTime.local()} />;
    }

    if (subscriptionDetails.billing_period_end_date) {
      const daysBehindCurrentDate = DateTime.fromISO(
        subscriptionDetails.billing_period_end_date,
      ).diffNow('day').days;

      if (daysBehindCurrentDate < 0) {
        return undefined;
      }
    } else {
      return undefined;
    }

    return (
      <FormattedDate
        variant="ShortDate"
        date={subscriptionDetails.billing_period_end_date}
      />
    );
  }, [subscriptionDetails]);

  const closeModal = () => {
    setModalState(undefined);
  };

  const isDowngradeRequested = !!downgradeRequestedStorageItem;

  const canDowngrade =
    isDowngradingEnabled &&
    subscriptionDetails.status !== 'Canceled' &&
    subscriptionDetails.status !== null &&
    !isDowngradeRequested;

  const isTrialExpired = subscriptionDetails.status === 'Canceled';
  const isSubscriptionPage = location.pathname.includes('subscription');

  useEffect(() => {
    if (subscriptionDetails.status === 'Canceled' && isDowngradeRequested) {
      LocalStore.delete(IS_DOWNGRADE_REQUESTED_STORAGE_KEY);
    }
  }, [subscriptionDetails.status, isDowngradeRequested]);

  return (
    <>
      <SubscriptionDowngrade
        onClose={closeModal}
        open={modalState === 'subscriptionDowngrade'}
        onSubmitSuccess={() => {
          closeModal();
          setModalState('downgradeSuccess');
          LocalStore.set(IS_DOWNGRADE_REQUESTED_STORAGE_KEY, 'true');
          trackEvent('CTMS: Requested Downgrade to Free Plan');
        }}
      />

      <Dialog open={modalState === 'downgradeSuccess'} onClose={closeModal}>
        <DialogTitle>Downgrade is Requested</DialogTitle>
        <DialogContent>
          <Stack space="large">
            <TextBox>
              Your request has been received and a member of our Support team
              will reach out to you shortly!
            </TextBox>

            <TextBox>
              If you have any questions please contact us at{' '}
              <Link href="mailto:support@superdispatch.com">
                support@superdispatch.com
              </Link>{' '}
              or click the chat icon in the bottom right corner.
            </TextBox>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal}>Got It</Button>
        </DialogActions>
      </Dialog>

      <Stack space="small">
        <Stack space="xxsmall">
          <Inline verticalAlign="bottom">
            <Typography display="inline" variant="h3">
              Plan
            </Typography>

            <Box height="24px">
              <Link
                target="_blank"
                rel="noreferrer noopener"
                href="https://support.superdispatch.com/en/articles/5273802-super-dispatch-subscription-plans"
                color="primary"
              >
                Learn More
              </Link>
            </Box>
          </Inline>

          {subscriptionDetails.status === 'Active' ? (
            <Typography>
              Your subscription renew on {upcomingBillingDate}
            </Typography>
          ) : (
            !!upcomingBillingDate && (
              <Typography>
                Your subscription will end on {upcomingBillingDate}
              </Typography>
            )
          )}

          {isDowngradeRequested &&
            subscriptionDetails.status !== 'Canceled' && (
              <Tag variant="subtle" color="yellow">
                Downgrade is Requested
              </Tag>
            )}
        </Stack>

        <Stack space={['small', 'xsmall']}>
          <DescriptionItem label="Type:">
            {subscriptionDetails.plan?.type} (
            {formatCurrency(subscriptionDetails.plan?.price)}
            /mo)
          </DescriptionItem>

          <DescriptionItem label="Drivers:">
            <Inline space="xsmall" verticalAlign="center">
              {subscriptionDetails.plan?.drivers_count}

              {isSubscriptionPage && isTrialExpired && (
                <LinkAnchor color="textSecondary" to="/drivers">
                  Manage Drivers
                </LinkAnchor>
              )}
            </Inline>
          </DescriptionItem>
          {subscriptionDetails.plan?.discount_amount != null && (
            <DescriptionItem label="Discount for this month:">
              {formatCurrency(subscriptionDetails.plan.discount_amount)}
            </DescriptionItem>
          )}
          <DescriptionItem label="Total price:">
            {formatCurrency(
              subscriptionDetails.plan?.discounted_price ||
                subscriptionDetails.plan?.price,
            )}
            /mo{' '}
            <TextBox color="secondary" variant="body">
              + tax if applicable
            </TextBox>
          </DescriptionItem>
        </Stack>

        {!isSubscriptionPage && isTrialExpired && (
          <Button
            variant="neutral"
            onClick={() => {
              openExternalURL('/tms/drivers');
            }}
          >
            Manage Drivers
          </Button>
        )}

        {canDowngrade && (
          <Button
            onClick={() => {
              setModalState('subscriptionDowngrade');
              trackEvent('CTMS: Clicked Downgrade to Free Plan');
            }}
            variant="neutral"
          >
            Downgrade to Free Plan
          </Button>
        )}
      </Stack>
    </>
  );
}
