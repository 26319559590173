import { Typography } from '@material-ui/core';
import { FormikTextField, useFormikEnhanced } from '@superdispatch/forms';
import { Stack, useSnackbarStack } from '@superdispatch/ui';
import { useEffect } from 'react';
import { APIResponse } from 'shared/api/CarrierAPIClient';
import { MobileAppBridge } from 'shared/data/MobileAppBridge';
import { useInitialValues } from 'shared/form/AppFormik';
import { DocumentTitle } from 'shared/layout/DocumentTitle';
import { trackCarrierProfileEvent } from 'shared/modules/verified-carrier-application/data/CarrierProfileAnalytics';
import {
  useBillingInfo,
  useCarrierProfileAPI,
} from 'shared/modules/verified-carrier-application/data/CarrierProfileAPI';
import {
  BillingInfoDTO,
  billingInfoSchema,
} from 'shared/modules/verified-carrier-application/data/CarrierProfileDTO';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';
import { ProfilePageLayout } from './ProfilePageLayout';

export function ProfileBillingInfoPage() {
  const { data, error, isLoading } = useBillingInfo();
  const { updateBillingInfo } = useCarrierProfileAPI();
  const { addSnackbar } = useSnackbarStack();
  const initialValues = useInitialValues(billingInfoSchema, data);
  const { data: settings } = useCarrierSettings();

  const isEditingDisabled =
    !settings?.drivers_can_edit_carrier_info && MobileAppBridge.isInjected();

  const formik = useFormikEnhanced<BillingInfoDTO, APIResponse>({
    initialValues,
    validationSchema: billingInfoSchema,
    onSubmit: updateBillingInfo,
    onSubmitSuccess: () => {
      addSnackbar('Billing info was successfully updated', {
        variant: 'success',
      });

      trackCarrierProfileEvent({
        name: 'Carrier Updated Carrier Info',
        utm_medium: 'Carrier Profile',
        utm_content: 'Billing Info',
      });
    },
    onSubmitFailure: ({ message }) =>
      addSnackbar(`Failed to update billing info: ${message}`, {
        variant: 'error',
      }),
  });

  useEffect(() => {
    if (error) {
      addSnackbar('Failed to fetch billing info', { variant: 'error' });
    }
  }, [addSnackbar, error]);

  return (
    <>
      <DocumentTitle title="Billing Info - Profile" />

      <ProfilePageLayout
        formik={formik}
        title="Billing Info"
        isLoading={isLoading}
      >
        <Stack space="small">
          <Typography variant="h4">Billing</Typography>

          <FormikTextField
            label="Name"
            name="billing_contact_name"
            fullWidth={true}
            disabled={isEditingDisabled}
          />

          <FormikTextField
            label="Phone Numbers"
            name="billing_phone_numbers"
            fullWidth={true}
            disabled={isEditingDisabled}
          />

          <FormikTextField
            label="Email"
            name="billing_email"
            fullWidth={true}
            disabled={isEditingDisabled}
          />
        </Stack>
      </ProfilePageLayout>
    </>
  );
}
