import type {
  BusinessType,
  Moov,
  NewAccount,
  NewBankAccount,
} from '@moovio/moov-js';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { logPaymentError } from '../helpers/PaymentLogger';
import {
  MoovInformation,
  MoovRequestError,
  MoovScope,
  NewCarrierBankAccount,
  NewCarrierMoovAccount,
  SearchRoutingNumberResponse,
} from './MoovDTO';
import {
  generateMoovTokenAndGetAccount,
  useSyncSuperPayOnboardingStep,
} from './SuperPayAPI';

function isMoovRequestError(error: unknown): error is MoovRequestError {
  return (
    !!error &&
    typeof error === 'object' &&
    !!(
      (error as MoovRequestError).statusCode ||
      (error as MoovRequestError).error
    )
  );
}

async function getMoov(scope: MoovScope) {
  const { loadMoov } = await import('@moovio/moov-js');
  const moovInformation = await generateMoovTokenAndGetAccount(scope);
  const moov = await loadMoov(moovInformation.access_token);
  if (!moov) {
    throw new Error('SuperPay is unavailable in your location.');
  }
  return { moov, moovInformation };
}

function actionErrorHandle(reason: unknown) {
  if (!isMoovRequestError(reason)) {
    return Promise.reject(reason);
  }
  const { statusCode, error } = reason;
  const message = typeof error === 'string' ? error : undefined;
  const errorCode = statusCode ? `Error code: ${statusCode}` : undefined;

  return Promise.reject(
    new Error(
      message && errorCode ? `${message}. ${errorCode}` : message || errorCode,
    ),
  );
}

async function actionWrapper<T>(
  scope: MoovScope,
  errorSource:
    | 'createCarrierAccount'
    | 'createCarrierBankAccount'
    | 'completeMicroDeposit'
    | 'bankNameByRoutingNumber'
    | 'accountLegalName',
  action: (moov: Moov, moovInformation: MoovInformation) => Promise<T>,
) {
  let moovInformation: MoovInformation | null = null;
  let moov: Moov | null = null;

  try {
    ({ moovInformation, moov } = await getMoov(scope));
    return await action(moov, moovInformation);
  } catch (error: unknown) {
    logPaymentError(error as Error, `MoovAPI.${errorSource}`, {
      moovAccountId: moovInformation?.moov_account_id,
    });
    return actionErrorHandle(error);
  }
}

export function useCreateCarrierMoovAccount() {
  const { mutateAsync: syncSuperPay } = useSyncSuperPayOnboardingStep();

  return useMutation((newAccountData: NewCarrierMoovAccount) => {
    const newAccount: NewAccount = {
      accountType: 'business',
      profile: {
        business: {
          businessType: newAccountData.businessType as BusinessType,
          description: newAccountData.description,
          legalBusinessName: newAccountData.legalBusinessName,
          taxID: { ein: { number: newAccountData.einNumber } },
        },
      },
      metadata: {
        guid: newAccountData.carrier_guid,
        type: 'carrier',
      },
      foreignID: newAccountData.carrier_guid,
    };

    const scope = 'account';

    return actionWrapper(scope, 'createCarrierAccount', async (moov) => {
      const account = await moov.accounts.create(newAccount);

      return syncSuperPay({
        scope,
        moov_bank_account_id: null,
        moov_account_id: account.accountID,
      }).finally(() => account);
    });
  });
}

export function useCreateBankAccount() {
  const { mutateAsync: syncSuperPay } = useSyncSuperPayOnboardingStep();

  return useMutation((newBankAccountData: NewCarrierBankAccount) => {
    const newBankAccount: NewBankAccount = {
      routingNumber: newBankAccountData.routingNumber,
      accountNumber: newBankAccountData.accountNumber,
      holderName: newBankAccountData.holderName,
      holderType: 'business',
      bankAccountType: 'checking',
    };

    const scope = 'bank_account';

    return actionWrapper(
      scope,
      'createCarrierBankAccount',
      async (moov, moovInformation) => {
        const bankAccount = await moov.accounts.bankAccounts.link({
          accountID: moovInformation.moov_account_id || '',
          bankAccount: newBankAccount,
        });

        return syncSuperPay({
          scope,
          moov_bank_account_id: bankAccount.bankAccountID,
          moov_account_id: moovInformation.moov_account_id,
        }).finally(() => bankAccount);
      },
    );
  });
}

export function useCompleteMicroDepositVerification() {
  return useMutation((amounts: number[]) => {
    return actionWrapper(
      'micro_deposit',
      'completeMicroDeposit',
      (moov, moovInformation) => {
        return moov.accounts.bankAccounts.completeMicroDepositVerification({
          accountID: moovInformation.moov_account_id || '',
          bankAccountID: moovInformation.moov_bank_account_id || '',
          amounts,
        });
      },
    );
  });
}

export function useBankNameByRoutingNumber() {
  return useMutation((routingNumber: string) => {
    return actionWrapper('account', 'bankNameByRoutingNumber', (moov) => {
      return moov.institutions
        .lookupByRoutingNumber(routingNumber)
        .then((response: SearchRoutingNumberResponse) => {
          return response.result?.[0]?.customerName;
        });
    });
  });
}

export function useAccountLegalBusinessName(isEnabled = false) {
  return useQuery(
    'moovAccountLegalName',
    () => {
      return actionWrapper(
        'micro_deposit',
        'accountLegalName',
        (moov, moovInformation) => {
          return moov.accounts
            .get({
              accountID: moovInformation.moov_account_id || '',
            })
            .then((account) => account.profile.business?.legalBusinessName);
        },
      );
    },
    {
      cacheTime: Infinity,
      enabled: isEnabled,
    },
  );
}

export function useMoovAvailable(isEnabled = false) {
  const [isAvailable, setIsAvailable] = useState(true);

  const setAvailable = async () => {
    try {
      await getMoov('account');
      setIsAvailable(true);
    } catch {
      setIsAvailable(false);
    }
  };

  useEffect(() => {
    if (isEnabled) {
      void setAvailable();
    }
  }, [isEnabled]);

  return isAvailable;
}
