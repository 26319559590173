import { Link, Tooltip } from '@material-ui/core';
import { Error, Warning } from '@material-ui/icons';
import { Color, Column, Columns, Inline, Stack, Tag } from '@superdispatch/ui';
import { Box, Sidebar, SidebarSubheader } from '@superdispatch/ui-lab';
import { Navigate, useLocation } from 'react-router-dom-v5-compat';
import { SuperCarrierBadgeIcon } from 'shared/icons/SuperCarrierBadgeIcon';
import { useCarrierInfo } from 'shared/modules/carrier-profile/CarrierInfo';
import { useIsCarrierWithoutUSDOT } from 'shared/modules/carrier-profile/data/CarrierPerformanceAPI';
import { useVerifiedCarrierApplicationStatus } from 'shared/modules/carrier-profile/VerifiedCarrierApplicationStatus';
import { VerifiedCarrierApplicationStatusDTO } from 'shared/modules/carrier-profile/VerifiedCarrierApplicationStatusDTO';
import { SidebarMenuItemLink } from 'shared/routing/SidebarMenuItemLink';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';
import { VerifiedCarrierApplicationStatus } from './core/VerifiedCarrierApplicationStatus';

type VerificationStatuses =
  VerifiedCarrierApplicationStatusDTO['verified_carrier_application']['status'];
export const phoneVerificationStatuses: VerificationStatuses[] = [
  'pending',
  'non_verifiable',
  'not_submitted',
];

const carrierInfoPages = [
  'overview',
  'carrier_info',
  'cargo_insurance',
  'w9',
  'usdot',
  'transporter_plate_registration',
  'state_motor_carrier_permit',
  'business_license',
] as const;

const paymentPages = ['superpay', 'billing_info', 'ach_payment'] as const;

type CarrierInfoPage = (typeof carrierInfoPages)[number];
type PaymentPage = (typeof paymentPages)[number];
type ProfilePageName = CarrierInfoPage | PaymentPage | 'terms_and_conditions';

function isValidProfilePageName(value: unknown): value is ProfilePageName {
  switch (value) {
    case 'overview':
    case 'carrier_info':
    case 'cargo_insurance':
    case 'w9':
    case 'usdot':
    case 'transporter_plate_registration':
    case 'state_motor_carrier_permit':
    case 'business_license':
    case 'billing_info':
    case 'ach_payment':
    case 'superpay':
    case 'terms_and_conditions':
      return true;
    default:
      return false;
  }
}

function getProfilePageDisplayName(name: ProfilePageName) {
  switch (name) {
    case 'overview':
      return 'Overview';

    case 'carrier_info':
      return 'Carrier Info';

    case 'cargo_insurance':
      return 'Cargo Insurance';

    case 'w9':
      return 'W-9 Form';

    case 'usdot':
      return 'USDOT Certificate';

    case 'transporter_plate_registration':
      return 'Transporter Plate Registration';

    case 'state_motor_carrier_permit':
      return 'State Motor Carrier Permit';

    case 'business_license':
      return 'Business License';

    case 'billing_info':
      return 'Billing Info';

    case 'ach_payment':
      return 'ACH Payment Info';

    case 'superpay':
      return 'SuperPay';

    case 'terms_and_conditions':
      return 'Terms and Conditions';

    default:
      return name;
  }
}

function VerifiedCarrierBadge() {
  return (
    <Tooltip
      interactive={true}
      title={
        <>
          You are a Verified Carrier.{' '}
          <Link
            href="https://support.superdispatch.com/en/articles/2644754"
            target="_blank"
            rel="noopener noreferrer"
            color="inherit"
          >
            Learn More
          </Link>
        </>
      }
    >
      <SuperCarrierBadgeIcon htmlColor={Color.Green300} />
    </Tooltip>
  );
}

export function ProfileSidebar() {
  const location = useLocation();
  const [_, _initial, step] = location.pathname.split('/');
  const isCarrierWithoutUSDOT = useIsCarrierWithoutUSDOT();
  const { data: carrierSettings } = useCarrierSettings();
  const { data: carrierInfo } = useCarrierInfo();
  const { data } = useVerifiedCarrierApplicationStatus();
  const verifiedCarrierApplication = data?.verified_carrier_application;
  const decliningReasons = verifiedCarrierApplication?.declining_reasons;
  const revokingReasons = verifiedCarrierApplication?.revoking_reasons;

  const isVerified = verifiedCarrierApplication?.status === 'approved';
  const superPayVerificationStatus = carrierSettings?.superpay.verification;
  const isSuperPayVerified = superPayVerificationStatus === 'verified';
  const isSuperPayOnboardingNotCompleted = isVerified && !isSuperPayVerified;
  const isSuperPayOnboardingError =
    superPayVerificationStatus === 'bank_account_failed' ||
    superPayVerificationStatus === 'bank_account_errored';
  const isSuperPayBankError = !!carrierSettings?.superpay.payment_error;
  const isSuperPayError = isSuperPayOnboardingError || isSuperPayBankError;
  const isSuperPayToDo = !isSuperPayError && isSuperPayOnboardingNotCompleted;

  const shouldShowUSDOT = !isCarrierWithoutUSDOT;
  const shouldShowStateMotorCarrierPermit =
    isCarrierWithoutUSDOT &&
    (carrierInfo?.logistics_type === 'auto_hauler' ||
      carrierInfo?.logistics_type === 'towing');
  const shouldShowBusinessLicense =
    isCarrierWithoutUSDOT && carrierInfo?.logistics_type === 'driveaway';

  const isFmcsaPhoneNeedVerification =
    !carrierInfo?.is_fmcsa_phone_verified &&
    verifiedCarrierApplication &&
    !phoneVerificationStatuses.includes(verifiedCarrierApplication.status);

  const hasOverviewError =
    !!decliningReasons?.reliability_score?.length ||
    !!revokingReasons?.terms_and_conditions?.length;
  const hasCargoInsuranceError =
    !!decliningReasons?.cargo_insurance_file_url?.length ||
    !!revokingReasons?.cargo_insurance_file_url?.length;
  const hasW9Error = !!decliningReasons?.w9_form_url?.length;
  const hasUSDOTError =
    !!decliningReasons?.usdot_certificate_url?.length ||
    !!revokingReasons?.usdot_certificate_url?.length;
  const hasCarrierInfoError = !!revokingReasons?.fmcsa_phone?.length;
  const hasTransporterPlateError =
    !!decliningReasons?.transporter_plate_registration?.length;
  const hasStatePermitError =
    !!decliningReasons?.state_motor_carrier_permit?.length;
  const hasBusinessLicenseError = !!decliningReasons?.business_license?.length;

  const hasCargoInsuranceWarning =
    verifiedCarrierApplication?.status === 'reviewed';

  if (!isValidProfilePageName(step)) {
    return <Navigate to="/profile/overview" replace={true} />;
  }

  return (
    <Sidebar
      title={
        <Columns align="center">
          <Column>Profile</Column>

          {isVerified && (
            <Column width="content">
              <VerifiedCarrierBadge />
            </Column>
          )}
        </Columns>
      }
      header={data && <VerifiedCarrierApplicationStatus {...data} />}
    >
      <Stack space="small">
        <Box>
          {carrierInfoPages.map((page) => {
            if (
              carrierInfo?.logistics_type !== 'driveaway' &&
              page === 'transporter_plate_registration'
            ) {
              return null;
            }

            return (
              <SideBarMenuItem
                key={page}
                page={page}
                hasError={
                  (page === 'overview' && hasOverviewError) ||
                  (page === 'cargo_insurance' && hasCargoInsuranceError) ||
                  (page === 'w9' && hasW9Error) ||
                  (page === 'usdot' && hasUSDOTError) ||
                  (page === 'carrier_info' && hasCarrierInfoError) ||
                  (page === 'transporter_plate_registration' &&
                    hasTransporterPlateError) ||
                  (page === 'state_motor_carrier_permit' &&
                    hasStatePermitError) ||
                  (page === 'business_license' && hasBusinessLicenseError)
                }
                hasWarning={
                  page === 'cargo_insurance' && hasCargoInsuranceWarning
                }
                shouldShowNewTag={page === 'overview'}
                shouldShowToDoTag={
                  page === 'carrier_info' &&
                  isFmcsaPhoneNeedVerification &&
                  !hasCarrierInfoError
                }
                hidden={
                  (carrierInfo?.logistics_type &&
                    carrierInfo.logistics_type !== 'driveaway' &&
                    page === 'transporter_plate_registration') ||
                  (page === 'usdot' && !shouldShowUSDOT) ||
                  (page === 'state_motor_carrier_permit' &&
                    !shouldShowStateMotorCarrierPermit) ||
                  (page === 'business_license' && !shouldShowBusinessLicense)
                }
              />
            );
          })}
        </Box>

        <Box>
          <SidebarSubheader>Payments</SidebarSubheader>
          {paymentPages.map((page) => {
            return (
              <SideBarMenuItem
                key={page}
                page={page}
                shouldShowToDoTag={page === 'superpay' && isSuperPayToDo}
                hasError={page === 'superpay' && isSuperPayError}
              />
            );
          })}
        </Box>

        <SideBarMenuItem page="terms_and_conditions" />
      </Stack>
    </Sidebar>
  );
}

interface SideBarMenuItemProps {
  page: ProfilePageName;
  hasError?: boolean;
  hasWarning?: boolean;
  shouldShowNewTag?: boolean;
  shouldShowToDoTag?: boolean;
  hidden?: boolean;
}

function SideBarMenuItem(props: SideBarMenuItemProps) {
  const { page, hasError, hasWarning, shouldShowNewTag, shouldShowToDoTag } =
    props;
  const pageDisplayName = getProfilePageDisplayName(page);

  if (props.hidden) return null;

  const pageLink =
    page === 'superpay'
      ? `/profile/${page}?utm_medium=SuperPay Menu`
      : `/profile/${page}`;

  return (
    <SidebarMenuItemLink
      key={page}
      to={pageLink}
      action={
        <Inline verticalAlign="center">
          {shouldShowNewTag && (
            <Tag color="blue" variant="subtle">
              New
            </Tag>
          )}
          {shouldShowToDoTag && (
            <Tag color="yellow" variant="subtle">
              To Do
            </Tag>
          )}
          {hasError ? (
            <Error
              aria-label={`${pageDisplayName} Error`}
              htmlColor={Color.Red300}
              fontSize="small"
            />
          ) : hasWarning ? (
            <Warning
              aria-label={`${pageDisplayName} Warning`}
              htmlColor={Color.Yellow300}
              fontSize="small"
            />
          ) : undefined}
        </Inline>
      }
    >
      {pageDisplayName}
    </SidebarMenuItemLink>
  );
}
