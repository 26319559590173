import { CircularProgress } from '@material-ui/core';
import { Inline } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { isAPIError } from 'shared/api/APIError';
import { useIsCarrierWithoutUSDOT } from 'shared/modules/carrier-profile/data/CarrierPerformanceAPI';
import { useVerifiedCarrierApplicationStatus } from 'shared/modules/carrier-profile/VerifiedCarrierApplicationStatus';
import { trackPhoneVerificationEvent } from './data/PhoneVerificationAnalytics';
import {
  useSendVerificationCode,
  VerificationCodeMethod,
} from './data/PhoneVerificationAPI';
import { PhoneVerificationAttemptsExceeded } from './PhoneVerificationAttemptsExceeded';
import { PhoneVerificationCodeValidation } from './PhoneVerificationCodeValidation';
import { PhoneVerificationInputForm } from './PhoneVerificationInputForm';
import { PhoneVerificationRequest } from './PhoneVerificationRequest';

type Step = 'send-code' | 'submit-code' | 'enter-phone-number';

interface PhoneVerificationProps {
  onComplete: () => void;
  onBack: () => void;
}

export function PhoneVerification({
  onComplete,
  onBack,
}: PhoneVerificationProps) {
  const [step, setStep] = useState<Step | undefined>();
  const [method, setMethod] = useState<VerificationCodeMethod>();
  const [retryDate, setRetryDate] = useState<DateTime>();

  const { data: verificationStatus } = useVerifiedCarrierApplicationStatus();
  const isCarrierWithoutUSDOT = useIsCarrierWithoutUSDOT();

  useEffect(() => {
    setStep(isCarrierWithoutUSDOT ? 'enter-phone-number' : 'send-code');
  }, [isCarrierWithoutUSDOT]);

  const {
    mutate: requestCode,
    isLoading: isCodeRequestLoading,
    error: sendCodeError,
    data: requestCodeInfo,
  } = useSendVerificationCode({
    onSettled: () => {
      setRetryDate(DateTime.local().plus({ minutes: 1 }));
    },
  });

  const onCodeSend = useCallback(
    (verificationMethod: VerificationCodeMethod) => {
      setMethod(verificationMethod);
      requestCode(verificationMethod);
      setStep('submit-code');
      trackPhoneVerificationEvent({
        name: 'Carrier Requested Phone Verification Code',
        attempt_count: (requestCodeInfo?.data.attempt_count ?? 0) + 1,
        method: verificationMethod,
        is_method_changed: !!method && method !== verificationMethod,
        carrier_verification_status:
          verificationStatus?.verified_carrier_application.status,
      });
    },
    [requestCode, requestCodeInfo, method, verificationStatus],
  );

  const isAttemptsExceeded = useMemo(() => {
    return (
      isAPIError(sendCodeError) &&
      sendCodeError.type === 'VerificationAttemptCountExceeded'
    );
  }, [sendCodeError]);

  return (
    <Box width={['auto', '500px']} maxWidth="500px">
      {isAttemptsExceeded ? (
        <PhoneVerificationAttemptsExceeded onBack={onBack} />
      ) : (
        <>
          {step === undefined && (
            <Inline verticalAlign="center" horizontalAlign="center">
              <CircularProgress />
            </Inline>
          )}

          {step === 'enter-phone-number' && (
            <PhoneVerificationInputForm
              onSubmitSuccess={() => {
                setStep('send-code');
              }}
            />
          )}

          {step === 'send-code' && (
            <PhoneVerificationRequest
              isCodeRequestLoading={isCodeRequestLoading}
              onCodeSend={onCodeSend}
              onPhoneChange={() => {
                setStep('enter-phone-number');
              }}
            />
          )}

          {step === 'submit-code' && method && (
            <PhoneVerificationCodeValidation
              isCodeRequestLoading={isCodeRequestLoading}
              method={method}
              retryDate={retryDate}
              onCodeSend={onCodeSend}
              onComplete={onComplete}
              requestError={sendCodeError}
            />
          )}
        </>
      )}
    </Box>
  );
}
