import { IconButton, Link } from '@material-ui/core';
import { Edit, Email, Inbox, Phone } from '@material-ui/icons';
import { SuspendedPhoneLink } from '@superdispatch/phones';
import { Column, Columns, InfoCard, Stack } from '@superdispatch/ui';
import { Box, DescriptionItem, TextBox } from '@superdispatch/ui-lab';
import { useEffect } from 'react';
import { showIntercomLauncher } from 'shared/helpers/Intercom';
import { TowTruckIcon } from 'shared/icons';
import { DriverDTO } from '../data/DriverDTO';

interface DriverInfoCardProps {
  currentDriver: DriverDTO;
  setCurrentModal: (currentModal: 'edit') => void;
  labelID: string;
  descriptionID: string;
  editIconID: string;
}

export function DriverInfoCard({
  currentDriver,
  setCurrentModal,
  labelID,
  descriptionID,
  editIconID,
}: DriverInfoCardProps) {
  useEffect(() => {
    showIntercomLauncher();
  }, []);

  return (
    <Box
      minWidth="516px"
      maxWidth="516px"
      role="description"
      aria-labelledby={labelID}
      aria-describedby={descriptionID}
    >
      <InfoCard>
        <Stack space="small">
          <Stack space="small">
            <Columns align="top">
              <Column>
                <TextBox variant="heading-3">Driver Info</TextBox>
              </Column>

              <Column width="content">
                <IconButton
                  size="small"
                  aria-labelledby={`${editIconID} ${labelID}`}
                  onClick={() => {
                    setCurrentModal('edit');
                  }}
                >
                  <Edit id={editIconID} aria-label="edit" />
                </IconButton>
              </Column>
            </Columns>

            <DescriptionItem
              wrap={true}
              label="Email"
              icon={<Email />}
              fallback="Not Available"
            >
              {!!currentDriver.email && (
                <Link href={`mailto:${currentDriver.email}`}>
                  {currentDriver.email}
                </Link>
              )}
            </DescriptionItem>

            <DescriptionItem
              label="Phone"
              icon={<Phone />}
              fallback="Not Available"
            >
              {!!currentDriver.phone && (
                <SuspendedPhoneLink phone={currentDriver.phone} />
              )}
            </DescriptionItem>

            <DescriptionItem
              wrap={true}
              label="Inbox"
              icon={<Inbox />}
              fallback="Not Available"
            >
              {!!currentDriver.inbox && (
                <Link href={`mailto:${currentDriver.inbox}`}>
                  {currentDriver.inbox}
                </Link>
              )}
            </DescriptionItem>

            <DescriptionItem
              label="Trailer Capacity"
              icon={<TowTruckIcon />}
              fallback="Not Available"
            >
              {currentDriver.trailer.capacity}
            </DescriptionItem>
          </Stack>
        </Stack>
      </InfoCard>
    </Box>
  );
}
