import { VEHICLE_TYPES } from '@superdispatch/sdk';
import { APIResponse } from 'shared/api/CarrierAPIClient';
import { isEmptyRecord } from 'shared/helpers/CommonHelpers';
import {
  yupArray,
  yupBoolean,
  yupDateString,
  yupEnum,
  yupNumber,
  yupObject,
  yupOmit,
  yupString,
} from 'shared/utils/YupUtils';
import { ArraySchema, InferType } from 'yup';
import { ContactDTO, contactSchema } from '../../contacts/data/ContactDTO';
import { ATTACHMENT_FILE_TYPES } from '../data/LoadDTO';
import {
  loadExpenseSchema,
  LoadPaymentDTO,
  loadPaymentSchema,
  LoadVehicleDTO,
  loadVehicleSchema,
  loadVenueSchema,
  LOAD_INSPECTION_TYPES,
  orderLoadSchema,
} from './LoadDTO';

//
// LoadDetailsEditDTO
//

export type LoadDetailsEditDTO = InferType<typeof loadDetailsEditSchema>;
export const loadDetailsEditSchema = yupObject({
  number: yupString().max(50),
  internal_load_id: yupString().max(25),
  instructions: yupString(),
  inspection_type: yupEnum(LOAD_INSPECTION_TYPES).required('Select method'),
});

//
// LoadPickupEditDTO
//

export type LoadPickupEditDTO = InferType<typeof loadPickupEditSchema>;
export const loadPickupEditSchema = yupObject({
  notes: yupString(),
  venue: loadVenueSchema,
  save_pickup_contact: yupBoolean(),
  scheduled_date: yupDateString('DateISO'),
  actual_date: yupDateString('DateTimeISO'),
});

//
// LoadDeliveryEditDTO
//

export type LoadDeliveryEditDTO = InferType<typeof loadDeliveryEditSchema>;
export const loadDeliveryEditSchema = yupObject({
  notes: yupString(),
  venue: loadVenueSchema,
  save_delivery_contact: yupBoolean(),
  scheduled_date: yupDateString('DateISO'),
  actual_date: yupDateString('DateTimeISO'),
});

//
// LoadCustomerEditDTO
//

export type LoadCustomerEditDTO = InferType<typeof loadCustomerEditSchema>;
export const loadCustomerEditSchema = yupObject({
  venue: loadVenueSchema,
  save_customer_contact: yupBoolean(),
});

//
// LoadVehicleCreateDTO
//

export type LoadVehicleCreateDTO = InferType<typeof loadVehicleCreateSchema>;
export const loadVehicleCreateSchema = loadVehicleSchema.shape({
  guid: yupOmit,
  load_guid: yupString(),
});

//
// LoadVehicleEditDTO
//

export function isEmptyLoadVehicleDTO({
  guid,
  type,
  is_inoperable,
  requires_enclosed_trailer,
  ...values
}: Partial<LoadVehicleDTO>): boolean {
  return (
    !is_inoperable &&
    !requires_enclosed_trailer &&
    (!type || type === 'other') &&
    isEmptyRecord(values)
  );
}

export type LoadVehicleEditDTO = InferType<typeof loadVehicleEditSchema>;
export const loadVehicleEditSchema = loadVehicleSchema.shape({
  load_guid: yupString(),
});

//
// LoadExpenseEditDTO
//

export function isEmptyLoadExpenseEditDTO({
  type,
  load_guid,
  show_on_invoice,
  deduct_from_driver_pay,
  ...values
}: LoadExpenseEditDTO) {
  return (
    type !== 'other' &&
    !show_on_invoice &&
    !deduct_from_driver_pay &&
    isEmptyRecord(values)
  );
}

export type LoadExpenseEditDTO = InferType<typeof loadExpenseEditSchema>;
export const loadExpenseEditSchema = loadExpenseSchema.shape({
  load_guid: yupString(),
});

//
// LoadCreateDTO
//

export type LoadCreateDTO = InferType<typeof loadCreateSchema>;
export type LoadCreateResponseDTO = APIResponse<{ guid: string }>;

export const loadCreateSchema = yupObject({
  number: yupString().max(50),
  internal_load_id: yupString().max(25),
  instructions: yupString(),
  inspection_type: yupEnum(LOAD_INSPECTION_TYPES).required('Select method'),

  save_pickup_contact: yupBoolean(),
  save_delivery_contact: yupBoolean(),
  save_customer_contact: yupBoolean(),

  pickup: yupObject({
    notes: yupString(),
    venue: loadVenueSchema,
    scheduled_date: yupDateString('DateISO'),
    actual_date: yupDateString('DateTimeISO'),
  }),

  delivery: yupObject({
    notes: yupString(),
    venue: loadVenueSchema,
    scheduled_date: yupDateString('DateISO'),
    actual_date: yupDateString('DateTimeISO'),
  }),

  customer: yupObject({
    venue: loadVenueSchema,
    shipper_profile_url: yupString(),
  }),
  payments: yupArray(loadPaymentSchema),
  vehicles: yupArray(
    yupObject({
      vin: yupString().max(200),
      make: yupString().max(300),
      model: yupString().max(300),
      year: yupNumber(),
      price: yupNumber(),
      type: yupEnum(VEHICLE_TYPES),
      color: yupString().max(200),
      lot_number: yupString().max(200),
      is_inoperable: yupBoolean(),
      requires_enclosed_trailer: yupBoolean(),
    }),
  ),

  attachments: yupArray(
    yupObject({
      name: yupString(),
      file_url: yupString(),
      type: yupEnum(ATTACHMENT_FILE_TYPES, 'any'),
    }),
  ),
});

//
// LoadBOLSendDTO
//

export type LoadBOLTemplate = (typeof LOAD_BOL_TEMPLATES)[number];
export const LOAD_BOL_TEMPLATES = [
  'default',
  'metrogistics',
  'multiple-units',
] as const;
export function formatLoadBOLTemplate(template: LoadBOLTemplate): string {
  switch (template) {
    default:
    case 'default':
      return 'Default';
    case 'metrogistics':
      return 'Metrogistics';
    case 'multiple-units':
      return 'Multiple units';
  }
}

export type LoadBOLSendDTO = InferType<typeof loadBOLSendSchema>;
export const loadBOLSendSchema = yupObject({
  email: yupString().email(),
  use_broker_info: yupBoolean(),
  template: yupEnum(LOAD_BOL_TEMPLATES),
});

export function isEmptyLoadPayment(
  payment: Partial<LoadPaymentDTO | undefined>,
): boolean {
  if (payment) {
    const { method, invoice, price, ...rest } = payment;
    return (
      method === 'other' &&
      isEmptyRecord(rest) &&
      !price &&
      isEmptyRecord(invoice)
    );
  }
  return true;
}

//Send Invoice
export type CustomerDTO = InferType<typeof customerSchema>;
const customerSchema = yupObject({
  name: yupString().required('Enter Customer Name'),
  contacts: yupArray(contactSchema).notRequired(),
});

export type LoadSendInvoiceDTO = InferType<typeof sendInvoiceSchema>;
export const sendInvoiceSchema = yupObject({
  customer: customerSchema.required(),
  invoice_id: yupString()
    .optional()
    .max(50, 'Maximum length for Invoice ID is 50 characters'), //max 50 charcaters but not required,
  invoice_date: yupDateString('DateTimeISO').required('Enter Invoice Date'),
  orders: yupArray(orderLoadSchema).min(1, 'Enter Load Id'),
  bol_template: yupString().notRequired(),
  email: yupBoolean().notRequired(),
  factor_this_invoice: yupBoolean().nullable().notRequired(),
  emails: yupArray(contactSchema)
    .required()
    .when('email', validateInvoiceEmails),
  is_carrier_requested_superpay: yupBoolean().nullable(),
});

function validateInvoiceEmails(
  email: boolean,
  emailsSchema: ArraySchema<ContactDTO>,
) {
  if (email) {
    return emailsSchema
      .min(1, 'Select at least one email')
      .required()
      .test('emails', 'Enter valid email', (contacts: ContactDTO[]) => {
        return contacts.every((item) => {
          return (
            item.email !== '' && yupString().email().isValidSync(item.email)
          );
        });
      });
  }
  return emailsSchema;
}

export type SendQuickBooksDTO = InferType<typeof sendQuickBooksSchema>;
export const sendQuickBooksSchema = yupObject({
  customer_name: yupString(),
  invoice_date: yupString(),
  invoice_number: yupString(),
  order_guids: yupArray(yupString()),
});

export type SendInvoiceEmailDTO = InferType<typeof sendInvoiceEmail>;
export const sendInvoiceEmail = yupObject({
  bol_template: yupString(),
  customer_name: yupString(),
  email: yupString(),
  factor_this_invoice: yupBoolean().nullable(),
  invoice_date: yupString(),
  invoice_number: yupString(),
  load_guids: yupArray(yupString()),
  attachments: yupArray(yupNumber()),
  is_carrier_requested_superpay: yupBoolean().nullable().notRequired(),
});
