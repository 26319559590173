import { Typography } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { Color, Column, Columns, Stack } from '@superdispatch/ui';
import { MobileAppBridge } from 'shared/data/MobileAppBridge';
import { startIntercomTour } from 'shared/helpers/Intercom';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import { ChatIcon } from 'shared/icons/ChatIcon';
import { ClockIcon } from 'shared/icons/ClockIcon';
import { CompletedIcon } from 'shared/icons/CompletedIcon';
import { LoadsSidebarIcon } from 'shared/icons/LoadsSidebarIcon';
import { VerificationIcon } from 'shared/icons/VerificationIcon';
import { WarningRoundedIcon } from 'shared/icons/WarningRounded';
import { becomeVerifiedCarrier } from 'shared/modules/loadboard/core/BecomVerifiedCarrier';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';
import { GET_TO_KNOW_CARRIER_TMS_TOUR_ID } from '../data/OnboardingConstants';
import { OnboardingStep } from '../data/OnboardingDTO';
import { OnboardingTaskItem } from './OnboardingTaskItem';
import { useOnboardingDialogState } from './OnboardingWrapper';
interface OnboardingVerificationTasksProps {
  onboardingSteps?: OnboardingStep;
  onClose: () => void;
}

export function OnboardingVerificationTasks({
  onboardingSteps,
  onClose,
}: OnboardingVerificationTasksProps) {
  return <CompletedSteps onboardingSteps={onboardingSteps} onClose={onClose} />;
}

interface CompletedStepsProps {
  onboardingSteps?: OnboardingStep;
  onClose: () => void;
}

export function CompletedSteps({
  onboardingSteps,
  onClose,
}: CompletedStepsProps) {
  const { data: settings } = useCarrierSettings();
  const { closeOnboardingDialog } = useOnboardingDialogState();

  const isVerified = settings?.carrier.verification_status === 'approved';
  const isVerificationDenied =
    settings?.carrier.verification_status === 'declined' ||
    settings?.carrier.verification_status === 'non_verifiable' ||
    settings?.carrier.verification_status === 'revoked';
  const steps = onboardingSteps?.steps;

  const isCreateOrImportLoadCompleted =
    steps?.tour_create_or_import_load?.status === 'started';
  const isGetToKnowCarrierTMSCompleted =
    steps?.get_to_know_ctms?.status === 'started';
  const isSLBSearchTourCompleted = steps?.tour_slb_search?.status === 'started';

  return (
    <Columns align="top" space="small">
      <Column width="content">
        <CompletedIcon />
      </Column>
      <Column width="fluid">
        <Stack space="small">
          <Typography variant="h3">Your completed steps</Typography>
          {isCreateOrImportLoadCompleted && (
            <OnboardingTaskItem
              name="Create or Import Load"
              description="Manage your loads with Carrier TMS and streamline your operations."
              icon={<LoadsSidebarIcon htmlColor={Color.Dark100} />}
              done={isCreateOrImportLoadCompleted}
              onClick={() => {
                openExternalURL(
                  '/tms/loads?launch_create_import_loads_tour=true',
                );
                onClose();
              }}
            />
          )}
          <OnboardingTaskItem
            done={isVerified}
            icon={
              isVerificationDenied ? (
                <WarningRoundedIcon />
              ) : isVerified ? (
                <VerificationIcon />
              ) : (
                <ClockIcon />
              )
            }
            name={
              isVerificationDenied
                ? 'Verification Documents Need Review'
                : isVerified
                ? 'Become Verified Carrier'
                : 'Verification in Progress'
            }
            description={
              isVerificationDenied
                ? 'Your Verified Carrier documents need review.'
                : isVerified
                ? 'Unlock unlimited and free load access. Apply now and start booking.'
                : 'Your verification application will be processed within 24-48 hours. We’ll keep you updated on the progress, and once approved, you can start requesting loads.'
            }
            onClick={() => {
              if (MobileAppBridge.isInjected()) {
                MobileAppBridge.openVerifiedCarrierPromotionalModal();
              } else {
                becomeVerifiedCarrier(settings?.carrier.verification_status, {
                  source: 'onboarding_dialog',
                });
              }
              closeOnboardingDialog();
            }}
          />
          {isGetToKnowCarrierTMSCompleted && (
            <OnboardingTaskItem
              done={isGetToKnowCarrierTMSCompleted}
              name="Get to Know Carrier TMS"
              description="Take a quick tour to master Carrier TMS."
              icon={<ChatIcon />}
              onClick={() => {
                startIntercomTour(GET_TO_KNOW_CARRIER_TMS_TOUR_ID);
                onClose();
              }}
            />
          )}
          {isSLBSearchTourCompleted && (
            <OnboardingTaskItem
              done={isSLBSearchTourCompleted}
              name="Explore Super Loadboard"
              description="Access loads now! More loads unlock once you're verified."
              icon={<Search htmlColor={Color.Dark100} />}
              onClick={() => {
                openExternalURL('/loadboard?launch_slb_search_tour=true');
                onClose();
              }}
            />
          )}
        </Stack>
      </Column>
    </Columns>
  );
}
