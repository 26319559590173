import { useMemo } from 'react';
import { MarkerProps } from 'shared/form/LeafletMap';
import { DeliveryDownIcon } from 'shared/icons/DeliveryDownIcon';
import { OriginUpIcon } from 'shared/icons/OriginUpIcon';
import { joinStrings } from 'shared/utils/StringUtils';
import deliveryFilledImg from '../assets/delivery-filled.png';
import endPinFilledImg from '../assets/end-pin-filled.png';
import pickupFilledImg from '../assets/pickup-filled.png';
import startPinFilledImg from '../assets/start-pin-filled.png';
import { TripMarkerPopup } from '../core/TripMarkerPopup';
import { AddressDTO, CreateTripLoadDTO } from './TripsDTO';
import { getMarkerIcon } from './TripsUtils';

interface TripMarkersProps {
  loads?: CreateTripLoadDTO[];
  start_address?: AddressDTO | undefined | null;
  end_address?: AddressDTO | undefined | null;
  fieldName: string;
}

export function useCreateTripMarkers({
  loads,
  start_address,
  end_address,
  fieldName,
}: TripMarkersProps) {
  const markers = useMemo((): MarkerProps[] => {
    let markersArray: MarkerProps[] = [];

    if (loads && loads.length > 0) {
      for (const [index, load] of loads.entries()) {
        if (load.pickup_latitude && load.pickup_longitude) {
          markersArray.push({
            name: `loads[${index}]`,
            type: 'pickup',
            position: [load.pickup_latitude, load.pickup_longitude],
            iconUrl: pickupFilledImg,
            popup: (
              <TripMarkerPopup
                icon={<OriginUpIcon />}
                caption={load.origin}
                loadId={load.load_id}
              />
            ),
          });
        }
        if (load.delivery_latitude && load.delivery_longitude) {
          markersArray.push({
            name: `loads[${index}]`,
            type: 'delivery',
            position: [load.delivery_latitude, load.delivery_longitude],
            iconUrl: deliveryFilledImg,
            popup: (
              <TripMarkerPopup
                icon={<DeliveryDownIcon />}
                caption={load.destination}
                loadId={load.load_id}
              />
            ),
          });
        }
      }
    }
    if (start_address?.latitude && start_address.longitude) {
      markersArray.push({
        name: 'start_address',
        position: [start_address.latitude, start_address.longitude],
        iconUrl: startPinFilledImg,
        type: 'pickup',
        popup: (
          <TripMarkerPopup
            icon={<OriginUpIcon />}
            caption={joinStrings(', ', start_address.state, start_address.zip)}
            loadId={start_address.address}
          />
        ),
      });
    }
    if (end_address?.latitude && end_address.longitude) {
      markersArray.push({
        name: 'end_address',
        position: [end_address.latitude, end_address.longitude],
        iconUrl: endPinFilledImg,
        type: 'delivery',
        popup: (
          <TripMarkerPopup
            icon={<DeliveryDownIcon />}
            caption={joinStrings(', ', end_address.state, end_address.zip)}
            loadId={end_address.address}
          />
        ),
      });
    }

    if (fieldName) {
      markersArray = markersArray.map((marker) =>
        getMarkerIcon(marker, fieldName),
      );
    }
    return markersArray;
  }, [loads, start_address, end_address, fieldName]);

  return markers;
}
