import { createSvgIcon } from 'shared/icons/IconUtils';

export const FlagIcon = createSvgIcon(
  'FlagIcon',
  <path
    d="M1.83332 8.66659V13.6666C1.83332 13.9027 1.75346 14.1006 1.59374 14.2603C1.43402 14.4201 1.2361 14.4999 0.99999 14.4999C0.763879 14.4999 0.565962 14.4201 0.40624 14.2603C0.246518 14.1006 0.166656 13.9027 0.166656 13.6666V1.16659C0.166656 0.930474 0.246518 0.732558 0.40624 0.572835C0.565962 0.413113 0.763879 0.333252 0.99999 0.333252H6.97916C7.1736 0.333252 7.34721 0.395752 7.49999 0.520752C7.65277 0.645752 7.74999 0.805474 7.79166 0.999919L7.99999 1.99992H11.8333C12.0694 1.99992 12.2674 2.07978 12.4271 2.2395C12.5868 2.39922 12.6667 2.59714 12.6667 2.83325V9.49992C12.6667 9.73603 12.5868 9.93395 12.4271 10.0937C12.2674 10.2534 12.0694 10.3333 11.8333 10.3333H7.52082C7.32638 10.3333 7.15277 10.2708 6.99999 10.1458C6.84721 10.0208 6.74999 9.86103 6.70832 9.66659L6.49999 8.66659H1.83332Z"
    fill="currentColor"
  />,
  {
    viewBox: '0 0 13 15',
    style: { height: '15px', width: '13px' },
  },
);
