import { Dialog, DialogActions, DialogTitle } from '@material-ui/core';
import { useSnackbarStack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { Form, FormikProps, FormikProvider } from 'formik';
import { useAppFormik } from 'shared/form/AppFormik';
import { yupObject, yupString } from 'shared/utils/YupUtils';
import { useDeleteTripInternalNote, useTripsCache } from '../data/TripsAPI';

interface TripDeleteInternalNoteConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
  guid: string;
  authorName: string;
  tripGUID: string;
}

export function TripDeleteInternalNoteConfirmationDialog({
  guid,
  authorName,
  open,
  onClose,
  tripGUID,
}: TripDeleteInternalNoteConfirmationDialogProps) {
  const { invalidateTrip } = useTripsCache();
  const { addSnackbar } = useSnackbarStack();
  const { mutateAsync: deleteTripInternalNote } = useDeleteTripInternalNote();

  const formik = useAppFormik({
    initialValues: { guid },
    validationSchema: yupObject({
      guid: yupString().required(),
    }),
    onSubmit: () => {
      return deleteTripInternalNote({ id: guid });
    },
    onSubmitSuccess: () => {
      invalidateTrip(tripGUID);
      onClose();
    },
    onSubmitFailure: (error) => {
      addSnackbar(
        error.message || 'Failed to delete internal note, contact support.',
        { variant: 'error' },
      );
    },
  });

  return (
    <Dialog
      open={open}
      onClose={() => {
        if (!formik.isSubmitting) {
          onClose();
        }
      }}
    >
      <FormikProvider value={formik}>
        <Content formik={formik} authorName={authorName} onClose={onClose} />
      </FormikProvider>
    </Dialog>
  );
}

interface ContentProps {
  authorName: string;
  onClose: () => void;
  formik: FormikProps<{ guid: string }>;
}

function Content({ authorName, onClose, formik }: ContentProps) {
  return (
    <Form>
      <DialogTitle>
        Delete notes from <b>{authorName}</b>?
      </DialogTitle>
      <DialogActions>
        <Button
          variant="neutral"
          onClick={onClose}
          disabled={formik.isSubmitting}
        >
          Cancel
        </Button>
        <Button variant="critical" type="submit" pending={formik.isSubmitting}>
          Delete
        </Button>
      </DialogActions>
    </Form>
  );
}
