import { InputAdornment, Typography } from '@material-ui/core';
import { ErrorRounded } from '@material-ui/icons';
import { Color, Inline } from '@superdispatch/ui';
import { useField } from 'formik';
import { useSetAtom } from 'jotai';
import { useState } from 'react';
import {
  FormikAddressAutofill,
  FormikAddressAutofillProps,
} from 'shared/form/FormikAddressAutofill';
import { useMapValue } from 'shared/form/LeafletMap';
import { Geocoding } from 'shared/geo/GeoHelpers';
import { FlagIcon } from 'shared/icons/FlagIcon';
import { joinStrings } from 'shared/utils/StringUtils';
import { AddressDTO } from '../data/TripsDTO';
import { hoveredFieldNameAtom } from '../TripsDetails';

interface FormikVenueAddressAutofillProps
  extends Omit<FormikAddressAutofillProps, 'onGeocode'> {
  onSelect?: (value: Geocoding | null, name: string) => void;
}

export function FormikVenueAddressAutofill({
  name,
  startText,
  onSelect,
  ...props
}: FormikVenueAddressAutofillProps) {
  const map = useMapValue();

  const setHoveredFieldName = useSetAtom(hoveredFieldNameAtom);
  const [isFocused, setFocused] = useState(false);
  const [field, { error: fieldError }, { setValue }] =
    useField<AddressDTO | null>({
      name,
    });

  const isStart = name.includes('start');

  return (
    <FormikAddressAutofill
      {...props}
      disableClearable={false}
      name={`${name}.address`}
      errorMsg={fieldError}
      placeholder={`Custom address where the trip ${
        isStart ? 'starts' : 'ends'
      }`}
      onMouseLeave={() => {
        setHoveredFieldName('');
      }}
      onMouseOver={() => {
        setHoveredFieldName(name);
      }}
      onFocus={() => {
        setFocused(true);
      }}
      onBlur={() => {
        setFocused(false);
      }}
      startIcon={
        <InputAdornment position="start">
          <Inline noWrap={true} space="xsmall" verticalAlign="center">
            {fieldError ? (
              <ErrorRounded htmlColor={Color.Red500} />
            ) : (
              <FlagIcon
                htmlColor={
                  isFocused
                    ? Color.Blue300
                    : field.value?.address
                    ? Color.Dark500
                    : Color.Dark100
                }
              />
            )}
            <Typography variant="body1" color="textSecondary">
              {startText}
            </Typography>
          </Inline>
        </InputAdornment>
      }
      onGeocode={(nextValue) => {
        if (nextValue) {
          void setValue({
            city: nextValue.place || nextValue.region || '',
            latitude: nextValue.latitude || null,
            zip: nextValue.postcode || null,
            longitude: nextValue.longitude || null,
            state: nextValue.region_short || nextValue.region || '',
            address:
              joinStrings(' ', nextValue.house_number, nextValue.address) ||
              nextValue.place_name ||
              '',
          });
          setHoveredFieldName(name);
          onSelect?.(nextValue, name);
          if (nextValue.latitude && nextValue.longitude) {
            map?.flyTo([nextValue.latitude, nextValue.longitude], 5);
          }
        } else {
          void setValue(null);
          setHoveredFieldName('');
          onSelect?.(null, name);
        }
      }}
    />
  );
}
