import { useMemo } from 'react';
import { useLocation } from 'react-router-dom-v5-compat';
import { LoadOfferSidebarIcon } from 'shared/icons/LoadOfferSidebarIcon';
import { LoadsSidebarIcon } from 'shared/icons/LoadsSidebarIcon';
import { LoadSuggestionsSidebarIcon } from 'shared/icons/LoadSuggestionsSidebarIcon';
import { SLBSidebarIcon } from 'shared/icons/SLBSidebarIcon';
import { useDispatcherProfile } from 'shared/modules/dispatcher/DispatcherAPI';
import { useLoadOffer } from 'shared/modules/load-offers/loadOfferCount';
import { useLoadSuggestionsUnseenCount } from 'shared/modules/load-suggestions/data/LoadSuggestionsAPI';
import { useShowAddBillingAddressDialog } from 'shared/modules/subscription/core/useShowAddBillingAddressDialog';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';
import { useFlag } from 'shared/settings/FeatureToggles';
import { useDispatcherPasswordEnhancement } from '../../../carrier/dispatchers/data/DispatcherProfileSecurityEnhancement';
import { useDispatcherProfileVisitedValue } from './IsDispatcherProfileVisited';
import { useLoadboardNudgeIndicator } from './useLoadboardNudgeIndicator';

export function useBottomItems() {
  const { data: settings } = useCarrierSettings();
  const { data: dispatcher } = useDispatcherProfile();
  const shouldShowUpdatePassword = useFlag('show_update_password');
  const isEnteredDispatcherProfile = useDispatcherProfileVisitedValue();
  const shouldShowPasswordReminder =
    useDispatcherPasswordEnhancement(dispatcher);
  const loadOffersCount = useLoadOffer();
  const { doesBillingAddressNotExist } = useShowAddBillingAddressDialog();

  const { data: loadSuggestionCount } = useLoadSuggestionsUnseenCount();

  const location = useLocation();
  const isSuperPayBankError = !!settings?.superpay.payment_error;

  const shouldHideLoadboardCounter = useFlag('ctms_hide_loadboard_counter');
  const { isIndicatorVisible } = useLoadboardNudgeIndicator();

  const bottomItems = useMemo(() => {
    return [
      {
        label: 'Loads',
        value: '/tms/loads',
        icon: <LoadsSidebarIcon />,
        active: location.pathname.includes('tms/loads'),
      },
      {
        value: 'load-offers',
        label: 'Offers',
        icon: <LoadOfferSidebarIcon />,
        hasBadge: Boolean(loadOffersCount),
        badgeContent: loadOffersCount,
      },
      {
        value: 'suggested-loads',
        label: 'Suggested',
        icon: <LoadSuggestionsSidebarIcon />,
        hasBadge: Boolean(loadSuggestionCount?.number_of_unseen_suggestions),
        badgeContent: loadSuggestionCount?.number_of_unseen_suggestions,
      },
      {
        value: '/loadboard',
        label: 'Loadboard',
        icon: <SLBSidebarIcon />,
        active: location.pathname.includes('loadboard'),
        hasBadge: shouldHideLoadboardCounter || isIndicatorVisible,
        badgeContent: undefined,
      },
    ];
  }, [
    location,
    loadOffersCount,
    loadSuggestionCount,
    shouldHideLoadboardCounter,
    isIndicatorVisible,
  ]);

  const hasMenuBadge = doesBillingAddressNotExist
    ? !doesBillingAddressNotExist
    : isSuperPayBankError
    ? !isSuperPayBankError
    : !shouldShowUpdatePassword ||
      !!isEnteredDispatcherProfile ||
      !shouldShowPasswordReminder;

  return { bottomItems, hasMenuBadge };
}
