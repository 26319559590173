import { Link, Typography } from '@material-ui/core';
import { Inline } from '@superdispatch/ui';
import { Banner, Button } from '@superdispatch/ui-lab';
import { useState } from 'react';
import { useQuery } from 'shared/helpers/RoutingHelpers';
import { useLatestShipperRating } from 'shared/modules/ratings/data/ShipperRatingsAPI';
import { RateShipperDialog } from 'shared/modules/ratings/RateShipperDialog';
import { LoadDTO } from '../data/LoadDTO';

function extractShipperGUID(url: string) {
  // todo get from backend
  const match = /\/loadboard\/shipper\/([a-z0-9-]+)/.exec(url);
  return match?.[1] ?? null;
}

interface Props {
  load: LoadDTO;
}

export function ViewLoadRatingBanner({ load }: Props) {
  const [_, setQuery] = useQuery();
  const shipperGUID = extractShipperGUID(load.customer.shipper_profile_url);

  const [isOpen, setIsOpen] = useState(false);
  const { data } = useLatestShipperRating(shipperGUID);
  const shouldShowBanner = data && !data.guid && !!shipperGUID;

  return (
    <>
      {shipperGUID && (
        <RateShipperDialog
          isOpen={isOpen}
          shipperGUID={shipperGUID}
          onClose={() => {
            setIsOpen(false);
          }}
        />
      )}

      <Banner
        border="top"
        in={shouldShowBanner}
        mountOnEnter={true}
        unmountOnExit={true}
        aria-label="Rating Banner"
      >
        <Inline verticalAlign="center" horizontalAlign="center" space="small">
          <Inline
            space="xxsmall"
            verticalAlign="center"
            horizontalAlign="center"
          >
            <Typography>Rate your recent experience with </Typography>
            <Link
              target="_blank"
              rel="noreferrer"
              color="inherit"
              href={load.customer.shipper_profile_url}
            >
              {load.customer.venue.name}
            </Link>
          </Inline>

          <Button
            size="small"
            variant="neutral"
            onClick={() => {
              setIsOpen(true);
              setQuery({ utm_medium: 'Order View' });
            }}
          >
            Rate Shipper
          </Button>
        </Inline>
      </Banner>
    </>
  );
}
