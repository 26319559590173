import { Color } from '@superdispatch/ui';
import { createSvgIcon } from 'shared/icons/IconUtils';

export const AddDriverIcon = createSvgIcon(
  'AddDriverIcon',
  <path
    d="M14.9999 11.6673V9.16732H12.4999V7.50065H14.9999V5.00065H16.6666V7.50065H19.1666V9.16732H16.6666V11.6673H14.9999ZM7.49992 10.0007C6.58325 10.0007 5.79853 9.67426 5.14575 9.02148C4.49297 8.36871 4.16658 7.58398 4.16658 6.66732C4.16658 5.75065 4.49297 4.96593 5.14575 4.31315C5.79853 3.66037 6.58325 3.33398 7.49992 3.33398C8.41658 3.33398 9.20131 3.66037 9.85408 4.31315C10.5069 4.96593 10.8333 5.75065 10.8333 6.66732C10.8333 7.58398 10.5069 8.36871 9.85408 9.02148C9.20131 9.67426 8.41658 10.0007 7.49992 10.0007ZM0.833252 16.6673V14.334C0.833252 13.8618 0.95478 13.4277 1.19784 13.0319C1.44089 12.6361 1.76381 12.334 2.16659 12.1257C3.0277 11.6951 3.9027 11.3722 4.79158 11.1569C5.68047 10.9416 6.58325 10.834 7.49992 10.834C8.41658 10.834 9.31936 10.9416 10.2083 11.1569C11.0971 11.3722 11.9721 11.6951 12.8333 12.1257C13.236 12.334 13.5589 12.6361 13.802 13.0319C14.0451 13.4277 14.1666 13.8618 14.1666 14.334V16.6673H0.833252Z"
    fill={Color.Dark100}
  />,
  { viewBox: '0 0 20 20', width: 20, height: 20, fill: 'none' },
);
