import { useValueObserver } from '@superdispatch/hooks';
import { Location } from 'history';
import { useLocation } from 'react-router-dom-v5-compat';

export function useLocationChangeEffect(
  effect: (location: Location, previousLocation: Location) => void,
): void {
  const location = useLocation();

  useValueObserver(location, (previousLocation) => {
    effect(location, previousLocation);
  });
}
