import { Color } from '@superdispatch/ui';
import { createSvgIcon } from 'shared/icons/IconUtils';

export const OriginUpIcon = createSvgIcon(
  'OriginUpIcon',

  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M19.5001 10.6302L17.7062 12.4566L13.2569 7.90254L13.2569 21H10.7432L10.7432 7.90254L6.29392 12.4566L4.50006 10.6302L12.006 3L19.5001 10.6302Z"
    fill={Color.Yellow300}
  />,
  { viewBox: '0 0 24 24', width: 24, height: 24, fill: 'none' },
);
