import { useMemo } from 'react';
import { APIPageQueryOptions } from 'shared/api/APIPageQuery';
import { useInsurance } from 'shared/modules/verified-carrier-application/data/CarrierProfileAPI';
import { CargoInsuranceDTO } from 'shared/modules/verified-carrier-application/data/CarrierProfileDTO';

export function useInsurances(
  options?: APIPageQueryOptions<CargoInsuranceDTO>,
) {
  const { data, isLoading } = useInsurance(options);
  return useMemo(() => {
    const currentInsurance = data?.data.find(
      (insurance) => insurance.is_current,
    );
    const renewalInsurance = data?.data.find(
      (insurance) => !insurance.is_current,
    );

    return {
      isLoading,
      currentInsurance,
      renewalInsurance,
    };
  }, [data, isLoading]);
}
