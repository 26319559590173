import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom-v5-compat';
import { LocalStore } from 'shared/helpers/Store';
import { ONBOARDING_FIRST_LOAD_TOUR_STORAGE_KEY } from 'shared/modules/onboarding/data/OnboardingConstants';
import { useFlag } from 'shared/settings/FeatureToggles';
import { AddLoadPage } from './add/AddLoadPage';
import { listenToLoadEvents } from './data/LoadEvents';
import { LoadsContextProvider } from './data/LoadsContext';
import { EditLoadPage } from './edit-page/EditLoadPage';
import { LoadsPageContextProvider } from './loads-page/data/LoadsPageParamsContext';
import { LoadsPage } from './loads-page/LoadsPage';
import { ViewLoadPage } from './view/ViewLoadPage';
import { ViewLoadPagePrintPage } from './view/ViewLoadPagePrintPage';

export function LoadsContainerPage() {
  const shouldPersistParams = useFlag('react_loads_page_filter_persist');

  useEffect(() => {
    listenToLoadEvents((event) => {
      if (event === 'created' || event === 'imported') {
        LocalStore.set(ONBOARDING_FIRST_LOAD_TOUR_STORAGE_KEY, 'pending');
      }
    });
  }, []);

  return (
    <LoadsContextProvider>
      <Routes>
        <Route path="add" element={<AddLoadPage />} />

        <Route path=":loadGUID/edit" element={<EditLoadPage />} />

        <Route path=":loadGUID/print" element={<ViewLoadPagePrintPage />} />

        <Route path=":loadGUID/*" element={<ViewLoadPage />} />

        <Route
          index={true}
          element={
            <LoadsPageContextProvider shouldPersistParams={shouldPersistParams}>
              <LoadsPage />
            </LoadsPageContextProvider>
          }
        />
      </Routes>
    </LoadsContextProvider>
  );
}
