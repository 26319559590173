import {
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { Archive, Delete, MoreVert, Unarchive } from '@material-ui/icons';
import { FormattedDate } from '@superdispatch/dates';
import React, { useState } from 'react';
import { useLocation, useMatch, useNavigate } from 'react-router-dom-v5-compat';
import { formatPlural } from 'shared/helpers/IntlHelpers';
import styled from 'styled-components';
import { TripShortDTO } from '../data/TripsDTO';
import { TripStatuses } from './TripStatuses';

const StyledTableRow = styled(TableRow)`
  cursor: pointer;
`;

interface TripsTableRowProps extends TripShortDTO {
  path: string;
}

export function TripsTableRow({
  guid,
  archived,
  name,
  loads,
  driver,
  changed_at: changedAt,
  path: pathname,
}: TripsTableRowProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const match = useMatch(pathname);
  const [menuAnchorElement, setMenuAnchorElement] =
    useState<HTMLElement | null>(null);

  const handleRowClick = (event: React.MouseEvent) => {
    if (event.button === 0) {
      event.preventDefault();
      navigate(`/trips/view/${guid}`);
    }
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setMenuAnchorElement(event.currentTarget);
  };

  return (
    <>
      <StyledTableRow key={guid} hover={true} onClick={handleRowClick}>
        <TableCell>
          <strong>{name}</strong>
        </TableCell>
        <TableCell>
          {loads.total}&nbsp;
          {formatPlural(loads.total, 'load', 'loads')}
        </TableCell>
        <TableCell>
          <TripStatuses loads={loads} />
        </TableCell>
        <TableCell>{driver?.name || driver?.email}</TableCell>
        <TableCell>
          <FormattedDate variant="Date" date={changedAt} />
        </TableCell>
        <TableCell align="right">
          <IconButton
            onClick={handleMenuClick}
            aria-label={`${name} trip actions`}
          >
            <MoreVert />
          </IconButton>
        </TableCell>
      </StyledTableRow>

      {menuAnchorElement && (
        <Menu
          anchorEl={menuAnchorElement}
          open={Boolean(menuAnchorElement)}
          onClose={() => {
            setMenuAnchorElement(null);
          }}
        >
          {archived ? (
            <MenuItem
              onClick={() => {
                setMenuAnchorElement(null);
                navigate({
                  pathname: `${match?.pathname}/unarchive/${guid}`,
                  search: location.search,
                });
              }}
            >
              <Unarchive fontSize="medium" color="action" /> Unarchive
            </MenuItem>
          ) : (
            <MenuItem
              onClick={() => {
                setMenuAnchorElement(null);
                navigate({
                  pathname: `${match?.pathname}/archive/${guid}`,
                  search: location.search,
                });
              }}
            >
              <Archive fontSize="medium" color="action" /> Archive
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              setMenuAnchorElement(null);
              navigate({
                pathname: `${match?.pathname}/delete/${guid}`,
                search: location.search,
              });
            }}
          >
            <Delete fontSize="medium" color="action" /> Delete
          </MenuItem>
        </Menu>
      )}
    </>
  );
}
