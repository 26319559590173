import { useFormikEnhanced } from '@superdispatch/forms';
import { useSnackbarStack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { APIError } from 'shared/api/APIError';
import { APIResponse } from 'shared/api/CarrierAPIClient';
import { isWebView } from 'shared/constants/AppTypeConstants';
import { useInitialValues } from 'shared/form/AppFormik';
import { DocumentTitle } from 'shared/layout/DocumentTitle';
import { ProfileStateMotorCarrierPermitForm } from 'shared/modules/verified-carrier-application/core/ProfileBusinessLicenseForm';
import { trackCarrierProfileEvent } from 'shared/modules/verified-carrier-application/data/CarrierProfileAnalytics';
import {
  useBusinessLicense,
  useCarrierProfileAPI,
} from 'shared/modules/verified-carrier-application/data/CarrierProfileAPI';
import {
  BusinessLicenseDTO,
  businessLicenseSchema,
} from 'shared/modules/verified-carrier-application/data/CarrierProfileDTO';
import {
  useSubmitVerificationDocument,
  VerificationDialogs,
} from './core/VerificationDialogs';
import { ProfilePageLayout } from './ProfilePageLayout';

export function ProfileBusinessLicensePage() {
  const { addSnackbar } = useSnackbarStack();
  const { updateBusinessLicense } = useCarrierProfileAPI();
  const { data, isLoading } = useBusinessLicense({
    onError: () => {
      addSnackbar('Failed to fetch Business License', {
        variant: 'error',
      });
    },
  });

  const initialValues = useInitialValues(businessLicenseSchema, data);

  const formik = useFormikEnhanced<BusinessLicenseDTO, APIResponse>({
    initialValues,
    validationSchema: businessLicenseSchema,
    onSubmit: updateBusinessLicense,
    onSubmitSuccess: () => {
      addSnackbar('Business License was successfully updated', {
        variant: 'success',
      });

      trackCarrierProfileEvent({
        name: 'Carrier Updated Carrier Info',
        utm_medium: 'Carrier Profile',
        utm_content: 'Business License',
      });
    },
    onSubmitFailure: ({ message }: APIError) => {
      addSnackbar(`Failed to update Business License: ${message}`, {
        variant: 'error',
      });
    },
  });

  const submitVerificationForm = useSubmitVerificationDocument(formik);

  return (
    <>
      <DocumentTitle title="Business License - Profile" />

      <VerificationDialogs formik={formik} />

      <ProfilePageLayout
        formik={formik}
        title="Business License"
        isLoading={isLoading}
        headerEndActions={
          <Button
            type="button"
            onClick={submitVerificationForm}
            pending={formik.isSubmitting}
            disabled={!formik.dirty}
            fullWidth={isWebView}
          >
            Save
          </Button>
        }
      >
        <ProfileStateMotorCarrierPermitForm />
      </ProfilePageLayout>
    </>
  );
}
