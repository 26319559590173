import { Card, CardContent, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Column, Columns, Inline, Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { useEffect } from 'react';
import { useLocation, useMatch, useNavigate } from 'react-router-dom-v5-compat';
import { trackEvent } from 'shared/helpers/Analytics';
import {
  hideIntercomLauncher,
  showIntercomLauncher,
} from 'shared/helpers/Intercom';
import { useQuery } from 'shared/helpers/RoutingHelpers';
import { useErrorSnackbar } from 'shared/hooks/useErrorSnackbar';
import { DocumentTitle } from 'shared/layout/DocumentTitle';
import {
  useBillingAddress,
  useSubscriptionSettings,
} from 'shared/modules/subscription/SubscriptionAPI';
import {
  BillingLocationState,
  SubscriptionEditBillingAddressDrawer,
} from 'shared/modules/subscription/SubscriptionEditBillingAddressDrawer';
import { SubscriptionReactivationDrawer } from 'shared/modules/subscription/SubscriptionReactivationDrawer';
import {
  LocationState,
  SubscriptionSalesTaxDrawer,
} from 'shared/modules/subscription/SubscriptionSalesTaxDrawer';
import {
  SubscriptionUpdateCardDetailsDrawer,
  SubscriptionUpdateCardDetailsDrawerProps,
} from 'shared/modules/subscription/SubscriptionUpdateCardDetailsDrawer';
import { SettingsPageLayout } from './core/SettingsPageLayout';
import { SubscriptionPaymentCard } from './subscription/SubscriptionPaymentCard';
import { SubscriptionPaymentInformation } from './subscription/SubscriptionPaymentInformation';
import { SettingsSubscriptionPlan } from './subscription/SubscriptionPlan';

function SubscriptionUpdateCardDetailsDrawerRoute(
  props: Omit<SubscriptionUpdateCardDetailsDrawerProps, 'open' | 'onClose'>,
) {
  const navigate = useNavigate();
  const match = useMatch('/settings/subscription/update_payment_info');

  return (
    <SubscriptionUpdateCardDetailsDrawer
      {...props}
      open={!!match}
      onClose={() => {
        navigate('/settings/subscription', { replace: true });
      }}
    />
  );
}

interface SubscriptionUpgradePlanDrawerRouteProps {
  refetch: () => void;
}

function SubscriptionUpgradePlanDrawerRoute({
  refetch,
}: SubscriptionUpgradePlanDrawerRouteProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const match = useMatch('/settings/subscription/upgrade_plan');

  const state = location.state as LocationState | undefined;

  if (state && !!match) {
    const { billingAddress, cardDetails, token, vaultToken } = state;

    return (
      <SubscriptionSalesTaxDrawer
        open={!!match}
        token={token}
        vaultToken={vaultToken}
        billingAddress={billingAddress}
        cardDetails={cardDetails}
        onClose={() => {
          navigate('/settings/subscription', {
            replace: true,
            state: undefined,
          });
        }}
        onSubmitSuccess={refetch}
      />
    );
  }

  return null;
}

function LoadingCard() {
  return (
    <Card aria-label="Plan">
      <CardContent>
        <Stack space="small">
          <Inline verticalAlign="bottom">
            <Typography display="inline" variant="h3">
              <Skeleton width={200} />
            </Typography>
          </Inline>
          <Stack space={['small', 'xsmall']}>
            <Skeleton width={240} />
            <Skeleton />
            <Skeleton />
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}

function EditBillingAddress() {
  const location = useLocation();
  const navigate = useNavigate();
  const { refetch } = useBillingAddress();
  const editBillingAddressmatch = useMatch(
    '/settings/subscription/edit_billing_address',
  );

  const state = location.state as BillingLocationState | undefined;

  return (
    <SubscriptionEditBillingAddressDrawer
      open={!!editBillingAddressmatch}
      enableReactivation={state?.enableReactivation}
      canAddBilling={state?.canAddBilling}
      onClose={() => {
        navigate('/settings/subscription', { replace: true });
      }}
      onSubmitSuccess={refetch}
    />
  );
}

export function SettingsSubscriptionLegacyPage() {
  const [{ utm_source }] = useQuery();
  const {
    data: subscriptionDetails,
    error: subscriptionError,
    isFetching,
    refetch,
    isLoading,
  } = useSubscriptionSettings();
  const navigate = useNavigate();
  const editCardDetailsMatch = useMatch(
    '/settings/subscription/edit_card_details',
  );
  const reactivatePlanMatch = useMatch(
    '/settings/subscription/reactivate_plan',
  );

  useErrorSnackbar(subscriptionError, {
    formatError: (error: Error) =>
      `Failed to fetch subscription details: ${error.message}`,
  });

  useEffect(() => {
    hideIntercomLauncher();
    trackEvent('CTMS: Viewed Subscription Details Page');
    return showIntercomLauncher;
  }, []);

  useEffect(() => {
    if (utm_source === 'email') {
      trackEvent('Carrier Clicked Upgrade Now', { utm_source });
    }
  }, [utm_source]);

  return (
    <>
      <DocumentTitle title="Subscription Details - Settings" />

      <SubscriptionUpdateCardDetailsDrawerRoute
        hasCardHolderName={true}
        onSubmitSuccess={refetch}
        subscriptionDetails={subscriptionDetails}
      />

      <SubscriptionUpdateCardDetailsDrawer
        open={!!editCardDetailsMatch}
        title="Edit Card Details"
        hasCardHolderName={false}
        onClose={() => {
          navigate('/settings/subscription', { replace: true });
        }}
        onSubmitSuccess={refetch}
        subscriptionDetails={subscriptionDetails}
      />

      <SubscriptionUpgradePlanDrawerRoute refetch={refetch} />

      <SubscriptionReactivationDrawer
        open={!!reactivatePlanMatch}
        onClose={() => {
          navigate('/settings/subscription', { replace: true });
        }}
      />

      <EditBillingAddress />

      <SettingsPageLayout title="Subscription Details" loading={isFetching}>
        <Box width="100%" maxWidth="950px">
          {isLoading && (
            <Stack space="small">
              <LoadingCard />
              <LoadingCard />
            </Stack>
          )}

          {!subscriptionDetails && !isFetching && (
            <Card aria-label="No Subscription Details">
              <CardContent>
                <Typography variant="body1" gutterBottom={true}>
                  There are no subscription details.
                </Typography>
                <Typography gutterBottom={true}>
                  If you are in a trial period, subscription details will appear
                  after you subscribe.
                </Typography>
                If you are already a subscribed user, please contact support
              </CardContent>
            </Card>
          )}

          {!!subscriptionDetails && (
            <Columns space="small">
              <Column>
                {subscriptionDetails.card_information ? (
                  <SubscriptionPaymentInformation />
                ) : (
                  <SubscriptionPaymentCard />
                )}
              </Column>
              <Column>
                <Card aria-label="Plan">
                  <CardContent>
                    <SettingsSubscriptionPlan
                      subscriptionDetails={subscriptionDetails}
                    />
                  </CardContent>
                </Card>
              </Column>
            </Columns>
          )}
        </Box>
      </SettingsPageLayout>
    </>
  );
}
