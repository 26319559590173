import { Dialog, IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Stack } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { useErrorSnackbar } from 'shared/hooks/useErrorSnackbar';
import { MoovActionError } from '../data/MoovDTO';
import { useSuperPayBusinessLegalName } from '../data/useSuperPayBusinessLegalName';
import { MicroDepositInitiatedContent } from './MicroDepositInitiatedContent';

interface MicroDepositInitiatedDialogProps {
  open: boolean;
  onClose: () => void;
}
export function MicroDepositInitiatedDialog({
  open,
  onClose,
}: MicroDepositInitiatedDialogProps) {
  return (
    <Dialog open={open} onClose={onClose}>
      <MicroDepositInitiatedDialogContent onClose={onClose} />
    </Dialog>
  );
}
interface MicroDepositInitiatedDialogContentProps {
  onClose: () => void;
}

function MicroDepositInitiatedDialogContent({
  onClose,
}: MicroDepositInitiatedDialogContentProps) {
  const { accountLegalNameError } = useSuperPayBusinessLegalName();

  useErrorSnackbar(accountLegalNameError, {
    formatError: (error: MoovActionError) => {
      if (error.statusCode === 403) {
        return 'Not authorized to access business account details.';
      }

      return 'Failed to load account legal business name.';
    },
  });

  return (
    <Box width="480px" padding="large">
      <Typography align="right">
        <IconButton size="small" onClick={onClose}>
          <Close />
        </IconButton>
      </Typography>
      <Stack space="large" align="center">
        <MicroDepositInitiatedContent />
        <Button onClick={onClose}>Got it</Button>
      </Stack>
    </Box>
  );
}
