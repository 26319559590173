import { parseURITemplate } from '@superdispatch/uri';
import { ReactNode } from 'react';
import { LinkAnchor } from 'shared/routing/Links';
import {
  ReportsAccountsReceivableParamsDTO,
  ReportsCompanyRevenueParamsDTO,
  ReportsPeriodDTO,
} from './data/ReportsDTO';

export type Parameters = Partial<
  ReportsAccountsReceivableParamsDTO &
    ReportsCompanyRevenueParamsDTO &
    ReportsPeriodDTO
>;

export interface ReportsLoadsLinkProps {
  stage: 'revenue' | 'payable' | 'receivable' | 'receivable/past-due';
  params?: Parameters;
  companyName: string;
  children: ReactNode;
}

export function ReportsLoadsLink({
  stage,
  params,
  companyName,
  children,
}: ReportsLoadsLinkProps) {
  const brokerFilters =
    companyName === 'EMPTY'
      ? { is_empty_broker_name: true }
      : { broker_name: companyName };

  return (
    <LinkAnchor
      to={parseURITemplate('/reports/loads{?params*}', {
        params: {
          stage,
          ...brokerFilters,
          ...getDates(params),
        },
      })}
    >
      {children}
    </LinkAnchor>
  );
}

function getDates(params?: Parameters) {
  if (params?.accounting_method === 'cash') {
    return {
      receipt_date_after: params.start_date,
      receipt_date_before: params.end_date,
    };
  }

  return {
    invoice_date_after: params?.start_date,
    invoice_date_before: params?.end_date,
  };
}
