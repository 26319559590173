import { Link, Typography } from '@material-ui/core';
import { DocumentPreview } from 'shared/modules/verified-carrier-application/core/DocumentPreview';
import { ImageMarker } from 'shared/modules/verified-carrier-application/core/ImageMarker';
import USDOTExample from '../assets/USDOTExample.png';

export function USDOTPreview() {
  return (
    <DocumentPreview
      title="USDOT Certificate Example Preview"
      imgSrc={USDOTExample}
      header={
        <>
          <div>
            <Typography variant="inherit" component="p">
              There are different variations of the USDOT certificate.
            </Typography>
            <Typography variant="inherit" component="p">
              You can see{' '}
              <Link
                href="/tms/assets/USDOTExamples.pdf"
                target="_blank"
                rel="noreferrer"
                color="primary"
              >
                examples of USDOT certificates here
              </Link>
            </Typography>
          </div>

          <Typography variant="inherit" component="p">
            For more detailed instructions refer to{' '}
            <Link
              href="https://support.superdispatch.com/en/articles/3112938-how-do-i-become-a-verified-carrier"
              target="_blank"
              rel="noreferrer"
              color="primary"
            >
              our support article.
            </Link>
          </Typography>
        </>
      }
    >
      <ImageMarker
        top="17%"
        left="44%"
        label="1"
        title={
          <Typography align="center">
            Upload the USDOT certificate that was mailed or emailed to you by
            FMCSA
          </Typography>
        }
      />
    </DocumentPreview>
  );
}
