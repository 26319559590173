import {
  FormikContextTypeEnhanced,
  FormikEnhancedProps,
  useFormikEnhanced,
} from '@superdispatch/forms';
import { useDeepEqualMemo, useValueObserver } from '@superdispatch/hooks';
import { useSnackbarStack } from '@superdispatch/ui';
import { FormikValues } from 'formik';
import { ReactChild, ReactNode } from 'react';
import { APIResponse } from 'shared/api/CarrierAPIClient';
import { Schema } from 'yup';

export function useInitialValues<TData>(
  schema: Schema<TData>,
  defaults?: unknown,
): TData {
  return useDeepEqualMemo(() => schema.cast(defaults), [schema, defaults]);
}

type SnackbarMessage<TValues extends FormikValues, TResponse> =
  | null
  | ReactChild
  | ((response: TResponse, values: TValues) => ReactNode);

export function useFormikStatusSnackbar<
  TValues extends FormikValues,
  TResponse,
>(
  { status, values }: FormikContextTypeEnhanced<TValues, TResponse>,
  successMessage?: SnackbarMessage<TValues, TResponse>,
  errorMessage: SnackbarMessage<TValues, Error> = (error) => error.message,
): void {
  const { addSnackbar } = useSnackbarStack();

  useValueObserver(status, () => {
    if (status.type === 'submitted') {
      const message =
        typeof successMessage === 'function'
          ? successMessage(status.payload, values)
          : successMessage;

      if (message) {
        addSnackbar(message, { variant: 'success' });
      }
    }

    if (status.type === 'rejected') {
      const message =
        typeof errorMessage === 'function'
          ? errorMessage(status.payload, values)
          : errorMessage;

      if (message) {
        addSnackbar(message, { variant: 'error' });
      }
    }
  });
}

export interface AppFormikProps<TValues extends FormikValues, TData>
  extends Omit<
    FormikEnhancedProps<TValues, APIResponse<TData>>,
    'initialValues'
  > {
  // useFormik
  initialValues?: unknown;
  validationSchema: Schema<TValues>;

  // useAppFormik
  getErrorMessage?: SnackbarMessage<TValues, Error>;
  getSuccessMessage?: SnackbarMessage<TValues, APIResponse<TData>>;
}

export function useAppFormik<TValues extends FormikValues, TData = unknown>({
  // useAppFormik
  getErrorMessage,
  getSuccessMessage,

  // useFormik
  onSubmit,
  validationSchema,
  initialValues: initialValuesOption,
  ...options
}: AppFormikProps<TValues, TData>) {
  const initialValues = useInitialValues(validationSchema, initialValuesOption);

  const formik = useFormikEnhanced({
    ...options,
    initialValues,
    validationSchema,
    onSubmit(values) {
      return onSubmit(validationSchema.cast(values));
    },
  });

  useFormikStatusSnackbar(formik, getSuccessMessage, getErrorMessage);

  return formik;
}
