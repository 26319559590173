import { CircularProgress } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { useSetAtom } from 'jotai';
import { lazy, Suspense } from 'react';
import { mapAtom } from 'shared/form/LeafletMap';
import { TripCreateDTO } from '../data/TripsDTO';
import { useCreateTripMarkers } from '../data/useCreateTripMarkers';
import { useHoveredFieldNameValue } from '../TripsDetails';
const LeafletMap = lazy(() => import('shared/form/LeafletMap'));

//kansas city
const DEFAULT_CENTER: [number, number] = [39.0997, -94.5786];
const DEFAULT_ZOOM = 5;

interface TripFormMapProps {
  center?: [number, number];
}

export function TripFormMap({ center }: TripFormMapProps) {
  const setMap = useSetAtom(mapAtom);
  const formik = useFormikContext<TripCreateDTO>();
  const fieldName = useHoveredFieldNameValue();

  const { loads, start_address, end_address } = formik.values;

  const markers = useCreateTripMarkers({
    loads,
    start_address,
    end_address,
    fieldName,
  });

  return (
    <Suspense fallback={<CircularProgress />}>
      <LeafletMap
        setMap={setMap}
        markers={markers}
        zoom={DEFAULT_ZOOM}
        center={center || DEFAULT_CENTER}
      />
    </Suspense>
  );
}
