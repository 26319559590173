import { ExitToApp } from '@material-ui/icons';
import { Button } from '@superdispatch/ui-lab';
import { useNavigate } from 'react-router-dom-v5-compat';
import { useDriverAccount } from 'shared/auth/data/useDriverAccount';

export function DriverLogoutButton() {
  const { removeDriverToken } = useDriverAccount();
  const navigate = useNavigate();

  return (
    <Button
      variant="neutral"
      startIcon={<ExitToApp />}
      onClick={() => {
        removeDriverToken();
        navigate('/driver-login', { replace: true });
      }}
    >
      Log Out
    </Button>
  );
}
