import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom-v5-compat';
import { hideIntercomLauncher } from 'shared/helpers/Intercom';
import { PublicOfferPage } from './pages/PublicOfferPage';
import { PublicOfferPageContextProvider } from './PublicOfferPageContext';

export function PublicOfferContainerPage() {
  useEffect(() => {
    hideIntercomLauncher();
  }, []);

  return (
    <Routes>
      <Route
        path=":guid"
        element={
          <PublicOfferPageContextProvider>
            <PublicOfferPage />
          </PublicOfferPageContextProvider>
        }
      />
      <Route
        index={true}
        element={
          <PublicOfferPageContextProvider>
            <PublicOfferPage />
          </PublicOfferPageContextProvider>
        }
      />
    </Routes>
  );
}
