import { renderChildren } from '@superdispatch/ui';
import { atom, useAtom } from 'jotai';
import { ReactNode } from 'react';
import { SplashScreen } from 'shared/layout/SplashScreen';
import { useStartOnboarding } from '../useStartOnboarding';

const onboardingDialogStateAtom = atom<boolean>(false);
const becomeVerifiedDialogStateAtom = atom<boolean>(false);

export function useOnboardingDialogState() {
  const [isOnboardingDialogOpen, setOnboardingDialogOpen] = useAtom(
    onboardingDialogStateAtom,
  );
  const closeOnboardingDialog = () => {
    setOnboardingDialogOpen(false);
  };
  const openOnboardingDialog = () => {
    setOnboardingDialogOpen(true);
  };
  const [isBecomeVerifiedDialogOpen, setBecomeVerifiedDialogOpen] = useAtom(
    becomeVerifiedDialogStateAtom,
  );
  const closeBecomeVerifiedDialog = () => {
    setBecomeVerifiedDialogOpen(false);
  };
  const openBecomeVerifiedDialog = () => {
    setBecomeVerifiedDialogOpen(true);
  };
  return {
    isOnboardingDialogOpen,
    isBecomeVerifiedDialogOpen,
    closeOnboardingDialog,
    openOnboardingDialog,
    closeBecomeVerifiedDialog,
    openBecomeVerifiedDialog,
  };
}

export interface OnboardingWrapperProps {
  children?: ReactNode;
}

export function OnboardingWrapper({ children }: OnboardingWrapperProps) {
  const { isLoading } = useStartOnboarding();

  if (isLoading) {
    return <SplashScreen />;
  }

  return renderChildren(children);
}
