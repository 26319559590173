import { SuperPayBankAccountDTO } from 'shared/modules/superpay/SuperPayDTO';
import { useFlag } from 'shared/settings/FeatureToggles';
import { useMoovAccountInfoLegacy } from './useMoovAccountInfoLegacy';

export function useSuperPayBankAccountStatus(
  bankAccount?: SuperPayBankAccountDTO,
) {
  const isMoovRefactoring = useFlag('payments_moovjs_refactoring');

  const moovAccountInfoLegacy = useMoovAccountInfoLegacy();
  const moovBankAccountLegacy = moovAccountInfoLegacy.getBankAccount();
  return isMoovRefactoring
    ? bankAccount?.verification_status
    : moovBankAccountLegacy?.status;
}
