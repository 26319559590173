import { formatDate } from '@superdispatch/dates';
import { useFormikEnhanced } from '@superdispatch/forms';
import { useSnackbarStack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { FormikProvider } from 'formik';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import { createAPIError } from 'shared/api/APIError';
import { APIResponse } from 'shared/api/CarrierAPIClient';
import { startIntercomTour } from 'shared/helpers/Intercom';
import { LocalStore, useLocalStore } from 'shared/helpers/Store';
import { trackTripsEvent } from '../data/TripsAnalytics';
import { CreateTripResponse, useTripsAPI } from '../data/TripsAPI';
import { EditTripDTO, TripCreateDTO, tripCreateSchema } from '../data/TripsDTO';
import { TripFormMap } from './TripFormMap';
import { TripFormSidebar } from './TripFormSidebar';

const FIRST_TRIP_INTERCOM_TOUR_ID =
  import.meta.env.VITE_APP_TARGET === 'production' ? '568709' : '567778';
const FIRST_TRIP_INTERCOM_TOUR_SEEN_STATE = 'trips_intercom_tours_seen_state';

const CREATE_TRIP_INTERCOM_TOUR_ID =
  import.meta.env.VITE_APP_TARGET === 'production' ? '569462' : '567772';
const CREATE_TRIP_INTERCOM_TOUR_SEEN_STATE =
  'create_trips_intercom_tours_seen_state';

export function CreateTripPage() {
  const navigate = useNavigate();
  const { addSnackbar } = useSnackbarStack();
  const { createTrip } = useTripsAPI();
  const tripCreatedIntercomTourSeenState = useLocalStore(
    FIRST_TRIP_INTERCOM_TOUR_SEEN_STATE,
  );
  const createTripIntercomTourSeenState = useLocalStore(
    CREATE_TRIP_INTERCOM_TOUR_SEEN_STATE,
  );

  useEffect(() => {
    if (createTripIntercomTourSeenState !== 'seen') {
      startIntercomTour(CREATE_TRIP_INTERCOM_TOUR_ID);
      LocalStore.set(CREATE_TRIP_INTERCOM_TOUR_SEEN_STATE, 'seen');
    }
  }, [createTripIntercomTourSeenState]);

  const formik = useFormikEnhanced({
    validationSchema: tripCreateSchema,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: {
      name: `Trip / ${formatDate(Date.now(), { variant: 'Date' })}`,
      loads: [
        {
          guid: '',
          load_id: '',
          origin: '',
          destination: '',
          pickup_latitude: null,
          pickup_longitude: null,
          delivery_latitude: null,
          delivery_longitude: null,
        },
      ],
      start_address: null,
      end_address: null,
      driver: null,
    },
    validate: (values: TripCreateDTO) => {
      const { start_address, end_address } = values;
      const errorMsg = "Couldn't recognize the full address";
      const errors: Record<string, string> = {};
      if (start_address?.address && !start_address.latitude) {
        errors.start_address = errorMsg;
      }
      if (end_address?.address && !end_address.latitude) {
        errors.end_address = errorMsg;
      }
      return errors;
    },
    onSubmit(values: TripCreateDTO) {
      const { start_address, end_address } = values;

      const loadGuids = values.loads?.map((load) => load.guid);
      const payload: EditTripDTO = {
        name: values.name,
        load_guids: loadGuids,
        driver_guid: values.driver?.guid,
      };

      const route = {
        ...(!!start_address?.address && {
          origin: {
            address: start_address.address,
            city: start_address.city,
            state: start_address.state,
            zip: start_address.zip || null,
          },
        }),
        ...(!!end_address?.address && {
          destination: {
            address: end_address.address,
            city: end_address.city,
            state: end_address.state,
            zip: end_address.zip || null,
          },
        }),
      };

      if (route.destination || route.origin) {
        Object.assign(payload, { route });
      }

      return createTrip(payload);
    },
    onSubmitSuccess: (response: APIResponse<CreateTripResponse>) => {
      trackTripsEvent({
        name: 'Carrier Created Trip',
      });
      const { guid } = response.data;
      if (tripCreatedIntercomTourSeenState !== 'seen') {
        startIntercomTour(FIRST_TRIP_INTERCOM_TOUR_ID);
        LocalStore.set(FIRST_TRIP_INTERCOM_TOUR_SEEN_STATE, 'seen');
      }
      navigate(`/trips/view/${guid}`, { replace: true });
    },
    onSubmitFailure: (e) => {
      trackTripsEvent({
        name: 'Carrier Failed to Create Trip',
      });
      const error = createAPIError<{
        load_guids: string[];
        route: {
          destination: {
            address: string;
            city: string;
            state: string;
            zip: string;
          };
          origin: {
            address: string;
            city: string;
            state: string;
            zip: string;
          };
        };
      }>(e);
      addSnackbar(error.message, {
        variant: 'error',
      });
    },
  });

  return (
    <FormikProvider value={formik}>
      <Box position="relative" display="flex" height="100%">
        <TripFormSidebar />
        <TripFormMap />
      </Box>
    </FormikProvider>
  );
}
