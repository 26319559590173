import { TableRow, Typography } from '@material-ui/core';
import { Flag, Lock } from '@material-ui/icons';
import { Color, Inline, Stack, Tag } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { joinStrings } from 'shared/utils/StringUtils';
import styled from 'styled-components';
import { VenueDTO } from '../data/TripsDTO';
import { StyledCell } from './TripDetailsTableRow';

const StyledRow = styled(TableRow)`
  display: flex;
  background-color: ${Color.White};
  min-height: 93px;
`;

interface TripLoadsTableRowProps {
  origin?: Partial<VenueDTO>;
  destination?: Partial<VenueDTO>;
  isTripArchived: boolean;
  position?: 'start' | 'end';
  onMouseLeave?: () => void;
  onMouseOver?: () => void;
}

export function TripDetailsStopPointRow({
  origin,
  destination,
  isTripArchived,
  position,
  onMouseLeave,
  onMouseOver,
  ...props
}: TripLoadsTableRowProps) {
  return (
    <StyledRow onMouseLeave={onMouseLeave} onMouseOver={onMouseOver} {...props}>
      <StyledCell width="50px">
        <Lock htmlColor={Color.Silver500} />
      </StyledCell>
      <StyledCell>
        <Box display="inline">
          {position === 'start' && (
            <Tag color="blue" variant="subtle">
              <Inline noWrap={true} verticalAlign="center" space="xxsmall">
                <Flag fontSize="small" />
                Start
              </Inline>
            </Tag>
          )}

          {position === 'end' && (
            <Tag color="grey" variant="subtle">
              <Inline noWrap={true} verticalAlign="center" space="xxsmall">
                <Flag fontSize="small" />
                End
              </Inline>
            </Tag>
          )}
        </Box>
      </StyledCell>
      <StyledCell>
        {!!origin && (
          <Stack space="xxsmall">
            <Typography color="textSecondary">{origin.address}</Typography>
            <Typography>
              {origin.city}
              {', '}
              {joinStrings(' ', origin.state, origin.zip)}
            </Typography>
          </Stack>
        )}
      </StyledCell>
      <StyledCell>
        {!!destination && (
          <Stack space="xxsmall">
            <Typography color="textSecondary">{destination.address}</Typography>
            <Typography>
              {destination.city}
              {', '}
              {joinStrings(' ', destination.state, destination.zip)}
            </Typography>
          </Stack>
        )}
      </StyledCell>
      <StyledCell customWidth="250px" />
      <StyledCell customWidth="120px" />
      <StyledCell customWidth="120px" />
      {!isTripArchived && <StyledCell customWidth="60px" />}
    </StyledRow>
  );
}
