import { Paper, Typography } from '@material-ui/core';
import { Column, Columns, Inline, Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { useSetAtom } from 'jotai';
import LeafletMap, { mapAtom } from 'shared/form/LeafletMap';
import { formatPlural } from 'shared/helpers/IntlHelpers';
import styled from 'styled-components';
import { TripDTO } from '../data/TripsDTO';
import { getCenter } from '../data/TripsUtils';
import { useAddTripDetailsMarkers } from '../data/useAddTripDetailsMarkers';
import { useHoveredFieldNameValue } from '../TripsDetails';
import { TripDetailsAssignDriver } from './TripDetailsAssignDriver';

// Kansas City
const DEFAULT_CENTER: [number, number] = [39.099724, -94.578331];
const DEFAULT_ZOOM = 4;

const MapWrapper = styled(Box)`
  height: 500px;
`;

export interface TripDetailsDriverContentProps {
  trip: TripDTO;
}

export function TripDetailsDriverContent({
  trip,
}: TripDetailsDriverContentProps) {
  const setMap = useSetAtom(mapAtom);
  const fieldName = useHoveredFieldNameValue();

  const isVehicleInfoVisible = trip.vehicles_count || trip.vehicles_weight;
  const markers = useAddTripDetailsMarkers({ trip, fieldName });
  const center = getCenter(trip.route?.origin);

  return (
    <Box data-intercom-target="trip-driver-content">
      <Paper>
        <Stack space="none">
          <MapWrapper>
            <LeafletMap
              center={center || DEFAULT_CENTER}
              zoom={DEFAULT_ZOOM}
              setMap={setMap}
              markers={markers}
            />
          </MapWrapper>
          <Box padding="small" paddingTop="medium">
            <Columns align="center" space="xxsmall">
              <Column width={isVehicleInfoVisible ? '2/3' : 'fluid'}>
                <TripDetailsAssignDriver trip={trip} />
              </Column>

              {isVehicleInfoVisible && (
                <Column width="1/3">
                  <Inline horizontalAlign="right">
                    <Stack space="none">
                      {trip.vehicles_count && (
                        <Typography variant="caption">
                          {trip.vehicles_count}{' '}
                          {formatPlural(
                            trip.vehicles_count,
                            'vehicle',
                            'vehicles',
                          )}
                        </Typography>
                      )}
                      {trip.vehicles_weight && (
                        <Typography variant="h4">
                          {trip.vehicles_weight} lb
                        </Typography>
                      )}
                    </Stack>
                  </Inline>
                </Column>
              )}
            </Columns>
          </Box>
        </Stack>
      </Paper>
    </Box>
  );
}
