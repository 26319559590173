import { Route, Routes } from 'react-router-dom-v5-compat';
import { LoadsContextProvider } from '../loads/data/LoadsContext';
import { LoadsPageContextProvider } from '../loads/loads-page/data/LoadsPageParamsContext';
import { DashboardLoadsPage } from './DashboardLoadsPage';
import { DashboardPage } from './DashboardPage';

export function DashboardContainerPage() {
  return (
    <Routes>
      <Route
        path="loads"
        element={
          <LoadsContextProvider>
            <LoadsPageContextProvider>
              <DashboardLoadsPage />
            </LoadsPageContextProvider>
          </LoadsContextProvider>
        }
      />
      <Route index={true} element={<DashboardPage />} />
    </Routes>
  );
}
