import { Typography } from '@material-ui/core';
import { Inline, Stack } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { useNavigate } from 'react-router-dom-v5-compat';
import { LoginLayout } from 'shared/auth/core/LoginLayout';
import { LoginLayoutContent } from 'shared/auth/core/LoginLayoutContent';
import { DriverLogoutButton } from './core/DriverLogoutButton';
import { DriverName } from './core/DriverName';
import { useDriverAccountDetails } from './data/DriverAccountAPI';

export function DriverAccountPage() {
  const navigate = useNavigate();
  const { data } = useDriverAccountDetails();

  const handleClick = () => {
    navigate('/driver-account/delete');
  };

  return (
    <LoginLayout showDot={false} header={<DriverLogoutButton />}>
      <Box marginTop="medium">
        <LoginLayoutContent>
          <Inline horizontalAlign="center">
            <Stack align="center" space="large">
              <DriverName />
              <Stack align="center" space="xsmall">
                <Typography variant="h2">{data?.name}</Typography>
                <Stack align="center" space="xxsmall">
                  <Typography>{data?.email}</Typography>
                  <Typography>{data?.phone_number}</Typography>
                </Stack>
              </Stack>
              <Button onClick={handleClick} variant="critical">
                Delete Account
              </Button>
            </Stack>
          </Inline>
        </LoginLayoutContent>
      </Box>
    </LoginLayout>
  );
}
