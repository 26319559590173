import { useFlag } from 'shared/settings/FeatureToggles';
import { useAccountLegalBusinessName } from './MoovAPI';
import { useAccountLegalBusinessName as useAccountLegalBusinessNameLegacy } from './MoovAPILegacy';

export function useSuperPayBusinessLegalName() {
  const isMoovRefactoring = useFlag('payments_moovjs_refactoring');
  const {
    data: moovAccountLegalNameLegacy,
    error: moovAccountLegalNameErrorLegacy,
  } = useAccountLegalBusinessNameLegacy(!isMoovRefactoring);

  const { data: moovAccountLegalName, error: moovAccountLegalNameError } =
    useAccountLegalBusinessName(isMoovRefactoring);

  const accountLegalNameError = isMoovRefactoring
    ? moovAccountLegalNameError
    : moovAccountLegalNameErrorLegacy;

  const accountLegalName = isMoovRefactoring
    ? moovAccountLegalName
    : moovAccountLegalNameLegacy;

  return { accountLegalNameError, accountLegalName };
}
