import {
  Dialog,
  DialogContent,
  DialogTitle as MuiDialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Color, Column, Columns } from '@superdispatch/ui';
import { useState } from 'react';
import styled from 'styled-components';
import { GettingStartedContent } from './GettingStartedContent';
import { VerificationSteps } from './VerificationSteps';

interface BecomeVerifiedDialogProps {
  open: boolean;
  onClose: () => void;
  onCompleted: () => void;
}

const DialogTitle = styled(MuiDialogTitle)`
  border-bottom: 1px solid ${Color.Silver400};
`;

export function BecomeVerifiedDialog({
  open,
  onClose,
  onCompleted,
}: BecomeVerifiedDialogProps) {
  const [isShowVerification, setShowVerification] = useState(false);

  return (
    <Dialog fullWidth={true} maxWidth="lg" open={open} onClose={onClose}>
      <DialogTitle>
        <Columns align="top">
          <Column width="fluid">
            <Typography variant="h3">
              {isShowVerification
                ? 'Verification Application'
                : 'Become Verified Carrier'}
            </Typography>
          </Column>

          <Column width="content">
            <IconButton size="small" aria-label="close" onClick={onClose}>
              <Close />
            </IconButton>
          </Column>
        </Columns>
      </DialogTitle>
      {open && (
        <BecomeVerifiedDialogContent
          onClose={onClose}
          onCompleted={onCompleted}
          setShowVerification={setShowVerification}
          isShowVerification={isShowVerification}
        />
      )}
    </Dialog>
  );
}

interface BecomeVerifiedDialogContentProps {
  onClose: () => void;
  onCompleted: () => void;
  setShowVerification: (value: boolean) => void;
  isShowVerification: boolean;
}

function BecomeVerifiedDialogContent({
  onClose,
  onCompleted,
  isShowVerification,
  setShowVerification,
}: BecomeVerifiedDialogContentProps) {
  return (
    <DialogContent id="scroll-to-top">
      {isShowVerification ? (
        <VerificationSteps
          onClose={onClose}
          onCompleted={onCompleted}
          onBack={() => {
            setShowVerification(false);
          }}
        />
      ) : (
        <GettingStartedContent
          onClick={() => {
            setShowVerification(true);
          }}
        />
      )}
    </DialogContent>
  );
}
