import { IconButton, MenuItem, Typography } from '@material-ui/core';
import { Add, DeleteOutline } from '@material-ui/icons';
import {
  FormikTextField,
  SuspendedFormikPhoneField,
  useFormikEnhanced,
} from '@superdispatch/forms';
import { Column, Columns, Stack, useSnackbarStack } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { useState } from 'react';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import { CheckIcon } from 'shared/icons/CheckIcon';
import styled from 'styled-components';
import { useDispatchersAPI } from '../../../carrier/dispatchers/data/DispatchersAPI';
import { useDriversAPI } from '../../../carrier/drivers/data/DriversAPI';
import { DispatcherDTO } from '../dispatcher/DispatcherDTO';
import { OnboardingLayout } from './core/OnboardingLayout';
import { inviteTeamSchema } from './data/OnboardingDTO';
import { useSaveOnboardingWithErrorHandler } from './data/useSaveOnboardingWithErrorHandler';

const TEAM_TYPES = [
  {
    label: 'Driver',
    name: 'driver',
  },
  {
    label: 'Dispatcher',
    name: 'dispatcher',
  },
];

const AddButton = styled(Button)`
  padding-left: 0;
  padding-right: 0;
`;
const CheckIconContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 4px;
`;

type TeamMemeberDTO = DispatcherDTO;

export function OnboardingInviteTeamStepPage() {
  const { inviteDispatcher } = useDispatchersAPI();
  const { inviteDriver } = useDriversAPI();
  const { addSnackbar } = useSnackbarStack();
  const { mutate: saveStep, isLoading: isSavingStep } =
    useSaveOnboardingWithErrorHandler({
      onSuccess: () => {
        openExternalURL('/loadboard');
      },
    });

  const [invitedMemberEmails, setInvitedMemberEmails] = useState<string[]>([]);

  const formik = useFormikEnhanced({
    initialValues: {
      teammates: [
        {
          type: 'driver',
          email: '',
          phone: '',
        },
      ],
    },
    validationSchema: inviteTeamSchema,
    validate: (values) => {
      const fieldErrors: Array<Record<string, string>> = [];

      const emails = values.teammates.map(({ email }) => email);
      const uniqueEmails = new Set(emails);

      if (emails.length !== uniqueEmails.size) {
        values.teammates.forEach(({ email }, index) => {
          if (emails.indexOf(email) !== index) {
            fieldErrors[index] = {
              email: 'Duplicate email',
            };
          }
        });
      }

      if (fieldErrors.length > 0) {
        return {
          teammates: fieldErrors,
        };
      }

      return {};
    },
    onSubmit: (values) => {
      const notInvitedTeammates = values.teammates.filter(
        ({ email }) => !invitedMemberEmails.includes(email),
      );

      return Promise.allSettled(
        notInvitedTeammates.map(({ email, phone, type }) =>
          type === 'dispatcher'
            ? inviteDispatcher({
                email,
                phone_number: phone,
              })
            : inviteDriver({
                email,
                phone_number: phone,
                name: '',
              }),
        ),
      );
    },
    onSubmitSuccess(
      responses: Array<PromiseSettledResult<TeamMemeberDTO>>,
      values,
    ) {
      const fulfilledResponses = responses.filter(
        (response) => response.status === 'fulfilled',
      );
      const notInvitedTeammates = values.teammates.filter(
        ({ email }) => !invitedMemberEmails.includes(email),
      );
      setInvitedMemberEmails((prev) => [
        ...prev,
        ...fulfilledResponses.map(
          (response: PromiseFulfilledResult<TeamMemeberDTO>) =>
            response.value.email,
        ),
      ]);

      responses.forEach((response, index) => {
        const idx = values.teammates.findIndex(
          (teammate) => teammate.email === notInvitedTeammates[index]?.email,
        );

        if (response.status === 'rejected') {
          const error = response.reason as Error;
          formik.setFieldError(
            `teammates[${idx}].email`,
            error.message === 'InviteAlreadySent' ||
              error.message === 'DispatcherHasBeenInvited'
              ? 'User with this email already exists'
              : error.message,
          );
        }
      });

      if (responses.length !== fulfilledResponses.length) {
        formik.setStatus({
          type: 'rejected',
          payload: new Error('Some invites were not sent'),
        });
      } else {
        saveStep({
          stepName: 'team_invitation',
          status: 'completed',
        });
      }
    },
    onSubmitFailure(e) {
      addSnackbar(e.message, { variant: 'error' });
    },
  });

  const { values, setValues } = formik;

  const { teammates } = values;

  function onRemove(index: number) {
    void setValues({
      ...values,
      teammates: teammates.filter((_, i) => i !== index),
    });
  }

  function onAdd() {
    void setValues({
      ...values,
      teammates: [
        ...teammates,
        {
          type: 'driver',
          email: '',
          phone: '',
        },
      ],
    });
  }

  function onSkip() {
    formik.setSubmitting(false);
    saveStep({
      stepName: 'team_invitation',
      status: 'skipped',
    });
  }

  return (
    <OnboardingLayout progress={80}>
      <FormikProvider value={formik}>
        <Form>
          <Stack space="large">
            <Stack space="xsmall" align="center">
              <Typography variant="h2">Invite Your Team</Typography>
              <Typography color="textSecondary">
                Add your co-workers to collaborate on the loads you will move.
              </Typography>
            </Stack>
            <Stack space="small">
              <Typography variant="h4">Send invite to</Typography>
              <Stack space={['xlarge', 'small']}>
                {teammates.map(({ email }, index) => {
                  const isInvited = invitedMemberEmails.includes(email);
                  return (
                    <Columns key={index} align="center">
                      <Column width="fluid">
                        <Columns
                          space="small"
                          align="top"
                          aria-label={`vehicle ${index}`}
                          collapseBelow="tablet"
                        >
                          <Column width={['adaptive', 'content']}>
                            <Box width={['100%', '120px']}>
                              <FormikTextField
                                select={true}
                                fullWidth={true}
                                disabled={isInvited}
                                name={`teammates[${index}].type`}
                              >
                                {TEAM_TYPES.map((key) => (
                                  <MenuItem key={key.name} value={key.name}>
                                    {key.label}
                                  </MenuItem>
                                ))}
                              </FormikTextField>
                            </Box>
                          </Column>

                          <Column width={['adaptive', 'content']}>
                            <Box width={['100%', '264px']}>
                              <FormikTextField
                                name={`teammates[${index}].email`}
                                fullWidth={true}
                                disabled={isInvited}
                                placeholder="Team member’s email"
                              />
                            </Box>
                          </Column>

                          <Column width={['adaptive', 'adaptive']}>
                            <Columns align="center" space="small">
                              <Column>
                                <SuspendedFormikPhoneField
                                  fullWidth={true}
                                  disabled={isInvited}
                                  name={`teammates[${index}].phone`}
                                />
                              </Column>
                              <Column width="content">
                                {index === 0 && !invitedMemberEmails.length ? (
                                  <Box
                                    width={['48px', '27px']}
                                    height={['56px', '30px']}
                                  />
                                ) : isInvited ? (
                                  <CheckIconContainer
                                    width={['48px', '27px']}
                                    height={['56px', '30px']}
                                  >
                                    <CheckIcon />
                                  </CheckIconContainer>
                                ) : (
                                  <IconButton
                                    edge="end"
                                    size="small"
                                    onClick={() => {
                                      onRemove(index);
                                    }}
                                  >
                                    <DeleteOutline />
                                  </IconButton>
                                )}
                              </Column>
                            </Columns>
                          </Column>
                        </Columns>
                      </Column>
                    </Columns>
                  );
                })}
              </Stack>
              <AddButton startIcon={<Add />} variant="text" onClick={onAdd}>
                Add Team Member
              </AddButton>
            </Stack>
            <Stack space="small" align="center">
              <Button
                type="submit"
                size="large"
                disabled={!formik.dirty || !formik.isValid || isSavingStep}
                pending={
                  formik.isSubmitting ||
                  (formik.status.type === 'submitted' && isSavingStep)
                }
              >
                Complete
              </Button>
              <Button
                type="button"
                onClick={onSkip}
                variant="text"
                size="large"
                pending={formik.status.type === 'initial' && isSavingStep}
                disabled={formik.isSubmitting || isSavingStep}
              >
                Skip
              </Button>
            </Stack>
          </Stack>
        </Form>
      </FormikProvider>
    </OnboardingLayout>
  );
}
