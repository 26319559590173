import { Typography } from '@material-ui/core';
import { FormikTextField, useFormikEnhanced } from '@superdispatch/forms';
import { Inline, Stack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { shuffle } from 'lodash-es';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import styled from 'styled-components';
import { OnboardingCheckboxField } from './core/OnboardingCheckboxField';
import { OnboardingLayout } from './core/OnboardingLayout';
import { useOnboardingDetails } from './data/OnboardingAPI';
import { onboardingQuestionStepSchema } from './data/OnboardingDTO';
import { useSaveOnboardingWithErrorHandler } from './data/useSaveOnboardingWithErrorHandler';

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const FIELD_OPTIONS = [
  {
    title: 'Find and Book Loads',
    description:
      'Find exclusive loads, personalized suggestions, and instantly book.',
  },
  {
    title: 'Import Loads from Other Platforms',
    description: 'Centralize and streamline all my loads in one place.',
  },
  {
    title: 'Manage Load Status',
    description:
      'Stay organized by getting status updates and e-documentation.',
  },
  {
    title: 'Track Drivers',
    description:
      'See where my drivers are for each load to reduce phone calls.',
  },
  {
    title: 'Accounting and Billing',
    description: 'Generate invoices, get paid, and report on load and payment.',
  },
  {
    title: 'Optimize Driver Routes',
    description:
      'Organize trips for my drivers based on load pickup and delivery.',
  },
  {
    title: 'Business Reporting and Dashboards',
    description:
      'Track revenue, profits, dispatcher or driver performance, and more.',
  },
  {
    title: 'Manage my Fleet',
    description:
      'Monitor and manage, trucks, equipment, maintenance, and more.',
  },
  {
    title: 'Other',
    description: '',
  },
];

export function OnboardingQuestionaireStep1Page() {
  const navigate = useNavigate();
  const { mutateAsync: saveStep } = useSaveOnboardingWithErrorHandler();
  const { data: onboarding } = useOnboardingDetails();
  const shuffledOptions = useMemo(() => {
    const options = FIELD_OPTIONS;
    const lastOption = options.pop();
    const nextOptions = shuffle(options);
    if (lastOption) nextOptions.push(lastOption);
    return nextOptions;
  }, []);

  const formik = useFormikEnhanced({
    initialValues: {
      selected_option: '',
      other_field_value: '',
    },
    validateOnChange: true,
    validationSchema: onboardingQuestionStepSchema,
    onSubmit: ({ selected_option, other_field_value }) => {
      return saveStep({
        stepName: 'question_how_can_ctms_make_your_job_easier',
        status: 'completed',
        data: {
          selected_options: [
            selected_option === 'Other'
              ? `Other: ${other_field_value}`
              : selected_option,
          ],
          unselected_options: FIELD_OPTIONS.filter(
            (field) => field.title !== selected_option,
          ).map((field) => field.title),
        },
      });
    },
    onSubmitSuccess: () => {
      if (onboarding?.steps?.team_invitation?.status === 'pending') {
        navigate('/onboarding/invite-team');
      } else {
        openExternalURL('/loadboard');
      }
    },
  });

  function handleClick(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    value: string,
  ) {
    e.preventDefault();
    void formik.setFieldValue('selected_option', value);
  }

  return (
    <OnboardingLayout maxWidth="1050px" progress={35}>
      <FormikProvider value={formik}>
        <Form>
          <Stack space="large">
            <Stack space="xsmall" align="center">
              <Typography variant="h2">
                What is the primary reason you joined Super Dispatch
              </Typography>
              <Typography color="textSecondary">
                Let us know how you&apos;ll be using the product. Please select
                the option that best applies.
              </Typography>
            </Stack>
            <Stack space="small">
              <Container>
                {shuffledOptions.map((field) => (
                  <OnboardingCheckboxField
                    key={field.title}
                    name="selected_option"
                    onClick={(event) => {
                      handleClick(event, field.title);
                    }}
                    selected={formik.values.selected_option === field.title}
                    label={
                      field.title === 'Other' ? (
                        <Stack>
                          <Typography variant="h5">Other</Typography>
                          <FormikTextField
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            name="other_field_value"
                            placeholder="Tell us what"
                          />
                        </Stack>
                      ) : (
                        <Stack>
                          <Typography variant="h5">{field.title}</Typography>
                          <Typography color="textSecondary">
                            {field.description}
                          </Typography>
                        </Stack>
                      )
                    }
                  />
                ))}
              </Container>
            </Stack>
            <Inline horizontalAlign="center">
              <Button
                size="large"
                type="submit"
                disabled={!Object.values(formik.values).some(Boolean)}
                pending={formik.isSubmitting}
              >
                Next
              </Button>
            </Inline>
          </Stack>
        </Form>
      </FormikProvider>
    </OnboardingLayout>
  );
}
