import { Link, Typography } from '@material-ui/core';
import { Color } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import ThunderIcon from 'shared/modules/superpay/assets/ic-thunder.png';
import styled from 'styled-components';

interface SuperPayExpeditedPayNudgingProps {
  onClick: () => void;
}

const StyledImg = styled.img`
  height: 14px;
  width: 12px;
  margin-top: 2px;
-`;
const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 282px;
  border-radius: 4px;
  gap: 4px;
  background-color: ${Color.Silver200};
`;
const GradientBox = styled.div`
  display: flex;
  gap: 4px;
  padding: 2px 4px;
  border-radius: 4px 0px 0px 4px;
  background: linear-gradient(
    90deg,
    #ffe6cea6 0%,
    #fbe5ffa6 50%,
    #c8ffff00 100%
  );
`;

export function SuperPayExpeditedPayNudging({
  onClick,
}: SuperPayExpeditedPayNudgingProps) {
  return (
    <StyledBox>
      <GradientBox>
        <StyledImg src={ThunderIcon} />
        <Typography>Get Paid Faster.</Typography>
      </GradientBox>
      <Link
        data-intercom-target="expedite-payment-link"
        onClick={(e) => {
          e.preventDefault();
          onClick();
        }}
      >
        <Typography color="textPrimary">Expedite Payment</Typography>
      </Link>
    </StyledBox>
  );
}
