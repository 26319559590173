import { useFlag } from 'shared/settings/FeatureToggles';
import { useMoovAvailable as useMoovAvailableLegacy } from '../superpay/useMoovAvailable';
import { useMoovAvailable } from './MoovAPI';

export function useSuperPayMoovAvailable() {
  const isMoovRefactoring = useFlag('payments_moovjs_refactoring');

  const { isMoovAvailable: isMoovRefactoringLegacy, isLoading } =
    useMoovAvailableLegacy(!isMoovRefactoring);
  const isAvailable = useMoovAvailable(isMoovRefactoring);

  const isMoovAvailable = isMoovRefactoring
    ? isAvailable
    : isMoovRefactoringLegacy;

  return { isLoading, isMoovAvailable };
}
