import {
  Card,
  CardContent,
  Link,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { PhoneText } from '@superdispatch/phones';
import { Stack } from '@superdispatch/ui';
import { Box, Button, TextBox } from '@superdispatch/ui-lab';
import { toInteger } from 'lodash-es';
import { useMatch, useNavigate } from 'react-router-dom-v5-compat';
import { formatAddressLine } from 'shared/helpers/AddressHelpers';
import { useQuery } from 'shared/helpers/RoutingHelpers';
import { DocumentTitle } from 'shared/layout/DocumentTitle';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';
import { PaginationCard } from 'shared/ui/PaginationCard';
import { useTerminalPage } from '../terminals/data/TerminalsAPI';
import { SettingsPageLayout } from './core/SettingsPageLayout';
import { SettingsTerminalAddDrawer } from './SettingsTerminalAddDrawer';

const PAGE_SIZE = 10;

export function SettingsTerminalPage() {
  const navigate = useNavigate();
  const match = useMatch('/settings/terminals/add/');
  const [query, setQuery] = useQuery();
  const page = Math.max(1, toInteger(query.page));
  const { refetch } = useCarrierSettings();
  const { data: terminals } = useTerminalPage({ page, page_size: PAGE_SIZE });

  return (
    <>
      <DocumentTitle title="Terminal - Settings" />

      <SettingsTerminalAddDrawer
        open={!!match}
        onClose={() => {
          void refetch();
          navigate('/settings/terminals/', { replace: true });
        }}
      />

      <SettingsPageLayout
        title="Terminals"
        loading={!terminals}
        actions={
          <Button
            onClick={() => {
              navigate('/settings/terminals/add/');
            }}
          >
            Add Terminal
          </Button>
        }
      >
        <Box maxWidth="512px" width="100%">
          <Stack space="small">
            <Card aria-label="terminals list card">
              <CardContent>
                <Typography>
                  Terminals are used to manage orders that involve yards or
                  terminals, order splits, and multiple drivers.{' '}
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://support.superdispatch.com/everything-dispatch-dashboard/terminal-and-yard-management"
                  >
                    Learn more...
                  </Link>
                </Typography>

                {terminals == null ? null : !terminals.data.length ? (
                  <Box paddingTop="small">
                    <TextBox align="center" color="secondary">
                      There are no terminals added
                    </TextBox>
                  </Box>
                ) : (
                  terminals.data.map((terminal, i) => (
                    <ListItem
                      key={terminal.guid}
                      disableGutters={true}
                      divider={i !== terminals.data.length - 1}
                    >
                      <ListItemText
                        primary={terminal.name}
                        secondary={
                          <>
                            {formatAddressLine(
                              terminal.zip,
                              `${terminal.street}, ${terminal.city}`,
                              terminal.state,
                            )}
                            <br />
                            {terminal.contact_name}{' '}
                            <PhoneText
                              phone={terminal.phone_number}
                              fallback="Invalid Phone"
                            />
                          </>
                        }
                      />
                    </ListItem>
                  ))
                )}
              </CardContent>
            </Card>

            {terminals?.pagination &&
              terminals.pagination.count > PAGE_SIZE && (
                <PaginationCard
                  page={page}
                  pageSize={PAGE_SIZE}
                  count={terminals.pagination.count}
                  onChange={(nextPage) => {
                    setQuery({ page: nextPage });
                  }}
                />
              )}
          </Stack>
        </Box>
      </SettingsPageLayout>
    </>
  );
}
