import { Route, Routes } from 'react-router-dom-v5-compat';
import { SplitLoadsPage } from './SplitLoadsPage';

export function SplitLoadsContainerPage() {
  return (
    <Routes>
      <Route path=":guid" element={<SplitLoadsPage />} />
      <Route index={true} element={<SplitLoadsPage />} />
    </Routes>
  );
}
