import { IconButton, Typography } from '@material-ui/core';
import { Add, DeleteOutline } from '@material-ui/icons';
import { Color, Column, Columns, Stack } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { FieldArray, useFormikContext } from 'formik';
import { useSetAtom } from 'jotai';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { DragIcon } from 'shared/icons/DragIcon';
import styled from 'styled-components';
import { trackTripsEvent } from '../data/TripsAnalytics';
import {
  useAddTripLoad,
  useRemoveTripLoad,
  useReorderTripLoads,
} from '../data/TripsAPI';
import { LoadForTripDTO, TripFormDTO } from '../data/TripsDTO';
import { TripFormLoad } from '../trip-form-page/TripFormLoad';
import { hoveredFieldNameAtom } from '../TripsDetails';

interface TripLoadFieldArrayProps {
  tripGuid: string;
  driverGuid?: string;
}

const DragWrapper = styled.div`
  width: 100%;

  &:hover {
    svg {
      color: ${Color.Dark300};
      path {
        fill: ${Color.Dark300};
      }
    }
  }
`;

const AddLoadButton = styled(Button)`
  padding-left: 0;
`;

export function TripLoadFieldArray({
  tripGuid,
  driverGuid,
}: TripLoadFieldArrayProps) {
  const formik = useFormikContext<TripFormDTO>();
  const setHoveredFieldName = useSetAtom(hoveredFieldNameAtom);
  const { loads } = formik.values;

  const addLoad = useAddTripLoad(tripGuid, {
    onSuccess: () => {
      trackTripsEvent({
        name: 'Carrier Added Load to Trip',
        trip_guid: tripGuid,
      });
    },
  });
  const removeLoad = useRemoveTripLoad(tripGuid, {
    onSuccess: () => {
      trackTripsEvent({
        name: 'Carrier Removed Load from Trip',
        trip_guid: tripGuid,
      });
    },
  });
  const reorderLoads = useReorderTripLoads(tripGuid, {
    onSuccess: () => {
      trackTripsEvent({
        name: 'Carrier Reordered Loads',
        trip_guid: tripGuid,
      });
    },
  });

  const handleSortEnd = (result: DropResult) => {
    if (!result.destination || addLoad.isLoading || removeLoad.isLoading) {
      return;
    }

    const { destination, source } = result;
    const { index: destinationIndex } = destination;
    const { index: sourceIndex } = source;

    if (loads) {
      const newLoads = [
        ...loads.slice(0, sourceIndex),
        ...loads.slice(sourceIndex + 1),
      ];

      newLoads.splice(
        destinationIndex,
        0,
        loads[sourceIndex] as LoadForTripDTO,
      );

      const reorderedLoadList = newLoads.map((loadItem, index) => ({
        load_guid: loadItem.guid,
        load_order: index,
      }));

      reorderLoads.mutate(reorderedLoadList);
      void formik.setFieldValue('loads', newLoads);
    }
  };

  const handleLoadSelect = (load: LoadForTripDTO) => {
    addLoad.mutate(load.guid);
  };

  return (
    <FieldArray
      name="loads"
      render={({ push, remove }) => {
        return (
          <DragDropContext onDragEnd={handleSortEnd}>
            <Droppable droppableId="TripLoadsInput">
              {({ droppableProps, innerRef: droppableRef, placeholder }) => (
                <Box ref={droppableRef} {...droppableProps}>
                  <Stack space="small">
                    {loads?.map((load, id) => {
                      return (
                        <Draggable draggableId={`${id}`} index={id} key={id}>
                          {({ dragHandleProps, draggableProps, innerRef }) => (
                            <DragWrapper
                              ref={innerRef}
                              {...draggableProps}
                              {...dragHandleProps}
                              onMouseLeave={() => {
                                setHoveredFieldName('');
                              }}
                              onMouseOver={() => {
                                if (load.guid) {
                                  setHoveredFieldName(`loads[${id}]`);
                                }
                              }}
                            >
                              <Columns align="center" space="xsmall">
                                <Column width="fluid">
                                  {load.guid ? (
                                    <Columns align="center" space="xsmall">
                                      <Column width="content">
                                        <DragIcon />
                                      </Column>
                                      <Column width="fluid">
                                        <Typography>{load.load_id}</Typography>
                                      </Column>
                                    </Columns>
                                  ) : (
                                    <TripFormLoad
                                      id={id}
                                      guid={tripGuid}
                                      driverGuid={driverGuid}
                                      onSelect={handleLoadSelect}
                                      name={`loads[${id}]`}
                                    />
                                  )}
                                </Column>

                                <Column width="adaptive">
                                  <IconButton
                                    onClick={() => {
                                      remove(id);
                                      if (load.guid) {
                                        removeLoad.mutate(load.guid);
                                      }
                                    }}
                                  >
                                    <DeleteOutline
                                      htmlColor={Color.Silver500}
                                    />
                                  </IconButton>
                                </Column>
                              </Columns>
                            </DragWrapper>
                          )}
                        </Draggable>
                      );
                    })}
                    {placeholder}

                    <AddLoadButton
                      variant="text"
                      startIcon={<Add />}
                      disabled={addLoad.isLoading || removeLoad.isLoading}
                      onClick={() => {
                        push({ guid: '', number: '' });
                      }}
                    >
                      {loads && loads.length > 0
                        ? 'Add Another Load'
                        : 'Add Load'}
                    </AddLoadButton>
                  </Stack>
                </Box>
              )}
            </Droppable>
          </DragDropContext>
        );
      }}
    />
  );
}
