import { Color } from '@superdispatch/ui';
import { createSvgIcon } from 'shared/icons/IconUtils';

export const DomainIcon = createSvgIcon(
  'DomainIcon',
  <>
    <rect
      x="1.5"
      y="1.5"
      width="59"
      height="59"
      rx="29.5"
      fill={Color.Blue300}
    />
    <rect
      x="1.5"
      y="1.5"
      width="59"
      height="59"
      rx="29.5"
      stroke={Color.White}
      strokeWidth="3"
    />
    <path
      d="M21 40V22H31V26H41V34H39V28H31V30H33V32H31V34H33V36H31V38H35V40H21ZM23 38H25V36H23V38ZM23 34H25V32H23V34ZM23 30H25V28H23V30ZM23 26H25V24H23V26ZM27 38H29V36H27V38ZM27 34H29V32H27V34ZM27 30H29V28H27V30ZM27 26H29V24H27V26ZM39 42V40H37V38H39V36H41V38H43V40H41V42H39ZM35 32V30H37V32H35ZM35 36V34H37V36H35Z"
      fill={Color.Blue50}
    />
  </>,
  {
    viewBox: '0 0 62 62',
    style: { height: '62px', width: '62px', fill: 'none' },
  },
);
