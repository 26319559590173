import { SidebarContainer } from '@superdispatch/ui-lab';
import { Outlet, Route, Routes } from 'react-router-dom-v5-compat';
import { useFlag } from 'shared/settings/FeatureToggles';
import { ProfileACHPaymentInfoPage } from './ProfileACHPaymentInfoPage';
import { ProfileBillingInfoPage } from './ProfileBillingInfoPage';
import { ProfileBusinessLicensePage } from './ProfileBusinessLicensePage';
import { ProfileCargoInsurancePage } from './ProfileCargoInsurancePage';
import { ProfileCargoInsurancePageLegacy } from './ProfileCargoInsurancePageLegacy';
import { ProfileCarrierInfoPage } from './ProfileCarrierInfoPage';
import { ProfileOverviewPage } from './ProfileOverviewPage';
import { ProfilePhoneVerificationPage } from './ProfilePhoneVerificationPage';
import { ProfileSidebar } from './ProfileSidebar';
import { ProfileStateMotorCarrierPermitPage } from './ProfileStateMotorCarrierPermit';
import { ProfileSuperPayPage } from './ProfileSuperPayPage';
import { ProfileTermsAndConditionsPage } from './ProfileTermsAndConditionsPage';
import { ProfileTransporterPlateRegistrationPage } from './ProfileTransporterPlateRegistrationPage';
import { ProfileUSDOTPage } from './ProfileUSDOTPage';
import { ProfileW9Page } from './ProfileW9Page';
import { ProfileVerifiedCarrierApplicationPage } from './verified-carrier-application/ProfileVerifiedCarrierApplicationPage';

export function ProfileContainerPage() {
  const isCargoRenewalEnabled = useFlag('cargo_insurance_renewal');
  return (
    <Routes>
      <Route
        path="verified-carrier-application/*"
        element={<ProfileVerifiedCarrierApplicationPage />}
      />

      <Route
        path="phone-verification"
        element={<ProfilePhoneVerificationPage />}
      />

      <Route
        path=":step?/*"
        element={
          <SidebarContainer sidebar={<ProfileSidebar />}>
            <Outlet />
          </SidebarContainer>
        }
      >
        <Route path="overview" element={<ProfileOverviewPage />} />
        <Route path="carrier_info" element={<ProfileCarrierInfoPage />} />
        <Route
          path="cargo_insurance"
          element={
            isCargoRenewalEnabled ? (
              <ProfileCargoInsurancePage />
            ) : (
              <ProfileCargoInsurancePageLegacy />
            )
          }
        />
        <Route path="w9" element={<ProfileW9Page />} />
        <Route path="usdot" element={<ProfileUSDOTPage />} />
        <Route
          path="transporter_plate_registration"
          element={<ProfileTransporterPlateRegistrationPage />}
        />
        <Route
          path="state_motor_carrier_permit"
          element={<ProfileStateMotorCarrierPermitPage />}
        />
        <Route
          path="business_license"
          element={<ProfileBusinessLicensePage />}
        />
        <Route path="billing_info" element={<ProfileBillingInfoPage />} />
        <Route path="ach_payment" element={<ProfileACHPaymentInfoPage />} />
        <Route path="superpay/*" element={<ProfileSuperPayPage />} />
        <Route
          path="terms_and_conditions"
          element={<ProfileTermsAndConditionsPage />}
        />
      </Route>
    </Routes>
  );
}
