import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from 'react-query';
import { requestJSON } from 'shared/api/CarrierAPIClient';
import { MEDIA_SERVICE_API_HOST } from 'shared/constants/ServerConstants';

export function uploadMedia(file: File): Promise<string> {
  const body = new FormData();
  body.append('file', file, file.name);
  return requestJSON('POST /v1/upload/', {
    body,
    baseURL: MEDIA_SERVICE_API_HOST,
  }).then((data) => {
    if (typeof data == 'object' && data) {
      const { url } = data as { url?: unknown };
      if (typeof url == 'string' && url) return url;
    }
    return Promise.reject(new Error('Failed to upload media file.'));
  });
}

export function useUploadMedia(
  options?: UseMutationOptions<string, Error, File>,
): UseMutationResult<string, Error, File> {
  return useMutation(uploadMedia, options);
}
