import {
  useCallback,
  useState,
  type Dispatch,
  type SetStateAction,
} from 'react';

interface UseBooleanOutput {
  isEnabled: boolean;
  setTrue: () => void;
  setFalse: () => void;
  toggle: () => void;
  setEnabled: Dispatch<SetStateAction<boolean>>;
}

interface OptionProps {
  onTrue: () => void;
  onFalse: () => void;
  onToggle: () => void;
}

export const useBoolean = (
  defaultValue: boolean | undefined = false,
  { onTrue, onFalse, onToggle }: Partial<OptionProps> = {},
): UseBooleanOutput => {
  const [isEnabled, setEnabled] = useState(!!defaultValue);

  const setTrue = useCallback(() => {
    setEnabled(true);
    if (onTrue) onTrue();
  }, [onTrue]);

  const setFalse = useCallback(() => {
    setEnabled(false);
    if (onFalse) onFalse();
  }, [onFalse]);

  const toggle = useCallback(() => {
    setEnabled((x) => !x);
    if (onToggle) onToggle();
  }, [onToggle]);

  return { isEnabled, setTrue, setFalse, toggle, setEnabled };
};
