import { Link, Typography } from '@material-ui/core';
import { parseURITemplate } from '@superdispatch/uri';
import { Link as RouterLink } from 'react-router-dom-v5-compat';
import { CopyToClipboardTooltip } from 'shared/ui/CopyToClipboardTooltip';
import { LoadDTO } from '../data/LoadDTO';
import { trackLoadsEvent } from '../data/LoadsAnalytics';

interface LoadPageLoadIdProps {
  load: LoadDTO;
}

export function LoadsPageLoadId({ load }: LoadPageLoadIdProps) {
  const currentURL = window.location.pathname + window.location.search;

  return (
    <CopyToClipboardTooltip
      textToCopy={load.number}
      title="Copy ID"
      onCopy={() => {
        trackLoadsEvent({
          name: 'Carrier Copied Load ID',
        });
      }}
    >
      <Link
        data-intercom-target="loads-page-load-id"
        component={RouterLink}
        to={parseURITemplate('/loads/{guid}{?next,stage}', {
          guid: load.guid,
          next: currentURL,
          stage: load.stage,
        })}
        onClick={() => {
          trackLoadsEvent({
            name: 'CTMS: View Load Page Opened',
            referrer_button: 'load_id',
          });
        }}
      >
        <Typography variant="h3" component="div">
          {load.number || "Load doesn't have ID"}
        </Typography>
      </Link>
    </CopyToClipboardTooltip>
  );
}
