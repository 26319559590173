import { Card, Typography } from '@material-ui/core';
import { Color, Column, Columns, Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import React from 'react';
import { formatCurrency } from 'shared/helpers/IntlHelpers';
import { LinkAnchor } from 'shared/routing/Links';
import styled from 'styled-components';
import { TripDTO } from '../data/TripsDTO';

const PaymentEntryDivider = styled.div`
  border-bottom: 1px dotted ${Color.Silver400};
  height: 18px;
`;

const CustomerNameWrapper = styled(Typography)`
  text-overflow: ellipsis;
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
`;

interface PaymentEntryProps {
  startContent: React.ReactNode;
  endContent: React.ReactNode;
  divider?: boolean;
}

const PaymentEntry: React.FC<PaymentEntryProps> = ({
  startContent,
  endContent,
  divider = true,
}) => (
  <Columns space="small">
    <Column width="content">{startContent}</Column>
    <Column width="fluid">{divider && <PaymentEntryDivider />}</Column>
    <Column width="content">{endContent}</Column>
  </Columns>
);

interface TripDetailsPaymentProps {
  trip: TripDTO | undefined;
}

export const TripDetailsPayment: React.FC<TripDetailsPaymentProps> = ({
  trip,
}) => {
  if (!trip) return null;

  const { broker_fee_sum, driver_pay_sum, expenses_sum, total_price, loads } =
    trip;

  return (
    <Card data-intercom-target="trip-payments">
      <Box padding="small">
        <Stack space="large">
          <Stack space="small">
            <Typography variant="h3">Payments</Typography>

            {!loads?.length ? (
              <Box maxWidth="300px" margin="auto" paddingBottom="small">
                <Typography align="center" color="textSecondary">
                  Add loads to the trip to start seeing payment information
                </Typography>
              </Box>
            ) : (
              <>
                {loads.map((load) => (
                  <Stack space="xxsmall" key={load.guid}>
                    <PaymentEntry
                      startContent={
                        <CustomerNameWrapper>
                          {load.customer_name || 'No Customer Name'}
                        </CustomerNameWrapper>
                      }
                      endContent={
                        <Typography variant="body1">
                          {load.payment.amount}
                        </Typography>
                      }
                    />
                    <PaymentEntry
                      startContent={
                        <LinkAnchor
                          to={`/loads/${load.guid}`}
                          color="textSecondary"
                        >
                          {load.number || 'No Load ID'}
                        </LinkAnchor>
                      }
                      endContent={
                        <Typography color="textSecondary">
                          {load.payment.term}
                        </Typography>
                      }
                      divider={false}
                    />
                  </Stack>
                ))}

                {expenses_sum && (
                  <PaymentEntry
                    startContent={
                      <Typography color="textSecondary">
                        Total Expenses
                      </Typography>
                    }
                    endContent={
                      <Typography>{formatCurrency(expenses_sum)}</Typography>
                    }
                  />
                )}
                {broker_fee_sum && (
                  <PaymentEntry
                    startContent={
                      <Typography color="textSecondary">
                        Total Broker Fee
                      </Typography>
                    }
                    endContent={
                      <Typography>{formatCurrency(broker_fee_sum)}</Typography>
                    }
                  />
                )}
                {driver_pay_sum && (
                  <PaymentEntry
                    startContent={
                      <Typography color="textSecondary">
                        Total Driver Pay
                      </Typography>
                    }
                    endContent={
                      <Typography>{formatCurrency(driver_pay_sum)}</Typography>
                    }
                  />
                )}
              </>
            )}
          </Stack>

          {!!loads?.length && !!total_price && (
            <PaymentEntry
              startContent={<Typography variant="h4">Total Price</Typography>}
              endContent={
                <Typography variant="h4">
                  {formatCurrency(total_price)}
                </Typography>
              }
            />
          )}
        </Stack>
      </Box>
    </Card>
  );
};
