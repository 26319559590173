import {
  InfiniteData,
  QueryClient,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
} from 'react-query';
import { QueryFilters } from 'react-query/types/core/utils';
import { APIQueryInput } from 'shared/api/APIQuery';
import { APIPageResponse } from 'shared/api/CarrierAPIClient';
import { Schema } from 'yup';

export type BaseAPIListQueryOptions<TData> = Omit<
  UseInfiniteQueryOptions<APIPageResponse<TData>, Error>,
  | 'queryFn'
  | 'queryKey'
  | 'queryHash'
  | 'queryKeyHashFn'
  | 'isDataEqual'
  | 'getNextPageParam'
  | 'getPreviousPageParam'
>;

export interface APIListQueryOptions<TData>
  extends BaseAPIListQueryOptions<TData> {
  schema?: Schema<TData>;
  normalize?: (response: unknown) => TData;
}

export type APIListQueryData<TData = unknown> = InfiniteData<
  APIPageResponse<TData>
>;

export type APIListQueryResult<TData> = UseInfiniteQueryResult<
  APIPageResponse<TData>,
  Error
>;

export function useAPIListQuery<TData>(
  input: APIQueryInput,
  query: (page: number) => Promise<APIPageResponse<TData>>,
  {
    schema,
    normalize,
    refetchOnWindowFocus = false,
    ...options
  }: APIListQueryOptions<TData>,
): APIListQueryResult<TData> {
  return useInfiniteQuery(
    input,
    ({ pageParam }) =>
      query(pageParam).then((response) => {
        if (schema || normalize) {
          response.data = response.data.map((entry) => {
            if (schema) {
              entry = schema.cast(entry);
            }

            if (normalize) {
              entry = normalize(entry);
            }

            return entry;
          });
        }

        return response;
      }),
    {
      ...options,
      refetchOnWindowFocus,
      getNextPageParam: (lastPage, allPages) => {
        const { next } = lastPage.pagination;

        if (next) {
          try {
            return new URL(next).searchParams.get('page');
          } catch {}
        }

        //loadboard return empty string for next page param
        //check if next is empty string and that all items are not loaded yet
        if (next === '') {
          const loadedCount = allPages.flatMap((page) => page.data).length;
          if (loadedCount < lastPage.pagination.count) {
            // number of loaded pages are represent the next page number because loadboard pagination starts from 0
            return allPages.length;
          }
        }

        return null;
      },
    },
  );
}

export function forEachAPIListQueryItem<TData>(
  input: APIQueryInput,
  queryClient: QueryClient,
  fn: (item: TData) => void | false,
  filters?: QueryFilters,
): void {
  for (const { state } of queryClient.getQueryCache().findAll(input, filters)) {
    if (state.data) {
      const { pages } = state.data as APIListQueryData<TData>;

      for (const page of pages) {
        for (const item of page.data) {
          if (fn(item) === false) return;
        }
      }
    }
  }
}

export function findAPIListQueryItem<TData>(
  input: APIQueryInput,
  queryClient: QueryClient,
  fn: (item: TData) => boolean,
  filters?: QueryFilters,
): undefined | TData {
  let found: undefined | TData = undefined;

  forEachAPIListQueryItem<TData>(
    input,
    queryClient,
    (item) => {
      if (!fn(item)) return;
      found = item;
      return false;
    },
    filters,
  );

  return found;
}
