import { MenuItem } from '@material-ui/core';
import { TimeField } from '@superdispatch/dates';
import {
  FormikCurrencyField,
  FormikDateField,
  FormikTextField,
  useFormikEnhanced,
} from '@superdispatch/forms';
import { formatLoadPaymentMethod } from '@superdispatch/sdk';
import { Column, Columns, Stack, useSnackbarStack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { useMemo } from 'react';
import { APIResponse } from 'shared/api/CarrierAPIClient';
import { FormikDrawerActions } from 'shared/form/FormikDrawer';
import { SlowFormikDrawer } from 'shared/form/SlowFormikDrawer';
import { useResource } from 'shared/helpers/Resource';
import { validateNumber } from 'shared/helpers/ValidationHelpers';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';
import {
  LoadDTO,
  LoadMarkAsPaidDTO,
  loadMarkAsPaidSchema,
} from '../data/LoadDTO';
import { useLoadsContext } from '../data/LoadsContext';

export interface LoadMarkAsPaidDrawerProps {
  load?: LoadDTO;
  onClose: () => void;
  onSubmitSuccess: () => void;
}

export function LoadMarkAsPaidDrawer({
  load,
  onClose,
  onSubmitSuccess,
}: LoadMarkAsPaidDrawerProps) {
  const loadGUID = load?.guid || '';
  const { method, price, notes } = load?.payments[0] || {};
  const { data } = useCarrierSettings();
  const { loadsAPI } = useLoadsContext();
  const { addSnackbar } = useSnackbarStack();
  const currentDate = useMemo(() => new Date().toISOString(), []);

  const paidMethodOptions = useMemo(
    () =>
      data?.paid_methods.filter((paidMethod) => {
        return !(
          method !== 'superpay' && paidMethod.text.toLowerCase() === 'superpay'
        );
      }),
    [data?.paid_methods, method],
  );

  const paidMethodID = useMemo(() => {
    if (method) {
      const formattedMethod = formatLoadPaymentMethod(method);
      const paidMethod = paidMethodOptions?.find(
        ({ text }) => formattedMethod === text,
      );
      return paidMethod?.value || null;
    }
    return null;
  }, [method, paidMethodOptions]);

  const quickbooksSettings = useResource(
    () => (!loadGUID ? undefined : loadsAPI.getQuickBooksSettings()),
    [loadsAPI, loadGUID],
  );

  const loadInvoiceInfo = useResource(
    () => (!loadGUID ? undefined : loadsAPI.getLoadInvoiceInfo(loadGUID)),
    [loadsAPI, loadGUID],
  );

  const formik = useFormikEnhanced<LoadMarkAsPaidDTO, APIResponse>({
    initialValues: {
      paid_amount: Number(price || 0),
      paid_method: paidMethodID,
      payment_notes: notes || '',
      payment_reference_number: '',
      receipt_date: currentDate,
    },
    onSubmit: (values) =>
      loadsAPI.markAsPaid(loadGUID, loadMarkAsPaidSchema.cast(values)),
    onSubmitSuccess: ({ user_message }) => {
      addSnackbar(user_message || 'Marked as paid.', { variant: 'success' });
      onSubmitSuccess();
    },
    onSubmitFailure: ({ message }) => {
      addSnackbar(message, { variant: 'error' });
    },
  });

  return (
    <SlowFormikDrawer
      formik={formik}
      title="Mark as Paid"
      open={!!load}
      onClose={onClose}
      actions={<FormikDrawerActions />}
    >
      <Stack>
        <FormikCurrencyField
          fullWidth={true}
          name="paid_amount"
          label="Paid Amount"
          validate={(value) => validateNumber(value, { min: 0 })}
          helperText={
            quickbooksSettings.data?.connected &&
            loadInvoiceInfo.data?.data.qb_desktop.connected &&
            'To sync with QuickBooks specify paid amount'
          }
        />

        <FormikTextField
          select={true}
          name="paid_method"
          fullWidth={true}
          label="Paid Method"
          validate={(value) =>
            value != null && value !== '' ? undefined : 'Select method'
          }
        >
          {paidMethodOptions?.map(({ value, text }) => (
            <MenuItem key={value} value={value}>
              {text}
            </MenuItem>
          ))}
        </FormikTextField>

        <FormikTextField
          fullWidth={true}
          name="payment_reference_number"
          label="Reference Number"
        />

        <FormikDateField
          fullWidth={true}
          variant="DateTime"
          label="Receipt Date"
          name="receipt_date"
          enableClearable={true}
          disableCloseOnSelect={true}
          renderFooter={(api) => (
            <Columns space="small">
              <Column>
                <TimeField
                  fullWidth={true}
                  placeholder="Select Time"
                  value={api.dateValue}
                  onChange={({ dateValue }) => {
                    api.change(dateValue);
                  }}
                />
              </Column>
              <Column width="content">
                <Button onClick={api.close}>Save</Button>
              </Column>
            </Columns>
          )}
        />

        <FormikTextField
          multiline={true}
          fullWidth={true}
          name="payment_notes"
          label="Payment Notes"
        />
      </Stack>
    </SlowFormikDrawer>
  );
}
