import { useRef } from 'react';

export function useCallbackRef<T extends (...args: any[]) => unknown>(
  callback: T,
): T {
  const callbackRef = useRef<T>(callback);
  callbackRef.current = callback;

  // @ts-expect-error complicated typing
  const ref = useRef<T>((...args: Parameters<T>): ReturnType<T> => {
    return callbackRef.current(...args) as ReturnType<T>;
  });

  return ref.current;
}
