import { useEffect } from 'react';

function getScrollParent(
  element: HTMLElement | null | undefined,
): HTMLElement | null {
  if (!element) {
    return null;
  }

  if (element.scrollTop > 0) {
    return element;
  }

  return getScrollParent(element.parentElement);
}

export function useScrollToTop(
  anchorElement?: HTMLElement | null,
  deps: unknown[] = [],
) {
  useEffect(() => {
    const scrollableElement = getScrollParent(anchorElement);

    if (scrollableElement) {
      scrollableElement.scrollTop = 0;
    } else {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchorElement, ...deps]);
}

export function ScrollToTop({
  anchorElement,
}: {
  anchorElement?: HTMLElement | null;
}) {
  useScrollToTop(anchorElement);

  return null;
}
