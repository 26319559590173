import { Column, Columns, Tag } from '@superdispatch/ui';
import {
  Sidebar,
  SidebarContainer,
  SidebarMenuItem,
} from '@superdispatch/ui-lab';
import { ReactNode } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom-v5-compat';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import { useShowAddBillingAddressDialog } from 'shared/modules/subscription/core/useShowAddBillingAddressDialog';
import { SidebarMenuItemLink } from 'shared/routing/SidebarMenuItemLink';
import { useFlag } from 'shared/settings/FeatureToggles';
import { SettingsBillingPage } from './SettingsBillingPage';
import { SettingsDriverAppPage } from './SettingsDriverAppPage';
import { SettingsFactoringPage } from './SettingsFactoringPage';
import { SettingsGeneralPage } from './SettingsGeneralPage';
import { SettingsNotificationsPage } from './SettingsNotificationsPage';
import { SettingsQuickbooksIntegrationPage } from './SettingsQuickbooksIntegrationPage';
import { SettingsSubscriptionLegacyPage } from './SettingsSubscriptionDetailsLegacyPage';
import { SettingsSubscriptionPage } from './SettingsSubscriptionDetailsPage';
import { SettingsTerminalPage } from './SettingsTerminalsPage';

interface SettingsSidebarProps {
  href: string;
  children?: ReactNode;
}

function SettingsSidebarExternalItem({ href, children }: SettingsSidebarProps) {
  return (
    <SidebarMenuItem
      external={true}
      onClick={() => {
        openExternalURL(href, { target: '_blank' });
      }}
    >
      {children}
    </SidebarMenuItem>
  );
}

export function SettingsContainerPage() {
  const { doesBillingAddressNotExist } = useShowAddBillingAddressDialog();
  const isSeatBasedPriceEnabled = useFlag('seats_based_pricing');

  return (
    <SidebarContainer
      sidebar={
        <Sidebar title="Settings">
          <SidebarMenuItemLink to="/settings/general/">
            General
          </SidebarMenuItemLink>
          <SidebarMenuItemLink to="/settings/terminals/">
            Terminals
          </SidebarMenuItemLink>
          <SidebarMenuItemLink to="/settings/driver-app/">
            Driver App
          </SidebarMenuItemLink>
          <SidebarMenuItemLink to="/settings/billing/">
            Billing
          </SidebarMenuItemLink>
          <SidebarMenuItemLink to="/settings/factoring/">
            Factoring
          </SidebarMenuItemLink>
          <SidebarMenuItemLink to="/settings/quickbooks/">
            Quickbooks Integration
          </SidebarMenuItemLink>
          <SidebarMenuItemLink to="/settings/subscription/">
            <Columns space="xsmall">
              <Column width="fluid">Subscription Details</Column>
              <Column width="content">
                {doesBillingAddressNotExist && (
                  <Tag variant="subtle" color="yellow">
                    To Do
                  </Tag>
                )}
              </Column>
            </Columns>
          </SidebarMenuItemLink>
          <SidebarMenuItemLink to="/settings/notifications/">
            Notifications
          </SidebarMenuItemLink>
          <SettingsSidebarExternalItem href="/loadboard/settings/notifications/">
            Loadboard Notifications
          </SettingsSidebarExternalItem>
        </Sidebar>
      }
    >
      <Routes>
        <Route path="general" element={<SettingsGeneralPage />} />

        <Route path="terminals/*" element={<SettingsTerminalPage />} />

        <Route path="driver-app" element={<SettingsDriverAppPage />} />

        <Route path="billing" element={<SettingsBillingPage />} />

        <Route path="factoring" element={<SettingsFactoringPage />} />

        <Route
          path="quickbooks"
          element={<SettingsQuickbooksIntegrationPage />}
        />

        <Route
          path="subscription/*"
          element={
            isSeatBasedPriceEnabled ? (
              <SettingsSubscriptionPage />
            ) : (
              <SettingsSubscriptionLegacyPage />
            )
          }
        />

        <Route path="notifications" element={<SettingsNotificationsPage />} />

        <Route
          path="notification-emails"
          element={<Navigate to="/settings/notifications/" />}
        />

        <Route path="*" element={<Navigate to="/settings/general" />} />
      </Routes>
    </SidebarContainer>
  );
}
