import { DialogActions } from '@material-ui/core';
import { Button } from '@superdispatch/ui-lab';
import {
  MutationDialog,
  MutationDialogContent,
} from 'shared/form/MutationDialog';
import { useDeleteTripAttachment } from '../data/TripsAPI';
import { TripAttachmentDTO } from '../data/TripsDTO';

interface TripDetailsAttachmentDeleteDialogProps {
  onClose: () => void;
  onSuccess?: () => void;
  attachment?: TripAttachmentDTO;
  tripGUID: string;
}

export function TripDetailsAttachmentDeleteDialog({
  onClose,
  onSuccess,
  attachment,
  tripGUID,
}: TripDetailsAttachmentDeleteDialogProps) {
  const { mutate, isLoading } = useDeleteTripAttachment({
    onSuccess: () => {
      onClose();
      onSuccess?.();
    },
  });
  return (
    <MutationDialog onClose={onClose} open={!!attachment}>
      <MutationDialogContent
        disableAutoClose={true}
        title={
          <>
            Delete <strong>{attachment?.name || 'file'}</strong>?
          </>
        }
        actions={
          <DialogActions>
            <Button variant="neutral" onClick={onClose} disabled={isLoading}>
              Cancel
            </Button>
            <Button
              variant="critical"
              autoFocus={true}
              pending={isLoading}
              disabled={!attachment}
              onClick={() => {
                if (attachment) {
                  mutate({ attachmentGUID: attachment.guid, tripGUID });
                }
              }}
            >
              Delete
            </Button>
          </DialogActions>
        }
      />
    </MutationDialog>
  );
}
