import { useAPIMutation } from 'shared/api/APIMutation';
import { useAPIQuery } from 'shared/api/APIQuery';
import { APIResponse, requestCarrierAPI } from 'shared/api/CarrierAPIClient';
import {
  SuperPaySettingsDTO,
  superPaySettingsSchema,
} from 'shared/modules/superpay/SuperPayDTO';
import { MoovInformation, MoovScope, SyncMoovStep } from './MoovDTO';

export function useSuperPaySettings() {
  return useAPIQuery(
    'superpay-settings',
    () =>
      requestCarrierAPI<APIResponse<SuperPaySettingsDTO>>(
        'GET /internal/web/payments/superpay-settings/',
      ),
    { schema: superPaySettingsSchema, refetchOnWindowFocus: false },
  );
}

export function useSyncSuperPayOnboardingStep() {
  return useAPIMutation((values: SyncMoovStep) =>
    requestCarrierAPI('POST /internal/web/payments/sync-onboarding-step/', {
      json: values,
    }),
  );
}

export function generateMoovTokenAndGetAccount(scope: MoovScope) {
  return requestCarrierAPI<APIResponse<MoovInformation>>(
    'POST /internal/web/payments/generate-moov-token/',
    { json: { scope } },
  ).then((response) => response.data);
}

export function useGenerateMoovTokenAndGetAccount(
  isEnabled = false,
  scope: MoovScope,
) {
  return useAPIQuery(
    ['superpay', 'moov-information', { scope }],
    () =>
      requestCarrierAPI<APIResponse<MoovInformation>>(
        'POST /internal/web/payments/generate-moov-token/',
        { json: { scope } },
      ),
    {
      refetchOnWindowFocus: false,
      enabled: isEnabled,
    },
  );
}
