import { Route, Routes } from 'react-router-dom-v5-compat';
import { OnboardingInviteTeamStepPage } from './OnboardingInviteTeamStepPage';
import { OnboardingQuestionaireStep1Page } from './OnboardingQuestionnaireStep1Page';
import { OnboardingQuestionaireStep2Page } from './OnboardingQuestionnaireStep2Page';
import { OnboardingQuestionaireStep3Page } from './OnboardingQuestionnaireStep3Page';
import { OnboardingWelcomeStepPage } from './OnboardingWelcomeStepPage';

export function OnboardingContainer() {
  return (
    <Routes>
      <Route path="invite-team" element={<OnboardingInviteTeamStepPage />} />
      <Route
        path="questions-step-3"
        element={<OnboardingQuestionaireStep3Page />}
      />
      <Route
        path="questions-step-2"
        element={<OnboardingQuestionaireStep2Page />}
      />
      <Route
        path="questions-step-1"
        element={<OnboardingQuestionaireStep1Page />}
      />
      <Route index={true} element={<OnboardingWelcomeStepPage />} />
    </Routes>
  );
}
