import { Stack } from '@superdispatch/ui';
import { Alert, Box, Button } from '@superdispatch/ui-lab';
import { useState } from 'react';
import { APIResponse } from 'shared/api/CarrierAPIClient';
import { useAppFormik } from 'shared/form/AppFormik';
import { FormikDrawerContent } from 'shared/form/FormikDrawer';
import { LinkAnchor } from 'shared/routing/Links';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';
import styled from 'styled-components';
import {
  DriverDTO,
  driverEditLegacySchema,
} from '../../drivers/data/DriverDTO';
import { trackDriversEvent } from '../../drivers/data/DriversAnalytics';
import { useDriversAPI } from '../../drivers/data/DriversAPI';
import { DriverFormFields } from '../../drivers/DriverFormDrawer';
import { PlanUpdateDialog } from '../../drivers/PlanUpdateDialog';
import { LoadDTO } from '../data/LoadDTO';
import { useLoadsContext } from '../data/LoadsContext';

const Link = styled(LinkAnchor)`
  text-decoration: underline !important;
`;

interface LoadsAddDriverFormProps {
  onClose: () => void;
  onSuccess: (driver: DriverDTO | null) => void;
  load?: LoadDTO;
}

export function LoadsAddDriverForm({
  onClose,
  load,
  onSuccess,
}: LoadsAddDriverFormProps) {
  const [shouldShowDriversLinkAlert, setShowDriversLinkAlert] = useState(true);
  const [shouldConfirm, setShouldConfirm] = useState(false);

  const { createDriver } = useDriversAPI();
  const { data: settings } = useCarrierSettings();
  const { loadsAPI } = useLoadsContext();

  const formik = useAppFormik({
    validationSchema: driverEditLegacySchema,
    async onSubmit(values) {
      const loadGUID = load?.guid || '';

      const currentDriver = await createDriver(values);
      await loadsAPI.assignDriver(loadGUID, currentDriver.data.guid);

      return currentDriver;
    },
    onSubmitSuccess(response: APIResponse<DriverDTO>) {
      onSuccess(response.data);

      trackDriversEvent({
        name: 'CTMS: Driver Added',
        properties: {
          guid: response.data.guid,
          source: 'Assign Loads Page',
          userGuid: settings?.user.guid,
        },
      });

      onClose();
    },
  });

  const { isSubmitting } = formik;

  return (
    <>
      <PlanUpdateDialog
        open={shouldConfirm}
        onClose={() => {
          setShouldConfirm(false);
        }}
        onClick={() => {
          setShouldConfirm(false);
          void formik.submitForm();
        }}
      />

      <FormikDrawerContent onClose={onClose} formik={formik}>
        <Box maxWidth="368px">
          <Stack space="small">
            {shouldShowDriversLinkAlert && (
              <Alert
                onClose={() => {
                  setShowDriversLinkAlert(false);
                }}
                severity="info"
              >
                You can manage drivers from{' '}
                <Link underline="none" color="primary" to="/drivers">
                  Drivers page
                </Link>{' '}
                on navigation bar.
              </Alert>
            )}

            <DriverFormFields type="add" hasWarning={false} />

            <Box paddingTop="small">
              <Button
                disabled={isSubmitting}
                onClick={() => {
                  if (
                    settings?.is_paying &&
                    driverEditLegacySchema.isValidSync(formik.values)
                  ) {
                    setShouldConfirm(true);
                  } else {
                    void formik.submitForm();
                  }
                }}
              >
                Save & Assign
              </Button>
            </Box>
          </Stack>
        </Box>
      </FormikDrawerContent>
    </>
  );
}
