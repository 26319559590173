import { PostingSearchCriteriaVenueDTO } from 'shared/modules/loadboard/PostingSearchCriteriaDTO';

export const USA_REGIONS = [
  {
    region: 'Northeast',
    values: ['CT', 'DE', 'MA', 'ME', 'NH', 'NJ', 'NY', 'PA', 'RI', 'VT'],
  },
  {
    region: 'Southeast',
    values: ['AL', 'DC', 'FL', 'GA', 'KY', 'MD', 'NC', 'SC', 'TN', 'VA', 'WV'],
  },
  {
    region: 'Midwest',
    values: [
      'IA',
      'IL',
      'IN',
      'KS',
      'MI',
      'MN',
      'MO',
      'ND',
      'NE',
      'OH',
      'SD',
      'WI',
    ],
  },
  { region: 'South Central', values: ['AR', 'LA', 'MS', 'OK', 'TX'] },
  { region: 'Northwest', values: ['ID', 'MT', 'OR', 'WA', 'WY'] },
  { region: 'Southwest', values: ['AZ', 'CA', 'CO', 'NM', 'NV', 'UT'] },
  { region: 'Pacific', values: ['AK', 'HI'] },
];

export function formatRegions(venues: PostingSearchCriteriaVenueDTO[]) {
  const result: string[] = [];
  const venueSet = new Set(
    venues.map((venue) =>
      [venue.state?.toLowerCase(), venue.city, venue.zip]
        .filter(Boolean)
        .join(','),
    ),
  );

  for (const { region, values } of USA_REGIONS) {
    const isMatched = values.some((state) => venueSet.has(state.toLowerCase()));

    if (isMatched) {
      result.push(region);
    }
  }

  return result;
}
