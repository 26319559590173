import { APIMutationOptions, useAPIMutation } from 'shared/api/APIMutation';
import { useAPIQuery } from 'shared/api/APIQuery';
import { APIResponse, requestCarrierAPI } from 'shared/api/CarrierAPIClient';
import {
  SuperPayExpeditedPayDTO,
  superPayExpeditedPaySchema,
  SuperPayExpeditedPayStatisticsDTO,
  superPayExpeditedPayStatisticsSchema,
  SuperPayInvitesShippersDTO,
  superPayInvitesShippersSchema,
} from 'shared/modules/superpay/SuperPayDTO';

export function useSuperPayInvitesShippers() {
  return useAPIQuery(
    'superpay-invites',
    () =>
      requestCarrierAPI<APIResponse<SuperPayInvitesShippersDTO>>(
        'GET /internal/web/payments/superpay-invites/',
      ),
    {
      schema: superPayInvitesShippersSchema,
      refetchOnWindowFocus: false,
    },
  );
}

export function useSendSuperPayInvitesShippers() {
  return useAPIMutation((value: string[]) =>
    requestCarrierAPI('POST /internal/web/payments/superpay-invites/', {
      json: {
        shipper_guids: value,
      },
    }),
  );
}

export function useConfirmExpeditedPay(
  guid = '',
  options?: APIMutationOptions,
) {
  return useAPIMutation(
    () =>
      requestCarrierAPI([
        'POST /internal/web/payments/{guid}/expedited-pay/',
        { guid },
      ]),
    options,
  );
}

export function useExpeditedPay(guid = '') {
  return useAPIQuery(
    ['superpay-expedited-pay', guid],
    () =>
      requestCarrierAPI<APIResponse<SuperPayExpeditedPayDTO>>([
        'GET /internal/web/payments/{guid}/expedited-pay/',
        { guid },
      ]),
    {
      schema: superPayExpeditedPaySchema,
      refetchOnWindowFocus: false,
      enabled: !!guid,
      keepPreviousData: true,
    },
  );
}

export function useExpeditedPayStatistics(enabled = false) {
  return useAPIQuery(
    'superpay-expedited-pay-statistic',
    () =>
      requestCarrierAPI<APIResponse<SuperPayExpeditedPayStatisticsDTO>>(
        'GET /internal/web/payments/expedited-pay-nudging/',
      ),
    {
      schema: superPayExpeditedPayStatisticsSchema,
      refetchOnWindowFocus: false,
      enabled,
    },
  );
}

export function useExpeditedPayReminder(options?: APIMutationOptions) {
  return useAPIMutation(
    () =>
      requestCarrierAPI('PATCH /internal/web/payments/expedited-pay-nudging/', {
        json: { is_superpay_expedited_pay_nudging_enabled: false },
      }),
    options,
  );
}
