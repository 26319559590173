import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import { PageHeader, PageLayout } from 'shared/layout/PageLayout';
import { PhoneVerification } from 'shared/modules/phone-verification/PhoneVerification';
import { BackButton } from 'shared/ui/BackButton';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export function ProfilePhoneVerificationPage() {
  const navigate = useNavigate();

  const onComplete = useCallback(() => {
    navigate('/profile/carrier_info');
  }, [navigate]);

  return (
    <PageLayout
      background="White"
      header={
        <PageHeader
          title="Phone Verification"
          startAction={
            <BackButton
              onClick={() => {
                navigate('/profile/carrier_info');
              }}
            />
          }
        />
      }
    >
      <Container>
        <PhoneVerification onBack={onComplete} onComplete={onComplete} />
      </Container>
    </PageLayout>
  );
}
