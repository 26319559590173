import {
  APIMutationOptions,
  APIMutationResult,
  useAPIMutation,
} from 'shared/api/APIMutation';
import { APIResponse, requestCarrierAPI } from 'shared/api/CarrierAPIClient';
import { DriverAccountDeletionDTO } from './DriverAccountDeletionDTO';

export function useValidateDriverAccountDeletionToken(
  options?: APIMutationOptions<{ token: string }, DriverAccountDeletionDTO>,
): APIMutationResult<{ token: string }, DriverAccountDeletionDTO> {
  return useAPIMutation((json) => {
    return requestCarrierAPI(
      'POST /internal/web/driver-account-deletion/validate/',
      { json },
    );
  }, options);
}

export function deleteDriverAccount({
  token,
  ...json
}: {
  reason: string;
  email: string;
  token: string;
}) {
  return requestCarrierAPI<APIResponse<DriverAccountDeletionDTO>>(
    'POST /internal/web/driver-account-deletion/delete/',
    { json, headers: { authorization: `Token ${token}` } },
  );
}
