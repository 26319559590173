import { Divider, Menu, MenuItem } from '@material-ui/core';
import { Map, MoreHoriz } from '@material-ui/icons';
import { Inline, Stack, useResponsiveValue } from '@superdispatch/ui';
import { AnchorButton, Button } from '@superdispatch/ui-lab';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import { trackTripsEvent } from '../data/TripsAnalytics';
import { TripDTO } from '../data/TripsDTO';
import { ArchiveTripDialog } from '../trips-list-page/ArchiveTripDialog';
import { DeleteTripDialog } from '../trips-list-page/DeleteTripDialog';
import { UnarchiveTripDialog } from '../trips-list-page/UnarchiveTripDialog';

interface TripsDetailsActionsProps {
  trip?: TripDTO;
  onAddLoad: () => void;
}

export function TripsDetailsActions({
  trip,
  onAddLoad,
}: TripsDetailsActionsProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMobile = useResponsiveValue(true, false);
  const [dialog, setDialog] = useState<'delete' | 'archive' | 'unarchive'>();
  const { guid = '' } = useParams<{ guid: string }>();
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleCloseDialog = () => {
    setDialog(undefined);
  };

  return (
    <>
      {dialog === 'delete' && (
        <DeleteTripDialog onClose={handleCloseDialog} tripGUID={guid} />
      )}

      {dialog === 'archive' && (
        <ArchiveTripDialog onClose={handleCloseDialog} tripGUID={guid} />
      )}

      {dialog === 'unarchive' && (
        <UnarchiveTripDialog
          onClose={handleCloseDialog}
          tripGUID={guid}
          onSubmitSuccess={() => {
            handleCloseDialog();
          }}
        />
      )}

      <Inline horizontalAlign="right" space="small">
        {!isMobile && (
          <>
            <Button onClick={onAddLoad}>
              {trip?.loads?.length ? 'Edit Route' : 'Add Route'}
            </Button>
            <AnchorButton
              onClick={() => {
                trackTripsEvent({
                  name: 'Carrier Opened Map',
                  trip_guid: trip?.guid,
                });
              }}
              startIcon={<Map />}
              variant="neutral"
              href={trip?.map_route_url || ''}
              target="_blank"
            >
              Open Map
            </AnchorButton>
          </>
        )}
        <Button
          variant="neutral"
          id="options-button"
          aria-controls={anchorEl ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={anchorEl ? 'true' : undefined}
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
          }}
        >
          <MoreHoriz />
        </Button>
        <Menu
          id="options-menu"
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={handleCloseMenu}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <Stack space="xxsmall">
            <Divider />
            <MenuItem
              onClick={() => {
                trackTripsEvent({
                  name: 'Carrier Opened Map',
                  trip_guid: trip?.guid,
                });
                openExternalURL(trip?.map_route_url || '', {
                  target: '_blank',
                });
                handleCloseMenu();
              }}
            >
              Open Map
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={() => {
                setDialog(trip?.archived ? 'unarchive' : 'archive');
                handleCloseMenu();
              }}
            >
              {trip?.archived ? 'Unarchive' : 'Archive'}
            </MenuItem>
            <MenuItem
              onClick={() => {
                setDialog('delete');
                handleCloseMenu();
              }}
            >
              Delete
            </MenuItem>
          </Stack>
        </Menu>
      </Inline>
    </>
  );
}
