import {
  createHTTP,
  HTTPEndpointInput,
  HTTPRequestJSONOptions,
} from '@superdispatch/http';
import { URITemplateParams } from '@superdispatch/uri';
import {
  APIResponse,
  handleError,
  parseAPIResponse,
} from 'shared/api/CarrierAPIClient';
import { CTMS_HOST } from 'shared/constants/ServerConstants';

const publicHttpClient = createHTTP({ baseURL: CTMS_HOST });

export function requestJSONPublic<
  TData,
  TParams extends URITemplateParams = URITemplateParams,
>(
  input: HTTPEndpointInput<TParams>,
  options?: HTTPRequestJSONOptions<TData>,
): Promise<TData> {
  return publicHttpClient.requestJSON(input, options).catch(handleError(input));
}

export function requestCarrierAPIPublic<
  TData extends APIResponse,
  TParams extends URITemplateParams = URITemplateParams,
>(
  input: HTTPEndpointInput<TParams>,
  options?: Omit<HTTPRequestJSONOptions<TData>, 'baseURL'>,
): Promise<TData> {
  return requestJSONPublic(input, options).then((response) =>
    parseAPIResponse(input, response),
  );
}
