import { Card, CardContent } from '@material-ui/core';
import { ReactNode } from 'react';
import styled from 'styled-components';

const OffersPageLayoutRoot = styled.div`
  margin: 0 auto;

  max-width: 600px;
  min-height: 100vh;

  display: flex;
  flex-grow: 1;
  flex-direction: column;
  word-break: break-word;
`;

const OffersPageLayoutCard = styled(Card)`
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
`;

const OffersPageLayoutCardContent = styled(CardContent)`
  display: flex;
  flex-direction: column;

  flex-grow: 1;
  padding: 40px 32px;
`;

const OffersPageFooterCard = styled(Card)`
  display: flex;
  border-radius: 0;

  left: 0;
  right: 0;
  bottom: 1px;
  position: sticky;
`;

export interface OffersPageLayoutProps {
  header?: ReactNode;
  footer?: ReactNode;
  children?: ReactNode;
}

export function OffersPageLayout({
  header,
  footer,
  children,
}: OffersPageLayoutProps) {
  return (
    <OffersPageLayoutRoot>
      {header}
      <OffersPageLayoutCard>
        <OffersPageLayoutCardContent>{children}</OffersPageLayoutCardContent>
      </OffersPageLayoutCard>
      {!!footer && <OffersPageFooterCard>{footer}</OffersPageFooterCard>}
    </OffersPageLayoutRoot>
  );
}
