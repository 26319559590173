import React, { useEffect, useMemo } from 'react';
import { Route, Routes, useParams } from 'react-router-dom-v5-compat';
import { identifyAnalytics } from 'shared/helpers/Analytics';
import { hideIntercomLauncher } from 'shared/helpers/Intercom';
import { useNullableContext } from 'shared/hooks/useNullableContext';
import { SplashScreen } from 'shared/layout/SplashScreen';
import { useValidateDriverAccountDeletionToken } from './data/DriverAccountDeletionAPI';
import { DriverAccountDeletionDTO } from './data/DriverAccountDeletionDTO';
import { DriverAccountDeletionErrorPage } from './DriverAccountDeletionErrorPage';
import { CompletionStepPage } from './steps/CompletionStepPage';
import { ConfirmationStepPage } from './steps/ConfirmationStepPage';
import { MainStepPage } from './steps/MainStepPage';
import { QuestionnaireStepPage } from './steps/QuestionnaireStepPage';

interface ContextProps {
  token: string;
  authToken: string;
  driverAccount: DriverAccountDeletionDTO['driver'] | undefined;
}

const Context = React.createContext<ContextProps | null>(null);

export function useDriverAccountDeletionContext() {
  return useNullableContext('DriverAccountDeletion', Context);
}

export function DriverAccountDeletionPage() {
  const { token = '' } = useParams();

  const {
    data,
    mutate: validateToken,
    isLoading,
  } = useValidateDriverAccountDeletionToken({
    onSuccess: ({ data: driverAccountInfo }) => {
      identifyAnalytics({
        guid: driverAccountInfo.driver.driver_guid,
      });
    },
  });

  const driverAccount = data?.data.driver;

  useEffect(() => {
    hideIntercomLauncher();
    validateToken({ token });
  }, [validateToken, token]);

  const contextValue = useMemo(
    () => ({
      token,
      authToken: data?.data.token || '',
      driverAccount,
    }),
    [data?.data.token, driverAccount, token],
  );

  if (isLoading) {
    return <SplashScreen />;
  }

  if (!driverAccount) {
    return <DriverAccountDeletionErrorPage />;
  }

  return (
    <Context.Provider value={contextValue}>
      <Routes>
        <Route path="questionnaire" element={<QuestionnaireStepPage />} />
        <Route path="confirmation" element={<ConfirmationStepPage />} />
        <Route path="completion" element={<CompletionStepPage />} />
        <Route index={true} element={<MainStepPage />} />
      </Routes>
    </Context.Provider>
  );
}
