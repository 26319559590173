import { DialogContent as MuiDialogContent } from '@material-ui/core';
import { Column, Columns } from '@superdispatch/ui';
import { useMemo } from 'react';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';
import styled from 'styled-components';
import { useOnboardingDetails } from '../data/OnboardingAPI';
import { OnboardingTasks } from './OnboardingTasks';
import { OnboardingVerificationTasks } from './OnboardingVerificationTasks';

const DialogContent = styled(MuiDialogContent)`
  padding: 60px 88px;
`;

interface OnboardingContentProps {
  onClose: () => void;
}

export function OnboardingContent({ onClose }: OnboardingContentProps) {
  const { data } = useOnboardingDetails();
  const { data: settings } = useCarrierSettings();

  const isCompleted = useMemo(() => {
    if (data) {
      const { steps } = data;
      const isSubmitted =
        settings?.carrier.verification_status !== 'not_submitted';

      return (
        isSubmitted &&
        steps?.tour_create_or_import_load?.status === 'started' &&
        steps.get_to_know_ctms?.status === 'started' &&
        steps.tour_slb_search?.status === 'started'
      );
    }

    return false;
  }, [data, settings?.carrier.verification_status]);

  return (
    <DialogContent>
      <Columns align={isCompleted ? 'center' : 'top'} space="xxlarge">
        <Column width="1/2">
          <OnboardingVerificationTasks
            onClose={onClose}
            onboardingSteps={data}
          />
        </Column>
        <Column width="1/2">
          <OnboardingTasks
            onboardingSteps={data}
            completed={isCompleted}
            onClose={onClose}
          />
        </Column>
      </Columns>
    </DialogContent>
  );
}
