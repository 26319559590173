import { Color, ThemeProvider, ThemeProviderProps } from '@superdispatch/ui';
import { createGlobalStyle } from 'styled-components';

if (navigator.userAgent.includes('Win')) {
  document.documentElement.classList.add('windows');
}

const GlobalStyles = createGlobalStyle`
/* Autocomplete disabled options tooltip config setup https://devjonas.medium.com/tooltip-on-disabled-options-in-material-ui-autocomplete-4727a0c65b9b  */
  .MuiAutocomplete-listbox li[aria-disabled='true'] {
    pointer-events: inherit;
  }
  .MuiAutocomplete-listbox li[aria-disabled='true']:hover,
  .MuiAutocomplete-listbox li[aria-disabled='true']:focus {
    background: ${Color.White};
  }
  .MuiAutocomplete-listbox li[aria-disabled='true']:active {
    background: ${Color.White};
    pointer-events: none;
  }
`;

export function AppThemeProvider({ children, modifier }: ThemeProviderProps) {
  return (
    <ThemeProvider modifier={modifier}>
      <GlobalStyles />
      {children}
    </ThemeProvider>
  );
}
