import {
  createHTTP,
  HTTPEndpointInput,
  HTTPError,
  HTTPRequestJSONOptions,
  HTTPRequestOptions,
} from '@superdispatch/http';
import { URITemplateParams } from '@superdispatch/uri';
import { createAPIError, isAPIError } from 'shared/api/APIError';
import { getAuthToken } from 'shared/auth/AuthToken';
import { CTMS_HOST } from 'shared/constants/ServerConstants';
import {
  getMobileAppToken,
  MobileAppBridge,
} from 'shared/data/MobileAppBridge';
import { emitServerError } from 'shared/errors/ServerErrorEvents';
import { logWarning } from 'shared/helpers/ErrorTracker';

export interface APIResponseMeta {
  code: number;
  request_id: string;
}

export interface APIBaseResponse {
  meta: APIResponseMeta;
  user_message: string;
}

export interface APIResponse<TData = unknown> extends APIBaseResponse {
  data: TData;
}

export interface APIPageResponse<TData = unknown> extends APIBaseResponse {
  data: TData[];
  pagination: { next?: string; previous?: string; count: number };
}

export interface APICursorPaginationResponse<TData> extends APIBaseResponse {
  data: TData[];
  pagination: { next: string; previous: string };
}

export interface APIErrorResponse extends APIBaseResponse {
  error: {
    type?: string;
    context?: unknown;
    dev_message?: string;
    user_message?: string;
  };
}

export function isAPIErrorResponse(
  response: unknown,
): response is APIErrorResponse {
  return (
    typeof response === 'object' &&
    response != null &&
    'meta' in response &&
    'error' in response
  );
}

function sanitizeSecret(secret: string | undefined) {
  if (secret == null) {
    return undefined;
  }

  return secret.substring(0, 4) + '****' + secret.substring(secret.length - 4);
}

export function parseAPIResponse<T extends APIBaseResponse>(
  requestParams: unknown,
  response: T,
): Promise<T> {
  const status = response.meta.code;
  if (status >= 200 && status < 400) return Promise.resolve(response);

  const error = isAPIErrorResponse(response) ? response.error : {};
  const httpError = createAPIError({
    status,
    requestParams,
    type: error.type,
    context: error.context,
    message: error.user_message || error.type || response.user_message,
  });

  logWarning(`APIError: ${httpError.message}`, {
    httpError,
    response,
    hasToken: !!getMobileAppToken(),
    hasBridge: MobileAppBridge.isInjected(),
    driverGuid: MobileAppBridge.getDriverGuid(),
    deviceGuid: MobileAppBridge.getDeviceGuid(),
    authToken: status === 401 ? sanitizeSecret(getAuthToken()) : undefined,
    mobileToken:
      status === 401 ? sanitizeSecret(getMobileAppToken()) : undefined,
  });

  emitServerError(error);

  return Promise.reject(httpError);
}

const httpClient = createHTTP({
  baseURL: CTMS_HOST,
  headers(headers) {
    if (!headers?.authorization) {
      const token = getAuthToken();
      if (token) headers = { ...headers, authorization: `Token ${token}` };
    }

    if (MobileAppBridge.getCarrierGuid()) {
      headers = {
        ...headers,
        'X-Carrier-Guid': MobileAppBridge.getCarrierGuid() ?? '',
      };
    }

    return headers;
  },
});

export function handleError(
  input: unknown,
): (error: unknown) => Promise<never> {
  return async (error) => {
    if (!isAPIError(error)) {
      if (error instanceof HTTPError) {
        let errorJson;
        try {
          errorJson = (await error.response.json()) as HTTPError;
        } catch (e: unknown) {}

        if (isAPIErrorResponse(errorJson)) {
          error = createAPIError({
            ...error,
            requestParams: input,
            status: errorJson.meta.code,
            context: errorJson.error.context,
            type: errorJson.error.type,
            message: errorJson.error.user_message || errorJson.error.type,
          });
        } else {
          error = createAPIError({
            ...error,
            requestParams: input,
            status: error.response.status,
            message: error.message || error.response.statusText,
          });
        }
      } else if (error instanceof Error) {
        error = createAPIError(error);
      } else {
        error = createAPIError({ message: 'Unknown Error', context: error });
      }
    }

    return Promise.reject(error);
  };
}

export function request<TParams extends URITemplateParams = URITemplateParams>(
  input: HTTPEndpointInput<TParams>,
  options?: HTTPRequestOptions,
) {
  return httpClient.request(input, options).catch(handleError(input));
}

export function requestJSON<
  TData,
  TParams extends URITemplateParams = URITemplateParams,
>(
  input: HTTPEndpointInput<TParams>,
  options?: HTTPRequestJSONOptions<TData>,
): Promise<TData> {
  return httpClient.requestJSON(input, options).catch(handleError(input));
}

export function requestCarrierAPI<
  TData extends APIResponse,
  TParams extends URITemplateParams = URITemplateParams,
>(
  input: HTTPEndpointInput<TParams>,
  options?: Omit<HTTPRequestJSONOptions<TData>, 'baseURL'>,
): Promise<TData> {
  return requestJSON(input, options).then((response) =>
    parseAPIResponse(input, response),
  );
}
