import { useMemo } from 'react';
import { requestCarrierAPI } from 'shared/api/CarrierAPIClient';
import { yupObject, yupString } from 'shared/utils/YupUtils';
import { InferType } from 'yup';

export type CentralDispatchAccountCredentialsDTO = InferType<
  typeof centralDispatchAccountCredentialsSchema
>;
export const centralDispatchAccountCredentialsSchema = yupObject({
  username: yupString().required('Enter username'),
  password: yupString().required('Enter password'),
});

export function useCentralDispatchIntegrationAPI() {
  return useMemo(
    () => ({
      verifyCentralAccount: (values: CentralDispatchAccountCredentialsDTO) =>
        requestCarrierAPI('POST /internal/web/credentials/', { json: values }),
    }),
    [],
  );
}
