import { useFlag } from 'shared/settings/FeatureToggles';
import { useGenerateMoovTokenAndGetAccount } from './SuperPayAPI';
import { useMoovAccountInfoLegacy } from './useMoovAccountInfoLegacy';

export function useSuperPayBankAccountID() {
  const isMoovRefactoring = useFlag('payments_moovjs_refactoring');
  const moovAccountInfoLegacy = useMoovAccountInfoLegacy();
  const { data: moovInformation } = useGenerateMoovTokenAndGetAccount(
    isMoovRefactoring,
    'bank_account',
  );
  return isMoovRefactoring
    ? moovInformation?.moov_bank_account_id
    : moovAccountInfoLegacy.getBankAccount()?.bankAccountID;
}
