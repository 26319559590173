import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import { HeaderWithLogo } from 'shared/layout/HeaderWithLogo';
import { SplashScreen } from 'shared/layout/SplashScreen';
import { DriverActivationCompletedPage } from '../core/DriverActivationCompletedPage';
import { useDriverActivationDetails } from '../data/DriversAPI';
import { DriverActivationAccountDeletedPage } from './DriverActivationAccountDeletedPage';
import { DriverActivationSetPasswordPage } from './DriverActivationSetPasswordPage';

function useLastDefinedValue<T>(value: T) {
  const [state, setState] = useState<T | undefined>();

  useEffect(() => {
    if (value) {
      setState(value);
    }
  }, [value, setState]);

  return state;
}

export function DriverActivationPage() {
  const { activationCode = '' } = useParams();
  const { data, isLoading } = useDriverActivationDetails(activationCode);
  const persistedCarrierName = useLastDefinedValue(data?.carrier_name);

  if (isLoading) {
    return <SplashScreen />;
  }

  return (
    <>
      <HeaderWithLogo />
      {data?.status === 'inactive' ? (
        <DriverActivationSetPasswordPage
          carrierName={data.carrier_name}
          email={data.email}
          activationCode={activationCode}
        />
      ) : data?.status === 'activated' ? (
        <DriverActivationCompletedPage carrierName={persistedCarrierName} />
      ) : (
        <DriverActivationAccountDeletedPage />
      )}
    </>
  );
}
