import { Menu, MenuItem } from '@material-ui/core';
import { Cancel, MoreHoriz, PowerSettingsNew } from '@material-ui/icons';
import { Color, Inline } from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import { useState } from 'react';
import { AccountOffIcon as MuiAccountOffIcon } from 'shared/icons';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';
import { useFlag } from 'shared/settings/FeatureToggles';
import { MenuButton } from 'shared/ui/MenuButton';
import styled from 'styled-components';
import { DriverDTO } from '../data/DriverDTO';
import { DriversPageMutationDialogType } from '../DriversPageMutationDialog';
import { DriverDeactivationPlanUpdateDialog } from './DriverDeactivationPlanUpdateDialog';

interface DriverDetailsMenuButtonProps {
  currentDriver: DriverDTO;
  setCurrentModal: (modal: DriversPageMutationDialogType) => void;
}

const AccountOffIcon = styled(MuiAccountOffIcon)`
  width: 20px;
  height: 20px;
`;

export function DriverDetailsMenuButton({
  currentDriver,
  setCurrentModal,
}: DriverDetailsMenuButtonProps) {
  const shouldUseSeatBasedPricing = useFlag('seats_based_pricing');
  const isMultiCarrierConnectionEnabled = useFlag(
    'multi_carrier_connection_management',
  );
  const [shouldShowPlanUpdateDrawer, setShouldShowPlanUpdateDrawer] =
    useState(false);
  const { data } = useCarrierSettings();
  const isPaying = data?.is_paying;

  return (
    <>
      <DriverDeactivationPlanUpdateDialog
        open={shouldShowPlanUpdateDrawer}
        onClose={() => {
          setShouldShowPlanUpdateDrawer(false);
        }}
        onSubmitSuccess={() => {
          setShouldShowPlanUpdateDrawer(false);
          setCurrentModal('deactivate');
        }}
      />
      <MenuButton
        aria-label="driver options"
        renderMenu={({ onClose, ...menuProps }) => (
          <Menu {...menuProps} onClose={onClose}>
            {currentDriver.is_suspended ? (
              [
                !isMultiCarrierConnectionEnabled && (
                  <MenuItem
                    key="reactivate"
                    onClick={() => {
                      onClose();
                      setCurrentModal('reactivate');
                    }}
                  >
                    <Inline
                      noWrap={true}
                      space="xxsmall"
                      verticalAlign="center"
                    >
                      <PowerSettingsNew fontSize="small" />
                      Reactivate
                    </Inline>
                  </MenuItem>
                ),
                <MenuItem
                  key="delete"
                  onClick={() => {
                    onClose();
                    setCurrentModal('delete');
                  }}
                >
                  <TextBox color="red">
                    <Inline
                      noWrap={true}
                      space="xxsmall"
                      verticalAlign="center"
                    >
                      <Cancel fontSize="small" />
                      Delete
                    </Inline>
                  </TextBox>
                </MenuItem>,
              ]
            ) : (
              <MenuItem
                onClick={() => {
                  onClose();
                  if (shouldUseSeatBasedPricing && isPaying) {
                    setShouldShowPlanUpdateDrawer(true);
                  } else {
                    setCurrentModal('deactivate');
                  }
                }}
              >
                <Inline noWrap={true} space="xxsmall" verticalAlign="center">
                  <AccountOffIcon htmlColor={Color.Red500} />
                  Deactivate
                </Inline>
              </MenuItem>
            )}
          </Menu>
        )}
      >
        <MoreHoriz />
      </MenuButton>
    </>
  );
}
