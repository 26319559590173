import { useFlag } from 'shared/settings/FeatureToggles';
import { useCreateCarrierMoovAccount } from './MoovAPI';
import { useMoovSyncAPI } from './MoovAPILegacy';

export function useSuperPayCreateAccountAction() {
  const isMoovRefactoring = useFlag('payments_moovjs_refactoring');

  const { createCarrierSyncMoovAccount } = useMoovSyncAPI();
  const { mutateAsync: createCarrierMoovAccount } =
    useCreateCarrierMoovAccount();

  if (isMoovRefactoring) {
    return createCarrierMoovAccount;
  }

  return createCarrierSyncMoovAccount;
}
