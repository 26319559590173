import { VehicleType } from '@superdispatch/sdk';
import { useMutation } from 'react-query';
import { APIMutationOptions, APIMutationResult } from 'shared/api/APIMutation';
import { requestCarrierAPI } from 'shared/api/CarrierAPIClient';
import { trim } from 'shared/helpers/CommonHelpers';

export function normalizeVIN(value: unknown): string {
  return trim(value).replace(/\s/g, '').toUpperCase();
}

export function isPossibleVIN(value: string): boolean {
  return trim(value).length === 17;
}

export interface DuplicatedLoad {
  id: number;
  vin?: string;
  guid?: string;
  number: string;
}

export interface DecodedVehicle {
  vin: string;
  year?: null | number | string;
  make?: null | string;
  model?: null | string;
  type?: null | VehicleType;
  color?: null | string;
  curb_weight?: null | number | string;
  height?: null | number | string;
  length?: null | number | string;
  width?: null | number | string;
}

export type DuplicateLoadsRecord = {
  [K in string]?: DuplicatedLoad[];
};

export interface DecodeVINsResponse {
  vehicles: DecodedVehicle[];
  duplicate_loads: DuplicateLoadsRecord;
}

export function useDecodeVINs(
  options?: APIMutationOptions<string[], DecodeVINsResponse>,
): APIMutationResult<string[], DecodeVINsResponse> {
  return useMutation(
    (vins) =>
      requestCarrierAPI('POST /internal/web/vins/decode/', { json: { vins } }),
    options,
  );
}
