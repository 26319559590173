import { useResponsiveValue } from '@superdispatch/ui';
import { Button, TextBox } from '@superdispatch/ui-lab';
import { PendingVerificationIllustration } from 'shared/icons/PendingVerificationIllustration';
import {
  DialogWithIllustration,
  DialogWithIllustrationProps,
} from 'shared/ui/DialogWithIllustration';
import styled from 'styled-components';

const InlineActions = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;

interface ResubmitVerificationDialogProps
  extends Omit<
    DialogWithIllustrationProps,
    'actions' | 'illustrationSrc' | 'title'
  > {
  onResubmit: () => void;
}

export function ResubmitVerificationDialog({
  onClose,
  onResubmit,
  ...props
}: ResubmitVerificationDialogProps) {
  const isMobile = useResponsiveValue(true, false);
  return (
    <DialogWithIllustration
      {...props}
      onClose={onClose}
      illustration={<PendingVerificationIllustration />}
      title="Resubmit for Verification?"
      alignActions="right"
      actions={
        <InlineActions>
          <Button variant="neutral" onClick={onClose} fullWidth={isMobile}>
            Cancel
          </Button>

          <Button onClick={onResubmit} fullWidth={isMobile}>
            Save and Resubmit
          </Button>
        </InlineActions>
      }
    >
      <TextBox variant="body-block">
        Looks like you are updating information which was already verified by
        Super. We will need to verify documents again.
      </TextBox>
    </DialogWithIllustration>
  );
}
