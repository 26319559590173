import { APIQueryResult, useAPIQuery } from 'shared/api/APIQuery';
import { requestCarrierAPI } from 'shared/api/CarrierAPIClient';

export type LoadCountType =
  | 'aging'
  | 'delayed-pickup'
  | 'delayed-delivery'
  | 'to-pickup-today'
  | 'to-deliver-today';

export interface DashboardCountDTO {
  count: number;
}

export function useDashboardLoadsCount(
  type: LoadCountType,
): APIQueryResult<DashboardCountDTO> {
  return useAPIQuery(['dashboard', 'loads', { type }], () =>
    requestCarrierAPI([
      'GET /internal/web/dashboard/loads/{type}/count/',
      { type },
    ]),
  );
}
