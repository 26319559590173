import type { Account, BankAccount } from '@moovio/moov-js';
import { TEN_MINUTES } from 'shared/constants/NumberConstants';
import { LocalStore } from 'shared/helpers/Store';
import { tryParseJSON, tryStringifyJSON } from 'shared/utils/DataUtils';
import { logPaymentError } from '../helpers/PaymentLogger';
import { MoovScope, MoovTokenResponse } from './MoovDTO';

const MOOV_ACCOUNT_KEY = 'MOOV_ACCOUNT_KEY';
const MOOV_BANK_ACCOUNT_KEY = 'MOOV_BANK_ACCOUNT_KEY';

export type TokenDataFetcher = (scope: MoovScope) => Promise<MoovTokenResponse>;
type Subscriber = () => void;

interface ScopeData {
  token: string;
  accountID: string | undefined | null;
  bankAccountID: string | undefined | null;
}

// An utility class for storing Moov account data and controlling token scope
export class MoovAccountInfoLegacy {
  private static instance: MoovAccountInfoLegacy | null = null;
  private currentScope: MoovScope;
  private currentToken: string;
  private account: Partial<Account> | null = null;
  private bankAccount: Partial<BankAccount> | null = null;
  private tokenUpdatedAt: number | null = null;
  private subscribers = new Set<Subscriber>();
  private getMoovToken: TokenDataFetcher;

  static getInstance(): MoovAccountInfoLegacy {
    if (!MoovAccountInfoLegacy.instance) {
      throw new Error('MoovAccountInfo is not initialized');
    }

    return MoovAccountInfoLegacy.instance;
  }

  static initialize(
    initialScope: MoovScope,
    getMoovToken: TokenDataFetcher,
  ): MoovAccountInfoLegacy {
    if (!MoovAccountInfoLegacy.instance) {
      MoovAccountInfoLegacy.instance = new MoovAccountInfoLegacy();
      MoovAccountInfoLegacy.instance.getMoovToken = getMoovToken;
      void MoovAccountInfoLegacy.instance.setScope(initialScope);
    }

    return MoovAccountInfoLegacy.instance;
  }

  private setTokenData(scope: MoovScope, data: MoovTokenResponse) {
    this.currentToken = data.access_token;
    this.currentScope = scope;
    this.tokenUpdatedAt = Date.now();

    if (data.moov_account_id) {
      this.setAccount({ accountID: data.moov_account_id });
      LocalStore.delete(MOOV_ACCOUNT_KEY); // remove localStorage data when it is available in backend
    }

    if (data.moov_bank_account_id) {
      this.setBankAccount({ bankAccountID: data.moov_bank_account_id });
      LocalStore.delete(MOOV_BANK_ACCOUNT_KEY); // remove localStorage data when it is available in backend
    }
  }

  getCurrentToken() {
    return this.currentToken;
  }

  /**
   * Each scope consists of particular permissions.
   * That's why when the scope is updated, a new token
   * with the required permisisons is requested.
   */
  async setScope(scope: MoovScope): Promise<ScopeData> {
    const isTokenExpired =
      Date.now() - (this.tokenUpdatedAt ?? 0) > TEN_MINUTES;

    if (this.currentScope === scope && !isTokenExpired) {
      return {
        token: this.currentToken,
        accountID: this.account?.accountID,
        bankAccountID: this.bankAccount?.bankAccountID,
      };
    }

    const data = await this.getMoovToken(scope);
    this.setTokenData(scope, data);

    return {
      token: this.currentToken,
      accountID: data.moov_account_id,
      bankAccountID: data.moov_bank_account_id,
    };
  }

  setAccount(newData: Partial<Account>) {
    this.account = newData;
    LocalStore.set(MOOV_ACCOUNT_KEY, tryStringifyJSON(newData) as string);
    this.emitAccountDataChange();
    return this.account;
  }

  setBankAccount(newData: Partial<BankAccount>) {
    this.bankAccount = newData;
    LocalStore.set(MOOV_BANK_ACCOUNT_KEY, tryStringifyJSON(newData) as string);
    this.emitAccountDataChange();
    return this.bankAccount;
  }

  getAccount() {
    return (
      this.account || tryParseJSON(LocalStore.get(MOOV_ACCOUNT_KEY) as string) // `null` value is parsed as `null`
    );
  }

  getBankAccount() {
    return (
      this.bankAccount ||
      tryParseJSON(LocalStore.get(MOOV_BANK_ACCOUNT_KEY) as string) // `null` value is parsed as `null`
    );
  }

  static flushStorageData() {
    LocalStore.delete(MOOV_ACCOUNT_KEY);
    LocalStore.delete(MOOV_BANK_ACCOUNT_KEY);
  }

  /**
   * Subscriber function is not provided with updated account data.
   * Account data should be retrieved by `getAccount` and `getBankAccount`. */
  subscribeToAccountDataChange(subscriber: Subscriber) {
    this.subscribers.add(subscriber);

    try {
      subscriber();
    } catch (error: unknown) {
      logPaymentError(
        error as Error,
        'MoovAccountInfo.subscribeToAccountDataChange',
        {
          moovAccountId: this.getAccount()?.accountID,
        },
      );
    }

    return () => {
      this.subscribers.delete(subscriber);
    };
  }

  private emitAccountDataChange() {
    for (const subscriber of this.subscribers) {
      try {
        subscriber();
      } catch (error: unknown) {
        logPaymentError(
          error as Error,
          'MoovAccountInfo.emitAccountDataChange',
        );
      }
    }
  }
}
