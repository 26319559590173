import { Divider, IconButton } from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { FormikContextTypeEnhanced } from '@superdispatch/forms';
import { Color } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { Form, FormikProvider, FormikValues } from 'formik';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import { isWebView } from 'shared/constants/AppTypeConstants';
import { MobileAppBridge } from 'shared/data/MobileAppBridge';
import { PageHeader, PageLayout } from 'shared/layout/PageLayout';
import { WebviewHeader } from 'shared/layout/WebviewHeader';
import { WebviewLayout } from 'shared/layout/WebviewLayout';
import { useNavigationBlock } from 'shared/routing/NavigationBlock';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 50px);
`;

const Content = styled.div`
  overflow: auto;
  padding: 16px;
`;

const StickyActions = styled.div`
  margin-top: auto;
`;

export interface ProfilePageLayoutProps {
  title: string;
  formik?: FormikContextTypeEnhanced<FormikValues, unknown>;

  isLoading?: boolean;
  size?: 'small' | 'medium' | 'large';

  headerEndActions?: ReactNode;
  showHeaderOnWebview?: boolean;
  children: ReactNode;
}

export function ProfilePageLayout({
  title,
  formik,
  children,
  isLoading,
  headerEndActions,
  showHeaderOnWebview = true,
  size = 'small',
}: ProfilePageLayoutProps) {
  const isSubmitted = formik?.status.type === 'submitted';

  useNavigationBlock(
    !!formik &&
      !isSubmitted &&
      (formik.dirty || formik.isSubmitting) &&
      'Changes have not been saved. Leaving will result in unsaved changes being lost.',
  );

  return (
    <ProfilePageUniversalLayoutWrapper
      title={title}
      formik={formik}
      isLoading={isLoading}
      size={size}
      headerEndActions={headerEndActions}
      showHeaderOnWebview={showHeaderOnWebview}
    >
      <ProfilePageLayoutFormWrapper
        formik={formik}
        title={title}
        isLoading={isLoading}
      >
        <Box
          marginLeft="auto"
          marginRight="auto"
          maxWidth={
            size === 'large' ? '1110px' : size === 'medium' ? '992px' : '496px'
          }
          paddingLeft={size !== 'small' ? 'large' : undefined}
          paddingRight={size !== 'small' ? 'large' : undefined}
        >
          {isLoading ? (
            <div aria-label={`Loading ${title}`}>
              <Skeleton width={170} height={24} />
              <Skeleton width={270} height={24} />
            </div>
          ) : (
            children
          )}
        </Box>
      </ProfilePageLayoutFormWrapper>
    </ProfilePageUniversalLayoutWrapper>
  );
}

function ProfilePageLayoutFormWrapper({
  title,
  formik,
  isLoading,
  children,
}: {
  title: string;
  formik?: FormikContextTypeEnhanced<FormikValues, unknown>;
  isLoading?: boolean;
  children: ReactNode;
}) {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (!formik) return <>{children}</>;

  return (
    <FormikProvider value={formik}>
      <Form aria-label={`${title} form`} aria-busy={isLoading}>
        {children}
      </Form>
    </FormikProvider>
  );
}

type ProfilePageLayoutWrapperProps = ProfilePageLayoutProps;

function ProfilePageUniversalLayoutWrapper({
  title,
  formik,
  children,
  isLoading,
  size,
  headerEndActions,
  showHeaderOnWebview,
}: ProfilePageLayoutWrapperProps) {
  const navigate = useNavigate();
  const isSubmitDisabled = !formik?.dirty;

  const isDynamicNavigationEnabled =
    MobileAppBridge.isDynamicNavigationEnabled();

  const defaultHeaderFormAction = formik ? (
    <Button
      type="button"
      onClick={formik.submitForm}
      pending={formik.isSubmitting}
      disabled={isSubmitDisabled}
      fullWidth={isWebView}
    >
      Save
    </Button>
  ) : null;

  if (isWebView) {
    return (
      <WebviewLayout
        header={
          showHeaderOnWebview &&
          !isDynamicNavigationEnabled && (
            <WebviewHeader
              title={title}
              primaryAction={
                <Box paddingLeft="xsmall">
                  <IconButton
                    aria-label="go back"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <ArrowBackIos fontSize="small" color="primary" />
                  </IconButton>
                </Box>
              }
            />
          )
        }
        scrollable={false}
        backgroundColor={Color.White}
      >
        <Container>
          <Content>{children}</Content>
          <StickyActions>
            <Divider />
            <Box padding="small">
              {headerEndActions || headerEndActions === false
                ? headerEndActions
                : defaultHeaderFormAction}
            </Box>
          </StickyActions>
        </Container>
      </WebviewLayout>
    );
  }

  return (
    <PageLayout
      background="White"
      loading={isLoading}
      stickyHeader={true}
      disablePaddings={size !== 'small'}
      header={
        <PageHeader
          title={title}
          endActions={
            headerEndActions || headerEndActions === false
              ? headerEndActions
              : defaultHeaderFormAction
          }
        />
      }
    >
      {children}
    </PageLayout>
  );
}
