import { useAPIQuery } from 'shared/api/APIQuery';
import { APIResponse } from 'shared/api/CarrierAPIClient';
import { requestAPIGateway } from 'shared/api/CarrierGatewayAPIClient';

export interface LoadboardLoadsCount {
  available: number;
  booked: number;
  requested: number;
  suggested: number;
}

export function useLoadboardLoadsCount() {
  return useAPIQuery(
    ['loadboard', 'loads-count'],
    () =>
      requestAPIGateway<APIResponse<LoadboardLoadsCount>>(
        'GET /loadboard/loads/count',
      ),
    { staleTime: Infinity },
  );
}
