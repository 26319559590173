import { trackEvent } from 'shared/helpers/Analytics';

export type DriversAnalyticsEvent =
  | {
      name: 'CTMS: Driver Added';
      properties: {
        guid: string;
        userGuid?: string;
        source: 'New Drivers Page' | 'Assign Offer Page' | 'Assign Loads Page';
      };
    }
  | {
      name: 'CTMS: Driver Invited';
      properties: {
        guid: string;
        userGuid?: string;
        source: 'New Drivers Page' | 'Assign Offer Page' | 'Assign Loads Page';
      };
    }
  | {
      name: 'Carrier Assigned Driver';
    }
  | {
      name: 'CTMS: Opened React Drivers Page';
    }
  | {
      name: 'Carrier Clicked Add Driver';
    }
  | {
      name: 'Carrier Clicked Invite Driver';
    }
  | {
      name: 'Carrier clicked Download the App';
    }
  | {
      name: 'Carrier Declined Join Request';
      driverGuid: string;
    }
  | {
      name: 'Carrier Accepted Join Request';
      driverGuid: string;
    }
  | {
      name: 'Carrier Canceled Driver Invite';
      driverGuid: string;
    }
  | {
      name: 'Carrier Deactivated Driver';
      driverGuid?: string;
      reason?: string;
    }
  | {
      name: 'Carrier Removed Driver';
      driverGuid?: string;
    }
  | { name: 'Carrier Clicked Decline Button' }
  | { name: 'Carrier Clicked Accept Button' }
  | { name: 'Carrier Sent Invite To Driver'; driverGuid: string }
  | { name: 'Carrier Clicked Reinvite Driver' };

export function trackDriversEvent(event: DriversAnalyticsEvent) {
  switch (event.name) {
    case 'CTMS: Driver Added':
      trackEvent(event.name, {
        driver_guid: event.properties.guid,
        user_guid: event.properties.userGuid,
        source: event.properties.source,
      });

      break;
    case 'CTMS: Driver Invited':
      trackEvent(event.name, {
        driver_guid: event.properties.guid,
        user_guid: event.properties.userGuid,
        source: event.properties.source,
      });

      break;
    case 'Carrier Deactivated Driver':
      trackEvent(event.name, {
        driver_guid: event.driverGuid,
        reason: event.reason,
      });
      break;
    case 'Carrier Sent Invite To Driver':
    case 'Carrier Removed Driver':
    case 'Carrier Declined Join Request':
    case 'Carrier Canceled Driver Invite':
    case 'Carrier Accepted Join Request':
      trackEvent(event.name, {
        driver_guid: event.driverGuid,
      });
      break;

    default:
      trackEvent(event.name);
  }
}
