import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from '@material-ui/core';
import { CheckCircle, Close } from '@material-ui/icons';
import { Rating } from '@material-ui/lab';
import { Color, Column, Columns, Inline, Stack } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import nudgeImg from 'shared/assets/extension-nudging-img.png';
import { getFormattedBrowser } from 'shared/helpers/BrowserHelpers';
import { CHROME_EXTENSION_STORE_URL } from 'shared/helpers/ExtensionHelper';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import { ArrowRightCircledIcon } from 'shared/icons';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  background: white;
  justify-content: center;
  gap: 32px;
  flex-wrap: wrap;

  padding-top: 128px;
  padding-bottom: 128px;
  margin: -16px;
`;

const StyledRating = styled(Rating)`
  .MuiRating-iconFilled {
    color: ${Color.Yellow300};
  }
`;

interface OnboardingChromeExtensionDialogProps {
  open: boolean;
  onClose: () => void;
  onComplete: () => void;
}

export function OnboardingSDConnectionDialog({
  open,
  onClose,
  onComplete,
}: OnboardingChromeExtensionDialogProps) {
  return (
    <Dialog fullWidth={true} maxWidth="lg" open={open} onClose={onClose}>
      <DialogTitle>
        <Columns align="top">
          <Column width="fluid">
            <Typography variant="h3">Add Super Dispatch Connection</Typography>
          </Column>

          <Column width="content">
            <IconButton size="small" aria-label="close">
              <Close />
            </IconButton>
          </Column>
        </Columns>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Container>
          <Box padding="small" paddingTop="none" maxWidth="452px">
            <Stack space="large">
              <Stack space="medium">
                <Columns space="small">
                  <Column width="content">
                    <CheckCircle htmlColor={Color.Green300} fontSize="large" />
                  </Column>
                  <Column width="fluid">
                    <Stack>
                      <Typography variant="h3" color="textSecondary">
                        Verification in progress
                      </Typography>
                      <Typography color="textSecondary">
                        Your verification application will be processed within
                        24-48 hours. We&apos;ll keep you updated on the
                        progress, and once approved, you can start requesting
                        loads.
                      </Typography>
                    </Stack>
                  </Column>
                </Columns>

                <Columns space="small">
                  <Column width="content">
                    <ArrowRightCircledIcon color="action" fontSize="large" />
                  </Column>
                  <Column width="fluid">
                    <Stack>
                      <Typography variant="h3">
                        Next, simplify load booking: no emails, <br /> calls, or
                        texts required.
                      </Typography>
                      <Typography color="textSecondary">
                        Add the Super Dispatch: Connection extension to make it
                        easier to book from other load boards.
                      </Typography>
                    </Stack>
                  </Column>
                </Columns>
              </Stack>

              <Box paddingLeft="xxlarge">
                <Stack space="small">
                  <Inline verticalAlign="center" space="small">
                    <Button
                      size="large"
                      onClick={(e) => {
                        e.preventDefault();
                        openExternalURL(CHROME_EXTENSION_STORE_URL, {
                          target: '_blank',
                        });
                        onComplete();
                      }}
                    >
                      Add to {getFormattedBrowser()}
                    </Button>

                    <Button size="large" variant="text" onClick={onClose}>
                      Dismiss
                    </Button>
                  </Inline>

                  <Inline>
                    <StyledRating value={5} readOnly={true} size="small" />
                    <Typography color="textSecondary">
                      <b>4.8 · 10,000 users</b> and counting
                    </Typography>
                  </Inline>
                </Stack>
              </Box>
            </Stack>
          </Box>

          <Box padding="small">
            <Stack>
              <Box borderRadius="medium" backgroundColor="Silver200">
                <img width={460} loading="lazy" src={nudgeImg} alt="" />
              </Box>
            </Stack>
          </Box>
        </Container>
      </DialogContent>
    </Dialog>
  );
}
