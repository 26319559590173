import { Route, Routes } from 'react-router-dom-v5-compat';
import { SuperPayVerificationStatus } from 'shared/settings/CarrierSettingsAPI';
import { AccountDetailsPage } from './superpay/AccountDetailsPage';
import { BankAccountPage } from './superpay/BankAccountPage';
import { BusinessDetailsPage } from './superpay/BusinessDetailsPage';
import { PromoPage } from './superpay/PromoPage';
import {
  SuperPayRoute,
  SUPERPAY_PROFILE_ROUTES,
} from './superpay/SuperPayRoute';

const getPath = (status: SuperPayVerificationStatus | null | undefined) => {
  switch (status) {
    case 'restricted':
      return [SUPERPAY_PROFILE_ROUTES.promo];
    case 'bank_account':
      return [SUPERPAY_PROFILE_ROUTES.bankDetails];
    case 'bank_account_verification':
    case 'verified':
    case 'bank_account_failed':
    case 'bank_account_errored':
      return [SUPERPAY_PROFILE_ROUTES.accountDetails];
    default:
      return [
        SUPERPAY_PROFILE_ROUTES.promo,
        SUPERPAY_PROFILE_ROUTES.businessDetails,
      ];
  }
};

export function ProfileSuperPayPage() {
  return (
    <Routes>
      <Route
        path="onboarding/create-account/*"
        element={
          <SuperPayRoute
            path={SUPERPAY_PROFILE_ROUTES.businessDetails}
            getPathByStatus={getPath}
          >
            <BusinessDetailsPage />
          </SuperPayRoute>
        }
      />
      <Route
        path="onboarding/bank-account/*"
        element={
          <SuperPayRoute
            path={SUPERPAY_PROFILE_ROUTES.bankDetails}
            getPathByStatus={getPath}
          >
            <BankAccountPage />
          </SuperPayRoute>
        }
      />
      <Route
        path="onboarding/account-details/*"
        element={
          <SuperPayRoute
            path={SUPERPAY_PROFILE_ROUTES.accountDetails}
            getPathByStatus={getPath}
          >
            <AccountDetailsPage />
          </SuperPayRoute>
        }
      />
      <Route
        index={true}
        element={
          <SuperPayRoute
            path={SUPERPAY_PROFILE_ROUTES.promo}
            getPathByStatus={getPath}
          >
            <PromoPage />
          </SuperPayRoute>
        }
      />
    </Routes>
  );
}
