import { FormikTextField, useFormikEnhanced } from '@superdispatch/forms';
import { useSnackbarStack } from '@superdispatch/ui';
import { useEffect } from 'react';
import { APIResponse } from 'shared/api/CarrierAPIClient';
import { MobileAppBridge } from 'shared/data/MobileAppBridge';
import { DocumentTitle } from 'shared/layout/DocumentTitle';
import { trackCarrierProfileEvent } from 'shared/modules/verified-carrier-application/data/CarrierProfileAnalytics';
import {
  useCarrierProfileAPI,
  useTermsAndConditions,
} from 'shared/modules/verified-carrier-application/data/CarrierProfileAPI';
import { TermsAndConditionsDTO } from 'shared/modules/verified-carrier-application/data/CarrierProfileDTO';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';
import { ProfilePageLayout } from './ProfilePageLayout';

export function ProfileTermsAndConditionsPage() {
  const { data, error, isLoading } = useTermsAndConditions();
  const { data: settings } = useCarrierSettings();
  const { updateTermsAndConditions } = useCarrierProfileAPI();
  const { addSnackbar } = useSnackbarStack();
  const initialValues = data || {
    terms_and_conditions: '',
  };

  const isEditingDisabled =
    !settings?.drivers_can_edit_carrier_info && MobileAppBridge.isInjected();

  const formik = useFormikEnhanced<TermsAndConditionsDTO, APIResponse>({
    initialValues,
    onSubmit: updateTermsAndConditions,
    onSubmitSuccess: () => {
      addSnackbar('Terms and Conditions were successfully updated', {
        variant: 'success',
      });

      trackCarrierProfileEvent({
        name: 'Carrier Updated Carrier Info',
        utm_medium: 'Carrier Profile',
        utm_content: 'Terms and Conditions',
      });
    },
    onSubmitFailure: () =>
      addSnackbar('Failed to update Terms and Conditions', {
        variant: 'error',
      }),
  });

  useEffect(() => {
    if (error) {
      addSnackbar('Failed to fetch Terms and Conditions', { variant: 'error' });
    }
  }, [addSnackbar, error]);

  return (
    <>
      <DocumentTitle title="Terms and Conditions" />

      <ProfilePageLayout
        formik={formik}
        title="Terms and Conditions"
        isLoading={isLoading}
      >
        <FormikTextField
          label="Terms and Conditions"
          name="terms_and_conditions"
          helperText="Terms and Conditions entered here will be attached to invoices sent"
          rows={20}
          rowsMax={20}
          fullWidth={true}
          multiline={true}
          disabled={isEditingDisabled}
        />
      </ProfilePageLayout>
    </>
  );
}
