import { LocalStore, useLocalStore } from 'shared/helpers/Store';

export function useExpeditedPayBannerAccessTime() {
  const EXPEDITED_PAY_BANNER_ACCESS_TIME = 'expedited_pay_access_banner_time';
  const time = useLocalStore(EXPEDITED_PAY_BANNER_ACCESS_TIME);

  const setNextDay = () => {
    const currentDate = new Date();
    const currentDay = currentDate.getDate();
    currentDate.setDate(currentDay + 1);
    const currentTimeDate = currentDate.getTime();
    LocalStore.set(
      EXPEDITED_PAY_BANNER_ACCESS_TIME,
      currentTimeDate.toString(),
    );
  };

  const getAccess = () => {
    if (!time) {
      return true;
    }
    const currentTimeDate = new Date().getTime();
    return currentTimeDate > Number(time);
  };

  return {
    setNextDay,
    getAccess,
  };
}
