import { Card, CardContent, Link } from '@material-ui/core';
import { FormikCheckboxField } from '@superdispatch/forms';
import { Stack } from '@superdispatch/ui';
import { useEffect } from 'react';
import { useAppFormik } from 'shared/form/AppFormik';
import { DocumentTitle } from 'shared/layout/DocumentTitle';
import { useFlag } from 'shared/settings/FeatureToggles';
import { SettingsPageForm } from './core/SettingsPageLayout';
import { useDriverAppSettings, useSettingsAPI } from './data/SettingsAPI';
import { driverAppSettingsSchema } from './data/SettingsDTO';

export function SettingsDriverAppPage() {
  const isNotPickedUpVehiclesEnabled = useFlag('not_picked_up_vehicles');
  const { patchDriverAppSettings } = useSettingsAPI();
  const { data } = useDriverAppSettings();
  const formik = useAppFormik({
    initialValues: data,
    validationSchema: driverAppSettingsSchema,
    onSubmit(values) {
      return patchDriverAppSettings(values, data);
    },
    getSuccessMessage() {
      return 'Driver App Settings successfully updated';
    },
  });

  const { setFieldValue, values } = formik;

  useEffect(() => {
    if (!values.fleet_mode && values.hide_pricing) {
      void setFieldValue('hide_pricing', false);
    }
  }, [setFieldValue, values.fleet_mode, values.hide_pricing]);

  return (
    <>
      <DocumentTitle title="Driver App - Settings" />

      <SettingsPageForm formik={formik} title="Driver App" loading={!data}>
        <Card>
          <CardContent>
            <Stack space="small">
              <FormikCheckboxField
                name="fleet_mode"
                label="Enable Fleet Drive mode on Driver apps"
                helperText={
                  <>
                    Fleet Drive mode controls and limits what the Drivers of the
                    fleet are able to do via their Mobile app.{' '}
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://support.superdispatch.com/everything-dispatch-dashboard/dashboard/how-does-fleet-drive-mode-work-in-the-dashboard"
                    >
                      Learn more...
                    </Link>
                  </>
                }
              />

              <FormikCheckboxField
                name="hide_pricing"
                disabled={!formik.values.fleet_mode}
                label="Hide Payment Information on Driver apps (will show price for payment terms: COD, COP and CKOD)"
                helperText="This works when Fleet Driver Mode is checked."
              />

              <FormikCheckboxField
                name="is_mobile_loadboard_enabled"
                label="Enable Super Loadboard on the Driver App"
              />

              <FormikCheckboxField
                name="is_customer_signature_required"
                label="Disable Customer not Available button on the Driver App"
              />

              <FormikCheckboxField
                name="is_subject_to_inspection_disabled"
                label="Disable STI (Subject To Inspection) button on the Driver App"
              />

              <FormikCheckboxField
                name="enabled_driver_pay"
                label="Enable Driver Pay field"
              />

              <FormikCheckboxField
                name="drivers_can_edit_carrier_info"
                label="Enable Driver to edit Carrier information"
              />

              <FormikCheckboxField
                name="drivers_can_rate"
                label="Enable Rating Management"
                helperText="Allow driver to add, edit and delete ratings and work with responses."
              />

              {isNotPickedUpVehiclesEnabled && (
                <FormikCheckboxField
                  name="drivers_can_mark_vehicles_as_cannot_be_picked_up"
                  label="Allow drivers to mark vehicles as Not Picked Up"
                  helperText={
                    <>
                      The status of the Not Picked Up vehicle will be shared
                      with the shipper.{' '}
                      <Link
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://support.superdispatch.com/en/articles/8418673-carrier-tms-marking-a-vehicle-as-not-picked-up"
                      >
                        Learn more...
                      </Link>
                    </>
                  }
                />
              )}
            </Stack>
          </CardContent>
        </Card>
      </SettingsPageForm>
    </>
  );
}
