import { Tooltip, Typography } from '@material-ui/core';
import {
  AccessTime,
  CheckCircle,
  Error,
  Info,
  PauseCircleOutline,
} from '@material-ui/icons';
import { FormattedDate } from '@superdispatch/dates';
import { Color, Column, Columns, Inline, Stack, Tag } from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import { ReactNode } from 'react';
import { BankErrorMessage } from 'shared/errors/SuperPayBankErrors';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';
import { IconTag } from 'shared/ui/IconTag';
import styled from 'styled-components';
import { LoadPaymentDTO } from '../data/LoadDTO';

function StatusWrapper({
  children,
  icon,
}: {
  children?: ReactNode | null;
  icon: JSX.Element;
}) {
  return (
    <Columns space="xxsmall" align="center">
      <Column width="content">{icon}</Column>
      {children && <Column>{children}</Column>}
    </Columns>
  );
}

const OnHoldReasonList = styled.ul`
  padding-inline-start: 24px;
  margin: 0;
`;
const OnHoldReasonListItem = styled.li`
  margin-bottom: 4px;
`;
const OnHoldReasonTooltipHeader = styled(Typography)`
  color: ${Color.Yellow300};
`;

const CustomHoldReason = styled(Typography)`
  word-break: break-word;
  hyphens: auto;
  -ms-hyphens: auto;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
`;

interface LoadPaymentStatusProps {
  payment: LoadPaymentDTO;
}

function StatusText({ payment }: LoadPaymentStatusProps) {
  const { data: carrierSettings } = useCarrierSettings();
  const paymentError = carrierSettings?.superpay.payment_error;
  const isSuperPay = payment.method === 'superpay';

  switch (payment.status) {
    case 'processing':
      return (
        <IconTag color="blue" icon={<AccessTime fontSize="small" />}>
          {payment.status_details}
        </IconTag>
      );
    case 'initiating':
      return isSuperPay ? null : (
        <StatusWrapper
          icon={<AccessTime fontSize="small" htmlColor={Color.Dark200} />}
        >
          <Typography color="textSecondary">
            {payment.status_details}
          </Typography>
        </StatusWrapper>
      );
    case 'received':
      return (
        <StatusWrapper
          icon={<CheckCircle fontSize="small" htmlColor={Color.Green300} />}
        >
          <TextBox color="green">{payment.status_details}</TextBox>
        </StatusWrapper>
      );
    case 'failed':
      return isSuperPay ? (
        <StatusWrapper
          icon={
            <IconTag
              display="inline"
              color="red"
              icon={<Info fontSize="small" color="error" />}
              align="center"
            >
              {payment.status_details}
            </IconTag>
          }
        >
          {paymentError && (
            <Tooltip
              interactive={true}
              title={
                <BankErrorMessage
                  paymentError={paymentError}
                  source="Load Tooltip"
                />
              }
            >
              <Info fontSize="small" color="action" />
            </Tooltip>
          )}
        </StatusWrapper>
      ) : (
        <StatusWrapper
          icon={<Error fontSize="small" htmlColor={Color.Red300} />}
        >
          <TextBox color="red">{payment.status_details}</TextBox>
        </StatusWrapper>
      );
    case 'on_hold':
      return isSuperPay ? (
        <StatusWrapper
          icon={
            <Tag variant="subtle" color="yellow">
              {payment.status_details}
            </Tag>
          }
        >
          <OnHoldReasonTooltip payment={payment} />
        </StatusWrapper>
      ) : (
        <StatusWrapper
          icon={
            <PauseCircleOutline
              fontSize="small"
              color="inherit"
              htmlColor={Color.Dark200}
            />
          }
        >
          <Typography color="textSecondary">
            <Inline verticalAlign="center" space="xxsmall">
              {payment.status_details}
              <Tooltip
                aria-label="On-hold details"
                enterTouchDelay={0}
                title="Shipper needs to initiate payment manually due to incomplete Advanced inspection, potential vehicle damage or manual marking."
              >
                <Info color="action" fontSize="small" />
              </Tooltip>
            </Inline>
          </Typography>
        </StatusWrapper>
      );
    default:
      return null;
  }
}

export function LoadPaymentStatus({ payment }: LoadPaymentStatusProps) {
  if (payment.status && payment.status_details) {
    return (
      <Stack space="xsmall">
        <StatusText payment={payment} />
        {payment.status !== 'received' && (
          <Typography variant="body1" color="textSecondary">
            No Payment received.
          </Typography>
        )}
      </Stack>
    );
  }

  if (payment.paid_at) {
    return (
      <StatusWrapper
        icon={<CheckCircle fontSize="small" htmlColor={Color.Green300} />}
      >
        <TextBox color="green">
          Payment received on{' '}
          <FormattedDate date={payment.paid_at} variant="DateTime" />
        </TextBox>
      </StatusWrapper>
    );
  }

  return (
    <Typography variant="body1" color="textSecondary">
      No Payment received.
    </Typography>
  );
}

interface OnHoldReasonTooltipProps {
  payment: LoadPaymentDTO;
}
function OnHoldReasonTooltip({ payment }: OnHoldReasonTooltipProps) {
  const { custom_on_hold_reason, on_hold_reasons } = payment;

  const isCustomHoldReasonAvailable = !!custom_on_hold_reason;
  const isHoldReasonsAvailable =
    !!on_hold_reasons && on_hold_reasons.length > 0;

  const isNoReasonProvided =
    !isHoldReasonsAvailable && !isCustomHoldReasonAvailable;

  if (isCustomHoldReasonAvailable || isNoReasonProvided) {
    return (
      <Tooltip
        aria-label="On-hold details"
        enterTouchDelay={0}
        title={
          <Box maxWidth="309px">
            <Stack space="xsmall">
              <OnHoldReasonTooltipHeader variant="h5">
                Shipper put Payment on Hold
              </OnHoldReasonTooltipHeader>
              <Stack space="small">
                {isCustomHoldReasonAvailable ? (
                  <CustomHoldReason>{custom_on_hold_reason}</CustomHoldReason>
                ) : (
                  <Typography color="textSecondary">
                    Reason wasn’t provided.
                  </Typography>
                )}
                <Columns space="xsmall" align="top">
                  <Column width="content">
                    <Info fontSize="small" color="action" />
                  </Column>
                  <Column>
                    <Typography color="textSecondary">
                      Shipper will initiate payment manually. Contact shipper if
                      on hold more than 3 business days.
                    </Typography>
                  </Column>
                </Columns>
              </Stack>
            </Stack>
          </Box>
        }
      >
        <Info
          aria-label="On-hold details icon"
          color="action"
          fontSize="small"
        />
      </Tooltip>
    );
  }

  return isHoldReasonsAvailable ? (
    <Tooltip
      aria-label="On-hold details"
      enterTouchDelay={0}
      title={
        <Box width="218px">
          <Stack space="xsmall">
            <Typography>
              Shipper will initiate payment manually due to:
            </Typography>
            <OnHoldReasonList>
              {on_hold_reasons.map((reason) => (
                <OnHoldReasonListItem key={reason}>
                  {reason}
                </OnHoldReasonListItem>
              ))}
            </OnHoldReasonList>
            <Typography>
              Contact shipper if on hold more than 3 business days.
            </Typography>
          </Stack>
        </Box>
      }
    >
      <Info aria-label="On-hold details icon" color="action" fontSize="small" />
    </Tooltip>
  ) : null;
}
