import { Skeleton } from '@material-ui/lab';
import { useFormikEnhanced } from '@superdispatch/forms';
import { useSnackbarStack } from '@superdispatch/ui';
import { FormikProvider } from 'formik';
import { useEffect } from 'react';
import { useInitialValues } from 'shared/form/AppFormik';
import { useNavigationBlock } from 'shared/routing/NavigationBlock';
import { CargoInsurancePreview } from './core/CargoInsurancePreview';
import { ProfileCargoInsuranceForm } from './core/ProfileCargoInsuranceForm';
import { StepForm } from './core/StepForm';
import { trackCarrierProfileEvent } from './data/CarrierProfileAnalytics';
import {
  useCargoInsurance,
  useCarrierProfileAPI,
} from './data/CarrierProfileAPI';
import { cargoInsuranceSchema } from './data/CarrierProfileDTO';

interface StepCargoInsurancePageProps {
  onComplete: () => void;
  onBack?: () => void;
}

export function StepCargoInsurancePage({
  onComplete,
  onBack,
}: StepCargoInsurancePageProps) {
  const cargoInsurance = useCargoInsurance();
  const { updateCargoInsurance } = useCarrierProfileAPI();
  const { addSnackbar } = useSnackbarStack();

  const initialValues = useInitialValues(
    cargoInsuranceSchema,
    cargoInsurance.data,
  );

  const formik = useFormikEnhanced({
    initialValues,
    validationSchema: cargoInsuranceSchema,
    onSubmit: updateCargoInsurance,
    onSubmitSuccess() {
      trackCarrierProfileEvent({
        name: 'Carrier Updated Carrier Info',
        utm_medium: 'Verified Carrier Application',
        utm_content: 'Cargo Insurance',
      });

      onComplete();
    },
    onSubmitFailure() {
      addSnackbar('Failed to update carrier information. Please try again.', {
        variant: 'error',
      });
    },
  });

  useNavigationBlock(
    formik.status.type !== 'submitted' &&
      (formik.dirty || formik.isSubmitting) &&
      'Changes have not been saved. Leaving will result in unsaved changes being lost.',
  );

  useEffect(() => {
    if (cargoInsurance.error) {
      addSnackbar('Failed to fetch carrier information', { variant: 'error' });
    }
  }, [addSnackbar, cargoInsurance.error]);

  if (cargoInsurance.isLoading) {
    return (
      <div aria-label="Loading Step 2 Cargo Insurance">
        <Skeleton width={170} height={24} />
        <Skeleton width={270} height={24} />
      </div>
    );
  }

  return (
    <FormikProvider value={formik}>
      <StepForm
        step={3}
        onBack={onBack}
        formContent={<ProfileCargoInsuranceForm />}
        submitButtonText="Continue to Identification"
        documentPreview={<CargoInsurancePreview />}
      />
    </FormikProvider>
  );
}
