import { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import {
  APIListQueryOptions,
  APIListQueryResult,
  BaseAPIListQueryOptions,
  useAPIListQuery,
} from 'shared/api/APIListQuery';
import {
  APIMutationOptions,
  APIMutationResult,
  useAPIMutation,
} from 'shared/api/APIMutation';
import {
  APIQueryOptions,
  APIQueryResult,
  useAPIQuery,
  UseAPIQueryOptions,
} from 'shared/api/APIQuery';
import {
  APIResponse,
  requestCarrierAPI,
  requestJSON,
} from 'shared/api/CarrierAPIClient';
import { requestCarrierAPIPublic } from 'shared/api/PublicCarrierAPIClient';
import { DISPATCHER_PROFILE_CACHE_KEY } from 'shared/modules/dispatcher/DispatcherAPI';
import {
  DispatcherDTO,
  dispatcherSchema,
} from 'shared/modules/dispatcher/DispatcherDTO';
import {
  DispatcherActivateDTO,
  DispatcherEditDTO,
  DispatcherInvitationDTO,
  dispatcherInvitationSchema,
  DispatcherProfilePasswordDTO,
  InviteDispatcherDTO,
} from './DispatchersDTO';

export type DispatcherAcceptInvitationResponse =
  APIResponse<DispatcherInvitationDTO> & {
    url: string | null;
  };

export function useDispatchersAPI() {
  const queryClient = useQueryClient();

  return useMemo(
    () => ({
      acceptInvite: (invitationCode: string, json: DispatcherActivateDTO) => {
        return requestCarrierAPIPublic<DispatcherAcceptInvitationResponse>(
          [
            'POST /internal/web/dispatchers/invites/{invitationCode}/',
            { invitationCode },
          ],
          {
            json,
          },
        ).then((response) => {
          return response;
        });
      },

      inviteDispatcher: (json: InviteDispatcherDTO): Promise<DispatcherDTO> =>
        requestCarrierAPI('POST /internal/web/dispatchers/', { json }).then(
          (res) => {
            void queryClient.invalidateQueries('dispatchers');

            return dispatcherSchema.cast(res.data);
          },
        ),
      resendInviteDispatcher: (guid: string) => {
        return requestCarrierAPI([
          'POST /internal/web/dispatchers/{guid}/resend-invite/',
          { guid },
        ]).then((res) => {
          void queryClient.invalidateQueries('dispatchers');

          return res;
        });
      },

      acceptJoinRequest: (guid: string) => {
        return requestCarrierAPI([
          'POST /internal/web/dispatchers/{guid}/accept/',
          { guid },
        ]).then((res) => {
          void queryClient.invalidateQueries('dispatchers');
          void queryClient.invalidateQueries('dispatcher-detail');

          return res;
        });
      },
      declineJoinRequest: (guid: string) => {
        return requestCarrierAPI([
          'POST /internal/web/dispatchers/{guid}/decline/',
          { guid },
        ]).then((res) => {
          void queryClient.invalidateQueries('dispatchers');
          void queryClient.invalidateQueries('dispatcher-detail');

          return res;
        });
      },
      reactivateDispatcher: (guid: string) => {
        return requestCarrierAPI([
          'POST /internal/web/dispatchers/{guid}/reactivate/',
          { guid },
        ]).then((res) => {
          void queryClient.invalidateQueries('dispatchers');
          void queryClient.invalidateQueries('dispatcher-detail');

          return res;
        });
      },

      deactivateDispatcher: (guid: string, reason: string) => {
        return requestCarrierAPI(
          ['POST /internal/web/dispatchers/{guid}/deactivate/', { guid }],
          {
            json: { reason },
          },
        ).then((res) => {
          void queryClient.invalidateQueries('dispatchers');
          void queryClient.invalidateQueries('dispatcher-detail');

          return res;
        });
      },
      deleteDispatcher: (guid: string) => {
        return requestCarrierAPI([
          'DELETE /internal/web/dispatchers/{guid}/',
          { guid },
        ]).then((res) => {
          void queryClient.invalidateQueries('dispatchers');
          void queryClient.invalidateQueries('dispatcher-detail');

          return res;
        });
      },
    }),
    [queryClient],
  );
}

export function useDispatcher(
  guid: null | string | undefined,
  options?: APIQueryOptions<DispatcherDTO>,
): APIQueryResult<DispatcherDTO> {
  return useAPIQuery(
    ['dispatcher-detail', 'item', { guid }],
    () =>
      requestCarrierAPI(['GET /internal/web/dispatchers/{guid}/', { guid }]),
    {
      ...options,
      enabled: !!guid,
    },
  );
}

export interface DispatchersPageParams {
  q?: string;
  guids?: string;
  page_size?: number;
}

export function useSearchDispatchersList(
  params?: DispatchersPageParams,
  options?: BaseAPIListQueryOptions<DispatcherDTO>,
): APIListQueryResult<DispatcherDTO> {
  return useAPIListQuery(
    ['dispatchers-search', 'list', params],
    (page) =>
      requestCarrierAPI([
        'GET /internal/web/dispatchers/search/{?page,params*}',
        { page, params },
      ]),
    { ...options, schema: dispatcherSchema },
  );
}

export function useAllSearchDispatchersList(
  options?: APIQueryOptions<DispatcherDTO[]>,
): APIQueryResult<DispatcherDTO[]> {
  return useAPIQuery(
    ['dispatchers-search', 'list-all'],
    () =>
      requestCarrierAPI('GET /internal/web/dispatchers/search/?page_size=1000'),
    { ...options },
  );
}

export function useDispatcherProfileWithoutErrorBoundary(
  options?: APIQueryOptions<DispatcherDTO>,
): APIQueryResult<DispatcherDTO> {
  return useAPIQuery(
    ['dispatchers', 'profile-no-error-boundary'],
    () => requestJSON('GET /internal/web/dispatchers/profile/'),
    { ...options, schema: dispatcherSchema },
  );
}

export function useDispatcherProfileAPI() {
  const queryClient = useQueryClient();

  return useMemo(
    () => ({
      updateDispatcherProfile: (json: DispatcherEditDTO) =>
        requestCarrierAPI('PATCH /internal/web/dispatchers/profile/', {
          json,
        }).then((response) => {
          void queryClient.invalidateQueries(DISPATCHER_PROFILE_CACHE_KEY);

          return response;
        }),

      updateDispatcherPassword: (json: DispatcherProfilePasswordDTO) =>
        requestCarrierAPI('PATCH /internal/web/dispatchers/reset-password/', {
          json,
        }),
    }),
    [queryClient],
  );
}

export interface DispatcherListParams {
  q?: string;
  page_size?: number;
}

export function useDispatcherList(
  params?: DispatcherListParams,
  options?: APIListQueryOptions<DispatcherDTO>,
): APIListQueryResult<DispatcherDTO> {
  return useAPIListQuery(
    ['dispatchers', 'list', params],
    (page) =>
      requestCarrierAPI([
        'GET /internal/web/dispatchers/{?page,params*}',
        { page, params },
      ]),
    { ...options },
  );
}

interface DispatcherInvitationDetailsParams {
  invitationCode: string;
}

export function useDispatcherInvitationDetails(
  params: DispatcherInvitationDetailsParams,
  options?: UseAPIQueryOptions<DispatcherInvitationDTO>,
): APIQueryResult<DispatcherInvitationDTO> {
  return useAPIQuery(
    ['dispatchers', 'invitation-details', { params }],
    () =>
      requestCarrierAPIPublic([
        'GET /internal/web/dispatchers/invites/{invitationCode}/',
        { ...params },
      ]),
    {
      ...options,
      schema: dispatcherInvitationSchema,
    },
  );
}

export function useCancelDispatcherInvite(
  options?: APIMutationOptions<string>,
): APIMutationResult<string> {
  const queryClient = useQueryClient();

  return useAPIMutation(
    (guid) =>
      requestCarrierAPI([
        'DELETE /internal/web/dispatchers/{guid}/cancel-invite/',
        { guid },
      ]).then((res) => {
        void queryClient.invalidateQueries('dispatchers');
        return res;
      }),
    options,
  );
}

export function useResendDispatcherInvite(
  options?: APIMutationOptions<string>,
): APIMutationResult<string> {
  return useAPIMutation(
    (guid) =>
      requestCarrierAPI([
        'POST /internal/web/dispatchers/{guid}/resend-invite/',
        { guid },
      ]),
    options,
  );
}

export function useAcceptDispatcherRequest(
  options?: APIMutationOptions<string>,
) {
  const { acceptJoinRequest } = useDispatchersAPI();

  return useAPIMutation((guid) => acceptJoinRequest(guid), options);
}

export function useDeclineDispatcherRequest(
  options?: APIMutationOptions<string>,
) {
  const { declineJoinRequest } = useDispatchersAPI();

  return useAPIMutation((guid) => declineJoinRequest(guid), options);
}

export function useReactivateDispatcher(options?: APIMutationOptions<string>) {
  const { reactivateDispatcher } = useDispatchersAPI();

  return useAPIMutation((guid) => reactivateDispatcher(guid), options);
}
