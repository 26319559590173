import {
  createPath,
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams,
} from 'react-router-dom-v5-compat';
import { AppContainer } from 'shared/app/AppContainer';
import { renderApp } from 'shared/app/AppRenderer';
import { LoginContainerPage } from 'shared/auth/LoginContainerPage';
import { RedirectToTMS } from 'shared/auth/LoginContextProvider';
import { LogoutContainerPage } from 'shared/auth/LogoutContainerPage';
import { ResetPasswordSuccessPage } from 'shared/auth/ResetPasswordSuccessPage';
import { VerifyAccountContainer } from 'shared/auth/VerifyAccountContainer';
import { VerifyAccountPage } from 'shared/auth/VerifyAccountPage';
import { ServerErrorBoundary } from 'shared/errors/ServerErrorBoundary';
import { useBasename } from 'shared/helpers/RoutingHelpers';
import { ExperimentsContainer } from 'shared/modules/experiments/ExperimentContainer';
import { OnboardingWrapper } from 'shared/modules/onboarding/core/OnboardingWrapper';
import { OnboardingContainer } from 'shared/modules/onboarding/OnboardingContainer';
import { PageRedirect } from 'shared/routing/PageRedirect';
import { CarrierApp } from './CarrierApp';
import { DispatcherInvitationPage } from './dispatchers/dispatcher-invitation/DispatcherInvitationPage';
import { DriverAccountPage } from './driver-account/DriverAccount';
import { DriverAccountContainerPage } from './driver-account/DriverAccountContainerPage';
import { DriverVerifyAccountPage } from './driver-account/DriverAccountVerifyAuthPage';
import { DriverAccountWrapper } from './driver-account/DriverAccountWrapper';
import { DriverDeleteAccountCompletionPage } from './driver-account/DriverDeleteAccountCompletionPage';
import { DriverDeleteAccountConfirmationPage } from './driver-account/DriverDeleteAccountConfirmationPage';
import { DriverDeleteAccountPage } from './driver-account/DriverDeleteAccountPage';
import { DriverAccountDeletionPage } from './drivers/driver-account-deletion/DriverAccountDeletionPage';
import { DriverActivationPage } from './drivers/driver-activation/DriverActivationPage';
import { DriverInvitationPage } from './drivers/driver-invitation/DriverInvitationPage';
import { PublicOfferContainerPage } from './offers/public-offers/PublicOfferContainerPage';

function LoadboardPageRedirectPage() {
  const basename = useBasename();
  const location = useLocation();

  return <PageRedirect to={basename + createPath(location)} />;
}

function SplitOrderRedirectPage() {
  const params = useParams<{ id: string }>();
  const { search } = useLocation();

  return <Navigate to={`/loads-split/${params.id}${search}`} />;
}

function ViewOffersRedirectPage() {
  const params = useParams<{ key: string }>();
  const { search } = useLocation();

  return <Navigate to={`/public-offer/${params.key}${search}`} />;
}

renderApp(
  <AppContainer>
    <Routes>
      {import.meta.env.NODE_ENV !== 'development' && (
        <Route path="/loadboard/*" element={<LoadboardPageRedirectPage />} />
      )}

      <Route path="/orders/:id/split" element={<SplitOrderRedirectPage />} />
      <Route path="/offers/:key/view/*" element={<ViewOffersRedirectPage />} />

      {/* Public routes */}
      <Route
        path="/login/verify"
        element={
          <VerifyAccountContainer>
            <VerifyAccountPage />
          </VerifyAccountContainer>
        }
      />
      <Route
        path="/login/*"
        element={
          <RedirectToTMS>
            <LoginContainerPage />
          </RedirectToTMS>
        }
      />
      <Route
        path="/reset-password-success"
        element={<ResetPasswordSuccessPage />}
      />
      <Route path="/logout" element={<LogoutContainerPage />} />
      <Route path="/public-offer/*" element={<PublicOfferContainerPage />} />
      <Route
        path="/driver-activation/:activationCode"
        element={<DriverActivationPage />}
      />
      <Route
        path="/driver-invite/:invitationCode"
        element={<DriverInvitationPage />}
      />
      <Route
        path="/driver-account-deletion/:token"
        element={<DriverAccountDeletionPage />}
      />
      <Route
        path="/dispatcher-invite/:invitationCode"
        element={<DispatcherInvitationPage />}
      />

      {/* Driver Account */}
      <Route path="/driver-login" element={<DriverAccountContainerPage />} />
      <Route
        path="/driver-account/verify"
        element={
          <VerifyAccountContainer>
            <DriverVerifyAccountPage />
          </VerifyAccountContainer>
        }
      />
      <Route
        path="/driver-account/delete/completion"
        element={<DriverDeleteAccountCompletionPage />}
      />
      <Route
        path="/driver-account/delete/confirmation"
        element={
          <DriverAccountWrapper>
            <DriverDeleteAccountConfirmationPage />
          </DriverAccountWrapper>
        }
      />
      <Route
        path="/driver-account/delete"
        element={
          <DriverAccountWrapper>
            <DriverDeleteAccountPage />
          </DriverAccountWrapper>
        }
      />
      <Route
        path="/driver-account"
        element={
          <DriverAccountWrapper>
            <DriverAccountPage />
          </DriverAccountWrapper>
        }
      />

      <Route path="/onboarding/*" element={<OnboardingContainer />} />

      {/* Private Routes */}
      <Route
        path="*"
        element={
          <ServerErrorBoundary>
            <ExperimentsContainer>
              <OnboardingWrapper>
                <CarrierApp />
              </OnboardingWrapper>
            </ExperimentsContainer>
          </ServerErrorBoundary>
        }
      />
    </Routes>
  </AppContainer>,
);
