import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography as MuiTypography,
} from '@material-ui/core';
import {
  FormikTextField,
  SuspendedFormikPhoneField,
  useFormikEnhanced,
} from '@superdispatch/forms';
import {
  Column,
  Columns,
  Inline,
  Stack,
  useSnackbarStack,
} from '@superdispatch/ui';
import { Box as SDBox, Button } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import { APIError, createAPIError, isAPIError } from 'shared/api/APIError';
import { DispatcherDTO } from 'shared/modules/dispatcher/DispatcherDTO';
import styled from 'styled-components';
import { trackDispatchersEvent } from '../data/DispatchersAnalytics';
import { useDispatchersAPI } from '../data/DispatchersAPI';
import { inviteDispatcherDTO } from '../data/DispatchersDTO';
import {
  INVITE_REQUEST_ALREADY_SENT_ERROR,
  JOIN_REQUEST_ALREADY_SENT_ERROR,
} from '../data/DispatchersUtils';

const Box = styled(SDBox)`
  padding: 6px 8px;
`;

const Typography = styled(MuiTypography)`
  font-size: 13px;
`;

interface DispatcherInviteDialogProps {
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
}

export function DispatcherInviteDialog({
  open,
  onClose,
  onOpen,
}: DispatcherInviteDialogProps) {
  const navigate = useNavigate();
  const { addSnackbar } = useSnackbarStack();
  const { inviteDispatcher } = useDispatchersAPI();

  const [requestedDispatcherError, setRequestedDispatcherError] = useState<{
    guid?: string;
    type?: string;
  }>();

  const formik = useFormikEnhanced({
    enableReinitialize: true,
    validationSchema: inviteDispatcherDTO,
    initialValues: {
      email: '',
      phone_number: '',
    },
    onSubmit: (values) => {
      return inviteDispatcher(values);
    },
    onSubmitSuccess: (response: DispatcherDTO) => {
      trackDispatchersEvent({ name: 'Carrier Sent Invite To Dispatcher' });
      addSnackbar('Invite successfully sent', { variant: 'success' });
      handleClose();
      navigate(`/dispatchers/${response.guid}`);
    },
    onSubmitFailure: (e) => {
      onOpen();

      if (
        isAPIError(e) &&
        (e.type === JOIN_REQUEST_ALREADY_SENT_ERROR ||
          e.type === INVITE_REQUEST_ALREADY_SENT_ERROR)
      ) {
        const error = createAPIError(e) as APIError<{ guid: string }>;
        setRequestedDispatcherError({
          guid: error.context?.guid,
          type: error.type,
        });
      }
    },
  });

  function handleClose() {
    formik.resetForm();
    setRequestedDispatcherError(undefined);
    onClose();
  }

  const { isSubmitting } = formik;

  return (
    <Dialog fullWidth={true} maxWidth="sm" open={open} onClose={handleClose}>
      <FormikProvider value={formik}>
        <Form>
          <DialogTitle>Send Invite to</DialogTitle>
          <DialogContent>
            <Stack space="small">
              <Columns space="small">
                <Column width="1/2">
                  <FormikTextField
                    name="email"
                    label="Email"
                    autoFocus={true}
                    fullWidth={true}
                    placeholder="Team member’s email"
                  />
                </Column>
                <Column width="1/2">
                  <SuspendedFormikPhoneField
                    fullWidth={true}
                    name="phone_number"
                    label="Phone Number"
                  />
                </Column>
              </Columns>

              {requestedDispatcherError?.type ===
                JOIN_REQUEST_ALREADY_SENT_ERROR && (
                <Box borderRadius="small" backgroundColor="Blue50">
                  <Typography>
                    This dispatcher has already requested to join your carrier.
                    Open Request to accept them.
                  </Typography>
                </Box>
              )}
              {requestedDispatcherError?.type ===
                INVITE_REQUEST_ALREADY_SENT_ERROR && (
                <Box borderRadius="small" backgroundColor="Blue50">
                  <Typography>
                    This dispatcher has already been invited. Open Invite to
                    resend or cancel it.
                  </Typography>
                </Box>
              )}
            </Stack>
          </DialogContent>
          <DialogActions>
            <Inline>
              <Button
                disabled={isSubmitting}
                onClick={handleClose}
                variant="neutral"
              >
                Cancel
              </Button>
              {requestedDispatcherError?.guid ? (
                <Button
                  onClick={() => {
                    navigate(`/dispatchers/${requestedDispatcherError.guid}`);
                    handleClose();
                  }}
                >
                  Open{' '}
                  {requestedDispatcherError.type ===
                  JOIN_REQUEST_ALREADY_SENT_ERROR
                    ? 'Request'
                    : 'Invite'}
                </Button>
              ) : (
                <Button
                  type="submit"
                  pending={isSubmitting}
                  disabled={!formik.dirty}
                >
                  Send Invite
                </Button>
              )}
            </Inline>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  );
}
