import { Drawer, IconButton, Link, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import {
  DrawerActions,
  DrawerContent,
  DrawerTitle,
  Stack,
} from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { showIntercomMessenger } from 'shared/helpers/Intercom';
import { WebViewLink } from 'shared/routing/WebViewLinks';
import { trackPhoneVerificationEvent } from './data/PhoneVerificationAnalytics';

interface UsdotPinHelpDrawerProps {
  open: boolean;
  onClose: () => void;
}
export function USDOTPinHelpDrawer({ open, onClose }: UsdotPinHelpDrawerProps) {
  return (
    <Drawer open={open} onClose={onClose}>
      <DrawerTitle
        title="Help"
        endAction={
          <IconButton edge="end" aria-label="close" onClick={onClose}>
            <Close />
          </IconButton>
        }
      />
      <DrawerContent>
        <Stack space="xlarge">
          <Typography variant="h2">
            Update your FMCSA-registered <br /> phone number
          </Typography>
          <Stack>
            <Typography variant="h4">Do you have your USDOT PIN?</Typography>
            <Typography>
              <WebViewLink
                color="primary"
                href="https://support.superdispatch.com/en/articles/8867378-verified-carrier-update-your-number-with-the-fmcsa?location=conversation"
                rel="noreferrer"
                target="_blank"
                onClick={() => {
                  trackPhoneVerificationEvent({
                    name: 'Carrier Clicked Instruction Fmcsa Usdot Pin',
                    utm_content: 'Code Request Screen Help Drawer',
                  });
                }}
              >
                Click here
              </WebViewLink>{' '}
              for detailed instructions
            </Typography>
          </Stack>
          <Stack>
            <Typography variant="h4">You don’t have your USDOT PIN?</Typography>
            <Typography>
              <WebViewLink
                color="primary"
                href="https://li-public.fmcsa.dot.gov/LIVIEW/PKG_PIN_START.PRC_INTRO"
                rel="noreferrer"
                target="_blank"
                onClick={() => {
                  trackPhoneVerificationEvent({
                    name: 'Carrier Clicked Request Fmcsa Usdot Pin',
                    utm_content: 'Code Request Screen Help Drawer',
                  });
                }}
              >
                Click here
              </WebViewLink>{' '}
              to request a new PIN
            </Typography>
          </Stack>
          <Stack>
            <Typography variant="h4">Can we help?</Typography>
            <Typography>
              <Link
                color="primary"
                href="#"
                onClick={(event) => {
                  event.preventDefault();
                  showIntercomMessenger();
                }}
              >
                Contact
              </Link>{' '}
              our Compliance Team
            </Typography>
          </Stack>
        </Stack>
      </DrawerContent>
      <DrawerActions>
        <Button onClick={onClose}>Ok</Button>
      </DrawerActions>
    </Drawer>
  );
}
