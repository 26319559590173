import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom-v5-compat';
import { usePageTrack } from 'shared/helpers/Analytics';
import { useFlag } from 'shared/settings/FeatureToggles';
import { trackTripsEvent } from './data/TripsAnalytics';
import { TripDetailsPage } from './trip-details-page/TripDetailsPage';
import { CreateTripPage } from './trip-form-page/TripFormPage';
import { TripsListPage } from './trips-list-page/TripsListPage';
import { TripsDetails } from './TripsDetails';
import { TripsList } from './TripsList';

const TRIPS_PATHS = {
  trips: '/trips',
  view: 'view',
  create: 'create',
};

export function TripsContainerPage() {
  const isNewTripsPageEnabled = useFlag('trips_improvements');
  usePageTrack('Trips');

  useEffect(() => {
    trackTripsEvent({
      name: 'CTMS: Opened React Trips Page',
    });
  }, []);

  if (isNewTripsPageEnabled) {
    return (
      <Routes>
        <Route path={`${TRIPS_PATHS.view}/:guid`} element={<TripsDetails />} />

        <Route path={TRIPS_PATHS.create} element={<CreateTripPage />} />

        <Route path="*" element={<TripsList path={TRIPS_PATHS.trips} />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route
        path={`${TRIPS_PATHS.view}/:guid/*`}
        element={<TripDetailsPage />}
      />

      <Route path="*" element={<TripsListPage path={TRIPS_PATHS.trips} />} />
    </Routes>
  );
}
