import { Stack, useSnackbarStack } from '@superdispatch/ui';
import { useField, useFormikContext } from 'formik';
import { useEffect } from 'react';
import { ContactDTO } from '../../contacts/data/ContactDTO';
import {
  LoadSendInvoiceDTO,
  SendInvoiceEmailDTO,
} from '../data/LoadActionsDTO';
import { trackLoadsEvent } from '../data/LoadsAnalytics';
import { useQbInvoice, useSendInvoiceEmail } from '../data/LoadsAPI';
import { LoadSendInvoiceStatusItem } from './LoadSendInvoiceStatusItem';

interface FormikDTO
  extends Required<Omit<LoadSendInvoiceDTO, 'email'>>,
    Required<SendInvoiceEmailDTO> {
  emails: ContactDTO[];
}

export function LoadSendInvoiceStatus({ onClose }: { onClose: () => void }) {
  const [email] = useField<boolean>({ name: 'email' });
  const [qbo] = useField<boolean>({ name: 'qb_online' });
  const [qbd] = useField<boolean>({ name: 'qb_desktop' });
  const { addSnackbar } = useSnackbarStack();

  const { values } = useFormikContext<FormikDTO>();
  const isEmailAvailable = email.value && values.emails.length > 0;
  const isQboAvailable = qbo.value;
  const isQbdAvailable = qbd.value;
  const emailText = isEmailAvailable
    ? values.emails.map((item) => item.email).join(',')
    : '';

  const {
    refetch,
    error,
    isLoading,
    status: emailStatus,
  } = useSendInvoiceEmail(
    {
      bol_template: values.bol_template,
      customer_name: values.customer.name
        ? values.customer.name.toString()
        : '',
      email: emailText,
      factor_this_invoice: values.factor_this_invoice,
      invoice_date: values.invoice_date ? values.invoice_date : '',
      invoice_number: values.invoice_id ? values.invoice_id : '',
      attachments: values.attachments.map((item) => Number(item)),
      load_guids: values.orders.map((item) => item.guid),
      is_carrier_requested_superpay: values.is_carrier_requested_superpay,
    },
    {
      enabled: isEmailAvailable,
    },
  );

  // QBO
  const {
    isLoading: isQboLoading,
    refetch: qboRefetch,
    error: qboError,
    status: qboStatus,
  } = useQbInvoice(
    'online',
    {
      customer_name: values.customer.name
        ? values.customer.name.toString()
        : '',
      invoice_date: values.invoice_date ? values.invoice_date : '',
      invoice_number: values.invoice_id ? values.invoice_id : '',
      order_guids: values.orders.map((item) => item.guid),
    },
    {
      enabled: isQboAvailable,
    },
  );

  const {
    isLoading: isQbdLoading,
    refetch: qbdRefetch,
    error: qbdError,
    status: qbdStatus,
  } = useQbInvoice(
    'desktop',
    {
      customer_name: values.customer.name
        ? values.customer.name.toString()
        : '',
      invoice_date: values.invoice_date ? values.invoice_date : '',
      invoice_number: values.invoice_id ? values.invoice_id : '',
      order_guids: values.orders.map((item) => item.guid),
    },
    {
      enabled: isQbdAvailable,
    },
  );

  useEffect(() => {
    const isAllSuccess = [emailStatus, qbdStatus, qboStatus].every(
      // `idle` state only happens if a query is initialized with enabled: false and no initial data is available.
      (s) => s === 'idle' || s === 'success',
    );
    if (isAllSuccess) {
      onClose();
      addSnackbar('Email sent successfully', { variant: 'success' });
    }
  }, [addSnackbar, emailStatus, onClose, qbdStatus, qboStatus]);

  const handleRefecth = (type: 'email' | 'qbo' | 'qbd') => {
    trackLoadsEvent({
      name: 'Carrier Clicked Try Again',
      sending_method: type,
    });
    switch (type) {
      case 'email':
        void refetch();
        break;
      case 'qbo':
        void qboRefetch();
        break;
      case 'qbd':
        void qbdRefetch();
        break;
    }
  };

  return (
    <Stack space="medium">
      {isEmailAvailable && (
        <LoadSendInvoiceStatusItem
          isLoading={isLoading}
          error={error}
          handleRetry={() => {
            handleRefecth('email');
          }}
          successText="Email sent successfully"
          loadingText="Sending Invoice to Email"
        />
      )}
      {isQboAvailable && (
        <LoadSendInvoiceStatusItem
          isLoading={isQboLoading}
          error={qboError}
          handleRetry={() => {
            handleRefecth('qbo');
          }}
          successText="QuickBooks Online sent successfully"
          loadingText="Sending QuickBooks Online"
        />
      )}
      {isQbdAvailable && (
        <LoadSendInvoiceStatusItem
          isLoading={isQbdLoading}
          error={qbdError}
          handleRetry={() => {
            handleRefecth('qbd');
          }}
          successText="QuickBooks Desktop sent successfully"
          loadingText="Sending QuickBooks Desktop"
        />
      )}
    </Stack>
  );
}
