import {
  APIMutationOptions,
  APIMutationResult,
  useAPIMutation,
} from 'shared/api/APIMutation';
import { APIQueryResult, useAPIQuery } from 'shared/api/APIQuery';
import { requestCarrierAPI } from 'shared/api/CarrierAPIClient';
import { SaveSplitLoadDTO, splitLoadDTO, SplitLoadDTO } from './SplitLoadsDTO';

export function useOrder(
  guid: null | string | undefined,
): APIQueryResult<SplitLoadDTO> {
  return useAPIQuery(
    ['orders', 'item', { guid }],
    () => requestCarrierAPI(['GET /internal/web/orders/{guid}/', { guid }]),
    {
      enabled: !!guid,
      schema: splitLoadDTO,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
    },
  );
}

export function useSaveSplitedLoad(
  loadGUID: string,
  options?: APIMutationOptions<SaveSplitLoadDTO>,
): APIMutationResult<SaveSplitLoadDTO> {
  return useAPIMutation(
    (loads) =>
      requestCarrierAPI(
        [
          'POST /internal/web/orders/{guid}/split-by-vehicle/',
          { guid: loadGUID },
        ],
        { json: loads },
      ),
    options,
  );
}
