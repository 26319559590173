import { useState } from 'react';
import { useCarrierSettingsCache } from 'shared/settings/CarrierSettingsAPI';
import { ExpeditedPayReminderDialog } from './ExpeditedPayReminderDialog';
import { ExpeditedPayReminderReasonCloseDialog } from './ExpeditedPayReminderReasonCloseDialog';

interface ExpeditedPayReminderDialogsProps {
  onClose: () => void;
  isOpen: boolean;
}

export function ExpeditedPayReminderDialogs({
  isOpen,
  onClose,
}: ExpeditedPayReminderDialogsProps) {
  const { invalidateCarrierSettings } = useCarrierSettingsCache();
  const [isOpenReasonDialog, setOpenReasonDialog] = useState(false);
  return (
    <>
      <ExpeditedPayReminderDialog
        isOpen={isOpen}
        onClose={onClose}
        onAlwaysClosed={() => {
          setOpenReasonDialog(true);
          onClose();
        }}
      />
      <ExpeditedPayReminderReasonCloseDialog
        isOpen={isOpenReasonDialog}
        onClose={() => {
          setOpenReasonDialog(false);
          invalidateCarrierSettings();
        }}
      />
    </>
  );
}
