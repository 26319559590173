import { BaseTextFieldProps, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { FocusEvent } from 'react';
import { Geocoding } from 'shared/geo/GeoHelpers';
import { useGeoFieldProps } from 'shared/geo/MapboxGeoField';

export interface AddressAutofillProps
  extends Pick<
    BaseTextFieldProps,
    'label' | 'fullWidth' | 'error' | 'helperText' | 'disabled' | 'placeholder'
  > {
  value: string;
  startIcon?: React.ReactNode;
  disableClearable?: boolean;
  onMouseOver?: () => void;
  onMouseLeave?: () => void;
  onBlur: (value: string) => void;
  onChange: (value: string) => void;
  onGeocode: (value: Geocoding | null) => void;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
}

export function AddressAutofill({
  value,
  onBlur,
  onFocus,
  onChange,
  onGeocode,
  disabled,
  startIcon,
  onMouseOver,
  onMouseLeave,
  disableClearable = true,
  ...textFieldProps
}: AddressAutofillProps) {
  const geoFieldProps = useGeoFieldProps({
    types: ['place', 'region', 'postcode', 'address'],
    formatOptionLabel: (option) => option.place_name || '',
  });

  return (
    <Autocomplete
      {...geoFieldProps}
      freeSolo={true}
      multiple={false}
      selectOnFocus={true}
      disableClearable={disableClearable}
      value={value || ''}
      disabled={disabled}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      onFocus={onFocus}
      onBlur={(event: FocusEvent<HTMLInputElement>) => {
        onBlur(event.target.value);
      }}
      onChange={(_, nextValue) => {
        if (typeof nextValue == 'string') {
          onChange(nextValue);
        } else {
          onGeocode(nextValue);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          {...textFieldProps}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password',
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment: startIcon,
          }}
        />
      )}
    />
  );
}
