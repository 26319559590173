import { Navigate, useMatch } from 'react-router-dom-v5-compat';
import { OffersPageDetails } from './OffersPageDetails';

export function OffersPage() {
  const offerRoute = useMatch('/offers/:guid');
  const offerGUID = offerRoute?.params.guid;

  if (!offerGUID) return <Navigate to="/loads" />;

  return <OffersPageDetails guid={offerGUID} />;
}
