import { IconButton, Typography } from '@material-ui/core';
import { Check, Close, Edit } from '@material-ui/icons';
import { FormikTextField, useFormikEnhanced } from '@superdispatch/forms';
import { Inline, useSnackbarStack } from '@superdispatch/ui';
import { Form, FormikProvider } from 'formik';
import { useState } from 'react';
import { useTripsAPI } from '../data/TripsAPI';
import { TripDTO } from '../data/TripsDTO';

export function TripDetailsName({ trip }: { trip: TripDTO | undefined }) {
  const [isEditing, setIsEditing] = useState(false);
  const { addSnackbar } = useSnackbarStack();
  const { editTrip } = useTripsAPI();

  const formik = useFormikEnhanced({
    key: isEditing,
    initialValues: {
      name: trip?.name || '',
    },
    onSubmit: (values) => {
      return editTrip(trip?.guid || '', {
        name: values.name,
      });
    },
    validate: (values) => {
      if (!values.name.trim()) {
        return {
          name: true, // we dont want to display error message but just highlight invalid field
        };
      }
      return {};
    },
    onSubmitFailure: (error) => {
      addSnackbar(error.message || 'Failed to update trip name', {
        variant: 'error',
      });
    },
    onSubmitSuccess: () => {
      setIsEditing(false);
    },
  });

  if (isEditing) {
    return (
      <FormikProvider value={formik}>
        <Form>
          <Inline verticalAlign="center" space="small">
            <FormikTextField
              name="name"
              required={true}
              error={!!formik.errors.name}
            />
            <IconButton
              size="small"
              type="submit"
              disabled={formik.isSubmitting}
            >
              <Check />
            </IconButton>
            <IconButton
              onClick={() => {
                setIsEditing(false);
                formik.resetForm();
              }}
              disabled={formik.isSubmitting}
              size="small"
            >
              <Close />
            </IconButton>
          </Inline>
        </Form>
      </FormikProvider>
    );
  }

  return (
    <Inline verticalAlign="center" space="small">
      <Typography variant="h2">{trip?.name}</Typography>
      <IconButton
        onClick={() => {
          setIsEditing(true);
        }}
        size="small"
      >
        <Edit />
      </IconButton>
    </Inline>
  );
}
