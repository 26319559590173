import { useFormikEnhanced } from '@superdispatch/forms';
import { Column, Columns, useSnackbarStack } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { APIError } from 'shared/api/APIError';
import { APIResponse } from 'shared/api/CarrierAPIClient';
import { isWebView } from 'shared/constants/AppTypeConstants';
import { useInitialValues } from 'shared/form/AppFormik';
import { DocumentTitle } from 'shared/layout/DocumentTitle';
import { ProfileUSDOTForm } from 'shared/modules/verified-carrier-application/core/ProfileUSDOTForm';
import { trackCarrierProfileEvent } from 'shared/modules/verified-carrier-application/data/CarrierProfileAnalytics';
import {
  useCarrierProfileAPI,
  useUSDOT,
} from 'shared/modules/verified-carrier-application/data/CarrierProfileAPI';
import {
  USDOTDTO,
  USDOTSchema,
} from 'shared/modules/verified-carrier-application/data/CarrierProfileDTO';
import { USDOTPreview } from './core/USDOTPreview';
import {
  useSubmitVerificationDocument,
  VerificationDialogs,
} from './core/VerificationDialogs';
import { ProfilePageLayout } from './ProfilePageLayout';

export function ProfileUSDOTPage() {
  const { addSnackbar } = useSnackbarStack();
  const { data, isLoading } = useUSDOT({
    onError: () => {
      addSnackbar('Failed to fetch USDOT Certificate', {
        variant: 'error',
      });
    },
  });
  const { updateUSDOT } = useCarrierProfileAPI();
  const initialValues = useInitialValues(USDOTSchema, data);

  const formik = useFormikEnhanced<USDOTDTO, APIResponse>({
    initialValues,
    validationSchema: USDOTSchema,
    onSubmit: updateUSDOT,
    onSubmitSuccess: () => {
      addSnackbar('USDOT Certificate was successfully updated', {
        variant: 'success',
      });

      trackCarrierProfileEvent({
        name: 'Carrier Updated Carrier Info',
        utm_medium: 'Carrier Profile',
        utm_content: 'USDOT Certificate',
      });
    },
    onSubmitFailure: ({ message }: APIError) => {
      addSnackbar(`Failed to update USDOT Certificate: ${message}`, {
        variant: 'error',
      });
    },
  });

  const submitVerificationForm = useSubmitVerificationDocument(formik);

  return (
    <>
      <DocumentTitle title="USDOT Certificate - Profile" />

      <VerificationDialogs formik={formik} />

      <ProfilePageLayout
        formik={formik}
        size={isWebView ? 'small' : 'medium'}
        title="USDOT Certificate"
        isLoading={isLoading}
        headerEndActions={
          <Button
            type="button"
            onClick={submitVerificationForm}
            pending={formik.isSubmitting}
            disabled={!formik.dirty}
            fullWidth={isWebView}
          >
            Save
          </Button>
        }
      >
        <Columns space="xxlarge" collapseBelow="desktop">
          <Column>
            <Box maxWidth="496px" paddingTop={isWebView ? 'small' : 'xxlarge'}>
              <ProfileUSDOTForm />
            </Box>
          </Column>

          {!isWebView && (
            <Column>
              <USDOTPreview />
            </Column>
          )}
        </Columns>
      </ProfilePageLayout>
    </>
  );
}
