import { IconButton, Toolbar } from '@material-ui/core';
import { Add, ArrowBack } from '@material-ui/icons';
import {
  CardButton,
  Column,
  Columns,
  Inline,
  Stack,
  useSnackbarStack,
} from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { atom, useAtomValue } from 'jotai';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import { useBoolean } from 'shared/hooks/useBoolean';
import { PageLayout } from 'shared/layout/PageLayout';
import styled from 'styled-components';
import { TripsDetailsActions } from './core/TripDetailsActions';
import { TripDetailsAttachment } from './core/TripDetailsAttachments';
import { TripDetailsDriverContent } from './core/TripDetailsDriverContent';
import { TripDetailsInternalNotes } from './core/TripDetailsInternalNotes';
import { TripDetailsName } from './core/TripDetailsName';
import { TripDetailsPayment } from './core/TripDetailsPayment';
import { TripDetailsTable } from './core/TripDetailsTable';
import { TripLoadDialog } from './core/TripLoadDialog';
import { useTrip } from './data/TripsAPI';

const AddLoadCardButton = styled(CardButton)`
  min-height: 140px;
`;

export function TripsDetails() {
  const loadDialog = useBoolean();
  const { guid = '' } = useParams<{ guid: string }>();
  const { addSnackbar } = useSnackbarStack();
  const navigate = useNavigate();

  const { data: trip, isLoading } = useTrip(guid, {
    onError: (error) => {
      addSnackbar(
        error.message || 'Error occurred while fetching trip details',
        {
          variant: 'error',
        },
      );
    },
  });

  if (!trip) {
    return <PageLayout loading={isLoading} />;
  }

  return (
    <PageLayout
      loading={isLoading}
      stickyHeader={true}
      disablePaddings={true}
      header={
        <Toolbar>
          <Columns space="small">
            <Column width="fluid">
              <Inline verticalAlign="center">
                <IconButton
                  size="small"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <ArrowBack />
                </IconButton>
                <TripDetailsName trip={trip} />
              </Inline>
            </Column>

            <Column width="content">
              <TripsDetailsActions trip={trip} onAddLoad={loadDialog.setTrue} />
            </Column>
          </Columns>
        </Toolbar>
      }
    >
      <Box padding="small" paddingLeft="medium" paddingRight="medium">
        <Stack space="small">
          <Columns space="small" collapseBelow="desktop">
            <Column width="2/3">
              <Stack space="small">
                {trip.loads?.length ? (
                  <Box
                    minWidth="500px"
                    overflowY="auto"
                    borderWidth="small"
                    borderRadius="small"
                    borderColor="Silver400"
                    data-intercom-target="trip-loads-table"
                  >
                    <TripDetailsTable
                      path={`/trips/view/${guid}`}
                      tripGUID={guid}
                      loads={trip.loads}
                      isTripArchived={!!trip.archived}
                      route={trip.route}
                    />
                  </Box>
                ) : (
                  <AddLoadCardButton
                    startIcon={<Add />}
                    onClick={loadDialog.setTrue}
                    data-intercom-target="trip-loads-table"
                  >
                    Add Route
                  </AddLoadCardButton>
                )}

                <Columns
                  space="small"
                  data-intercom-target="trip-attachments-and-notes"
                >
                  <Column width="1/2">
                    <TripDetailsAttachment trip={trip} />
                  </Column>
                  <Column width="1/2">
                    <TripDetailsInternalNotes trip={trip} />
                  </Column>
                </Columns>
              </Stack>
            </Column>
            <Column width="1/3">
              <Stack space="small">
                <TripDetailsDriverContent trip={trip} />
                <TripDetailsPayment trip={trip} />
              </Stack>
            </Column>
          </Columns>
        </Stack>
      </Box>

      {/* Extra space to allow scrolling past content obscured by the Intercom popup */}
      <Box height="100px" />

      <TripLoadDialog
        open={loadDialog.isEnabled}
        onClose={loadDialog.setFalse}
        trip={trip}
      />
    </PageLayout>
  );
}

export const hoveredFieldNameAtom = atom('');
export function useHoveredFieldNameValue() {
  return useAtomValue(hoveredFieldNameAtom);
}
