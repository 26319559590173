import { Color } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import styled from 'styled-components';

export const ExpeditedPayBannerGradientBox = styled(Box)`
  border: 1.5px solid ${Color.Silver400};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 52px;
  padding: 15px;
  position: relative;
  z-index: 1;
  &:before {
    content: '';
    background: linear-gradient(
      90deg,
      #ffe6cea6 0%,
      #fbe5ffa6 10%,
      #c8ffff00 28%
    );
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  &:after {
    content: '';
    background: linear-gradient(
      270deg,
      #ffe6cea6 0%,
      #fbe5ffa6 10%,
      #c8ffff00 28%
    );
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
`;
