import { Inline } from '@superdispatch/ui';
import { useSearchParam } from 'shared/helpers/SearchParamsHelpers';
import { DriverInvitationDTO } from '../data/DriverDTO';
import { DriverRequestContent } from './DriverRequestContent';
import { DriverRequestDeclinedContent } from './DriverRequestDeclinedContent';

interface ExistingDriverContentProps {
  data: DriverInvitationDTO;
}

export function ExistingDriverContent({ data }: ExistingDriverContentProps) {
  const [status, setStatus] = useSearchParam('status');

  return (
    <Inline horizontalAlign="center">
      {status === 'declined' ? (
        <DriverRequestDeclinedContent />
      ) : (
        <DriverRequestContent data={data} onStatusUpdate={setStatus} />
      )}
    </Inline>
  );
}
