import { Typography } from '@material-ui/core';
import { useFormikEnhanced } from '@superdispatch/forms';
import { Inline, Stack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom-v5-compat';
import styled from 'styled-components';
import { OnboardingCheckboxField } from './core/OnboardingCheckboxField';
import { OnboardingLayout } from './core/OnboardingLayout';
import { useSaveOnboardingWithErrorHandler } from './data/useSaveOnboardingWithErrorHandler';

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const OPTIONS = [
  {
    label: "I'm new to Super Dispatch",
    name: 'new',
  },
  {
    label: 'I’ve used Super Loadboard to find loads',
    name: 'used_loadboard',
  },
  {
    label: 'I’ve managed loads with Super Dispatch',
    name: 'managed_loads',
  },
  {
    label: 'I’ve used the Super Dispatch mobile Driver App',
    name: 'used_driver_app',
  },
];

export function OnboardingQuestionaireStep3Page() {
  const navigate = useNavigate();
  const { mutateAsync: saveStep } = useSaveOnboardingWithErrorHandler();

  const formik = useFormikEnhanced({
    initialValues: {
      new: false,
      used_loadboard: false,
      managed_loads: false,
      used_driver_app: false,
    },
    onSubmit: (values) => {
      const selectedOptions = Object.keys(values).filter(
        (key: keyof typeof values) => values[key],
      );
      const unselectedOptions = Object.keys(values).filter(
        (key: keyof typeof values) => !values[key],
      );

      return saveStep({
        stepName: 'question_experience_with_ctms',
        status: 'completed',
        data: {
          selected_options: selectedOptions.map(
            (s) => OPTIONS.find((o) => o.name === s)?.label,
          ),
          unselected_options: unselectedOptions.map(
            (s) => OPTIONS.find((o) => o.name === s)?.label,
          ),
        },
      });
    },
    onSubmitSuccess: () => {
      navigate('/onboarding/invite-team');
    },
  });

  return (
    <OnboardingLayout progress={65}>
      <FormikProvider value={formik}>
        <Form>
          <Stack space="large">
            <Stack space="xsmall" align="center">
              <Typography variant="h2">
                How much experience do you have with Super Dispatch?
              </Typography>
              <Typography color="textSecondary">
                This will help us personalize your experience.
              </Typography>
            </Stack>
            <Stack space="small">
              <Container>
                {OPTIONS.map((option) => (
                  <OnboardingCheckboxField
                    disabled={
                      option.name === 'new'
                        ? formik.values.managed_loads ||
                          formik.values.used_driver_app ||
                          formik.values.used_loadboard
                        : formik.values.new
                    }
                    key={option.name}
                    name={option.name}
                    label={option.label}
                    shouldShowCheckbox={true}
                    selected={
                      formik.values[option.name as keyof typeof formik.values]
                    }
                  />
                ))}
              </Container>
            </Stack>
            <Inline horizontalAlign="center">
              <Button
                type="submit"
                size="large"
                disabled={!Object.values(formik.values).some(Boolean)}
                pending={formik.isSubmitting}
              >
                Next
              </Button>
            </Inline>
          </Stack>
        </Form>
      </FormikProvider>
    </OnboardingLayout>
  );
}
