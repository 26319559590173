import { Typography } from '@material-ui/core';
import { useFormikEnhanced } from '@superdispatch/forms';
import { useSnackbarStack } from '@superdispatch/ui';
import { Form, FormikProvider } from 'formik';
import { DateTime } from 'luxon';
import { useCallback } from 'react';
import { useVerifiedCarrierApplicationStatus } from 'shared/modules/carrier-profile/VerifiedCarrierApplicationStatus';
import {
  CallAction,
  CodeValidationContainer,
  SmsAction,
} from './CodeValidationContainer';
import { trackPhoneVerificationEvent } from './data/PhoneVerificationAnalytics';
import {
  useCarrierFmcsaPhoneNumber,
  useCarrierProfileApi,
  VerificationCodeMethod,
} from './data/PhoneVerificationAPI';
import { verificationCodeSubmittingSchema } from './data/PhoneVerificationDTO';
import {
  CodeValidationFormStatus,
  getVerificationFormikErrors,
  useErrorMessage,
} from './PhoneVerificationErrorMessage';
import { PhoneVerificationPlaceholder } from './PhoneVerificationPlaceholder';

export interface PhoneVerificationCodeValidationProps {
  method: VerificationCodeMethod;
  retryDate: DateTime | undefined;
  onCodeSend: (method: VerificationCodeMethod) => void;
  onComplete: () => void;
  isCodeRequestLoading: boolean;
  requestError: Error | null;
}

export function PhoneVerificationCodeValidation({
  method,
  retryDate,
  onCodeSend,
  isCodeRequestLoading,
  requestError,
  onComplete,
}: PhoneVerificationCodeValidationProps) {
  const { addSnackbar } = useSnackbarStack();

  const { data: verificationStatus } = useVerifiedCarrierApplicationStatus();
  const { data: fmcsaPhoneInfo } = useCarrierFmcsaPhoneNumber();
  const { verifyCode } = useCarrierProfileApi();

  const formik = useFormikEnhanced({
    validationSchema: verificationCodeSubmittingSchema,
    getFormErrors: getVerificationFormikErrors,
    initialValues: {
      code: '',
    },
    onSubmit: (values) => {
      return verifyCode(values);
    },
    onSubmitSuccess: () => {
      addSnackbar('Your phone number was verified successfully', {
        variant: 'success',
      });
      trackPhoneVerificationEvent({
        name: 'Carrier Submitted Phone Verification Code',
        method,
        is_successful: true,
        carrier_verification_status:
          verificationStatus?.verified_carrier_application.status,
      });
      onComplete();
    },
    onSubmitFailure: () => {
      trackPhoneVerificationEvent({
        name: 'Carrier Submitted Phone Verification Code',
        method,
        is_successful: false,
        carrier_verification_status:
          verificationStatus?.verified_carrier_application.status,
      });
    },
  });

  const onCodeSendHandler = useCallback(
    (verificationMethod: VerificationCodeMethod) => {
      onCodeSend(verificationMethod);
      formik.resetForm();
    },
    [formik, onCodeSend],
  );

  const errorMessage = useErrorMessage(
    formik.status as CodeValidationFormStatus,
    requestError,
    method,
  );

  if (!fmcsaPhoneInfo) {
    return <PhoneVerificationPlaceholder />;
  }

  return (
    <FormikProvider value={formik}>
      <Form>
        {method === 'SMS' ? (
          <CodeValidationContainer
            retryDate={retryDate}
            details={
              <>
                <Typography color="textSecondary">
                  Enter the 6-digit verification code sent via text to:
                </Typography>
                <Typography variant="body1">{fmcsaPhoneInfo.number}</Typography>
              </>
            }
            retryText="Resend code"
            onRetry={() => {
              onCodeSendHandler('SMS');
            }}
            alternativeAction={
              <CallAction
                onClick={() => {
                  onCodeSendHandler('CALL');
                }}
                hasError={!!errorMessage}
                retryDate={retryDate}
              />
            }
            isLoading={isCodeRequestLoading}
            errorMessage={errorMessage}
          />
        ) : (
          <CodeValidationContainer
            retryDate={retryDate}
            details={
              <>
                <Typography color="textSecondary">
                  Shortly, you will receive a phone call and a code at:
                </Typography>
                <Typography variant="body1">{fmcsaPhoneInfo.number}</Typography>
              </>
            }
            retryText="Request another call"
            onRetry={() => {
              onCodeSendHandler('CALL');
            }}
            alternativeAction={
              <SmsAction
                onClick={() => {
                  onCodeSendHandler('SMS');
                }}
                hasError={!!errorMessage}
                retryDate={retryDate}
              />
            }
            isLoading={isCodeRequestLoading}
            errorMessage={errorMessage}
          />
        )}
      </Form>
    </FormikProvider>
  );
}
