import { ReactNode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { CompatRouter } from 'react-router-dom-v5-compat';

export const basePath: string = import.meta.env.BASE_URL;

export interface AppRouterProviderProps {
  children?: ReactNode;
}

export function AppRouterProvider({ children }: AppRouterProviderProps) {
  return (
    <BrowserRouter basename={basePath}>
      <CompatRouter>{children}</CompatRouter>
    </BrowserRouter>
  );
}
