import { Link, Typography } from '@material-ui/core';
import { Lock, MonetizationOn } from '@material-ui/icons';
import { FormikTextField } from '@superdispatch/forms';
import { Column, Columns, Stack } from '@superdispatch/ui';
import { Box, DescriptionItem } from '@superdispatch/ui-lab';
import { useField } from 'formik';
import { useAppFormik } from 'shared/form/AppFormik';
import { performNavigation } from 'shared/routing/NavigationBlock';
import { hasOnlyDigits } from 'shared/utils/StringUtils';
import { yupObject, yupString } from 'shared/utils/YupUtils';
import { StringSchema } from 'yup';
import routingAccountIllustration from '../assets/routing_account.png';
import { FormikRoutingNumberField } from '../core/FormikRoutingNumberField';
import { MoovActionError } from '../data/MoovDTO';
import { trackSuperPayEvent } from '../data/SuperPayAnalytics';
import { useSuperPayCreateBankAccountAction } from '../data/useSuperPayCreateBankAccountAction';
import { logPaymentInfo } from '../helpers/PaymentLogger';

const NUMBERS_MISMATCH_ERROR = "Numbers don't match";

const bankAccountValidationSchema = yupObject({
  holderName: yupString().required(),
  routingNumber: yupString()
    .required()
    .length(9, 'Routing number must have 9 digits')
    .test('only digits', 'Invalid routing number', hasOnlyDigits),
  accountNumber: yupString()
    .required()
    .min(5, 'Account number must have more than 4 digits')
    .max(17, 'Account number must have 17 or less digits')
    .test('only digits', 'Invalid bank account number', hasOnlyDigits),
  confirmAccountNumber: yupString()
    .when(
      'accountNumber',
      (accountNumber: string | undefined, schema: StringSchema) =>
        schema.test(
          'match',
          NUMBERS_MISMATCH_ERROR,
          (confirmAccountNumber) => accountNumber === confirmAccountNumber,
        ),
    )
    .required(),
});

interface Props {
  onComplete: () => void;
  onSettled: () => void;
}

export function useBankAccountForm({ onComplete, onSettled }: Props) {
  const createBankAccount = useSuperPayCreateBankAccountAction();

  return useAppFormik({
    validationSchema: bankAccountValidationSchema,
    onSubmit: ({ holderName, accountNumber, routingNumber }) => {
      return createBankAccount({
        holderName,
        accountNumber,
        routingNumber,
      });
    },
    onSubmitSuccess: () => {
      trackSuperPayEvent({
        name: 'Carrier Submitted Bank Account',
      });

      logPaymentInfo('Carrier Submitted Bank Account', 'BankAccountForm');
      performNavigation(onComplete);
      onSettled();
    },
    getErrorMessage: (error: MoovActionError) => {
      if (error.statusCode === 403) {
        return 'Not authorized to add bank account.';
      }

      return `Failed to submit the form. ${error.message}`;
    },
    onSubmitFailure: () => {
      onSettled();
    },
  });
}

export function BankAccountForm() {
  const [_, { error, touched }] = useField('confirmAccountNumber');
  const hasAccountNumbersMatchError =
    error === NUMBERS_MISMATCH_ERROR && touched;

  return (
    <>
      <Stack space="small">
        <Stack space="xsmall">
          <DescriptionItem
            icon={<Lock />}
            label="Your personal data is encrypted and private."
            wrap={true}
          />
          <DescriptionItem
            icon={<MonetizationOn />}
            label={
              <Typography>
                We will make{' '}
                <Link
                  target="_blank"
                  rel="noreferrer"
                  href="https://support.superdispatch.com/en/articles/6985826-what-is-a-micro-deposit"
                  color="primary"
                >
                  three transactions
                </Link>{' '}
                into your bank account to verify it.
              </Typography>
            }
            wrap={true}
          />
        </Stack>

        <FormikTextField
          label="Account Holder Name"
          name="holderName"
          fullWidth={true}
        />

        <Box maxWidth={['100%', '70%']}>
          <FormikRoutingNumberField
            label={
              <>
                <Typography component="span">Routing Number</Typography>{' '}
                <Typography color="textSecondary" component="span">
                  (US banks only)
                </Typography>
              </>
            }
            name="routingNumber"
            fullWidth={true}
          />
        </Box>

        <Columns space="small" collapseBelow="tablet">
          <Column>
            <FormikTextField
              label="Account Number"
              name="accountNumber"
              helperText={
                hasAccountNumbersMatchError
                  ? ' '
                  : 'A 5-17 digit number next to the routing number'
              }
              fullWidth={true}
              error={hasAccountNumbersMatchError}
            />
          </Column>

          <Column>
            <FormikTextField
              label="Confirm Account Number"
              name="confirmAccountNumber"
              fullWidth={true}
            />
          </Column>
        </Columns>
      </Stack>

      <Box marginTop="large">
        <Stack>
          <Typography color="textSecondary">
            These numbers can be found at the bottom of a check or by contacting
            your bank.
          </Typography>

          <img
            src={routingAccountIllustration}
            width="292px"
            alt="routing account illustration"
          />
        </Stack>
      </Box>
    </>
  );
}
