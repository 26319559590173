import { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import {
  APIMutationOptions,
  APIMutationResult,
  useAPIMutation,
} from 'shared/api/APIMutation';
import { APIPageQueryResult, useAPIPageQuery } from 'shared/api/APIPageQuery';
import { APIQueryResult, useAPIQuery } from 'shared/api/APIQuery';
import { APIResponse } from 'shared/api/CarrierAPIClient';
import {
  requestAPIGateway,
  requestAPIGatewayPublic,
} from 'shared/api/CarrierGatewayAPIClient';
import {
  ShipperNewRatingDTO,
  ShipperRatingDetailsDTO,
  shipperRatingDetailsSchema,
  ShipperRatingDTO,
  shipperRatingSchema,
  ShipperRatingsSummaryDTO,
  shipperRatingsSummarySchema,
} from './ShipperRatingDTO';

export function useShipperRatingDetails(
  guid: string | undefined,
): APIQueryResult<ShipperRatingDetailsDTO> {
  return useAPIQuery(
    ['shippers', 'ratings', { guid }],
    () =>
      requestAPIGatewayPublic([
        'GET /loadboard/shippers/{guid}/ratings/details',
        { guid },
      ]),
    {
      schema: shipperRatingDetailsSchema,
      enabled: !!guid,
    },
  );
}

export function useShipperRatingsAPI() {
  const queryClient = useQueryClient();

  return useMemo(
    () => ({
      rateShipper: (guid: string, data: ShipperNewRatingDTO) =>
        requestAPIGateway<APIResponse<ShipperRatingDTO>>(
          ['POST /loadboard/shippers/{guid}/ratings', { guid }],
          { json: data },
        ).then((response) => {
          void queryClient.invalidateQueries(['ratings']);
          void queryClient.invalidateQueries(['shippers']);
          return response;
        }),
    }),
    [queryClient],
  );
}

export function useLatestShipperRating(
  guid: string | undefined | null,
): APIQueryResult<ShipperRatingDTO> {
  return useAPIQuery(
    ['shippers', 'latest-rating', { guid }],
    () =>
      requestAPIGateway([
        'GET /loadboard/shippers/{guid}/ratings/latest',
        { guid },
      ]),
    {
      enabled: !!guid,
      schema: shipperRatingSchema,
      refetchOnWindowFocus: false,
    },
  );
}

export function useShipperRatingsSummary(
  guid: string,
): APIQueryResult<ShipperRatingsSummaryDTO> {
  return useAPIQuery(
    ['shippers', 'ratings', { guid }],
    () =>
      requestAPIGateway([
        'GET /loadboard/shippers/{guid}/ratings/summary',
        { guid },
      ]),
    {
      schema: shipperRatingsSummarySchema,
    },
  );
}

export function useShipperRatings(
  guid: string,
  params: { size: number; page: number },
): APIPageQueryResult<ShipperRatingDTO> {
  return useAPIPageQuery(
    ['shippers', 'ratings-list', { guid, params }],
    () =>
      requestAPIGateway([
        'GET /loadboard/shippers/{guid}/ratings{?params*}',
        {
          guid,
          params: {
            size: params.size,
            page: params.page - 1, // normalize page number as count start from 0 on backend
          },
        },
      ]),
    { schema: shipperRatingSchema },
  );
}

export function useDeleteNegativeRating(
  options?: APIMutationOptions<string>,
): APIMutationResult<string> {
  const queryClient = useQueryClient();

  return useAPIMutation(
    (guid) =>
      requestAPIGateway([
        'DELETE /loadboard/shippers/{guid}/ratings',
        { guid },
      ]).then((response) => {
        void queryClient.invalidateQueries(['shippers']);
        return response;
      }),
    options,
  );
}
