import {
  APIQueryInput,
  APIQueryOptions,
  APIQueryResult,
  useAPIQuery,
} from 'shared/api/APIQuery';
import { requestCarrierAPI } from 'shared/api/CarrierAPIClient';
import { DispatcherDTO, dispatcherSchema } from './DispatcherDTO';

export const DISPATCHER_PROFILE_CACHE_KEY: APIQueryInput = [
  'dispatchers',
  'profile',
];

export function useDispatcherProfile(
  options?: APIQueryOptions<DispatcherDTO>,
): APIQueryResult<DispatcherDTO> {
  return useAPIQuery(
    DISPATCHER_PROFILE_CACHE_KEY,
    () => requestCarrierAPI('GET /internal/web/dispatchers/profile/'),
    { ...options, schema: dispatcherSchema },
  );
}
