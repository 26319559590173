import { startCase } from 'lodash-es';
import { isEmpty } from 'shared/helpers/CommonHelpers';
import {
  yupBooleanParam,
  yupDateString,
  yupEnum,
  yupNumberParam,
  yupObject,
  yupStringParam,
} from 'shared/utils/YupUtils';
import { InferType, MixedSchema, NumberSchema, StringSchema } from 'yup';
import { isLoadStageComplete, LoadStage, LOAD_STAGES } from './LoadDTO';

//
// Filter

export const LOAD_FILTER_FIELDS = [
  'drivers',
  'dispatchers',
  'terminals',
] as const;

export type LoadFilterField = (typeof LOAD_FILTER_FIELDS)[number];

//
// Order By

export const LOAD_ORDER_BY_FIELDS = [
  'broker_name',
  'pickup_state',
  'delivery_state',
  'pickup_city',
  'delivery_city',
  'pickup_date',
  'pickedup_date',
  'delivery_date',
  'delivered_date',
  'superpay_status',
  'is_dispatched_to_carrier',
] as const;

export type LoadOrderByField = (typeof LOAD_ORDER_BY_FIELDS)[number];

export function isLoadOrderByField(value: unknown): value is LoadOrderByField {
  return (
    typeof value == 'string' &&
    LOAD_ORDER_BY_FIELDS.includes(value as LoadOrderByField)
  );
}

export function formatLoadOrderByField(field: LoadOrderByField): string {
  switch (field) {
    case 'is_dispatched_to_carrier':
      return 'Dispatched to Carrier';
    case 'superpay_status':
      return 'SuperPay';
    case 'broker_name':
      return 'Customer';
    default:
      return startCase(field);
  }
}

//
// Search

export const LOAD_SEARCH_FIELDS = [
  'vin',
  'number',
  'vehicle',
  'order_id',
  'customer',
  'invoice_id',
  'pickup_name',
  'delivery_name',
  'internal_load_id',
  'all',
] as const;

export type LoadSearchField = (typeof LOAD_SEARCH_FIELDS)[number];

export function isLoadSearchField(value: unknown): value is LoadSearchField {
  return (
    typeof value == 'string' &&
    LOAD_SEARCH_FIELDS.includes(value as LoadSearchField)
  );
}

export function isEmptyLoadsPageParamsSearch(params: LoadsPageParams): boolean {
  for (const key of LOAD_SEARCH_FIELDS) {
    if (!isEmpty(params[key])) {
      return false;
    }
  }

  return true;
}

export function findLoadsPageParamsSearchEntry(
  params: LoadsPageParams,
):
  | [undefined, undefined]
  | [LoadSearchField, LoadsPageParams[LoadSearchField]] {
  if (params.stage === 'active') {
    for (let key of LOAD_SEARCH_FIELDS) {
      if (!isEmpty(params[key])) {
        return [key, params[key]];
      }
    }
  }

  return [undefined, undefined];
}

//
// Params

export function formatLoadsPageField(field: keyof LoadsPageParams) {
  switch (field) {
    case 'number':
      return 'Load ID';
    case 'order_id':
      return 'Order ID';
    case 'invoice_id':
      return 'Invoice ID';
    case 'internal_load_id':
      return 'Internal Load ID';
    case 'vin':
      return 'VIN';
    case 'is_dispatched_to_carrier':
      return 'Dispatched to Carrier';
    case 'pickup_date_after':
    case 'pickup_date_before':
      return 'Pickup Date';
    case 'delivery_date_after':
    case 'delivery_date_before':
      return 'Delivery Date';
    case 'customer':
      return 'Shipper/Customer';
    default:
      return startCase(field);
  }
}

const stringParam = yupStringParam();
const booleanParam = yupBooleanParam();
const dateStringParam = yupDateString('DateISO');

export const loadsPageParamsSchema = yupObject({
  /** Internal variable to determine the params shape. */
  stage: yupEnum(LOAD_STAGES, 'new'),

  //
  // Filter Params

  page: yupNumberParam()
    .default(1)
    .transform((value) =>
      Math.max(1, Math.round(value)),
    ) as NumberSchema<number>,

  drivers: stringParam,
  dispatchers: stringParam,

  terminals: stringParam.when(
    'stage',
    (stage: LoadStage, schema: StringSchema) => {
      if (stage !== 'in_terminal') {
        return schema.transform(() => null);
      }

      return schema;
    },
  ),

  //
  // Search Params

  vin: stringParam,
  number: stringParam,
  vehicle: stringParam,
  order_id: stringParam,
  customer: stringParam,
  is_empty_customer: booleanParam,
  invoice_id: stringParam,
  pickup_name: stringParam,
  delivery_name: stringParam,
  internal_load_id: stringParam,
  all: stringParam,

  //
  // Order By Params

  order_by: yupEnum(LOAD_ORDER_BY_FIELDS, null).when(
    'stage',
    (stage: LoadStage, schema: MixedSchema) =>
      schema.transform(
        (value: null | LoadOrderByField): null | LoadOrderByField => {
          if (value != null) {
            const isPickedUp = isLoadStageComplete(stage, 'picked_up');

            if (value === 'pickup_date' && isPickedUp) {
              return 'pickedup_date';
            }

            if (value === 'pickedup_date' && !isPickedUp) {
              return 'pickup_date';
            }

            const isDelivered = isLoadStageComplete(stage, 'delivered');

            if (value === 'delivery_date' && isDelivered) {
              return 'delivered_date';
            }

            if (value === 'delivered_date' && !isDelivered) {
              return 'delivery_date';
            }
          }

          return value;
        },
      ),
  ),

  broker_name: stringParam,
  is_empty_broker_name: booleanParam,

  pickup_state: stringParam,
  is_empty_pickup_state: booleanParam,

  delivery_state: stringParam,
  is_empty_delivery_state: booleanParam,

  pickup_city: stringParam,
  is_empty_pickup_city: booleanParam,

  delivery_city: stringParam,
  is_empty_delivery_city: booleanParam,

  is_empty_pickup_date: booleanParam,
  pickup_date_after: dateStringParam,
  pickup_date_before: dateStringParam,

  is_empty_pickedup_date: booleanParam,
  pickedup_date_after: dateStringParam,
  pickedup_date_before: dateStringParam,

  is_empty_delivery_date: booleanParam,
  delivery_date_after: dateStringParam,
  delivery_date_before: dateStringParam,

  is_empty_delivered_date: booleanParam,
  delivered_date_after: dateStringParam,
  delivered_date_before: dateStringParam,

  is_dispatched_to_carrier: booleanParam,

  invoice_date_after: dateStringParam,
  invoice_date_before: dateStringParam,

  receipt_date_after: dateStringParam,
  receipt_date_before: dateStringParam,

  superpay_status: stringParam,
});

export type LoadsPageParams = InferType<typeof loadsPageParamsSchema>;

//
// DateTimeISO filter used when feature toggle is enabled.
//

const dateTimeStringParam = yupDateString('DateTimeISO');

export const loadsPageParamsSchemaWithDateTimeISO = loadsPageParamsSchema.shape(
  {
    pickup_date_after: dateTimeStringParam,
    pickup_date_before: dateTimeStringParam,

    pickedup_date_after: dateTimeStringParam,
    pickedup_date_before: dateTimeStringParam,

    delivery_date_after: dateTimeStringParam,
    delivery_date_before: dateTimeStringParam,

    delivered_date_after: dateTimeStringParam,
    delivered_date_before: dateTimeStringParam,

    invoice_date_after: dateTimeStringParam,
    invoice_date_before: dateTimeStringParam,

    receipt_date_after: dateTimeStringParam,
    receipt_date_before: dateTimeStringParam,
  },
);
