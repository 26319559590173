import { Dialog, TextField, Typography } from '@material-ui/core';
import {
  Column,
  Columns,
  Inline,
  Stack,
  useSnackbarStack,
} from '@superdispatch/ui';
import { Box, Button, TextBox } from '@superdispatch/ui-lab';
import { useState } from 'react';
import { trackEvent } from 'shared/helpers/Analytics';

interface ExpeditedPayReminderReasonCloseContentProps {
  onSkip: () => void;
  onSend: () => void;
  feedback: string;
  onChangeFeedback: (reason: string) => void;
}

function ExpeditedPayReminderReasonCloseContent({
  onSkip,
  onSend,
  feedback,
  onChangeFeedback,
}: ExpeditedPayReminderReasonCloseContentProps) {
  return (
    <Box
      paddingLeft="medium"
      paddingRight="medium"
      paddingTop="small"
      paddingBottom="small"
    >
      <Stack space="medium">
        <Stack space="small">
          <Typography variant="h3">
            Why do you not want to see it again?
          </Typography>
          <Typography>
            Your feedback matters to us! Can you let us know why you don’t want
            to see these reminders anymore?
          </Typography>
          <TextField
            value={feedback}
            onChange={(e) => {
              onChangeFeedback(e.target.value);
            }}
            multiline={true}
            fullWidth={true}
            label={
              <Columns>
                <Column>Reason</Column>
                <Column width="content">
                  <TextBox color="secondary">{feedback.length} of 100</TextBox>
                </Column>
              </Columns>
            }
            inputProps={{ maxLength: 100 }}
          />
        </Stack>
        <Stack align="right">
          <Inline space="small" verticalAlign="center">
            <Button variant="neutral" onClick={onSkip}>
              Skip
            </Button>
            <Button onClick={onSend}>Send</Button>
          </Inline>
        </Stack>
      </Stack>
    </Box>
  );
}

interface ExpeditedPayReminderReasonCloseDialogProps {
  onClose: () => void;
  isOpen: boolean;
}

export function ExpeditedPayReminderReasonCloseDialog({
  isOpen,
  onClose,
}: ExpeditedPayReminderReasonCloseDialogProps) {
  const { addSnackbar } = useSnackbarStack();
  const [feedback, setFeedback] = useState('');

  const onSendReason = () => {
    trackEvent(
      'Carrier Clicked Don’t Show Me Again on Expedited Payment Reminder',
      { feedback: feedback || 'No Feedback' },
    );
    addSnackbar('Feedback Sent & Expedited Payment Reminders Turned Off.');
    onClose();
  };

  const onSkip = () => {
    trackEvent(
      'Carrier Clicked Don’t Show Me Again on Expedited Payment Reminder',
      { feedback: 'Empty Field' },
    );
    addSnackbar('Expedited Payment Reminders Turned Off.');
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onSkip} maxWidth="xs">
      <ExpeditedPayReminderReasonCloseContent
        onSkip={onSkip}
        onSend={onSendReason}
        feedback={feedback}
        onChangeFeedback={setFeedback}
      />
    </Dialog>
  );
}
