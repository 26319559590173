import { createSvgIcon } from 'shared/icons/IconUtils';

export const Logo = createSvgIcon(
  'FullLogoIcon',

  <path
    fill="#fe4a49"
    fillRule="evenodd"
    d="M17.5 142A238 238 0 01232 0c92.2 0 178.6 57.2 214.5 142 40.3 95 5.8 190-51.3 269.4a876.6 876.6 0 01-151.4 160c-.8.9-2 .7-2.8-.1-5-5.6-9.8-11.4-14.5-17.2l-9-10.8c-.8-.8-.6-2.1.2-2.8a913.4 913.4 0 00135.6-139c37.5-48.3 70.7-106 70.7-168.9 0-103.5-88.4-191.9-192.2-191.9A194.2 194.2 0 0039.6 232.6c0 31.8 13.5 85.3 78.1 159.4 26 30 52.4 53.7 67.4 66.6a912 912 0 0072.1-73c53.3-61.1 81.5-114 81.5-153a107.1 107.1 0 00-214.2 0c0 52.8 46.5 94.8 83.2 127.9l4.5 4c.8.8.8 2 .1 2.9l-24.6 27c-.7.9-2 .9-2.8.2-46.7-42.3-101-94-101-162C84 154 153.3 84.9 232 84.9c79.8 0 147.8 67.9 147.8 147.7 0 49.7-30.9 110.4-91.8 180a902.3 902.3 0 01-100.7 97.8 2 2 0 01-2.5 0 784 784 0 01-97.4-92 461 461 0 01-59.8-84.8c-32-60.6-37-127.9-10-191.6zM232 169.5a63.2 63.2 0 110 126.4 63.2 63.2 0 010-126.4zm-27.7 63.1a27.7 27.7 0 0055.4 0 27.7 27.7 0 00-55.4 0z"
  />,
  {
    viewBox: '0 0 464 572',
    style: { height: '44px', width: '36px' },
  },
);
