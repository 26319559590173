import { LinearProgress, Link, Typography } from '@material-ui/core';
import { Stack, useResponsiveValue } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { useNavigate } from 'react-router-dom-v5-compat';
import { isWebView } from 'shared/constants/AppTypeConstants';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import {
  Declined,
  PendingVerification,
  Reviewed,
  Revoked,
} from 'shared/modules/carrier-profile/core/VerificationBadge';
import { VerifiedCarrierApplicationStatusDTO } from 'shared/modules/carrier-profile/VerifiedCarrierApplicationStatusDTO';
import { LinkButton } from 'shared/routing/Links';
import styled from 'styled-components';

// Temporary fix for word break inside Button.
// Component is used in the Sidebar header.
// The bug should be investigated because
// there wasn't issue in Figma design.
const StyledLinkButton = styled(LinkButton)`
  padding-left: 14px;
  padding-right: 14px;
`;

const Progress = styled(LinearProgress)`
  height: 8px;
  border-radius: 4px;

  & > div {
    border-radius: 4px;
  }
`;

function ApplicationProgress({ value }: { value: number }) {
  const isMobile = useResponsiveValue(true, false);

  return (
    <Stack>
      <Typography variant="h5">{value}% Application Complete</Typography>

      {!isMobile && <Progress variant="determinate" value={value} />}

      <Typography color="textSecondary">
        Apply to become a Verified Carrier to book loads on the Super Loadboard.{' '}
      </Typography>

      <Link
        href="https://support.superdispatch.com/en/articles/2644754-what-is-a-super-carrier"
        target="_blank"
        rel="noopener noreferrer"
      >
        Learn More
      </Link>

      {isMobile && <Progress variant="determinate" value={value} />}

      <StyledLinkButton
        variant="primary"
        fullWidth={isMobile}
        size={isMobile ? 'small' : 'medium'}
        to="/profile/verified-carrier-application/step-1?utm_medium=carrier_profile"
      >
        Become Verified Carrier
      </StyledLinkButton>
    </Stack>
  );
}

function ApplicationPending() {
  return (
    <Stack>
      {!isWebView && <PendingVerification />}

      <Typography gutterBottom={true} color="textSecondary">
        Your application will be reviewed within 24-48 hours. We will keep you
        informed of any updates.
      </Typography>

      <Button
        fullWidth={true}
        size="small"
        variant="neutral"
        onClick={() => {
          openExternalURL(
            'https://support.superdispatch.com/en/articles/2644754',
            { target: '_blank' },
          );
        }}
      >
        Learn More
      </Button>
    </Stack>
  );
}

function ApplicationReviewed() {
  const navigate = useNavigate();

  return (
    <Stack>
      {!isWebView && <Reviewed />}

      <Typography color="textSecondary">
        To expedite insurance verification, ask your agent to send a Certificate
        of Insurance (COI) listing Super Dispatch as a Certificate Holder, then
        upload the COI in Cargo Insurance.
      </Typography>

      <Button
        fullWidth={true}
        size="small"
        variant="neutral"
        onClick={() => {
          navigate(
            isWebView ? '/profile/cargo-insurance' : '/profile/cargo_insurance',
          );
        }}
      >
        View
      </Button>
    </Stack>
  );
}

function ApplicationNotVerifiable() {
  return (
    <Stack aria-label="non-verifiable details">
      <Typography color="textSecondary">
        Super Dispatch can only verify Auto Haulers, Towaway and Driveaway
        carriers based in the U.S. You can continue to book loads from the Super
        Loadboard for shippers who have approved you to request or instantly
        book.
      </Typography>

      <Button
        fullWidth={true}
        size="small"
        variant="neutral"
        onClick={() => {
          openExternalURL(
            'https://support.superdispatch.com/en/articles/6516658-why-can-t-my-documents-be-verified',
            { target: '_blank' },
          );
        }}
      >
        Learn More
      </Button>
    </Stack>
  );
}

export function VerifiedCarrierApplicationStatus({
  verified_carrier_application,
}: VerifiedCarrierApplicationStatusDTO) {
  const { progress, status } = verified_carrier_application;

  if (status === 'not_submitted') {
    return <ApplicationProgress value={progress.percentage} />;
  }

  if (status === 'pending') {
    return <ApplicationPending />;
  }

  if (status === 'reviewed') {
    return <ApplicationReviewed />;
  }

  if (status === 'revoked' && !isWebView) {
    return <Revoked />;
  }

  if (status === 'declined' && !isWebView) {
    return <Declined />;
  }

  if (status === 'non_verifiable') {
    return <ApplicationNotVerifiable />;
  }

  return null;
}
