import { Link, Typography } from '@material-ui/core';
import { DocumentPreview } from 'shared/modules/verified-carrier-application/core/DocumentPreview';
import { ImageMarker } from 'shared/modules/verified-carrier-application/core/ImageMarker';
import w9Example from '../assets/w9Example.png';

export function W9Preview() {
  return (
    <DocumentPreview
      title="W-9 Form Example Preview"
      imgSrc={w9Example}
      header={
        <Typography variant="inherit" paragraph={true}>
          For more detailed instructions refer to{' '}
          <Link
            href="https://support.superdispatch.com/en/articles/3112938-how-do-i-become-a-verified-carrier"
            target="_blank"
            rel="noreferrer"
            color="primary"
          >
            our support article.
          </Link>
        </Typography>
      }
    >
      <ImageMarker
        top="14%"
        left="36%"
        label="1"
        title={
          <Typography align="center">
            The company name on W9 Form matches the company name on your Super
            Dispatch account
          </Typography>
        }
      />

      <ImageMarker
        top="47%"
        left="64%"
        label="2"
        title={
          <Typography align="center">
            Taxpayer Identification Number is filled in Part 1
          </Typography>
        }
      />

      <ImageMarker
        top="69%"
        left="50%"
        label="3"
        title={
          <Typography align="center">
            The document is signed and dated properly
          </Typography>
        }
      />

      <ImageMarker
        top="72%"
        left="72%"
        label="4"
        title={
          <Typography align="center">
            Signed no older than two years prior to the present date
          </Typography>
        }
      />
    </DocumentPreview>
  );
}
