import {
  APIQueryOptions,
  APIQueryResult,
  useAPIQuery,
} from 'shared/api/APIQuery';
import { requestCarrierAPI } from 'shared/api/CarrierAPIClient';
import { TEN_MINUTES } from 'shared/constants/NumberConstants';
import { NotificationVariant } from 'shared/layout/notification-stack/data/NotificationDTO';

export function useNotifications({
  staleTime = TEN_MINUTES,
  ...options
}: APIQueryOptions<NotificationVariant[]> = {}): APIQueryResult<
  NotificationVariant[]
> {
  return useAPIQuery(
    'notifications',
    () => requestCarrierAPI('GET /internal/web/notifications/'),
    { ...options, staleTime },
  );
}
