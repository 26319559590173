import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { useFormikEnhanced } from '@superdispatch/forms';
import { useSnackbarStack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom-v5-compat';
import styled from 'styled-components';
import { trackDispatchersEvent } from '../data/DispatchersAnalytics';
import { useDispatchersAPI } from '../data/DispatchersAPI';
import { useDispatcherPageContext } from '../data/DispatchersProvider';

interface DispatcherDeletionDialogProps {
  open: boolean;
  onClose: () => void;
}

const List = styled.ul`
  padding-inline-start: 20px;
  margin: 0;
`;

export function DispatcherDeletionDialog({
  open,
  onClose,
}: DispatcherDeletionDialogProps) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DispatcherDeletionDialogContent onClose={onClose} />
    </Dialog>
  );
}

interface DispatcherDeletionDialogContentProps {
  onClose: () => void;
}

function DispatcherDeletionDialogContent({
  onClose,
}: DispatcherDeletionDialogContentProps) {
  const navigate = useNavigate();
  const { addSnackbar } = useSnackbarStack();
  const { deleteDispatcher } = useDispatchersAPI();
  const { dispatcher, currentDispatcherGUID } = useDispatcherPageContext();
  const { data } = dispatcher;

  const formik = useFormikEnhanced({
    initialValues: {},
    onSubmit: () => {
      if (currentDispatcherGUID) {
        return deleteDispatcher(currentDispatcherGUID);
      }
      return Promise.reject(new Error('Please select dispatcher!'));
    },
    onSubmitSuccess: () => {
      trackDispatchersEvent({ name: 'Carrier Deleted Dispatcher' });
      addSnackbar(`${data?.name || 'Dispatcher'} deleted`);
      navigate('/dispatchers');
      onClose();
    },
    onSubmitFailure: (error) => {
      addSnackbar(error.message, { variant: 'error' });
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <DialogTitle>Delete {data?.name}?</DialogTitle>
        <DialogContent>
          <List>
            <li>Removing a dispatcher is permanent. This cannot be undone.</li>
            <li>
              This dispatcher will be removed from your list of dispatchers.
            </li>
            <li>
              The loads managed by this dispatcher will not be deleted but will
              be unassigned from them.
            </li>
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            variant="neutral"
            onClick={onClose}
            disabled={formik.isSubmitting}
          >
            Cancel
          </Button>
          <Button
            pending={formik.isSubmitting}
            variant="critical"
            type="submit"
          >
            Delete Dispatcher
          </Button>
        </DialogActions>
      </Form>
    </FormikProvider>
  );
}
