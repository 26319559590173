import {
  IconButton,
  InputAdornment,
  ListItem,
  ListItemText,
  TextField,
} from '@material-ui/core';
import { Add, Clear, CloudUpload, Search } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { Stack, VisibilityObserver } from '@superdispatch/ui';
import {
  Box,
  Button,
  Sidebar,
  SidebarMenuItem,
  SidebarMenuItemAvatar,
  TextBox,
} from '@superdispatch/ui-lab';
import { useEffect, useState } from 'react';
import { useSearchQueryText } from 'shared/helpers/ReactHelpers';
import { performNavigation } from 'shared/routing/NavigationBlock';
import { ContactFormDrawer } from './ContactFormDrawer';
import { ContactsImportDrawer } from './ContactsImportDrawer';
import { useContactsPageRouter } from './ContactsPageRouter';
import { useContactsCache, useContactsList } from './data/ContactsAPI';

export interface ContactsPageListProps {
  selectedContacts: Set<string>;
  onSelectedContactsChange: (value: Set<string>) => void;
}

export function ContactsPageList({
  selectedContacts,
  onSelectedContactsChange,
}: ContactsPageListProps) {
  const [searchText, searchQuery, setSearchText] = useSearchQueryText();
  const contactList = useContactsList({ q: searchQuery, page_size: 20 });
  const { openContact, currentContactGUID } = useContactsPageRouter();
  const { findNextContact } = useContactsCache();

  const [currentModal, setCurrentModal] = useState<'add' | 'import'>();

  const closeModal = () => {
    setCurrentModal(undefined);
  };

  useEffect(() => {
    if (currentContactGUID) return;

    const nextGUID = findNextContact();
    if (nextGUID) {
      openContact(nextGUID, true);
    }
  }, [currentContactGUID, findNextContact, openContact, contactList]);

  return (
    <>
      <ContactFormDrawer
        type="add"
        onClose={closeModal}
        open={currentModal === 'add'}
        onSuccess={(contact) => {
          closeModal();
          performNavigation(() => {
            openContact(contact.guid);
          });
        }}
      />

      <ContactsImportDrawer
        onClose={closeModal}
        open={currentModal === 'import'}
      />

      <Sidebar
        title="Contacts"
        header={
          <Stack space="small">
            <Button
              fullWidth={true}
              startIcon={<CloudUpload />}
              variant="neutral"
              onClick={() => {
                setCurrentModal('import');
              }}
            >
              Import Contacts
            </Button>

            <Button
              startIcon={<Add />}
              fullWidth={true}
              onClick={() => {
                setCurrentModal('add');
              }}
            >
              Add Contact
            </Button>

            <TextField
              fullWidth={true}
              value={searchText}
              onChange={(event) => {
                setSearchText(event.target.value);
              }}
              placeholder="Search for contact…"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search color="action" />
                  </InputAdornment>
                ),
                endAdornment: !!searchText && (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        setSearchText('');
                      }}
                    >
                      <Clear color="action" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
        }
      >
        {!contactList.data?.pages.length ? (
          <ListItem>
            <ListItemText primary={<Skeleton />} secondary={<Skeleton />} />
          </ListItem>
        ) : !contactList.data.pages[0]?.data.length ? (
          <Box padding="small">
            <TextBox color="secondary" align="center">
              {searchText ? 'No search results' : 'No contacts'}
            </TextBox>
          </Box>
        ) : (
          contactList.data.pages.flatMap((page) =>
            page.data.map((contact) => {
              const isCurrent = currentContactGUID === contact.guid;

              return (
                <SidebarMenuItem
                  key={contact.guid}
                  selected={isCurrent}
                  onClick={() => {
                    openContact(contact.guid);
                  }}
                  avatar={
                    <SidebarMenuItemAvatar
                      value={selectedContacts.has(contact.guid)}
                      onChange={(checked) => {
                        const nextSelectedContacts = new Set(selectedContacts);

                        if (checked) {
                          nextSelectedContacts.add(contact.guid);
                        } else {
                          nextSelectedContacts.delete(contact.guid);
                        }

                        onSelectedContactsChange(nextSelectedContacts);
                      }}
                    >
                      {contact.name || ''}
                    </SidebarMenuItemAvatar>
                  }
                >
                  {contact.name}
                </SidebarMenuItem>
              );
            }),
          )
        )}

        {contactList.hasNextPage && (
          <VisibilityObserver
            onChange={(visibility) => {
              if (!contactList.isFetchingNextPage && visibility === 'visible') {
                void contactList.fetchNextPage();
              }
            }}
            render={({ ref }) => (
              <ListItem ref={ref}>
                <ListItemText primary={<Skeleton />} secondary={<Skeleton />} />
              </ListItem>
            )}
          />
        )}
      </Sidebar>
    </>
  );
}
