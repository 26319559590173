import { Typography } from '@material-ui/core';
import { Column, Columns, Stack } from '@superdispatch/ui';
import { ReactNode } from 'react';
import styled from 'styled-components';

const HintText = styled(Typography)`
  font-size: 13px;
`;

interface TripMarkerPopupProps {
  caption: string;
  loadId: string;
  icon: ReactNode;
}

export function TripMarkerPopup({
  caption,
  loadId,
  icon,
}: TripMarkerPopupProps) {
  return (
    <Columns align="center" space="xsmall">
      <Column width="content">{icon}</Column>
      <Column width="fluid">
        <Stack space="none">
          <Typography variant="caption" color="textSecondary">
            {caption}
          </Typography>
          <HintText>{loadId}</HintText>
        </Stack>
      </Column>
    </Columns>
  );
}
