import { Link, Typography } from '@material-ui/core';
import { FormattedDate } from '@superdispatch/dates';
import { Stack } from '@superdispatch/ui';
import { Alert, Box } from '@superdispatch/ui-lab';
import { MouseEvent } from 'react';
import { trackAnchorClick } from 'shared/helpers/Analytics';
import { InsuranceCertificateHolderInstruction } from 'shared/modules/carrier-profile/InsuranceCertificateHolderInstruction';

export function InsuranceExpiringBanner() {
  return (
    <Alert severity="caution">
      Your current Certificate of Insurance (COI) expires in 30 days. Upload
      your new COI to maintain access to the Super Loadboard.
    </Alert>
  );
}

export function RenewalInsurancePendingBanner() {
  return (
    <Alert severity="info">
      Your renewed Certificate of Insurance is currently under review.
    </Alert>
  );
}

interface RenewalInsuranceVerifiedBannerProps {
  expirationDate: string;
}

export function RenewalInsuranceVerifiedBanner({
  expirationDate,
}: RenewalInsuranceVerifiedBannerProps) {
  return (
    <Alert severity="positive">
      Your Certificate of Insurance renewal has been successfully approved and
      will replace your current coverage on{' '}
      <FormattedDate date={expirationDate} variant="Date" />.
    </Alert>
  );
}

export function ReviewedBanner() {
  return (
    <Box
      maxWidth="500px"
      backgroundColor="Yellow50"
      padding="small"
      borderRadius="small"
    >
      <Stack space="xsmall">
        <Typography>
          We are waiting for a third party to confirm your insurance.
        </Typography>

        <InsuranceCertificateHolderInstruction boldHeader={true} />

        <Link
          href="https://support.superdispatch.com/en/articles/6167393-listing-super-dispatch-as-the-certificate-holder-on-your-coi"
          target="_blank"
          rel="noopener noreferrer"
          onClick={(event: MouseEvent<HTMLAnchorElement>) => {
            trackAnchorClick(
              'Clicked Learn More In Carrier Profile Reviewed Banner',
              {},
              event,
            );
          }}
        >
          Learn More
        </Link>
      </Stack>
    </Box>
  );
}
