import { useFlag } from 'shared/settings/FeatureToggles';
import { useCreateBankAccount } from './MoovAPI';
import { useMoovSyncAPI } from './MoovAPILegacy';

export function useSuperPayCreateBankAccountAction() {
  const isMoovRefactoring = useFlag('payments_moovjs_refactoring');

  const { linkSyncMoovBankAccount } = useMoovSyncAPI();
  const { mutateAsync: createBankAccount } = useCreateBankAccount();

  if (isMoovRefactoring) {
    return createBankAccount;
  }

  return linkSyncMoovBankAccount;
}
