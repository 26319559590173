import {
  yupArray,
  yupEnum,
  yupObject,
  yupPhone,
  yupString,
} from 'shared/utils/YupUtils';
import { InferType } from 'yup';

export const inviteTeamSchema = yupObject({
  teammates: yupArray(
    yupObject({
      type: yupString().required(),
      email: yupString().email().required(),
      phone: yupPhone().required(),
    }).required(),
  ),
});

const onboardingStepSchema = yupObject({
  status: yupEnum([
    'pending',
    'completed',
    'skipped',
    'not_applicable',
    'started',
  ]),
}).nullable();

export type OnboardingStep = InferType<typeof onboardingSchema>;
export const onboardingSchema = yupObject({
  steps: yupObject({
    welcome_to_ctms: onboardingStepSchema,
    question_how_can_ctms_make_your_job_easier: onboardingStepSchema,
    question_what_brings_you_to_ctms: onboardingStepSchema,
    question_experience_with_ctms: onboardingStepSchema,
    team_invitation: onboardingStepSchema,
    install_chrome_extension: onboardingStepSchema,
    checkout_video_tutorials: onboardingStepSchema,
    getting_started_guide: onboardingStepSchema,

    //tours
    get_to_know_ctms: onboardingStepSchema,
    tour_slb_search: onboardingStepSchema,
    tour_create_or_import_load: onboardingStepSchema,
    tour_getting_started: onboardingStepSchema,
    tour_assign_driver: onboardingStepSchema,
    tour_load_delivered: onboardingStepSchema,
    tour_verification_approval: onboardingStepSchema,
    load_offers_tab_tour: onboardingStepSchema,
    tour_offer_accepted: onboardingStepSchema,
    tour_offer_received: onboardingStepSchema,
  }).nullable(),
});

export type OnboardingStepName = keyof NonNullable<OnboardingStep['steps']>;

export const onboardingQuestionStepSchema = yupObject({
  selected_option: yupString(),
  other_field_value: yupString().when('selected_option', {
    is: 'Other',
    then: yupString().required(),
  }),
});
