import { useMemo } from 'react';
import {
  APIQueryOptions,
  APIQueryResult,
  useAPIQuery,
} from 'shared/api/APIQuery';
import { APIResponse, requestCarrierAPI } from 'shared/api/CarrierAPIClient';

export interface Experiment {
  number: number;
  seen: boolean;
  id: number;
}

export interface ExperimentGroups {
  groups: Experiment[];
}

export function fetchExperiments(token?: string) {
  return requestCarrierAPI<APIResponse<ExperimentGroups>>(
    'GET /internal/web/carriers/experiments/',
    token
      ? {
          headers: { Authorization: `Token ${token}` },
        }
      : undefined,
  );
}

export function useExperiments(
  token?: string,
  options?: APIQueryOptions<ExperimentGroups>,
): APIQueryResult<ExperimentGroups> {
  return useAPIQuery(
    ['carrier', 'experiments'],
    () => fetchExperiments(token),
    { staleTime: Infinity, ...options },
  );
}

export const EXPERIMENT_TYPES = {
  'Driver Activation': 6,
  'Chrome Extension': 14,
  'Central Dispatch Integration': 4,
  Signup1: 1,
  Signup2: 2,
  'Redirect To SLB': 9,
  Onboarding: 18,
};

export function useExperimentState(
  name: keyof typeof EXPERIMENT_TYPES,
  props?: {
    token?: string;
    options?: APIQueryOptions<ExperimentGroups>;
  },
) {
  const { data: experiment } = useExperiments(props?.token, props?.options);

  const currentExperiment = useMemo(() => {
    if (Array.isArray(experiment?.groups)) {
      //TODO: should be deleted after a full migration to new endpoint
      return experiment?.groups.find(
        (e) => e.number === EXPERIMENT_TYPES[name],
      );
    }
    return experiment as unknown as { group: number; seen: boolean };
  }, [experiment, name]);

  const isExperimentViewed = currentExperiment?.seen === true;
  const isExperimentRunning = currentExperiment?.seen === false;
  const isInExperiment = !!currentExperiment;

  return {
    isExperimentViewed,
    isExperimentRunning,
    isInExperiment,
    markAsViewed: () => {
      if (!currentExperiment) {
        throw Error('No experiment found');
      }

      if ('id' in currentExperiment) {
        //TODO: should be deleted after a full migration to new endpoint

        return requestCarrierAPI(
          [
            'PATCH /internal/web/carriers/experiments/{id}/',
            { id: currentExperiment.id },
          ],
          {
            json: { seen: true },
          },
        );
      }

      return requestCarrierAPI(
        'PATCH /internal/web/carriers/experiments/update/',
        {
          json: { seen: true },
        },
      );
    },
  };
}
