import { useMemo } from 'react';
import { isAPIError } from 'shared/api/APIError';
import {
  APIMutationOptions,
  APIMutationResult,
  useAPIMutation,
} from 'shared/api/APIMutation';
import { useAPIQuery } from 'shared/api/APIQuery';
import { APIResponse, requestCarrierAPI } from 'shared/api/CarrierAPIClient';
import {
  LoginDTO,
  ResendVerificationCodeDTO,
  VerifyAccountDTO,
} from 'shared/auth/data/AuthDTO';

export function isInvalidRoleError(error: unknown): boolean {
  return isAPIError(error) && error.type === 'AccountHasDifferentRole';
}

export function isInactiveAccountError(error: unknown): boolean {
  return (
    isAPIError(error) &&
    (error.type === 'DispatcherAccountDeactivated' ||
      error.type === 'CarrierDeactivatedUseCaseError')
  );
}

export function useLogout(options?: APIMutationOptions) {
  return useAPIMutation(
    () => requestCarrierAPI('POST /internal/web/logout/'),
    options,
  );
}

export function useResetPasswordCode(code: null | string | undefined) {
  return useAPIQuery(
    ['auth', 'reset_code', { code }],
    () =>
      requestCarrierAPI([
        'GET /internal/web/reset-password/{?reset_code}',
        { reset_code: code },
      ]),
    { enabled: !!code, cacheTime: Infinity, staleTime: Infinity },
  );
}

export type AuthResponse = APIResponse<{
  token: string;
  has_dispatcher_role: boolean;
  has_driver_role: boolean;
  is_mfa_enabled: boolean;
  email: string;
  phone_number: string;
  mfa_channel: 'sms' | 'email';
}>;

export type VerificationResponse = APIResponse<{
  token: string;
}>;

export function useAuthAPI() {
  return useMemo(() => {
    function login(json: LoginDTO) {
      return requestCarrierAPI<AuthResponse>('POST /internal/web/login/', {
        json,
      });
    }

    function resetPassword(email: string) {
      return requestCarrierAPI('POST /internal/web/forgot-password/', {
        json: { email },
      });
    }

    function confirmPasswordReset(code: string, password: string) {
      return requestCarrierAPI<AuthResponse>(
        [
          'POST /internal/web/reset-password/{?reset_code}',
          { reset_code: code },
        ],
        { json: { password } },
      );
    }

    function checkVerificationCode(json: VerifyAccountDTO) {
      return requestCarrierAPI<VerificationResponse>(
        'POST /internal/web/auth/verification/check-code/',
        {
          json,
        },
      );
    }

    function resendVerificationCode(json: ResendVerificationCodeDTO) {
      return requestCarrierAPI(
        'POST /internal/web/auth/verification/resend-code/',
        {
          json,
        },
      );
    }

    return {
      login,
      resetPassword,
      confirmPasswordReset,
      checkVerificationCode,
      resendVerificationCode,
    };
  }, []);
}

export function useResendVerificationCode(
  options?: APIMutationOptions<ResendVerificationCodeDTO>,
): APIMutationResult<ResendVerificationCodeDTO> {
  const { resendVerificationCode } = useAuthAPI();
  return useAPIMutation(resendVerificationCode, options);
}
