import { IconButton as MuiIconButton, Typography } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { Color, Inline, Stack } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { Form, useFormikContext } from 'formik';
import { useNavigate } from 'react-router-dom-v5-compat';
import styled from 'styled-components';
import { TripCreateDTO } from '../data/TripsDTO';
import { TripForm } from './TripForm';

const IconButton = styled(MuiIconButton)`
  padding: 0;
`;

const Wrapper = styled(Box)`
  width: 400px;
  height: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${Color.White};
  border-right-width: 1px;
  border-right-color: ${Color.Silver400};
`;

export function TripFormSidebar() {
  const navigate = useNavigate();
  const { isSubmitting } = useFormikContext<TripCreateDTO>();

  return (
    <Form>
      <Wrapper>
        <Box
          paddingTop="small"
          paddingLeft="large"
          paddingRight="large"
          paddingBottom="small"
        >
          <Stack space="small">
            <Inline horizontalAlign="left" verticalAlign="center">
              <IconButton
                onClick={() => {
                  navigate('/trips', { replace: true });
                }}
              >
                <ArrowBack />
              </IconButton>
              <Typography variant="h3">Create Trip</Typography>
            </Inline>

            <TripForm />
          </Stack>
        </Box>

        <Box
          left="0"
          right="0"
          bottom="0"
          width="100%"
          paddingTop="small"
          position="sticky"
          paddingLeft="large"
          paddingBottom="small"
          borderTopWidth="small"
          borderTopColor="Silver400"
          backgroundColor="White"
        >
          <Button
            pending={isSubmitting}
            type="submit"
            data-intercom-target="trip-save-button"
          >
            Save
          </Button>
        </Box>
      </Wrapper>
    </Form>
  );
}
