import { useFlag } from 'shared/settings/FeatureToggles';
import { useCompleteMicroDepositVerification } from './MoovAPI';
import { useMoovAPI } from './MoovAPILegacy';

export function useSuperPayMicroDepositCompleteAction() {
  const isMoovRefactoring = useFlag('payments_moovjs_refactoring');

  const {
    completeMicroDepositVerification: completeMicroDepositVerificationLegacy,
  } = useMoovAPI();
  const { mutateAsync: completeMicroDepositVerification } =
    useCompleteMicroDepositVerification();

  if (isMoovRefactoring) {
    return completeMicroDepositVerification;
  }

  return completeMicroDepositVerificationLegacy;
}
