import { SidebarContainer } from '@superdispatch/ui-lab';
import { ReactElement } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom-v5-compat';
import { LoadsContextProvider } from '../loads/data/LoadsContext';
import { LoadsPageContextProvider } from '../loads/loads-page/data/LoadsPageParamsContext';
import { ReportsSidebar } from './core/ReportsSidebar';
import { ReportsContextProvider } from './data/ReportsContext';
import { ReportsAccountsPayablePage } from './ReportsAccountsPayable';
import { ReportsAccountsReceivablePage } from './ReportsAccountsReceivablePage';
import { ReportsCompanyRevenuePage } from './ReportsCompanyRevenuePage';
import { ReportsCustomPage } from './ReportsCustomPage';
import { ReportsDriverPayPage } from './ReportsDriverPayPage';
import { ReportsLoadsPage } from './ReportsLoadsPage';

interface ReportsPageRouteProps {
  children: ReactElement;
}

function ReportsPageRoute({ children }: ReportsPageRouteProps) {
  return (
    <SidebarContainer sidebar={<ReportsSidebar />}>{children}</SidebarContainer>
  );
}

export function ReportsContainerPage() {
  return (
    <ReportsContextProvider>
      <Routes>
        <Route
          path="loads"
          element={
            <LoadsContextProvider>
              <LoadsPageContextProvider>
                <ReportsLoadsPage />
              </LoadsPageContextProvider>
            </LoadsContextProvider>
          }
        />

        <Route
          path="driver-pay"
          element={
            <ReportsPageRoute>
              <ReportsDriverPayPage />
            </ReportsPageRoute>
          }
        />
        <Route
          path="accounts-receivable"
          element={
            <ReportsPageRoute>
              <ReportsAccountsReceivablePage />
            </ReportsPageRoute>
          }
        />
        <Route
          path="company-revenue"
          element={
            <ReportsPageRoute>
              <ReportsCompanyRevenuePage />
            </ReportsPageRoute>
          }
        />
        <Route
          path="accounts-payable"
          element={
            <ReportsPageRoute>
              <ReportsAccountsPayablePage />
            </ReportsPageRoute>
          }
        />
        <Route
          path="custom-report/:reportGuid"
          element={
            <ReportsPageRoute>
              <ReportsCustomPage />
            </ReportsPageRoute>
          }
        />
        <Route
          path="custom-report/*"
          element={
            <ReportsPageRoute>
              <ReportsCustomPage />
            </ReportsPageRoute>
          }
        />

        <Route
          path="*"
          element={<Navigate to="/reports/accounts-receivable" />}
        />
      </Routes>
    </ReportsContextProvider>
  );
}
