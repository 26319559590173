import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@material-ui/core';
import { Inline, useSnackbarStack } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { DriverDTO } from '../data/DriverDTO';
import { useReinviteDriver } from '../data/DriversAPI';

interface ReinviteDialogProps {
  open: boolean;
  currentDriver?: DriverDTO;
  onClose: () => void;
}

export function ReinviteDialog({
  open,
  onClose,
  currentDriver,
}: ReinviteDialogProps) {
  const { addSnackbar } = useSnackbarStack();
  const { mutate: sendResendInvite, isLoading } = useReinviteDriver({
    onSuccess: () => {
      addSnackbar(`${currentDriver?.name || currentDriver?.email} reinvited`, {
        variant: 'success',
      });
      onClose();
    },
    onError: (error) => {
      addSnackbar(error.message, { variant: 'error' });
    },
  });

  const handleResendInvite = () => {
    if (currentDriver?.guid) {
      sendResendInvite(currentDriver.guid);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <Box>
          <Typography align="left" variant="h3">
            Reinvite {currentDriver?.name || currentDriver?.email}?
          </Typography>
        </Box>
      </DialogContent>

      <DialogActions>
        <Box paddingTop="xsmall">
          <Inline space="small">
            <Button disabled={isLoading} variant="neutral" onClick={onClose}>
              Cancel
            </Button>
            <Button pending={isLoading} onClick={handleResendInvite}>
              Reinvite
            </Button>
          </Inline>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
