import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { useSnackbarStack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { trackTripsEvent } from '../data/TripsAnalytics';
import { useUnassignTripDriver } from '../data/TripsAPI';
import { TripDriverDTO, TripDTO } from '../data/TripsDTO';

interface TripUnassignDriverDialogProps {
  open: boolean;
  onClose: () => void;
  trip: TripDTO;
  currentDriver: TripDriverDTO | null;
}

export function TripUnassignDriverDialog({
  open,
  onClose,
  trip,
  currentDriver,
}: TripUnassignDriverDialogProps) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        Unassign {currentDriver?.name || currentDriver?.email} from this Trip?
      </DialogTitle>
      {open && <Content trip={trip} onClose={onClose} />}
    </Dialog>
  );
}

interface ContentProps {
  trip: TripDTO;
  onClose: () => void;
}

function Content({ trip, onClose }: ContentProps) {
  const { addSnackbar } = useSnackbarStack();

  const { mutate: unassignDriver, isLoading } = useUnassignTripDriver(
    trip.guid,
    {
      onSuccess: () => {
        trackTripsEvent({
          name: 'Carrier Unassigned from Driver',
          trip_guid: trip.guid,
        });
        onClose();
        addSnackbar('Unassigned the driver from the trip.', {
          variant: 'success',
        });
      },
      onError: (e) => {
        addSnackbar(e.message, { variant: 'error' });
      },
    },
  );

  const handleUnassign = () => {
    unassignDriver();
  };

  return (
    <>
      <DialogContent>
        <Typography>
          Removing the driver from the trip will also unassign them from all
          loads in this trip.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} variant="neutral" onClick={onClose}>
          Cancel
        </Button>
        <Button pending={isLoading} onClick={handleUnassign}>
          Unassign
        </Button>
      </DialogActions>
    </>
  );
}
