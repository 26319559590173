import { useFormikEnhanced } from '@superdispatch/forms';
import { Stack, useSnackbarStack } from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import { FormikProvider, prepareDataForValidation } from 'formik';
import { useInitialValues } from 'shared/form/AppFormik';
import { FormikMediaField } from 'shared/form/FormikMediaField';
import { mapYupErrorsToFormik } from 'shared/form/MapYupErrorsToFormik';
import { useCarrierInfo } from 'shared/modules/carrier-profile/CarrierInfo';
import { useIsCarrierWithoutUSDOT } from 'shared/modules/carrier-profile/data/CarrierPerformanceAPI';
import styled from 'styled-components';
import { StepForm } from './core/StepForm';
import { trackCarrierProfileEvent } from './data/CarrierProfileAnalytics';
import {
  useBusinessLicense,
  useCarrierProfileAPI,
  useStateMotorCarrierPermit,
  useTransporterPlateRegistration,
  useUSDOT,
  useW9,
} from './data/CarrierProfileAPI';
import { identityStepEditSchema } from './data/VerifiedCarrierApplicationDTO';

const HelperList = styled.ul`
  padding-inline-start: 20px;
  margin: 0;
`;

interface Props {
  onComplete: () => void;
  onBack?: () => void;
}

export function StepIdentificationPage({ onComplete, onBack }: Props) {
  const { addSnackbar } = useSnackbarStack();
  const { data: carrierInfo } = useCarrierInfo();
  const isCarrierWithoutUSDOT = useIsCarrierWithoutUSDOT();
  const { data: w9 } = useW9();
  const { data: usdot } = useUSDOT();
  const { data: plateRegistration } = useTransporterPlateRegistration();
  const { data: statePermit } = useStateMotorCarrierPermit();
  const { data: businessLicense } = useBusinessLicense();
  const {
    updateUSDOT,
    updateW9,
    updateTransporterPlateRegistration,
    updateStateMotorCarrierPermit,
    updateBusinessLicense,
  } = useCarrierProfileAPI();

  const initialValues = useInitialValues(identityStepEditSchema, {
    usdot_certificate_url: usdot?.usdot_certificate_url,
    w9_form_url: w9?.w9_form_url,
    transportation_plate_registration: plateRegistration?.file_url,
    state_motor_carrier_permit: statePermit?.file_url,
    business_license: businessLicense?.file_url,
  });

  const formik = useFormikEnhanced({
    initialValues,
    validate(values) {
      return mapYupErrorsToFormik(() =>
        identityStepEditSchema.validateSync(prepareDataForValidation(values), {
          abortEarly: false,
          context: {
            logisticsType: carrierInfo?.logistics_type,
            isCarrierWithoutUSDOT,
          },
        }),
      );
    },
    onSubmit: async (values) => {
      if (values.usdot_certificate_url) {
        await updateUSDOT({
          usdot_certificate_url: values.usdot_certificate_url,
        });
      }

      if (values.w9_form_url) {
        await updateW9({
          w9_form_url: values.w9_form_url,
        });
      }

      if (values.transportation_plate_registration) {
        await updateTransporterPlateRegistration({
          file_url: values.transportation_plate_registration,
        });
      }

      if (values.state_motor_carrier_permit) {
        await updateStateMotorCarrierPermit({
          file_url: values.state_motor_carrier_permit,
        });
      }

      if (values.business_license) {
        await updateBusinessLicense({
          file_url: values.business_license,
        });
      }
    },
    onSubmitSuccess() {
      trackCarrierProfileEvent({
        name: 'Carrier Updated Carrier Info',
        utm_medium: 'Verified Carrier Application',
        utm_content: 'Identification',
        logistics_type: carrierInfo?.logistics_type,
        is_carrier_has_usdot: !isCarrierWithoutUSDOT,
      });

      onComplete();
    },
    onSubmitFailure() {
      addSnackbar('Failed to update identification. Please try again.', {
        variant: 'error',
      });
    },
  });

  return (
    <FormikProvider value={formik}>
      <StepForm
        step={4}
        onBack={onBack}
        formContent={
          <Stack space="large">
            {!isCarrierWithoutUSDOT && (
              <FormikMediaField
                label="USDOT Certificate"
                name="usdot_certificate_url"
                fullWidth={true}
              />
            )}

            {isCarrierWithoutUSDOT &&
              carrierInfo?.logistics_type === 'driveaway' && (
                <FormikMediaField
                  label="Business License"
                  name="business_license"
                  fullWidth={true}
                />
              )}

            {carrierInfo?.logistics_type === 'driveaway' && (
              <FormikMediaField
                label="Transporter Plate Registration"
                name="transportation_plate_registration"
                helperText="Plate Inventory form DMV"
                fullWidth={true}
              />
            )}

            {isCarrierWithoutUSDOT &&
              carrierInfo?.logistics_type !== 'driveaway' && (
                <FormikMediaField
                  label="State Motor Carrier Permit"
                  name="state_motor_carrier_permit"
                  fullWidth={true}
                />
              )}

            <FormikMediaField
              label="W-9 Form"
              name="w9_form_url"
              fullWidth={true}
              helperText={
                <HelperList>
                  <li>
                    <TextBox variant="caption" color="secondary">
                      A signed and dated W-9, issued within the last two years,
                      is required.
                    </TextBox>
                  </li>
                  <li>
                    <TextBox variant="caption" color="secondary">
                      Including your Social Security Number (SSN) is optional.
                      If you choose to include it, we will take every precaution
                      to ensure it remains confidential.
                    </TextBox>
                  </li>
                </HelperList>
              }
            />
          </Stack>
        }
        submitButtonText="Submit"
      />
    </FormikProvider>
  );
}
