import { useSnackbarStack } from '@superdispatch/ui';
import { FileListItem } from '@superdispatch/ui-lab';
import { useEffect, useMemo } from 'react';
import { AddTripAttachmentInput, useAddTripAttachment } from '../data/TripsAPI';

interface TripDetailsAttachmentListItemProps {
  file: File;
  tripGUID: string;
  canDelete: boolean;
  onDelete: () => void;
  onSuccess?: () => void;
}

export function TripDetailsAttachmentListItem({
  file,
  tripGUID,
  canDelete,
  onDelete,
  onSuccess,
}: TripDetailsAttachmentListItemProps) {
  const { addSnackbar } = useSnackbarStack();
  const { status, mutate } = useAddTripAttachment({
    onSuccess,
    onError: (error) => {
      addSnackbar(error.message || 'Failed to upload attachment', {
        variant: 'error',
      });
    },
  });

  const input = useMemo<AddTripAttachmentInput>(
    () => ({ file, tripGUID }),
    [file, tripGUID],
  );

  useEffect(() => {
    mutate(input);
  }, [input, mutate]);

  return (
    <FileListItem
      status={status}
      name={file.name}
      canDelete={canDelete}
      onDelete={onDelete}
      onRetry={() => {
        mutate(input);
      }}
    />
  );
}
