import {
  Card,
  CardContent,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Add, Delete, Edit } from '@material-ui/icons';
import { FormattedDate } from '@superdispatch/dates';
import { CardButton, Inline, Tag } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { useNavigate } from 'react-router-dom-v5-compat';
import { getFilenameFromURL } from 'shared/helpers/CommonHelpers';
import { formatCurrency } from 'shared/helpers/IntlHelpers';
import { LinkIconButton } from 'shared/routing/Links';
import { HiddenForPrint } from 'shared/ui/Print';
import {
  formatLoadExpenseType,
  LoadDTO,
  LoadExpenseDTO,
} from '../data/LoadDTO';

interface ExpensesTableRowProps {
  load: LoadDTO;
  expense: LoadExpenseDTO;
}

function ExpensesTableRow({ load, expense }: ExpensesTableRowProps) {
  const expenseType = formatLoadExpenseType(expense.type);
  return (
    <TableRow aria-label="Expense Row">
      <TableCell>
        {expense.type === 'other' ? expense.name || expenseType : expenseType}
      </TableCell>
      <TableCell>{expense.price && formatCurrency(expense.price)}</TableCell>
      <TableCell aria-label="Expense Date">
        <FormattedDate
          date={expense.date}
          format="DateTimeISO"
          variant="ShortDate"
        />
      </TableCell>
      <TableCell width={50}>
        {expense.receipt_url && (
          <Box maxWidth="120px">
            <Typography noWrap={true}>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                href={expense.receipt_url}
              >
                {getFilenameFromURL(expense.receipt_url)}
              </Link>
            </Typography>
          </Box>
        )}
      </TableCell>
      <TableCell aria-label="Show on invoice">
        {expense.show_on_invoice ? (
          <Tag variant="subtle" color="green">
            Yes
          </Tag>
        ) : (
          <Tag variant="subtle" color="grey">
            No
          </Tag>
        )}
      </TableCell>
      <TableCell aria-label="Deduct from driver pay">
        {expense.deduct_from_driver_pay ? (
          <Tag variant="subtle" color="green">
            Yes
          </Tag>
        ) : (
          <Tag variant="subtle" color="grey">
            No
          </Tag>
        )}
      </TableCell>
      <TableCell align="right">
        <Inline horizontalAlign="right">
          <LinkIconButton
            size="small"
            aria-label="edit expense"
            to={`/loads/${load.guid}/expense/${expense.guid}`}
          >
            <Edit />
          </LinkIconButton>

          <LinkIconButton
            size="small"
            aria-label="delete expense"
            to={`/loads/${load.guid}/delete-expense/${expense.guid}`}
          >
            <Delete />
          </LinkIconButton>
        </Inline>
      </TableCell>
    </TableRow>
  );
}

interface ViewLoadExpensesProps {
  load: LoadDTO;
}

export function ViewLoadExpenses({ load }: ViewLoadExpensesProps) {
  const navigate = useNavigate();

  return !load.expenses?.length ? (
    <HiddenForPrint>
      <CardButton
        startIcon={<Add />}
        onClick={() => {
          navigate(`/loads/${load.guid}/add-expense/`);
        }}
      >
        Add Expenses
      </CardButton>
    </HiddenForPrint>
  ) : (
    <Card aria-label="Expenses Card">
      <CardContent>
        <Inline verticalAlign="center" space="xsmall">
          <Typography variant="h3">Expenses</Typography>

          <Button
            variant="text"
            startIcon={<Add />}
            onClick={() => {
              navigate(`/loads/${load.guid}/add-expense/`);
            }}
          >
            Add
          </Button>
        </Inline>
      </CardContent>
      <TableContainer>
        <Table aria-label="Expenses Table">
          <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>Cost</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Receipt</TableCell>
              <TableCell>Show On Invoice</TableCell>
              <TableCell colSpan={2}>Deduct From Driver Pay</TableCell>
            </TableRow>
          </TableHead>
          <TableBody aria-label="Expenses List">
            {load.expenses.map((expense) => (
              <ExpensesTableRow
                load={load}
                expense={expense}
                key={expense.guid}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}
