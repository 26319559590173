import { MarkerProps } from 'shared/form/LeafletMap';
import deliveryFilledImg from '../assets/delivery-filled.png';
import deliveryImg from '../assets/delivery.png';
import endPinFilledImg from '../assets/end-pin-filled.png';
import endPinImg from '../assets/end-pin.png';
import pickupFilledImg from '../assets/pickup-filled.png';
import pickupImg from '../assets/pickup.png';
import startPinFilledImg from '../assets/start-pin-filled.png';
import startPinImg from '../assets/start-pin.png';
import { TripLoadDTO, VenueDTO } from './TripsDTO';

export function getFilledMarkerIconUrl(
  marker: MarkerProps,
  type: 'filled' | 'empty',
) {
  if (marker.name === 'start_address') {
    return type === 'filled' ? startPinFilledImg : startPinImg;
  }

  if (marker.name === 'end_address') {
    return type === 'filled' ? endPinFilledImg : endPinImg;
  }

  if (marker.type === 'pickup') {
    return type === 'filled' ? pickupFilledImg : pickupImg;
  }

  return type === 'filled' ? deliveryFilledImg : deliveryImg;
}

export function getMarkerIcon(marker: MarkerProps, fieldName: string) {
  if (marker.name === fieldName) {
    return { ...marker, iconUrl: getFilledMarkerIconUrl(marker, 'filled') };
  }

  return {
    ...marker,
    iconUrl: getFilledMarkerIconUrl(marker, 'empty'),
  };
}

export function getLoads(loads: TripLoadDTO[] | undefined | null) {
  if (!loads) {
    return [];
  }

  return loads.map((load) => ({
    ...load,
    load_id: load.number,
    pickup_latitude: load.pickup.latitude,
    pickup_longitude: load.pickup.longitude,
    delivery_latitude: load.delivery.latitude,
    delivery_longitude: load.delivery.longitude,
  }));
}

export function getCenter(
  address: VenueDTO | undefined | null,
): [number, number] | undefined {
  if (!address?.latitude || !address.longitude) {
    return undefined;
  }

  return [address.latitude, address.longitude];
}
