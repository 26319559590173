import { kebabCase } from 'lodash-es';
import { yupEnum, yupObject, yupStringParam } from 'shared/utils/YupUtils';
import { InferType } from 'yup';
import { LOAD_ORDER_BY_FIELDS } from './LoadsPageParams';

export const LOAD_GROUP_BY_TABS = [
  'new',
  'assigned',
  'in-terminal',
  'picked-up',
  'delivered',
  'billed',
  'paid',
  'archived',
] as const;

export type LoadGroupByTab = (typeof LOAD_GROUP_BY_TABS)[number];

export function isLoadGroupByTab(value: unknown): value is LoadGroupByTab {
  return (
    typeof value == 'string' &&
    LOAD_GROUP_BY_TABS.includes(value as LoadGroupByTab)
  );
}

export function toLoadGroupByTab(input: unknown): LoadGroupByTab | undefined {
  const value = typeof input == 'string' ? kebabCase(input) : undefined;

  return isLoadGroupByTab(value) ? value : undefined;
}

export type LoadGroupByParams = InferType<typeof loadGroupByParamsSchema>;
export const loadGroupByParamsSchema = yupObject({
  tab: yupEnum(LOAD_GROUP_BY_TABS),
  groupby: yupEnum(LOAD_ORDER_BY_FIELDS),

  drivers: yupStringParam(),
  terminals: yupStringParam(),
  dispatchers: yupStringParam(),
});

export interface LoadGroupByOptionDTO {
  count: number;
  name: boolean | string | null;
}

export interface LoadGroupByOptionsDTO {
  items: LoadGroupByOptionDTO[] | null;
}
