import { ReactElement, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom-v5-compat';
import { useAuthState } from 'shared/auth/AuthState';
import { getAuthRole } from 'shared/auth/AuthToken';
import { useShowSubscriptionCancelledDialog } from 'shared/errors/data/useShowSubscriptionCancelledDialog';
import { useShowTrialExpiredDialog } from 'shared/errors/data/useShowTrialExpiredDialog';
import { PaywallDialog } from 'shared/errors/PaywallDialog';
import { identifyAnalytics, trackEvent } from 'shared/helpers/Analytics';
import {
  identifyErrorTrackerUser,
  initializeUserMonitor,
} from 'shared/helpers/ErrorTracker';
import { useCheckExtension } from 'shared/helpers/ExtensionHelper';
import { useViewportMeta } from 'shared/helpers/ResponsiveHelpers';
import { useSearchParam } from 'shared/helpers/SearchParamsHelpers';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import { setupUserflow } from 'shared/helpers/Userflow';
import { NavigationHeaderBanner } from 'shared/layout/NavigationHeaderBanner';
import { NotificationStack } from 'shared/layout/notification-stack/NotificationStack';
import { NavigationBarContainer } from 'shared/layout/root/NavigationBarContainer';
import { AppLayoutSidebarLegacy } from 'shared/layout/sidebar/AppLayoutSidebarLegacy';
import { NavbarBottomBar } from 'shared/layout/sidebar/NavbarBottomBar';
import { NavbarContextProvider } from 'shared/layout/sidebar/NavbarContext';
import { useBottomItems } from 'shared/layout/sidebar/useBottomItems';
import { SplashScreen } from 'shared/layout/SplashScreen';
import { ChromeExtensionReminderSnackBar } from 'shared/modules/chrome-extension-reminder/ChromeExtensionReminderSnackBar';
import { useDriverActivationExperiment } from 'shared/modules/experiments/driver-activation/DriverActivationExperiment';
import { useRedirectToSLBExperiment } from 'shared/modules/experiments/redirect-to-slb-experiment/RedirectToSLBExperiment';
import { useLoadboardNotifications } from 'shared/modules/loadboard/LoadboardNotifications';
import { OnboardingDialogs } from 'shared/modules/onboarding/OnboardingDialogs';
import { useOnboardingTourHandler } from 'shared/modules/onboarding/OnboardingIntercomTours';
import { useRatingNotifications } from 'shared/modules/ratings/data/RatingNotifications';
import { useShowAddBillingAddressDialog } from 'shared/modules/subscription/core/useShowAddBillingAddressDialog';
import { SubscriptionAddBillingAddressDialog } from 'shared/modules/subscription/SubscriptionAddBillingAddressDialog';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';
import { useFlag } from 'shared/settings/FeatureToggles';
import { TrialExpiredDialog } from '../loadboard/core/TrialExpiredDialog';
import { ContactsPage } from './contacts/ContactsPage';
import { DashboardContainerPage } from './dashboard/DashboardContainerPage';
import { useDispatcherProfileWithoutErrorBoundary } from './dispatchers/data/DispatchersAPI';
import { DispatchersContainerPage } from './dispatchers/DispatchersContainerPage';
import { DriversPage } from './drivers/DriversPage';
import { LoadsContainerPage } from './loads/LoadsContainerPage';
import { OffersPage } from './offers/offers/OffersPage';
import { ProfileContainerPage } from './profile/ProfileContainerPage';
import { ReportsContainerPage } from './reports/ReportsContainerPage';
import { SettingsContainerPage } from './settings/SettingsContainerPage';
import { SplitLoadsContainerPage } from './split-loads/SplitLoadsContainerPage';
import { TrackingContainerPage } from './tracking/TrackingContainer';
import { TripsContainerPage } from './trips/TripsContainerPage';

const localPaths = {
  vehicles: '/vehicles',
  dashboard: '/dashboard',
  loads: '/loads',
  trips: '/trips',
  tracking: '/tracking',
  drivers: '/drivers',
  contacts: '/contacts',
  ratings: '/profile/overview',
  dispatcherManagement: '/dispatchers?utm_medium=navigation_bar',
  dispatcherProfile: '/dispatchers/profile',
  carrierProfile: '/profile',
  reports: '/reports',
  settings: '/settings',
  subscriptionDetails: '/settings/subscription',
  logout: '/logout',
} as const;

interface CarrierAppRoutesContainerProps {
  children: ReactElement;
}

function CarrierAppRoutesContainer({
  children,
}: CarrierAppRoutesContainerProps) {
  const authState = useAuthState();
  const shouldShowNotifications = useFlag('react_notification_banners');
  const shouldShowAdminLoginWarningBanner = useFlag(
    'admin_login_warning_banner',
  );
  const { isOpenUpgradeWarningDialog, closeUpgradeWarningDialog } =
    useShowAddBillingAddressDialog();
  const {
    isOpenSubscriptionCancelledDialog,
    closeSubscriptionCancelledDialog,
    trialType,
  } = useShowSubscriptionCancelledDialog();
  const { isOpenTrialExpiredDialog } = useShowTrialExpiredDialog();
  const role = getAuthRole();

  const { bottomItems, hasMenuBadge } = useBottomItems();

  useSignUpEvent();

  if (authState === 'undetermined') {
    return <SplashScreen />;
  }

  return (
    <NavbarContextProvider>
      {shouldShowNotifications && <NotificationStack />}

      <ChromeExtensionReminderSnackBar />
      <OnboardingDialogs />

      {shouldShowAdminLoginWarningBanner && role === 'admin' && (
        <NavigationHeaderBanner />
      )}
      <SubscriptionAddBillingAddressDialog
        open={Boolean(isOpenUpgradeWarningDialog)}
        onClose={closeUpgradeWarningDialog}
      />

      <TrialExpiredDialog
        open={Boolean(isOpenSubscriptionCancelledDialog)}
        onClose={() => {
          closeSubscriptionCancelledDialog();
          openExternalURL('/loadboard');
        }}
        onReactivate={() => {
          closeSubscriptionCancelledDialog();
          openExternalURL('/tms/settings/subscription');
        }}
        type={trialType === 'expired' ? 'trial_expired' : 'cancelled'}
      />

      {isOpenTrialExpiredDialog && (
        <PaywallDialog isTrialExpired={isOpenTrialExpiredDialog} />
      )}

      <NavigationBarContainer
        navigationBar={
          <AppLayoutSidebarLegacy
            hasMenuBadge={hasMenuBadge}
            localPaths={localPaths}
          />
        }
        mobileMenuBar={
          <NavbarBottomBar
            items={bottomItems}
            hasMenuBadge={hasMenuBadge}
            localPaths={localPaths}
          />
        }
      >
        {children}
      </NavigationBarContainer>
    </NavbarContextProvider>
  );
}

export function CarrierApp() {
  const isResponsivenessEnabled = useFlag('carrier_tms_responsiveness');
  const { data: settings, isFetching } = useCarrierSettings();
  const hasExtensionInstalled = useCheckExtension();

  useViewportMeta({
    width: 960,
    disabled: isResponsivenessEnabled,
  });
  useStartUserFlow(isFetching);
  useLoadboardNotifications();
  useRatingNotifications();
  useDriverActivationExperiment();
  useRedirectToSLBExperiment();

  useOnboardingTourHandler({
    tourName: 'tour_verification_approval',
    enabled: settings?.carrier.verification_status === 'approved',
  });

  useEffect(() => {
    const user = settings?.user;
    const carrier = settings?.carrier;
    const isSuper = settings?.carrier.verification_status === 'approved';

    if (user) {
      void initializeUserMonitor();
      identifyErrorTrackerUser({
        id: user.guid,
        carrier_guid: settings.carrier.guid,
        carrier_name: settings.carrier.name,
      });
      identifyAnalytics({
        guid: user.guid,
        hash: user.user_hash,
        accountType: settings.company_type,
        experiment_group: settings.groups?.map((e) => e.number),
        sd_importer_installed: hasExtensionInstalled,
        company: {
          id: carrier?.guid,
          guid: carrier?.guid,
          name: carrier?.name,
          is_super: isSuper,
          superpay_onboarding_status: settings.superpay.verification,
          is_fmcsa_phone_verified: settings.is_fmcsa_phone_verified,
        },
      });
    } else {
      identifyAnalytics();
    }
  }, [settings, hasExtensionInstalled]);
  return (
    <CarrierAppRoutesContainer>
      <Routes>
        <Route path="/offers/*" element={<OffersPage />} />

        <Route path="/dashboard/*" element={<DashboardContainerPage />} />

        <Route path="/loads/*" element={<LoadsContainerPage />} />

        <Route path="/tracking/*" element={<TrackingContainerPage />} />

        <Route path="/trips/*" element={<TripsContainerPage />} />

        <Route path="/dispatchers/*" element={<DispatchersContainerPage />} />

        <Route path="/contacts/*" element={<ContactsPage />} />

        <Route path="/drivers/*" element={<DriversPage />} />

        <Route path="/reports/*" element={<ReportsContainerPage />} />

        <Route path="/profile/*" element={<ProfileContainerPage />} />

        <Route path="/settings/*" element={<SettingsContainerPage />} />

        <Route path="/loads-split/*" element={<SplitLoadsContainerPage />} />

        <Route
          path="/ratings"
          element={
            <Navigate to={`/profile/overview${window.location.search}`} />
          }
        />

        <Route path="*" element={<Navigate to="/loads" />} />
      </Routes>
    </CarrierAppRoutesContainer>
  );
}

export function useStartUserFlow(isFetching: boolean) {
  const isUserflowEnabled = useFlag('enable_userflow');
  const { data: dispatcher } = useDispatcherProfileWithoutErrorBoundary();

  useEffect(() => {
    const isFeatureEnabled = isUserflowEnabled && !isFetching;

    if (dispatcher && isFeatureEnabled) {
      void setupUserflow({
        guid: dispatcher.guid,
        name: dispatcher.name,
        email: dispatcher.email,
        created_at: dispatcher.created_at,
      });
    }
  }, [dispatcher, isUserflowEnabled, isFetching]);
}

export function useSignUpEvent() {
  const [utm_source, setUtmSource] = useSearchParam('utm_source');

  useEffect(() => {
    if (utm_source === 'carrier_signup') {
      trackEvent('User Signed Up', { utm_source });
      setUtmSource('');
    }
  }, [utm_source, setUtmSource]);
}
