import { Color } from '@superdispatch/ui';
import { createSvgIcon } from 'shared/icons/IconUtils';

export const DeliveryDownIcon = createSvgIcon(
  'DeliveryDownIcon',

  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M4.49994 13.3698L6.2938 11.5434L10.7431 16.0975V3L13.2568 3V16.0975L17.7061 11.5434L19.4999 13.3698L11.994 21L4.49994 13.3698Z"
    fill={Color.Green300}
  />,

  { viewBox: '0 0 24 24', width: 24, height: 24, fill: 'none' },
);
