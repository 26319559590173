import { BankAccountStatusType } from '@moovio/moov-js';
import { Stack } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { ErrorMessageBox } from 'shared/errors/SuperPayBankErrors';
import { showIntercomMessenger } from 'shared/helpers/Intercom';
import { BankAccountVerificationStatusType } from 'shared/modules/superpay/SuperPayDTO';
import { performNavigation } from 'shared/routing/NavigationBlock';

interface BankValidationErrorBannerProps {
  verificationStatus?:
    | BankAccountVerificationStatusType
    | BankAccountStatusType;
}

export function BankValidationErrorBanner({
  verificationStatus,
}: BankValidationErrorBannerProps) {
  if (verificationStatus === 'locked') {
    return (
      <ErrorMessageBox
        message="Too many failed attempts of micro-deposits verification. Contact support for assistance."
        action={
          <Button
            data-intercom-target="SuperPay Bank Verification Status Failed"
            variant="neutral"
            size="small"
            onClick={() => {
              showIntercomMessenger();
            }}
          >
            Contact Support
          </Button>
        }
      />
    );
  }

  return (
    <ErrorMessageBox
      message="Bank account verification error. Contact support for assistance."
      action={
        <Button
          data-intercom-target="SuperPay Bank Verification Status Failed"
          variant="neutral"
          size="small"
          onClick={() => {
            showIntercomMessenger();
          }}
        >
          Contact Support
        </Button>
      }
    />
  );
}

interface VerificationErrorBannerProps {
  isDisabled: boolean;
  isErrorMoov: boolean;
  isErrorTotalSum: boolean;
  isErrorLastValues: boolean;
  onContactSupport?: () => void;
}

export function VerificationErrorBanner({
  isErrorMoov,
  isDisabled,
  isErrorTotalSum,
  isErrorLastValues,
  onContactSupport,
}: VerificationErrorBannerProps) {
  if (isDisabled) {
    return (
      <ErrorMessageBox
        message="Too many failed attempts. Contact support for assistance."
        action={
          <Button
            data-intercom-target="SuperPay Bank Verification Status Failed"
            variant="neutral"
            size="small"
            onClick={() => {
              if (onContactSupport) {
                performNavigation(onContactSupport);
              }
              showIntercomMessenger();
            }}
          >
            Contact Support
          </Button>
        }
      />
    );
  }

  return isErrorTotalSum ? (
    <ErrorMessageBox
      message={
        <Stack space="xsmall">
          <Box>
            The amounts are incorrect. One entry should be the sum of the other
            two.
          </Box>
          <Box>
            Please look for transactions from{' '}
            <b>Super Dispatch mentioning AcctVerify.</b>
          </Box>
        </Stack>
      }
    />
  ) : isErrorLastValues ? (
    <ErrorMessageBox
      message={
        <Stack space="xsmall">
          <Box>This trio was already marked as incorrect.</Box>
          <Box>
            Please look for transactions from{' '}
            <b>Super Dispatch mentioning AcctVerify.</b>
          </Box>
        </Stack>
      }
    />
  ) : isErrorMoov ? (
    <ErrorMessageBox
      message={
        <Stack space="xsmall">
          <Box>
            Wrong deposit amounts. Only <b>ONE</b> attempt left.
          </Box>
          <Box>
            Please look for transactions from{' '}
            <b>Super Dispatch mentioning AcctVerify.</b>
          </Box>
        </Stack>
      }
    />
  ) : null;
}
