import { IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Column, Columns, Inline } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { useState } from 'react';
import { trackEvent } from 'shared/helpers/Analytics';
import { formatCurrency, formatPlural } from 'shared/helpers/IntlHelpers';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import { useExpeditedPayStatistics } from 'shared/modules/superpay/SuperPayAPI';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';
import { useFlag } from 'shared/settings/FeatureToggles';
import { SuperPayExpeditedPayStatisticsDTO } from '../SuperPayDTO';
import { ExpeditedPayBannerGradientBox } from './ExpeditedPayBannerGradientBox';
import { ExpeditedPayReminderDialogs } from './ExpeditedPayReminderDialogs';
import { useExpeditedPayBannerAccessTime } from './useExpeditedPayBannerAccessTime';

function ExpeditedPayBannerContent({
  data,
  source,
}: {
  data: SuperPayExpeditedPayStatisticsDTO;
  source: string;
}) {
  const [isOpenDialog, setOpenDialog] = useState(false);
  const hasValueToDisplay = Number(data.amount) > 99;
  const fasterValue = hasValueToDisplay
    ? formatCurrency(data.amount, { minimumFractionDigits: 0 })
    : 'Paid';
  const loadsValue = hasValueToDisplay
    ? `${data.loads_count} ${formatPlural(data.loads_count, 'load', 'loads')}`
    : 'loads';

  return (
    <ExpeditedPayBannerGradientBox aria-label="Expedited Pay Banner">
      <Columns align="center">
        <Column>
          <Inline
            verticalAlign="center"
            space="xsmall"
            horizontalAlign="center"
          >
            <Typography variant="body1">Get {fasterValue} faster!</Typography>
            <Typography>
              You have {loadsValue} available for Expedited Payment.
            </Typography>
            <Button
              variant="neutral"
              size="small"
              onClick={() => {
                openExternalURL(
                  '/tms/loads/{?stage,order_by,superpay_status,utm_medium}',
                  {
                    stage: 'delivered',
                    order_by: 'superpay_status',
                    superpay_status: 'Available for Expedited Payment',
                    utm_medium: 'Expedited Payment Banner',
                  },
                );
                trackEvent(
                  'Carrier Clicks Review Payment on Expedited Payments Banner',
                  {
                    utm_medium: source,
                    available_for_expedited_payment_count: data.loads_count,
                    overall_value_of_expedited_payments: formatCurrency(
                      data.amount,
                      { minimumFractionDigits: 0 },
                    ),
                  },
                );
              }}
            >
              Review Payments
            </Button>
          </Inline>
        </Column>
        <Column width="content">
          <IconButton
            aria-label="Close Expedited Pay Banner"
            size="small"
            onClick={() => {
              setOpenDialog(true);
            }}
          >
            <Close />
          </IconButton>
        </Column>
      </Columns>
      <ExpeditedPayReminderDialogs
        isOpen={isOpenDialog}
        onClose={() => {
          setOpenDialog(false);
        }}
      />
    </ExpeditedPayBannerGradientBox>
  );
}

export function ExpeditedPayBanner({ source }: { source: string }) {
  const isExpeditedPayReminderEnabled = useFlag(
    'payments_expedited_pay_reminder',
  );
  const { data: settings } = useCarrierSettings();
  const { getAccess } = useExpeditedPayBannerAccessTime();
  const isAccess = getAccess();
  const hasBanner =
    isExpeditedPayReminderEnabled &&
    isAccess &&
    settings?.superpay.show_expedited_pay_nudging;
  const { data } = useExpeditedPayStatistics(hasBanner);

  return hasBanner && data ? (
    <ExpeditedPayBannerContent data={data} source={source} />
  ) : null;
}
