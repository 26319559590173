import { Typography } from '@material-ui/core';
import { FormikPasswordField } from '@superdispatch/forms';
import { Inline, Stack, useResponsiveValue, useUID } from '@superdispatch/ui';
import { Alert, Button } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import { refreshAuthToken } from 'shared/auth/AuthToken';
import { LoginLayoutContent } from 'shared/auth/core/LoginLayoutContent';
import {
  AuthResponse,
  useAuthAPI,
  useResetPasswordCode,
} from 'shared/auth/data/AuthAPI';
import { confirmPasswordResetSchema } from 'shared/auth/data/AuthDTO';
import { trackLoginEvent } from 'shared/auth/data/LoginAnalytics';
import { ForgotPasswordPage } from 'shared/auth/ForgotPasswordPage';
import { useAppFormik } from 'shared/form/AppFormik';
import { useSearchParam } from 'shared/helpers/SearchParamsHelpers';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import { SplashScreen } from 'shared/layout/SplashScreen';
import { useGlobalFeatures } from 'shared/settings/CarrierSettingsAPI';
import { isFlagEnabled, useFlag } from 'shared/settings/FeatureToggles';
import { getBackURL } from 'shared/ui/BackButton';
import { PasswordValidationStepper } from 'shared/ui/PasswordValidationStepper';
import { validatePasswordStrength } from 'shared/utils/PasswordUtils';
import { yupString } from 'shared/utils/YupUtils';
import { ref } from 'yup';

const formSchema = confirmPasswordResetSchema.shape({
  password_confirm: yupString().oneOf(
    [ref('password')],
    'Passwords do not match',
  ),
});

export interface ResetPasswordPage {
  onOpenLogin: () => void;
}

export function ResetPasswordPage({ onOpenLogin }: ResetPasswordPage) {
  const uid = useUID();
  const navigate = useNavigate();
  const { confirmPasswordReset } = useAuthAPI();
  const [resetCode = ''] = useSearchParam('reset_code');
  const { error, isLoading } = useResetPasswordCode(resetCode);
  const features = useGlobalFeatures();
  const isMobile = useResponsiveValue(true, false);
  const isSuccessResetPasswordAvailable = useFlag(
    'success_reset_password_page',
  );

  const isChangePasswordMessageEnabled = isFlagEnabled(
    features.data ?? null,
    'save_new_password_to_generic_user_in_dispatcher',
  );

  const formik = useAppFormik({
    validationSchema: formSchema,
    onSubmit(values) {
      return confirmPasswordReset(resetCode, values.password);
    },
    onSubmitSuccess(response: AuthResponse) {
      if (
        (isMobile || !response.data.has_dispatcher_role) &&
        isSuccessResetPasswordAvailable
      ) {
        navigate(
          {
            pathname: `/reset-password-success`,
          },
          {
            state: {
              has_dispatcher_role: response.data.has_dispatcher_role,
              token: response.data.token,
            },
          },
        );
      } else {
        refreshAuthToken(response.data.token);
        openExternalURL(getBackURL(window.location.search, '/tms/loads'));
      }
      trackLoginEvent({ name: 'CTMS: Reset Password Success via Email' });
    },
    getErrorMessage({ message }) {
      return message || 'Error occurred, contact support.';
    },
  });

  const passwordstrength = useMemo(
    () => validatePasswordStrength(formik.values.password),
    [formik.values.password],
  );

  if (isLoading) return <SplashScreen />;

  if (error) {
    return (
      <ForgotPasswordPage
        onOpenLogin={onOpenLogin}
        isResetLinkExpiredPage={true}
      />
    );
  }

  return (
    <LoginLayoutContent>
      <FormikProvider value={formik}>
        <Form aria-labelledby={uid}>
          <Stack space="large">
            <Stack space="small">
              <Inline space="xsmall" verticalAlign="center">
                <Typography id={uid} variant="h2">
                  Reset Password
                </Typography>
              </Inline>

              {isChangePasswordMessageEnabled && (
                <Alert severity="caution">
                  Updating your password will automatically apply to both your
                  Dispatcher and Driver accounts if you use the same email
                </Alert>
              )}
            </Stack>

            <Stack space="small">
              <Stack space="xsmall">
                <FormikPasswordField
                  name="password"
                  label="New Password"
                  fullWidth={true}
                />

                <PasswordValidationStepper
                  passwordstrength={passwordstrength}
                  value={formik.values.password}
                />
              </Stack>

              <FormikPasswordField
                name="password_confirm"
                label="Re-enter Your New Password"
                fullWidth={true}
              />
            </Stack>

            <Button
              type="submit"
              size="large"
              fullWidth={true}
              pending={formik.isSubmitting}
              disabled={!formik.isValid || !formik.dirty}
              onClick={() => {
                trackLoginEvent({ name: 'CTMS: Clicked Reset Password' });
              }}
            >
              Reset Password
            </Button>
          </Stack>
        </Form>
      </FormikProvider>
    </LoginLayoutContent>
  );
}
