import { Color, Column, Columns, Inline, Stack } from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import styled from 'styled-components';
import { LoadForTripDTO } from '../data/TripsDTO';

const GutterRoot = styled.div`
  min-height: 44px;
  height: 100%;
  padding: 4px 0;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const GutterPoint = styled.div`
  width: 8px;
  height: 8px;
  display: block;
  border-radius: 50%;

  &[data-start='true'] {
    background: ${Color.Yellow300};
  }

  &[data-middle='true'] {
    margin-top: 4px;
    margin-bottom: 4px;
    border: 1px solid ${Color.Dark100};
  }

  &[data-end='true'] {
    background: ${Color.Green300};
  }
`;

const GutterLine = styled.div`
  flex: 1;
  width: 2px;
  background: repeating-linear-gradient(
    ${Color.Dark100},
    ${Color.Dark100} 3px,
    transparent 3px,
    transparent 5px
  );
`;

function Gutter() {
  return (
    <GutterRoot>
      <GutterPoint data-start="true" />
      <GutterLine />
      <GutterPoint data-end="true" />
    </GutterRoot>
  );
}

export interface PostingWaypointsProps {
  load: LoadForTripDTO;
}

export function TripLoadOrderWaypoints({ load }: PostingWaypointsProps) {
  if (!load.origin) {
    return null;
  }
  return (
    <Columns space="xxsmall">
      <Column width="content">{load.destination ? <Gutter /> : null}</Column>

      <Column width="fluid">
        <Stack space="xxsmall">
          <Inline>
            <TextBox
              noWrap={true}
              wrapOverflow={true}
              aria-label="Pickup address"
            >
              {load.origin}
            </TextBox>
          </Inline>

          <Inline>
            <TextBox
              noWrap={true}
              wrapOverflow={true}
              aria-label="Delivery address"
            >
              {load.destination}
            </TextBox>
          </Inline>
        </Stack>
      </Column>
    </Columns>
  );
}
