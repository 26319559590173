import { parseDate } from '@superdispatch/dates';
import { useMemo } from 'react';
import { useInsurance } from 'shared/modules/verified-carrier-application/data/CarrierProfileAPI';

export function useCargoInsuranceExpiring() {
  const { data: profileCargoInsurance } = useInsurance();

  return useMemo(() => {
    const currentInsurance = profileCargoInsurance?.data[0];
    const renewalInsurance = profileCargoInsurance?.data[1];

    const diffDate = parseDate(currentInsurance?.expiration_date).diffNow(
      'days',
    );

    return {
      isInsuranceExpiring: diffDate.isValid && diffDate.days <= 30,
      isRenewalInsuranceUploaded:
        profileCargoInsurance && profileCargoInsurance.data.length > 1,
      isRenewalInsuranceVerified: !!renewalInsurance?.is_verified,
    };
  }, [profileCargoInsurance]);
}
