import {
  DateTimeRange,
  NullableDateInput,
  parseDate,
  parseDateRange,
} from '@superdispatch/dates';
import { CUSTOMER_TYPES, VEHICLE_TYPES } from '@superdispatch/sdk';
import { round } from 'lodash-es';
import { DateTime } from 'luxon';
import { kmToMile } from 'shared/modules/loadboard/LoadboardUtils';
import { PricingInsightsDTO } from 'shared/modules/pricing-insights/PricingInsightsDTO';
import { SHIPPER_VERIFICATION_STATUSES } from 'shared/modules/shipper/ShipperProfileDTO';
import { joinStrings } from 'shared/utils/StringUtils';
import {
  yupArray,
  yupBoolean,
  yupDateString,
  yupEnum,
  yupFloat,
  yupNumber,
  yupObject,
  yupString,
} from 'shared/utils/YupUtils';
import { InferType, number, object, string } from 'yup';

export function shouldReviewShipperRequirements(load: PostingLoadDTO): boolean {
  return !!(
    load.carrier_access.is_ach_payment_required ||
    load.carrier_access.is_certificate_required ||
    load.shipper?.carrier_insurance_requirements?.length
  );
}

export function hasShipperRequirements(load: PostingLoadDTO): boolean {
  return (
    shouldReviewShipperRequirements(load) ||
    !!load.shipper?.carrier_requirements
  );
}

export type PostingShipperDTO = InferType<typeof postingShipperSchema>;
const postingShipperSchema = yupObject({
  guid: yupString(),
  name: yupString(),
  contact_name: yupString(),
  contact_email: yupString(),
  contact_phone: yupString(),
  in_business_since: yupString().nullable(),
  accepted_loads_count: yupNumber(),
  overall_moved_vehicles_count: yupNumber(),
  rating_details: yupObject({
    overall_rating: yupNumber().default(0),
    total_rating_count: yupNumber().default(0),
  })
    .nullable()
    .optional(),
  carrier_requirements: yupString().nullable(),
  carrier_certificate_of_insurance: yupEnum(
    ['CERTIFICATE_HOLDER', 'ADDITIONALLY_INSURED'],
    null,
  ),
  carrier_insurance_requirements: yupArray(
    yupObject({
      trailer_size: yupNumber().default(0),
      cargo_limit: yupNumber().default(0),
    }),
  ).nullable(),

  state: yupString().nullable(),
  zip: yupString().nullable(),
  city: yupString().nullable(),
  address: yupString().nullable(),
  verification_status: yupEnum(SHIPPER_VERIFICATION_STATUSES, null),
  external_notes: yupString().nullable(),
});

export function getShipperInBusinessSinceYear(inBusinessSince: string): string {
  const [year = ''] = inBusinessSince.split('.');

  return year;
}

export function displayShipperVehiclesCount(count: number) {
  if (count > 1_000_000) {
    return '1m+';
  }
  if (count > 500_000) {
    return '500k+';
  }
  if (count > 200_000) {
    return '200k+';
  }
  if (count > 50_000) {
    return '50k+';
  }
  if (count > 40_000) {
    return '40k-50k';
  }
  if (count > 30_000) {
    return '30k-40k';
  }
  if (count > 20_000) {
    return '20k-30k';
  }
  if (count > 10_000) {
    return '10k-20k';
  }
  if (count > 5_000) {
    return '5k-10k';
  }
  if (count > 3_000) {
    return '3k-5k';
  }
  if (count > 1_000) {
    return '1k-3k';
  }
  if (count > 800) {
    return '801-1k';
  }
  if (count > 500) {
    return '501-800';
  }
  if (count > 300) {
    return '301-500';
  }
  if (count > 100) {
    return '101-300';
  }
  if (count > 50) {
    return '51-100';
  }
  if (count > 0) {
    return '1-50';
  }

  return count;
}

const nullableStringSchema = string().nullable().default(null);

export type PostingStepDTO = InferType<typeof postingStepSchema>;
export const postingStepSchema = yupObject({
  venue: yupObject({
    city: nullableStringSchema,
    state: nullableStringSchema,
    zip: nullableStringSchema,
    business_type: yupEnum(CUSTOMER_TYPES, null),
    metro_area: yupString().nullable(),
  }),
  date_type: yupEnum(
    ['estimated', 'exact', 'not_earlier_than', 'not_later_than'],
    null,
  ),
  scheduled_at: yupDateString('JodaISO'),
  scheduled_ends_at: yupDateString('JodaISO'),
});

export function getBookingTimeRange(
  postingStep: PostingStepDTO,
): DateTimeRange {
  const today = DateTime.local();
  const notEarlierThanRange = parseDate(postingStep.scheduled_at, {
    format: 'JodaISO',
  }).plus({ days: 45 });

  switch (postingStep.date_type) {
    case 'not_later_than':
      return parseDateRange([today, postingStep.scheduled_at], {
        format: 'JodaISO',
      });
    case 'not_earlier_than':
      return parseDateRange([postingStep.scheduled_at, notEarlierThanRange], {
        format: 'JodaISO',
      });
    default:
      return parseDateRange(
        [
          postingStep.scheduled_at,
          postingStep.scheduled_ends_at || postingStep.scheduled_at,
        ],
        { format: 'JodaISO' },
      );
  }
}

export function isPostingStepBookable(
  input: NullableDateInput,
  address: PostingStepDTO,
): boolean {
  if (!input) {
    return true;
  }

  const date = parseDate(input, { format: 'JodaISO' });
  const [start, finish] = getBookingTimeRange(address);

  return !!(
    date.isValid &&
    start?.isValid &&
    finish?.isValid &&
    start.startOf('day') <= date &&
    date <= finish.endOf('day')
  );
}

export type PostingVehicleDTO = InferType<typeof postingVehicleSchema>;
export const postingVehicleSchema = yupObject({
  guid: yupString(),
  curb_weight: yupFloat(),
  curb_weight_unit: yupString(),
  is_inoperable: yupBoolean().required(),
  make: yupString(),
  model: yupString(),
  year: yupString(),
  type: yupEnum(VEHICLE_TYPES, null),
  height: yupFloat(),
  width: yupFloat(),
  length: yupFloat(),
  photos: yupArray(
    yupObject({
      id: yupNumber(),
      guid: yupString(),
      photo_url: yupString(),
      rendered_photo_url: yupString(),
      photo_type: yupEnum(['Sample', 'Delivery', 'Pickup'], null),
    }),
  ),
});

export type PostingTransportType = PostingLoadDTO['transport_type'];
export type PostingLoadDTO = InferType<typeof postingLoadSchema>;
export const postingLoadSchema = yupObject({
  id: number(),
  guid: yupString(),
  number: yupString(),
  price: yupNumber().required(),
  is_exclusive: yupBoolean(),
  is_search_along_route: yupBoolean().optional(),
  posting_guid: yupString().nullable(), // It could be nullable for old postings
  shipper: postingShipperSchema.nullable(),
  booking: yupObject({
    carrier_name: yupString(),
    created_at: yupDateString('JodaISO'),
  })
    .nullable()
    .default(null),
  pickup: postingStepSchema,
  delivery: postingStepSchema,
  distance_meters: yupNumber(),
  instructions: yupString().nullable(),
  posted_to_loadboard_at: yupDateString('JodaISO'),
  is_posted_to_private_loadboard: yupBoolean(),
  unposted_at: yupDateString('JodaISO'),
  price_per_km: yupNumber(),
  payment: yupObject({
    terms: yupString().lowercase().optional(),
    method: yupString().lowercase().optional(),
  }),
  vehicles: yupArray(postingVehicleSchema).defined(),
  status: yupEnum(['accepted', 'declined', 'canceled', 'pending', 'new']),
  transport_type: yupEnum(['OPEN', 'ENCLOSED', 'DRIVEAWAY']),
  carrier_access: object({
    can_book: yupBoolean(),
    can_request: yupBoolean(),
    is_approved_by_shipper: yupBoolean(),
    is_ach_payment_required: yupBoolean(),
    is_certificate_required: yupBoolean(),
  })
    .defined()
    // Saved Loads API may return null for `carrier_access` field.
    .transform(function transformAccess(value) {
      if (this.isType(value)) return value;

      return {
        can_book: false,
        can_request: false,
        is_approved_by_shipper: false,
        is_ach_payment_required: false,
        is_certificate_required: false,
      };
    }),
});

export function findVehicleSamplePhoto(vehicle: PostingVehicleDTO) {
  return vehicle.photos?.find((x) => x.photo_type === 'Sample');
}

export function hasVehicleSamplePhoto(vehicle: PostingVehicleDTO): boolean {
  return !!findVehicleSamplePhoto(vehicle);
}

export function formatVehicleTitle({ year, make, model }: PostingVehicleDTO) {
  return joinStrings(' ', year, make, model);
}

export function formatLoadDistance(distanceMeters: number) {
  return `${round(kmToMile(distanceMeters / 1000))} mi`;
}

export function mapPostingToPricingInsights(
  load: PostingLoadDTO,
): PricingInsightsDTO {
  return {
    origin: {
      zip: load.pickup.venue.zip,
      city: load.pickup.venue.city,
      state: load.pickup.venue.state,
    },
    destination: {
      zip: load.delivery.venue.zip,
      city: load.delivery.venue.city,
      state: load.delivery.venue.state,
    },

    vehicles: load.vehicles.map((vehicle) => ({
      type: vehicle.type,
      year: vehicle.year,
      make: vehicle.make,
      model: vehicle.model,
      is_inoperable: vehicle.is_inoperable,
    })),
  };
}
