import { useCallback, useEffect } from 'react';
import { Location, useLocation } from 'react-router-dom-v5-compat';
import { useCallbackRef } from 'shared/hooks/useCallbackRef';

const useOnLocationChange = (
  handleLocationChange: (location: Location, action?: string) => void,
) => {
  const location = useLocation();
  const changeCallbackRef = useCallbackRef(handleLocationChange);

  const unsubscribe = useCallback(() => {
    changeCallbackRef(location);
  }, [location, changeCallbackRef]);

  useEffect(() => {
    changeCallbackRef(location);
  }, [location, changeCallbackRef]);

  return unsubscribe;
};

export default useOnLocationChange;
