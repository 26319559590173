import { useMutation, useQueryClient } from 'react-query';
import { APIMutationOptions } from 'shared/api/APIMutation';
import {
  APIQueryOptions,
  APIQueryResult,
  useAPIQuery,
} from 'shared/api/APIQuery';
import { requestCarrierAPI } from 'shared/api/CarrierAPIClient';
import { useDispatcherProfile } from 'shared/modules/dispatcher/DispatcherAPI';
import { OnboardingStep, OnboardingStepName } from './OnboardingDTO';

export function useOnboardingDetails(
  options?: APIQueryOptions<OnboardingStep>,
): APIQueryResult<OnboardingStep> {
  const { data: dispatcher } = useDispatcherProfile();
  const guid = dispatcher?.guid || '';

  return useAPIQuery(
    ['onboarding', guid],
    () => {
      return requestCarrierAPI(
        `GET /internal/web/dispatchers/${guid}/onboarding/`,
      );
    },
    {
      ...options,
      enabled: !!guid && options?.enabled,
    },
  );
}

export interface SaveOnboardingProps {
  stepName: OnboardingStepName;
  status: 'completed' | 'skipped' | 'started';
  data?: Record<string, unknown>;
}

export function useSaveOnboardingStep(
  options?: APIMutationOptions<SaveOnboardingProps>,
) {
  const { data: dispatcher } = useDispatcherProfile();
  const queryClient = useQueryClient();
  const guid = dispatcher?.guid || '';

  return useMutation(
    ['onboarding-save-step', guid],
    ({ stepName, status, data }: SaveOnboardingProps) => {
      return requestCarrierAPI(
        `POST /internal/web/dispatchers/${guid}/onboarding/`,
        {
          json: {
            step_name: stepName,
            status,
            data,
          },
        },
      );
    },
    {
      ...options,
      onSuccess: (data, variables, context) => {
        void queryClient.invalidateQueries(['onboarding', guid]);

        void options?.onSuccess?.(data, variables, context);
      },
    },
  );
}
