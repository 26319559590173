import { Link, Typography } from '@material-ui/core';
import { FormikPasswordField, FormikTextField } from '@superdispatch/forms';
import { useValueObserver } from '@superdispatch/hooks';
import { Inline, Stack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import { APIResponse } from 'shared/api/CarrierAPIClient';
import { LoginErrorDialog } from 'shared/auth/core/LoginErrorDialog';
import { LoginLayoutContent } from 'shared/auth/core/LoginLayoutContent';
import { useVerification } from 'shared/auth/core/VerificationStorage';
import {
  isInactiveAccountError,
  isInvalidRoleError,
} from 'shared/auth/data/AuthAPI';
import { loginSchema } from 'shared/auth/data/AuthDTO';
import { trackLoginEvent } from 'shared/auth/data/LoginAnalytics';
import { useDriverAccount } from 'shared/auth/data/useDriverAccount';
import { useAppFormik } from 'shared/form/AppFormik';
import { showNewIntercomMessage } from 'shared/helpers/Intercom';
import { useDriverAccountAuthAPI } from './data/DriverAccountAPI';
import { DriverAuthDTO } from './data/DriverAccountDTO';

export interface LoginPageProps {
  initialEmail: string;
  onEmailChange: (email: string) => void;
}

export function UserAccountLoginPage({
  initialEmail,
  onEmailChange,
}: LoginPageProps) {
  const navigate = useNavigate();
  const { driverLogin } = useDriverAccountAuthAPI();
  const {
    saveVerificationEmail,
    saveVerificationPhoneNumber,
    saveVerificationCodeChannel,
  } = useVerification();
  const { saveDriverToken } = useDriverAccount();

  const formik = useAppFormik({
    enableReinitialize: false,
    validationSchema: loginSchema,
    initialValues: { email: initialEmail },
    onSubmit(values) {
      return driverLogin(values);
    },
    onSubmitSuccess(response: APIResponse<DriverAuthDTO>) {
      const { token } = response.data;
      saveDriverToken(token);

      if (response.data.is_mfa_enabled) {
        saveVerificationEmail(formik.values.email);
        saveVerificationPhoneNumber(response.data.phone_number || '');
        saveVerificationCodeChannel(response.data.mfa_channel || 'email');
        navigate('/driver-account/verify');
      } else {
        navigate('/driver-account');
      }
    },

    getErrorMessage(error) {
      return isInactiveAccountError(error) ? null : error.message;
    },

    onSubmitFailure(error) {
      trackLoginEvent({
        name: 'CTMS: Attempted to Login with Driver Email',
        isInvalidRole: isInvalidRoleError(error),
      });
    },
  });

  const { values } = formik;

  useValueObserver(values.email, () => {
    onEmailChange(values.email);
  });

  useEffect(() => {
    trackLoginEvent({ name: 'CTMS: Opened New Login Page' });
  }, []);

  return (
    <FormikProvider value={formik}>
      <LoginErrorDialog
        title="Account Deactivated"
        onClose={() => {
          formik.resetForm({ values });
        }}
        message={
          formik.status.type === 'rejected' &&
          isInactiveAccountError(formik.status.payload)
            ? formik.status.payload.message
            : undefined
        }
      />

      <LoginLayoutContent>
        <Form aria-label="login" onSubmit={formik.handleSubmit}>
          <Stack space="large">
            <Stack space="small">
              <Typography variant="h1" align="center">
                Driver Account
              </Typography>
              <FormikTextField name="email" label="Email" fullWidth={true} />
              <FormikPasswordField
                name="password"
                label="Password"
                fullWidth={true}
              />
            </Stack>

            <Button
              type="submit"
              size="large"
              fullWidth={true}
              pending={formik.isSubmitting}
            >
              Log In
            </Button>
            <Inline horizontalAlign="right">
              <Link
                type="button"
                component="button"
                align="center"
                color="primary"
                onClick={() => {
                  showNewIntercomMessage();
                  trackLoginEvent({ name: 'CTMS: Clicked Contact Support' });
                }}
              >
                Contact support
              </Link>
            </Inline>
          </Stack>
        </Form>
      </LoginLayoutContent>

      <Typography align="center" color="textSecondary">
        By signing in, you agree to Super Dispatch’s <br />
        <Link href="https://superdispatch.com/terms-of-service">
          Terms &amp; Conditions
        </Link>{' '}
        and{' '}
        <Link href="https://superdispatch.com/privacy-policy/">
          Privacy Policy
        </Link>
      </Typography>
    </FormikProvider>
  );
}
