import { trackEvent } from 'shared/helpers/Analytics';

export type TripsAnalyticsEvent =
  | {
      name:
        | 'CTMS: Opened React Trips Page'
        | 'Carrier Created Trip'
        | 'Carrier Failed to Create Trip';
    }
  | {
      name: 'Carrier Assigned to Driver';
      trip_guid: string;
    }
  | {
      name: 'Carrier Reassigned to Driver';
      trip_guid: string;
    }
  | {
      name: 'Carrier Unassigned from Driver';
      trip_guid: string;
    }
  | {
      name: 'Carrier Reordered Loads';
      trip_guid: string;
    }
  | {
      name: 'Carrier Added Load to Trip';
      trip_guid: string;
    }
  | {
      name: 'Carrier Removed Load from Trip';
      trip_guid: string;
    }
  | {
      name: 'Carrier Added Route to Trip';
      direction: 'destination' | 'origin';
    }
  | {
      name: 'Carrier Opened Map';
      trip_guid?: string;
    }
  | {
      name: 'Carrier Deleted Trip';
      trip_guid: string;
    }
  | {
      name: 'Carrier Archived Trip';
      trip_guid: string;
    }
  | {
      name: 'Carrier Removed Load';
      trip_guid: string;
    };

export function trackTripsEvent(event: TripsAnalyticsEvent) {
  const { name, ...props } = event;
  trackEvent(event.name, props as Record<string, unknown>);
}
