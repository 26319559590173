import { Card, CardContent, Typography } from '@material-ui/core';
import { AttachFile } from '@material-ui/icons';
import { Inline, Stack, useUID } from '@superdispatch/ui';
import { FileListItem } from '@superdispatch/ui-lab';
import { useEffect, useState } from 'react';
import { FileDropZone } from 'shared/form/FileDropZone';
import { getFileHash } from 'shared/utils/FileUtils';
import { TripAttachmentDTO, TripDTO } from '../data/TripsDTO';
import { TripDetailsAttachmentDeleteDialog } from './TripDetailsAttachmentDeleteDialog';
import { TripDetailsAttachmentListItem } from './TripDetailsAttachmentListItem';

interface FileState {
  file: File;
  url?: string;
}

interface TripDetailsAttachmentProps {
  trip: TripDTO | undefined;
}

export function TripDetailsAttachment({ trip }: TripDetailsAttachmentProps) {
  const uid = useUID();
  const [files, setFiles] = useState<FileState[]>([]);
  const [attachmentToDelete, setAttachmentToDelete] =
    useState<TripAttachmentDTO>();

  useEffect(() => {
    if (trip?.attachments?.length) {
      setFiles((prev) =>
        prev.filter(({ url }) =>
          trip.attachments?.some((attachment) => url === attachment.url),
        ),
      );
    }
  }, [trip?.attachments]);

  return (
    <Card aria-labelledby={uid}>
      <TripDetailsAttachmentDeleteDialog
        tripGUID={trip?.guid || ''}
        attachment={attachmentToDelete}
        onClose={() => {
          setAttachmentToDelete(undefined);
        }}
      />

      <CardContent aria-label="Attachment Card">
        <Stack space="small">
          <Inline verticalAlign="center" space="xsmall">
            <AttachFile />
            <Typography variant="h3" id={uid}>
              Attachments
            </Typography>
          </Inline>

          <Stack space="small">
            <FileDropZone
              onDropAccepted={(acceptedFiles) => {
                setFiles((prev) => [
                  ...prev,
                  ...acceptedFiles.map((file) => ({ file })),
                ]);
              }}
            />

            <Stack space="xsmall">
              {files.map(({ file }) => (
                <TripDetailsAttachmentListItem
                  file={file}
                  tripGUID={trip?.guid || ''}
                  key={getFileHash(file)}
                  onDelete={() => {
                    setFiles((prev) =>
                      prev.filter((item) => item.file !== file),
                    );
                  }}
                  canDelete={true}
                />
              ))}

              {trip?.attachments?.map((attachment) => (
                <FileListItem
                  key={attachment.guid}
                  name={attachment.name}
                  url={attachment.url}
                  onDelete={() => {
                    setAttachmentToDelete(attachment);
                  }}
                />
              ))}
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}
